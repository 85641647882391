import { Box, Typography } from "@mui/material";

import { HStack } from "Components/LabIZO/Stackizo";
import { ColorX } from "static";

export const Project = (
  <HStack width="70px">
    <Box width="25px" height="25px" className="syva_logo" style={{ backgroundColor: ColorX.GetColorCSS("Secondary"), paddingRight: "10px" }}></Box>
    <Typography style={{ color: ColorX.GetColorCSS("Secondary") }}>SYVA</Typography>
  </HStack>
);
export const hasContainer = true;
export const serverCheck = true;
export const loginSys = true;

export const IZOTheme = {
  background: ColorX.GetColorCSS("Secondary"),
  foreground: ColorX.GetColorCSS("Primary"),
};

export const NavbarDis = {
  src: `${process.env.PUBLIC_URL}/Images/Logo_Elain_No.png`,
  style: {
    width: 70,
    marginX: 3,
    right: 180,
    top: -20,
    opacity: 0.2,
  },
};

export const StartDate = process.env.REACT_APP_STARTDATE || "2022-01-01";

export interface IMultiENVConf {
  production: string | undefined;
  test?: string | undefined;
  development: string;
}

export const _domain: IMultiENVConf = {
  production: process.env.REACT_APP_DOMAIN,
  development: "http://localhost:7777",
};

export const DOMAIN = _domain[process.env.NODE_ENV];

export const Chatbot: { user: object; interval: number; version: string; channel: string; domain: IMultiENVConf } = {
  user: {
    _id: 2,
    name: "Elain",
    avatar: "https://placeimg.com/140/140/any",
  },
  interval: 3000,
  version: "1.0.0",
  channel: "Talk2VA",
  domain: {
    production: process.env.REACT_APP_CHATBOTDOMAIN,
    development: "http://localhost:5034",
  },
};

export const ChatDOMAIN = Chatbot.domain[process.env.NODE_ENV];

export const Global = {};

//aws
export const aws_domain: IMultiENVConf = {
  development: "https://mobi-production.my.connect.aws/ccp-v2",
  production: process.env.REACT_APP_AWS_DOMAIN,
};

export const aws_login: IMultiENVConf = {
  development: "https://mobi-production.my.connect.aws/ccp-v2",
  production: process.env.REACT_APP_AWS_LOGIN,
};

export const aws_region: IMultiENVConf = {
  development: "ap-southeast-1",
  production: process.env.REACT_APP_AWS_REGION,
};
export const CCPDomain = aws_domain[process.env.NODE_ENV];
export const CCPLoginUrl = aws_login[process.env.NODE_ENV];
export const AWSRegion = aws_region[process.env.NODE_ENV];

// QA
export const QA_DOMAIN = process.env.NODE_ENV === "production" ? "https://robot.mobinology.com:8151" : "http://localhost:5002";
