const Request = {
  DBInfo: "/Tables/Promotion/Info",
  List: "/Tables/Promotion/List",
  Add: "/Tables/Promotion/Add",
  Delete: "/Tables/Promotion/Delete",
  Edit: "/Tables/Promotion/Edit",
  Export: "/Tables/Promotion/Export",
  Import: "/Tables/Promotion/Import",
  DeleteBulk: "/Tables/Promotion/DeleteBulk",
};

let exports = {
  Request,
};

export default exports;
