import { Paper, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Authority, ColorX } from "static";
import _ from "lodash";
import { useMemo, useState } from "react";
import { ChatTab, tabs } from "./tabs";
import { HStack, VStack } from "Components";
import { Spacer } from "Components/LabIZO/Stackizo";
import { CusAuth } from "interfaces";
import { DetailsPanel } from "./components/DetailsPanel/DetailsPanel";
import { ITalk2VAAddOns } from "./interfaces/combined-talk2va-addons.interface";
import { StyledPageHStack } from "./components/styled-components/StyledPageHStack";
import { StyledChatInterface } from "./components/styled-components/StyledChatInterface";
import SuggestedIntent from "./components/DetailsPanel/SuggestedIntent/SuggestedIntent";

const Talk2VA: React.FC<{}> = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [addOns, setAddOns] = useState<ITalk2VAAddOns>({ project: ["All"] });

  const authorisedTabs = useMemo(() => tabs.filter(({ reqAuth, reqLevel, reqFunc }: ChatTab) => reqAuth === "None" || Authority.IsAccessibleQ(reqAuth, reqLevel, reqFunc)), []);

  const themeColor = ColorX.GetColorCSS("Primary");

  const renderTabPanels = (tabs: ChatTab[]) => {
    const preLoad = false;
    return tabs.map((tab, i) => {
      if (!preLoad && selectedTab !== i) return <Box key={i} />;

      const { settingComponent, detailsComponentOveride, render, reqAuth, qaMode, allowSuggestedIntent } = tab;
      if (!detailsComponentOveride && !qaMode) throw new Error("QAMode must be specified if tab does not have detailsComponentOveride");

      return (
        <StyledPageHStack tabs={tabs} key={i} gap={1}>
          {settingComponent && <Paper sx={{ width: "100%", maxWidth: "400px", height: "100%", p: 2, display: "flex" }}>{settingComponent(addOns, setAddOns)}</Paper>}

          <StyledChatInterface>
            <VStack key={i} hidden={selectedTab !== i} sx={{ width: "100%", height: "100%" }}>
              {typeof render === "function" ? render(addOns, setAddOns) : render}
            </VStack>
          </StyledChatInterface>

          <VStack key={i} hidden={selectedTab !== i} sx={{ width: "100%", height: "100%", maxWidth: detailsComponentOveride ? "500px" : "600px" }}>
            {detailsComponentOveride ? (
              detailsComponentOveride(addOns, setAddOns)
            ) : (
              <DetailsPanel allowSuggestedIntent={allowSuggestedIntent} qaMode={qaMode!} addOns={addOns} reqAuth={reqAuth} reqFunc={CusAuth.Details} />
            )}
          </VStack>
        </StyledPageHStack>
      );
    });
  };

  const renderTabButtons = (tabs: ChatTab[]) => {
    let buttonLabel: JSX.Element;
    return tabs.map((tab: ChatTab, i) => {
      const { label, icon, iconPos, noTransform, spacing, alignment, disabled, minHeight, minWidth } = tab;

      if (noTransform) {
        buttonLabel = <Typography style={{ height: "100%", textTransform: "none" }}>{label}</Typography>;
      }
      switch (iconPos) {
        case "bottom":
          buttonLabel = (
            <VStack gap={spacing || 5}>
              {buttonLabel}
              {icon}
            </VStack>
          );
          break;
        case "left":
          buttonLabel = (
            <HStack gap={spacing || 5}>
              {alignment === "right" && <Spacer />}
              {icon}
              {label}
              {alignment === "left" && <Spacer />}
            </HStack>
          );
          break;
        case "right":
          buttonLabel = (
            <HStack gap={spacing || 5}>
              {alignment === "right" && <Spacer />}
              {label}
              {icon}
              {alignment === "left" && <Spacer />}
            </HStack>
          );
          break;
        default:
          break;
      }
      return (
        <Tab
          key={i} //
          label={!iconPos || iconPos === "top" ? label : buttonLabel}
          icon={iconPos === "top" ? icon : undefined}
          disabled={disabled}
          style={{ color: ColorX.GetColorCSS("Primary"), minHeight: minHeight || "100%", minWidth: minWidth || 200 }}
        />
      );
    });
  };

  const onChangeTab = (_e: any, tab: number) => {
    setSelectedTab(tab);
    setAddOns({ project: ["All"] });
  };

  return (
    <VStack className="TestChat" width="calc(100vw - 40px)" sx={{ background: "#f0f8ff", height: "100%" }}>
      <VStack className={"centre"} style={{ width: "100%", minHeight: "100%", height: "100%", justifyContent: "flex-start", gap: 0 }}>
        {authorisedTabs.length > 1 && (
          <Paper style={{ width: "100%", zIndex: 3 }}>
            <Tabs
              TabIndicatorProps={{ style: { background: ColorX.GetColorCSS("Primary") } }}
              value={selectedTab}
              onChange={onChangeTab}
              sx={{ backgroundColor: ColorX.GetColorCSS("Secondary"), color: themeColor, height: "50px" }}
              variant="scrollable"
              scrollButtons={"auto"}
              allowScrollButtonsMobile={true}
            >
              {renderTabButtons(authorisedTabs)}
            </Tabs>
          </Paper>
        )}
        {renderTabPanels(authorisedTabs)}
      </VStack>
    </VStack>
  );
};

export default Talk2VA;
