import { ConditionOperator, FieldType, Filterable } from "Components/LabIZO/Tablizo";
import { Accessor } from "static";

export const FILTERABLES: Filterable[] = [
  {
    field: "alias",
    label: "Knowledge Group Name",
    type: FieldType.text,
  },
  {
    field: "chosenDocs",
    label: "Chosen Documents",
    type: FieldType.custom,
    CustomFilterOperator: {
      contains: ConditionOperator.contains,
    },
    CustomCondition: (condition) => {
      return {
        $custom: {
          field: condition.field,
          operator: "contains",
          value: condition.value,
        },
      };
    },
    CustomFilter: (baseData: any, value: string) => {
      let filteredData = baseData;
      let keyOfDocs = [`docs`];
      filteredData = filteredData.filter((_d: any) => {
        for (var key of keyOfDocs) {
          const dataValue = Accessor.Get(_d, key);
          // console.log(dataValue);
          if (Array.isArray(dataValue)) {
            for (var obj of dataValue) {
              const alias = Accessor.Get(obj, "alias");
              if (alias && new RegExp(value).test(alias)) {
                return true;
              }
            }
          }
          return false;
        }
      });
      return filteredData;
    },
  },
  // TODO
  {
    field: "enabledDocs",
    label: "Enabled Documents",
    type: FieldType.custom,
    CustomFilterOperator: {
      contains: ConditionOperator.contains,
    },
    CustomCondition: (condition) => {
      return {
        $custom: {
          field: condition.field,
          operator: "contains",
          value: condition.value,
        },
      };
    },
    CustomFilter: (baseData: any, value: string) => {
      let filteredData = baseData;
      let keyOfDocs = [`docs`];
      filteredData = filteredData.filter((_d: any) => {
        for (var key of keyOfDocs) {
          let dataValue = Accessor.Get(_d, key);
          // console.log(dataValue);
          if (Array.isArray(dataValue)) {
            dataValue = dataValue.filter((item: { enabled: boolean }) => item?.enabled === true);
            for (var obj of dataValue) {
              const alias = Accessor.Get(obj, "alias");
              if (alias && new RegExp(value).test(alias)) {
                return true;
              }
            }
          }
          return false;
        }
      });
      return filteredData;
    },
  },
];
