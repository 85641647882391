import { Button, Stack, styled, Typography } from "@mui/material";
import { AvailLANG } from "interfaces";
import { useState } from "react";
import _ from "lodash";
import { ColorX, store } from "static";
import { ExampleGenerationByLang, QuestionDetails } from "./ExampleGenerationByLang";

interface ExampleGenerationProps {
  intent: Record<AvailLANG, { examples: { text: string }[] }>;
  answers: Record<AvailLANG, string[]>;
  existingGenerations?: Record<AvailLANG, string[]>;
  onSubmit: (generatedExamples: Record<AvailLANG, string[]>) => void;
}

const StyledContainer = styled(Stack)({
  width: "80vw",
  maxWidth: "1050px",
  flexDirection: "column",
});

const StyledButtonContainer = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "2rem",
  gap: "16px",
});

const StyledButton = styled(Button)({
  background: ColorX.GetColorCSS("Primary"),
  color: "white",
  "&:hover": {
    background: ColorX.GetColorCSS("Primary", 0.8),
  },
});

export const ExampleGeneration: React.FC<ExampleGenerationProps> = ({ intent, answers, existingGenerations, onSubmit }) => {
  const unavailableText = "Sorry, question generation is not available at the moment.";

  const [generatedQuestions, setGeneratedQuestions] = useState<Record<AvailLANG, string[]>>({
    EN: [],
    TC: [],
    SC: [],
    ...existingGenerations,
  });
  const [showError, setShowError] = useState(false);

  const handleSubmit = () => {
    onSubmit(generatedQuestions);
  };

  const updateGeneratedQuestions = (lang: AvailLANG, add: boolean, newQuestions: QuestionDetails) => {
    setGeneratedQuestions((state) => {
      return {
        ...state,
        [lang]: add ? _.uniq([...state[lang], newQuestions.question]) : state[lang].filter((q) => q !== newQuestions.question),
      };
    });
  };

  const defaultLang = (store.sysInfo.Language.default ?? "EN") as AvailLANG;
  return (
    <StyledContainer>
      {showError ? (
        <Typography sx={{ fontWeight: "bold" }}>{unavailableText}</Typography>
      ) : (
        Object.values(AvailLANG).map((lang) => (
          <ExampleGenerationByLang
            key={lang}
            lang={lang}
            question={intent?.[lang]?.examples[0].text ?? intent[defaultLang]?.examples[0].text}
            answer={answers?.[lang]?.[0]}
            existingGenerations={generatedQuestions[lang]}
            setGeneratedQuestions={updateGeneratedQuestions}
            setShowError={setShowError}
          />
        ))
      )}
      <StyledButtonContainer>
        <StyledButton onClick={handleSubmit}>Next</StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};
