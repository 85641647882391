import { FormizoSchema } from "Components/LabIZO/Formizo";

export const schema: FormizoSchema[] = [
  {
    label: "Username",
    name: "auth.user",
    format: "text",
  },
  {
    label: "Password",
    name: "auth.pass",
    format: "password",
  },
  {
    label: "Host",
    name: "host",
    format: "text",
  },
  {
    label: "Port",
    name: "port",
    format: "number",
  },
  {
    label: "Sender",
    name: "sender",
    format: "text",
  },
  {
    label: "Website Manager Receiver",
    name: "receiver",
    format: "text",
  },
  {
    label: "Website Manager Edit URL",
    name: "editPath",
    format: "text",
  },
  {
    label: "Forgot Password Login URL",
    name: "loginURL",
    format: "text",
  },
];
