import _ from "lodash";

import { ZMath } from "static";

class AnsLib {
  static Doc2Chat(doc, lang = "EN") {
    //Text
    let replace = "@";
    let rtn = {};
    if (doc.text) {
      let textInDB = doc.text[lang] || doc.text;
      textInDB = textInDB[ZMath.RandomInt(textInDB.length)];
      textInDB = textInDB && textInDB.replace(escape, replace);
      rtn.text = textInDB;
    }

    //Text for promotional message
    if (doc[lang]) {
      rtn.text = doc[lang];
    }

    //Image
    if (doc.image) {
      let imgInDB = doc.image[lang] || doc.image;
      rtn.image = imgInDB;
    }

    //Video
    if (doc.video) {
      let videoInDB = doc.video;
      if (videoInDB) {
        let src = (videoInDB.src && videoInDB.src[lang]) || videoInDB.src;
        let poster = (videoInDB.poster && videoInDB.poster[lang]) || videoInDB.poster;
        rtn.video = {
          src: src,
          poster: poster,
        };
      }
    }

    //Table
    if (doc.table) {
      let tableInDB = doc.table;
      rtn.table = tableInDB;
    }

    //Buttons
    if (doc.buttons) {
      let btnInDB = doc.buttons;
      if (btnInDB) {
        rtn.buttons = [];
        _.map(btnInDB, (o, i) => {
          let title = o.title[lang] || o.title;
          title = title && title.replace(escape, replace);

          let payload = o.payload[lang] || o.payload;

          rtn.buttons.push({
            title: title,
            payload: payload,
            type: o.type,
          });
        });
      }
    }

    //ImageButtons
    if (doc.imgbuttons) {
      let imgbtnInDB = doc.imgbuttons;
      rtn.imgbuttons = [];
      _.map(imgbtnInDB, (o, i) => {
        let image = (o.image && o.image[lang]) || o.image;
        let title = o.title[lang] || o.title;
        title = title = typeof title === "string" ? title.replace(escape, replace) : title;

        let payload = (o.payload && o.payload[lang]) || o.payload;

        rtn.imgbuttons.push({
          image: image,
          title: title,
          payload: payload,
          type: o.type,
        });
      });
    }

    //Templates
    if (doc.templates) {
      let tpInDB = doc.templates;
      let templates = [];
      _.map(tpInDB, (o, i) => {
        let tmp = {};
        let otextInDB = o.text && (o.text[lang] || o.text);
        let obtnsInDB = o.buttons || [];

        let oimgInDB = o.image && (o.image[lang] || o.image);
        let ovideoInDB = o.video && (o.video[lang] || o.video);
        let otitleInDB = o.title && (o.title[lang] || o.title);
        let osubtitleInDB = o.subtitle && (o.subtitle[lang] || o.subtitle);

        tmp.text = otextInDB;
        tmp.title = otitleInDB;
        tmp.subtitle = osubtitleInDB;
        tmp.image = oimgInDB;
        tmp.video = ovideoInDB;

        tmp.buttons = [];

        _.map(obtnsInDB, (v, x) => {
          let active = v.active;
          if (v.active === undefined || active === "true") {
            let title = v.title[lang] || v.title;
            title = title && title.replace(escape, replace);

            let payload = v.payload[lang] || v.payload;

            tmp.buttons.push({
              title: title,
              payload: payload,
              type: v.type,
              stype: v.stype,
            });
          }
        });
        templates.push(tmp);
      });
      rtn.templates = templates;
    }

    //Quick Replies
    if (doc.quickReplies) {
      let qrInDB = doc.quickReplies;
      rtn.quickReplies = [];
      _.map(qrInDB, (o, i) => {
        try {
          let title = (o?.title && (o?.title[lang] || (_.isString(o.title) && o.title))) || "";
          if (title) {
            title = (title && title.replace(escape, replace)) || "";
          }

          let payload = "";
          if (o?.payload && _.isObject(o?.payload) && o?.payload[lang]) payload = o.payload[lang];
          else if (o?.payload) payload = o.payload;

          rtn.quickReplies.push({
            title: title,
            payload: payload,
            type: o.type,
          });
        } catch (e) {
          console.log(e);
        }
      });
    }
    console.log("rtn", rtn);

    return {
      msg: rtn,
    };
  }
}

export default AnsLib;
