import { Box } from "@mui/material";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import { StyledButton } from "Components/LabIZO/Stylizo";
import { Accessor, ColorX, ErrorX, store } from "static";
import _ from "lodash";
import { observer } from "mobx-react";
import PropsType from "prop-types";
import React, { Component } from "react";
import "./Formizo.css";
import Validation from "./Validation";
import FItem from "./_gears/FItem";

/**
 * Formizo - Generic form generator
 * [Props]{@tutorial Formizo}
 * @see [schema]{@tutorial Formizo-schema}
 * @augments {Component<Props, State>}
 * @property {{
 *  textfield: { ... }
 * }} theme
 */
class Formizo extends Component {
  static propTypes = {
    //basic
    width: PropsType.oneOfType([PropsType.string, PropsType.number]),
    height: PropsType.oneOfType([PropsType.string, PropsType.number]),
    overflow: PropsType.string,
    formID: PropsType.string,

    customStyles: PropsType.object,

    //schema
    schema: PropsType.oneOfType([PropsType.array.isRequired, PropsType.func.isRequired]),

    //listeners
    onMounted: PropsType.func,
    onSubmit: PropsType.func,
    onCancel: PropsType.func,
    onClear: PropsType.func,
    onRevert: PropsType.func,
    onInvalid: PropsType.func,
    onInlineSubmit: PropsType.func,
    onInlineRevert: PropsType.func,
    onChange: PropsType.func,

    //disability
    enableOnChangeValidation: PropsType.bool,
    enableInlineSubmit: PropsType.bool,
    enableOnBlurInlineSubmit: PropsType.bool,
    enableOnBlurAutoSubmit: PropsType.bool,
    errorsShowOnHelperText: PropsType.bool,
    readOnly: PropsType.bool,

    //access
    auth: PropsType.object,
    level: PropsType.number,

    //data
    defaultValue: PropsType.object,
    addOns: PropsType.object,

    //bottom buttons
    buttons: PropsType.array,

    //style
    buttonAlign: PropsType.string,
    buttonPadding: PropsType.oneOfType([PropsType.number, PropsType.string]),
    buttonWidth: PropsType.oneOfType([PropsType.number, PropsType.string]),
    fieldStyle: PropsType.oneOf(["grid", "standard", "filled", "outlined"]),

    //grid specific
    labelXS: PropsType.number,
    labelPaddingX: PropsType.number,
    labelJustify: PropsType.string,
    fieldXS: PropsType.number,
    fieldPaddingX: PropsType.number,
    separator: PropsType.string,

    //input style
    fieldSize: PropsType.string,
    theme: PropsType.object,
    disabled: PropsType.object,
  };

  static defaultProps = {
    width: "100%",
    height: "100%",
    formID: "",
    schema: [],

    onMounted: undefined,
    onSubmit: () => {},
    onCancel: () => {},
    onClear: () => {},
    onRevert: () => {},
    onInvalid: () => {},
    onInlineSubmit: () => {},
    onChange: () => {},

    enableOnChangeValidation: false,
    enableInlineSubmit: false,
    enableOnBlurAutoSubmit: false,
    errorsShowOnHelperText: true,
    readOnly: false,

    auth: {},
    level: 999,

    defaultValue: {},
    addOns: {},

    buttons: ["Cancel", "Submit"],

    buttonAlign: "center",
    buttonPadding: 2,
    buttonWidth: 100,
    fieldStyle: "grid",

    labelXS: 3,
    labelPaddingX: 1,
    labelJustify: "flex-end",
    fieldXS: 6,
    fieldPaddingX: 1,
    separator: "1px solid rgba(125, 125, 125, 0.2)",

    fieldSize: "medium",
    theme: {},
  };

  constructor() {
    super();
    this.state = {
      formValue: {},
      formError: {},
      formHidden: {},
      fLang: { display: "Default ", value: store.sysInfo.Language.default },
    };
  }

  componentDidMount() {
    this._setAllStates(() => {
      this._FillForm(this.props.defaultValue);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Formizo.defaultProps))) {
      if (
        typeof this.props.schema !== "function" &&
        !Accessor.IsIdentical(
          prevProps.schema.map((s) => (({ defaultValue, ...otherProperties }) => ({ ...otherProperties }))(s)),
          this.props.schema.map((s) => (({ defaultValue, ...otherProperties }) => ({ ...otherProperties }))(s))
        )
      ) {
        this._ClearError();
        this._ClearHidden();
        this._onClear();
      }
      this._setAllStates();

      if (!Accessor.IsIdentical(prevProps.defaultValue, this.props.defaultValue)) {
        this._FillForm(this.props.defaultValue);
      }
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      () => {
        if (this.props.onMounted) {
          this.props.onMounted({
            Submit: this._onSubmit,
            InlineSubmit: this._onInlineSubmit,
            Clear: this._onClear,
            Cancel: this._onCancel,
            Revert: this._onRevert,
            Fill: this._FillForm,
            SetValue: this._onValueChange,
            GetValue: this._GetValue,
          });
        }
        if (callback) callback();
      }
    );
  };

  _FillForm = (data) => {
    this.setState(
      {
        formValue: _.cloneDeep(data),
      },
      () => {
        this.ValidateForm();
      }
    );
  };

  _GetValue = (accessor) => {
    let { formValue } = this.state;
    return Accessor.Get(formValue, accessor);
  };

  _SetError = (accessor, message) => {
    let { formError } = this.state;
    if (formError) {
      Accessor.Set(formError, accessor, message);
      this.setState({ formError });
    }
  };

  _GetError = (accessor) => {
    let { formError } = this.state;
    return Accessor.Get(formError, accessor);
  };

  _ClearError = () => {
    this.setState({
      formError: {},
    });
  };

  _onValueChange = (name, value, criteria, fetchData = true) => {
    let { formValue, onChange } = this.state;
    this._Validate(name, value, criteria);
    if (formValue) {
      let names = name.split(".");
      if (!isNaN(names[names.length - 1])) {
        let parentName = names.slice(0, -1).join(".");
        if (Accessor.Get(formValue, parentName) === undefined) {
          Accessor.Set(formValue, parentName, []);
        }
      }

      if (value instanceof File) {
        Accessor.Set(formValue, name, value);
      } else {
        Accessor.Set(formValue, name, _.cloneDeep(value));
      }

      this.setState({ formValue }, () => {
        if (onChange) {
          onChange(formValue, name, value, fetchData);
        }
      });
    }
  };

  _setHiddenValue = (name, value) => {
    let { formHidden } = this.state;
    if (formHidden) {
      Accessor.Set(formHidden, name, value);
      this.setState({ formHidden });
    }
  };

  _ClearHidden = () => {
    this.setState({
      formHidden: {},
    });
  };

  _onBlurAutoSubmit = () => {
    let { enableOnBlurAutoSubmit } = this.state;
    if (enableOnBlurAutoSubmit) {
      console.log("_onBlurAutoSubmit");
      this._onSubmit();
    }
  };

  _onBlurInlineSubmit = (field, crteria) => {
    let { enableOnBlurInlineSubmit } = this.state;
    if (enableOnBlurInlineSubmit) {
      console.log("_onBlurInlineSubmit");
      this._onInlineSubmit(field, crteria);
    }
  };

  _onSubmit = (e) => {
    console.log("_onSubmit");
    if (e && e.preventDefault) e.preventDefault();
    let { onSubmit, formValue, formHidden, onInvalid, formError } = this.state;

    if (!this.ValidateForm()) {
      console.log("Form invalid", formError);
      ErrorX.HandleError({ ...formError, message: "Form Invalid, Please check the form" });
      if (onInvalid) {
        onInvalid(formValue, formError);
      }
      return;
    }

    if (onSubmit) {
      onSubmit(_.merge(formValue, formHidden));
    }
    return false;
  };

  _onInlineSubmit = (field, criteria) => {
    console.log("_onInlineSubmit");
    let { onInlineSubmit, formValue, formHidden } = this.state;
    let value = Accessor.Get(formValue, field);

    if (!this._Validate(field, value, criteria)) {
      return;
    }

    let fprops = {
      [field]: value,
    };
    let props = _.merge(fprops, formHidden);

    if (onInlineSubmit) {
      onInlineSubmit(field, value, props);
    }
    return false;
  };

  _onCancel = () => {
    console.log("_onCancel");
    let { onCancel } = this.state;
    if (onCancel) {
      onCancel();
    }
  };

  _onClear = () => {
    console.log("_onClear");

    this.setState({
      formValue: {},
    });
    let { onClear } = this.state;
    if (onClear) {
      onClear();
    }
  };

  _onRevert = () => {
    console.log("_onRevert");
    let { onRevert } = this.state;
    let { defaultValue } = this.props;
    this._FillForm(defaultValue);
    if (onRevert) {
      onRevert();
    }
  };

  _onInlineRevert = (field) => {
    console.log("_onInlineRevert");
    let { onInlineRevert, defaultValue, formValue } = this.state;
    let dvalue = Accessor.Get(_.clone(defaultValue), field);
    Accessor.Set(formValue, field, dvalue);

    this.setState(
      {
        formValue: _.clone(formValue),
      },
      () => {
        if (onInlineRevert) {
          onInlineRevert(field);
        }
      }
    );
  };

  getSchema = () => {
    let { schema, formValue, addOns } = this.props;
    const { fLang } = this.state;
    if (_.isFunction(schema)) {
      return schema(formValue, addOns, fLang);
    }
    return schema;
  };

  getInnerSchema = (cschema) => {
    let { formValue, addOns } = this.props;
    if (_.isFunction(cschema)) {
      return cschema(formValue, addOns);
    }
    return cschema;
  };

  ValidateForm = () => {
    let { formError } = this.state;
    if (Accessor.isDeepEmpty(formError)) {
      this._ClearError();
      return true;
    }
    return false;
  };

  _Validate = (name, value, criteria = []) => {
    let { formError } = this.state;
    if (_.isEmpty(criteria)) return true;

    let error = "";
    _.map(criteria, (o, i) => {
      if (!Validation.Rules[o](value)) {
        if (_.isEmpty(error)) {
          error = Validation.ErrorMsg[o];
        }
      }
    });

    if (_.isEmpty(error)) {
      Accessor.Delete(formError, name);
    } else {
      Accessor.Set(formError, name, error);
    }
    this.setState({
      formError,
    });

    return _.isEmpty(error);
  };

  renderSchema() {
    let { formValue, formError } = this.state;
    let schema = this.getSchema();
    return _.map(schema, (o, i) => {
      let innerSchema = this.getInnerSchema(o);
      if (_.isArray(innerSchema)) {
        return _.map(innerSchema, (v, w) => {
          return (
            <FItem
              key={"inner_" + i + "_" + w}
              ischema={v}
              preAccessor=""
              _onValueChange={this._onValueChange}
              _onBlurInlineSubmit={this._onBlurInlineSubmit}
              _onInlineSubmit={this._onInlineSubmit}
              _onInlineRevert={this._onInlineRevert}
              _setHiddenValue={this._setHiddenValue}
              _Validate={this._Validate}
              formValue={formValue}
              formError={formError}
              {...this.props}
            />
          );
        });
      }
      return (
        <FItem
          key={i}
          ischema={innerSchema}
          preAccessor=""
          _onValueChange={this._onValueChange}
          _onBlurInlineSubmit={this._onBlurInlineSubmit}
          _onInlineSubmit={this._onInlineSubmit}
          _onInlineRevert={this._onInlineRevert}
          _setHiddenValue={this._setHiddenValue}
          _Validate={this._Validate}
          formValue={formValue}
          formError={formError}
          {...this.props}
        />
      );
    });
  }

  renderButtons() {
    let { buttons, buttonWidth } = this.state;
    const { readOnly } = this.props;
    let buttonsJSX = {
      OK: (
        <StyledButton
          id="formizo-ok"
          className={"formizo-h-m"}
          addkey={0}
          theme={{
            color: "grey",
            background: "white",
            boxShadow: ColorX.GetBoxShadowCSS("grey"),
            width: buttonWidth,
          }}
          onClick={this._onCancel}
        >
          <i className="fas fa-check" />
          <div className="formizo-h-m">OK</div>
        </StyledButton>
      ),
      Submit: (
        <StyledButton
          id="formizo-submit"
          className={"formizo-h-m"}
          key={1}
          theme={{
            color: "green",
            background: "white",
            boxShadow: ColorX.GetBoxShadowCSS("grey"),
            width: buttonWidth,
          }}
          onClick={this._onSubmit}
        >
          <i className="fas fa-paper-plane" />
          <div className="formizo-h-m">Submit</div>
        </StyledButton>
      ),
      Cancel: (
        <StyledButton
          id="formizo-cancel"
          className={"formizo-h-m"}
          key={2}
          theme={{
            color: "red",
            background: "white",
            boxShadow: ColorX.GetBoxShadowCSS("grey"),
            width: buttonWidth,
          }}
          onClick={this._onCancel}
        >
          <i className="fas fa-ban" />
          <div className="formizo-h-m">Cancel</div>
        </StyledButton>
      ),
      Clear: (
        <StyledButton
          id="formizo-clear"
          className={"formizo-h-m"}
          key={3}
          theme={{
            color: ColorX.GetColorCSS("Edit"),
            background: "white",
            boxShadow: ColorX.GetBoxShadowCSS("grey"),
            width: buttonWidth,
          }}
          onClick={this._onClear}
        >
          <i className="fas fa-undo" />
          <div className="formizo-h-m">Clear</div>
        </StyledButton>
      ),
      Revert: (
        <StyledButton
          id="formizo-revert"
          className={"formizo-h-m"}
          key={4}
          theme={{
            color: ColorX.GetColorCSS("Primary"),
            background: "white",
            boxShadow: ColorX.GetBoxShadowCSS("grey"),
            width: buttonWidth,
          }}
          onClick={this._onRevert}
        >
          <i className="fas fa-history" />
          <div className="formizo-h-m">Revert</div>
        </StyledButton>
      ),
      Login: (
        <StyledButton
          id="formizo-login"
          className={"formizo-h-m"}
          key={5}
          theme={{
            color: ColorX.GetColorCSS("Decorate2"),
            background: "white",
            boxShadow: ColorX.GetBoxShadowCSS("grey"),
            width: buttonWidth,
          }}
          onClick={this._onSubmit}
        >
          <i className="fas fa-sign-in-alt" />
          <div className="formizo-h-m">Login</div>
        </StyledButton>
      ),
      Logout: (
        <StyledButton
          id="formizo-logout"
          className={"formizo-h-m"}
          key={6}
          theme={{
            color: "yellow",
            background: "white",
            boxShadow: ColorX.GetBoxShadowCSS("grey"),
            width: buttonWidth,
          }}
          onClick={this._onSubmit}
        >
          <i className="fas fa-sign-out-alt" />
          <div className="formizo-h-m">Logout</div>
        </StyledButton>
      ),
    };

    if (!readOnly) {
      return _.map(buttons, (o, i) => {
        if (_.isString(o) && buttonsJSX[o]) return buttonsJSX[o];
        else {
          return o;
        }
      });
    }
  }

  renderForm() {
    let { buttons, buttonAlign, buttonPadding } = this.state;
    const { customStyles } = this.props;
    return (
      <VStack>
        <VStack className="formSchemaContainer" alignItems={"flex-start"} padding="0 5px" width="100%" flexGrow={1} overflow={customStyles?.formSchemaContainer?.overflow ?? "auto"}>
          {this.renderSchema()}
          <Spacer />
        </VStack>
        {buttons && buttons.length > 0 && (
          <HStack padding={buttonPadding}>
            {buttonAlign === "right" && <Spacer />}
            {this.renderButtons()}
            {buttonAlign === "left" && <Spacer />}
          </HStack>
        )}
        <Spacer />
      </VStack>
    );
  }

  render() {
    const { formID, width, height, overflow } = this.props;
    return (
      <Box width={width} height={height} overflow={overflow ?? "auto"}>
        <form id={formID} style={{ width: "100%", height: "100%" }} onBlur={() => this._onBlurAutoSubmit()} noValidate onSubmit={(e) => this._onSubmit(e)}>
          {this.renderForm()}
        </form>
      </Box>
    );
  }
}

export default observer(Formizo);
