import { Close } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography, Button, SxProps, Theme } from "@mui/material";
import { AvailLANG, T2EContext } from "interfaces";
import { HStack } from "Components";

interface IZCContextButtonProps {
  context: T2EContext;
  onDelete?: any;
  fontSize?: string | number;
  sx?: SxProps<Theme>;
  lang?: AvailLANG;
}

const defaultSx = {
  boder: "1px solid",
  borderColor: "primary.main",
  borderRadius: "10px",
  boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.2)",
  width: "auto",
  p: 1,
  color: "primary.main",
};
export function ZCContextButton({ context, onDelete, fontSize = "0.8rem", sx = defaultSx, lang = AvailLANG.EN, ...props }: IZCContextButtonProps) {
  return (
    <HStack sx={defaultSx} gap={0}>
      <Typography sx={{ fontSize }}>{context.title[lang] + ":" + context.value}</Typography>
      <IconButton onClick={() => onDelete && onDelete(context)}>
        <Close sx={{ fontSize }} />
      </IconButton>
    </HStack>
  );
}
