import { NativeSelect, Typography } from "@mui/material";
import { FormizoSchema } from "Components/LabIZO/Formizo";
import { CustomFilterValueProps, FieldType, Filterable, TablizoSchema } from "Components/LabIZO/Tablizo";
import _ from "lodash";
import moment from "moment";
import { IExportSchemaEntry } from "interfaces/be.interfaces/file-export-interface";

const Table: TablizoSchema[] = [
  {
    label: "Created At",
    name: "CREATED_AT",
    filterable: true,
    type: "dateTime",
    transform: "datetime",
    defaultSort: "desc",
  },
  {
    label: "Project",
    name: "knowledge_base",
    filterable: true,
  },
  {
    label: "Model",
    name: "model_name",
    filterable: true,
  },
  {
    label: "Question",
    name: "question",
  },
  {
    label: "Passage",
    name: "context",
  },
  {
    label: "Answer",
    name: "answer",
  },
  {
    label: "Tokens",
    name: "usage.total_tokens",
  },
  {
    label: "Conversation ID",
    name: "_id",
  },
  {
    label: "Session ID",
    name: "session_id",
  },
];

const Info: FormizoSchema[] = [
  {
    columns: [
      {
        width: "100%",
        page: [
          {
            label: "Conversation ID",
            name: "_id",
            format: "text",
            readOnly: true,
          },
          {
            label: "Session ID",
            name: "session_id",
            format: "text",
            readOnly: true,
          },
          {
            label: "Created At",
            name: "CREATED_AT",
            format: "text",
            readOnly: true,
          },
          {
            label: "Answer Messsage",
            name: "answer",
            format: "text",
            readOnly: true,
          },
          {
            label: "Answer Time",
            name: "answers.created",
            format: "display",
            readOnly: true,
            Custom: (data: any, field: any, addOns: any) => {
              return <Typography>{moment(field).format("DD MMM YYYY, HH:mm:ss 0.SSS")}</Typography>;
            },
          },
          {
            label: "Model",
            name: "answers.model",
            format: "text",
            readOnly: true,
          },
          {
            label: "Completion Tokens",
            name: "usage.completion_tokens",
            format: "text",
            readOnly: true,
          },
          {
            label: "Prompt Tokens",
            name: "usage.prompt_tokens",
            format: "text",
            readOnly: true,
          },
          {
            label: "Total Tokens",
            name: "usage.total_tokens",
            format: "text",
            readOnly: true,
          },
          {
            label: "Passage",
            name: "context",
            format: "display",
            readOnly: true,
            Custom: (data: any, field: any, addOns: any) => {
              return <>{field}</>;
            },
          },
          {
            label: "language",
            name: "language",
            format: "text",
            readOnly: true,
          },
          {
            label: "Project",
            name: "knowledge_base",
            format: "text",
            readOnly: true,
          },
          {
            label: "Question",
            name: "question",
            format: "text",
            readOnly: true,
          },
        ],
      },
    ],
  },
];

const Export: IExportSchemaEntry[] = [
  {
    Header: "Created At",
    accessor: "CREATED_AT",
    format: "datetime",
  },
  {
    Header: "Knowledge Base",
    accessor: "knowledge_base",
    colWidth: 30,
  },
  {
    Header: "Language",
    accessor: "language",
  },
  {
    Header: "Channel",
    accessor: "channel",
  },
  {
    Header: "Question",
    accessor: "question",
    colWidth: 50,
  },
  {
    Header: "Answer",
    accessor: "answer",
    colWidth: 50,
  },
  {
    Header: "Context",
    accessor: "context",
    colWidth: 40,
  },
  {
    Header: "Top Contexts",
    accessor: "top_contexts",
    format: "array",
    mappedNestedFields: ["id", "filename", "alias", "chunks"],
    colWidth: 40,
  },
  {
    Header: "Model Name",
    accessor: "model_name",
  },
  {
    Header: "Session ID",
    accessor: "session_id",
  },
  {
    Header: "Conversation ID",
    accessor: "_id",
  },
  {
    Header: "Liked By User?",
    accessor: "user_feedback.like",
    format: "boolean",
  },
];

const Filterables: Filterable[] = [
  {
    label: "Session ID",
    field: "session_id",
    type: FieldType.text,
  },
  {
    label: "Conversation ID",
    field: "_id",
    type: FieldType.text,
  },
  {
    label: "Project",
    field: "knowledge_base",
    type: FieldType.text,
  },
  {
    label: "QA Model",
    field: "model_name",
    type: FieldType.text,
  },
  {
    label: "Question",
    field: "question",
    type: FieldType.text,
  },
  {
    label: "Answer",
    field: "answer",
    type: FieldType.text,
  },
  {
    label: "Feedback",
    field: "user_feedback.like",
    type: FieldType.select,
    CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => {
      return (
        <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
          <option value={""}></option>
          <option value={"true"}>Like</option>
          <option value={"false"}>Dislike</option>
        </NativeSelect>
      );
    },
  },
];

const AdvFilter: any = [];

const schema: any = {
  Table,
  Info,
  Export,
  Filterables,
  AdvFilter,
};

export default schema;
