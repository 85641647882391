import { CircularProgress, ThemeProvider, Typography, createTheme, styled } from "@mui/material";
import { HStack, VStack } from "Components";
import { TableHeader } from "Components/DetailsTable";
import { DATA_LINK, backendConnector } from "connectors";
import { useEffect, useState } from "react";
import { ColorX, store } from "static";
import { AuditLog, AuditLogWithDataEventDetails } from "interfaces/db.interfaces/AuditLog.interface";
import { DataEventLog } from "interfaces/db.interfaces/DataEventLog.interface";
import { Legend } from "./Legend/Legend";
import { DocumentDataDiff } from "./DocumentDataDiff/DocumentDataDiff";
import { Section } from "./Section/Section";

export interface AuditLogDetailProps {
  id: string; // audit log id
  doc: AuditLog;
}

const StyledDataChangeContainer = styled(VStack)({
  alignItems: "flex-start",
  width: "50%",
  height: "100%",
  justifyContent: "flex-start",
  minWidth: "550px",
  paddingRight: "8px",
});

export const AuditLogDetail: React.FC<AuditLogDetailProps> = ({ id, doc }) => {
  const [combinedLog, setCombinedLog] = useState<AuditLogWithDataEventDetails | null>(null);

  // generally not a good idea to fetch data at the child element
  // but perhaps it will be too messy to put it the parent element (Inner / Datumizo)
  useEffect(() => {
    const setAuditLogWithDataEventDetails = async (): Promise<void> => {
      const docWithDataEventDetails: AuditLogWithDataEventDetails = { ...doc, data_events: [] };

      if (doc.data_events.length) {
        const data = { id, data_events_ids: doc.data_events };
        const { Success, payload: rtn } = await backendConnector.post<{ docs: DataEventLog[] }>(DATA_LINK.DataEventGet, data);
        if (!Success) {
          store.Alert("Cannot fetch details", "error");
          throw new Error("Failed to fetch data event details");
        }
        docWithDataEventDetails.data_events = rtn.docs;
      }

      setCombinedLog(docWithDataEventDetails);
    };

    setAuditLogWithDataEventDetails().catch((err) => console.error(err));
  }, []);

  if (!combinedLog) return <CircularProgress />;

  const DetailsTable: React.FC<{}> = () => {
    return (
      <VStack
        className="details-table"
        gap={5}
        padding={2}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        height="100%"
        width="50%"
        minWidth="550px"
        sx={{
          flexWrap: "wrap",
        }}
      >
        <Section
          header={"Basic Info"}
          items={[
            { label: "ID", value: combinedLog._id },
            { label: "Received Time", value: combinedLog.recTime, options: { format: "date" } },
          ]}
        />
        <Section
          header={"User Info"}
          items={[
            { label: "User Name", value: combinedLog.user.username },
            { label: "User Display Name", value: combinedLog.user.userDisplayName },
            { label: "Role", value: combinedLog.user.role },
          ]}
        />
        <Section
          header={"Action Info"}
          items={[
            { label: "Component", value: combinedLog.action.component },
            { label: "Action Type", value: combinedLog.action.displayType },
            { label: "Outcome", value: combinedLog.outcome.success ? "Success" : "Failure" },
            { label: "Remarks", value: combinedLog.outcome.message, options: { skipIfEmpty: true } },
          ]}
        />
        <Section
          header={"API Info"}
          items={[
            { label: "Method", value: combinedLog.api.method },
            { label: "URL", value: combinedLog.api.url },
            { label: "Cat", value: combinedLog.api.cat },
            { label: "Subcat", value: combinedLog.api.subcat },
            { label: "Action", value: combinedLog.api.action },
            { label: "Origin", value: combinedLog.api.origin },
            { label: "Referrer", value: combinedLog.api.referrer },
            { label: "IP", value: combinedLog.api.ip },
            { label: "Request Body", value: combinedLog.api.body, options: { wordBreak: "break-word" } },
          ]}
        />
      </VStack>
    );
  };

  const DataChanges: React.FC<{}> = () => {
    const localTheme = createTheme({
      components: {
        MuiTypography: {
          styleOverrides: {
            root: {
              fontFamily: "ui-monospace, Menlo, Monaco",
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            label: {
              fontFamily: "ui-monospace, Menlo, Monaco",
            },
          },
        },
      },
    });

    const content = combinedLog.data_events.length ? (
      <ThemeProvider theme={localTheme}>
        <Legend />
        {combinedLog.data_events.map((dataEvent, idx) => (
          <DocumentDataDiff key={idx} dataEvent={dataEvent} />
        ))}
      </ThemeProvider>
    ) : (
      <Typography variant="h6" color={ColorX.GetColorCSS("primary")}>
        No Loggabe Data Change
      </Typography>
    );

    return (
      <StyledDataChangeContainer className="data-change-container" gap={3}>
        <TableHeader label="Associated Data Changes" />
        {content}
      </StyledDataChangeContainer>
    );
  };

  return (
    <HStack width="100%" sx={{ gap: 0, flexWrap: "wrap" }}>
      <DetailsTable />
      <DataChanges />
    </HStack>
  );
};
