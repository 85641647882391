import React, { useState } from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";

import { DOMAIN } from "config/config";

import axios from "axios";
import { store } from "static";
import Validation from "./Validation";
import { HtmlParserWrapper } from "Components/HtmlParserWrapper";
import EmailFeild from "./EmailFeild";

const { Criteria } = Validation;

const Popup = (props) => {
  const { open, onClose, onSubmit, onAlert } = props;
  const [newEmail, setNewEmail] = useState("");
  const [newEmailConfirm, setNewEmailConfirm] = useState("");

  return (
    <Dialog open={open ? true : false} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Change Email</DialogTitle>
      <DialogContent>
        <DialogContentText>{<HtmlParserWrapper html={Criteria.join("<br>")} />}</DialogContentText>
        <EmailFeild id="newEmail" label="New Email" criteria={["required", "email"]} onInput={(value) => setNewEmail(value)} />
        <EmailFeild id="newEmailConfirm" label="New Email Confirm" criteria={["required", "email"]} onInput={(value) => setNewEmailConfirm(value)} />
        {onAlert}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!(newEmail && newEmailConfirm)}
          onClick={() => {
            onSubmit({ newEmail, newEmailConfirm });
          }}
          color="primary"
          type="submit"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EmailChanger = ({ docID, username, jwt }) => {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(<div />);

  const onClickOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setAlert(<div />);
    setOpen(false);
  };
  const onSubmit = async ({ prevEmail, newEmail, newEmailConfirm }) => {
    try {
      let url = DOMAIN + "/Base/User/ChangeEmail";

      let payload = {
        data: {
          docID: docID,
          UserName: username,
          NewEmail: newEmail,
          NewEmailConfirm: newEmailConfirm,
        },
        JWT: jwt,
      };

      let { data } = await axios.post(url, payload);
      let { Success } = data;

      if (!Success) return setAlert(<Alert severity="error">{data?.payload?.message}</Alert>);

      store.Alert(data?.payload?.message);
      setAlert(<div />);

      setOpen(false);
    } catch (e) {
      setAlert(<Alert severity="error">{JSON.stringify(e)}</Alert>);
    }
  };

  return (
    <div className="EmailChanger">
      <Button variant="outlined" color="secondary" onClick={onClickOpen}>
        Change Email
      </Button>
      <Popup open={open} onClose={onClose} onSubmit={onSubmit} onAlert={alert} />
    </div>
  );
};

export default EmailChanger;
