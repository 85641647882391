import { AvailLANG } from "interfaces";
import { store } from "static";
import { IUIEntity } from "Pages/FLE/interfaces/IUIEntity";
import { BodyTitle } from "Components";
import { VStack } from "Components/Layouts";
import { EntitySelection } from "./Selection/EntitySelection";
import { EntityValSelection } from "./Selection/EntityValSelection";

interface IEntitySettingsProps {
  entities: IUIEntity[];
  name: `contexts.${number}.setting`;
  contextIndex: number;
  disabled?: boolean;
}

export function EntitySettings({ entities, contextIndex, name, ...props }: IEntitySettingsProps) {
  const getOptions = (entities: IUIEntity[]) => {
    let entityValueOptions: any = {};
    const entityOptions = entities.map((entity) => {
      entity.values.map((o) => {
        if (!entityValueOptions[entity.name]) entityValueOptions[entity.name] = [];

        const defaultLang = store.sysInfo.Language.default as AvailLANG;
        const synonymLabel = o.synonyms[defaultLang] && o.synonyms[defaultLang][0];

        const dropdownOption = {
          label: synonymLabel || o.value || "",
          value: o.value || "",
        };
        entityValueOptions[entity.name].push(dropdownOption);
        return o; // return o to avoid lint error
      });

      return {
        label: entity.name,
        value: entity.name,
      };
    });

    return { entityOptions, entityValueOptions };
  };

  const { entityOptions, entityValueOptions } = getOptions(entities);

  const GridProps = {
    container: true,
  };
  return (
    <VStack width="100%" alignItems={"flex-start"} gap={2}>
      <BodyTitle>Entity Settings</BodyTitle>
      <EntitySelection GridProps={GridProps} entityOptions={entityOptions} name={`${name}.values`} {...props} />

      <BodyTitle>Selected Entity Values</BodyTitle>
      <EntityValSelection contextIndex={contextIndex} entityValueOptions={entityValueOptions} name={`${name}.values`} {...props} />
    </VStack>
  );
}
