import { IDocumentManagerPayload } from "../../interfaces/document-manager-api.interface";
import { useState } from "react";
import { HStack, VStack } from "Components/Layouts";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { DocumentManagerFormContext } from "../hooks/contexts-hook";
import { ContextEditors } from "./ContextEditors";
import { DocumentInfo } from "./DocumentInfo";
import { Button } from "@mui/material";
import { ErrorSummary } from "Pages/FLE/components";
import { DATA_LINK } from "../../../../connectors/datalink";
import { ErrorX, store } from "static";
import { backendConnector } from "connectors/backend-connector";
import { Loading } from "Components/Loading";

interface IProps {
  id: string;
  row?: IDocumentManagerPayload;
  onQuit: () => void;
  onQuitRefresh: (_id: string) => void;
  renderFormizo: (schema: any, buttons: any, onSubmit: any) => JSX.Element;
  mode?: "Add" | "Edit" | "Info";
}
export default function DocumentManagerForm(props: IProps) {
  const methods = useForm<IDocumentManagerPayload>({ defaultValues: props.row, mode: "all" });
  const [errors, setErrors] = useState<FieldErrors<IDocumentManagerPayload> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const mode = props.mode || "Info";

  const handleSubmit = (quitForm: boolean = true) => {
    const submitStatus = {
      valid: async (_newRow: IDocumentManagerPayload) => {
        setErrors(null);
        setLoading(true);
        console.log(`Valid Submit: ${JSON.stringify(_newRow)}`);

        try {
          const res = await backendConnector.post(DATA_LINK.DMEdit, _newRow);
          if (!res.Success) {
            store.Alert("Document Edit Failed: " + res.payload?.message, "error");
            return ErrorX.Handle(res.payload);
          }
          store.Alert("Document Edited Successfully", "success");

          if (quitForm) props.onQuitRefresh(_newRow._id);
        } catch (err: any) {
          ErrorX.Handle(err);
        }
        setLoading(false);
      },
      invalid: (errors: FieldErrors<IDocumentManagerPayload>) => {
        setErrors(errors);
        setLoading(false);
      },
    };

    methods.handleSubmit(submitStatus.valid, submitStatus.invalid)();
  };

  return (
    <VStack sx={{ pt: 3 }} width={"100%"}>
      <Loading open={loading} />
      {errors && <ErrorSummary errors={errors} />}
      <FormProvider {...methods}>
        <DocumentManagerFormContext.Provider value={{ mode }}>
          <ButtonPannel mode={mode} onSubmit={handleSubmit} />
          <DocumentInfo mode={mode} />
          <ContextEditors mode={mode} />
        </DocumentManagerFormContext.Provider>
      </FormProvider>
    </VStack>
  );
}

const ButtonPannel = ({ mode, onSubmit }: { mode: "Add" | "Edit" | "Info"; onSubmit: (quitForm?: boolean) => void }) => {
  if (mode === "Info") return <></>;

  return (
    <HStack width={"100%"} justifyContent={"flex-end"} sx={{ position: "absolute", zIndex: 5, top: 40, right: 100 }}>
      <Button
        //
        variant="contained"
        color="secondary"
        onClick={() => {
          onSubmit(false);
        }}
      >
        Submit & Continue
      </Button>
      <Button
        //
        variant="contained"
        color="secondary"
        onClick={() => {
          onSubmit(true);
        }}
      >
        Submit & Quit
      </Button>
    </HStack>
  );
};
