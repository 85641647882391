import React from "react";
import { Typography, Box } from "@mui/material";
import { ColorX } from "static";

export const MessengerLink = () => {
  return (
    <Box>
      <Typography
        sx={{
          color: ColorX.GetColorCSS("Primary"),
          fontSize: 9,
          width: "100%",
          paddingTop: "60px",
          position: "fixed",
        }}
      >
        {/* {"Connecting >>> " + method._getURL()} */}
      </Typography>
    </Box>
  );
};
