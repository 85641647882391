import { FormizoSchema } from "Components/LabIZO/Formizo";
import { CusAuth } from "interfaces";

const schema: FormizoSchema[] = [
  {
    label: "Default Language",
    name: "Language.default",
    format: "select",
    selectStyle: "dropdown",
    selectRef: "lang",
    selectCap: "caption",
    selectVal: "value",
  },
  {
    label: "System Name",
    name: "Name",
    format: "text",
  },
  {
    reqAuth: "System.General",
    reqFunc: CusAuth.Menu,
    accessizo: [
      {
        label: "Menu Mode",
        name: "MenuMode",
        format: "select",
        selectStyle: "dropdown",
        options: [
          { value: "classic", label: "Classic" },
          { value: "starter", label: "Starter" },
        ],
      },
    ],
  },
];

export default schema;
