import { Box, Paper, styled } from "@mui/material";
import Formizo from "Components/LabIZO/Formizo";
import { Authority } from "static";
import { ITalk2VAAddOns } from "../../interfaces/combined-talk2va-addons.interface";
import _ from "lodash";
import { ArenaDetailsPanelSchema } from "./schema";
import { T2EResponse } from "interfaces";
interface IArenaDetailsAddOns extends ITalk2VAAddOns {
  payload1: T2EResponse;
  payload2: T2EResponse;
}

export interface DetailsPanelProps {
  addOns: IArenaDetailsAddOns;
  reqAuth?: string;
  reqFunc?: string;
  reqLevel?: number;
}

const StyledPanelContainer = styled(Paper)({
  height: "100%",
  width: "100%",
  padding: 0,
  display: "flex",
});

export const ArenaDetailsPanel: React.FC<DetailsPanelProps> = ({ addOns, reqAuth, reqLevel, reqFunc }) => {
  if (!Authority.IsAccessibleQ(reqAuth, reqLevel, reqFunc)) return <></>;

  let data;
  let messageData1: any = addOns.payload1;
  let messageData2: any = addOns.payload2;
  if (messageData1 !== undefined && messageData2 !== undefined) {
    data = {
      firstModel: messageData1,
      secondModel: messageData2,
    };
    console.log(data);
  }

  return (
    <StyledPanelContainer>
      <Box height="100%" width="100%">
        <Formizo
          height="inherit" //
          overflow="auto"
          schema={ArenaDetailsPanelSchema}
          readOnly={true}
          defaultValue={data}
          fieldSize={"small"}
          buttons={[]}
          customStyles={{ formSchemaContainer: { overflow: "inherit" } }}
        />
      </Box>
    </StyledPanelContainer>
  );
};
