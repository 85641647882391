import Switch from "@mui/material/Switch";
import axios from "axios";
import { useEffect, useState } from "react";
import { ErrorX, store } from "static";

interface SwitchButtonProps {
  defaultValue: boolean;
  URL: string;
  row?: any;
  fetchData?: (force_refresh: boolean) => void;
  disabled?: boolean;
}

export const SwitchButton = (props: SwitchButtonProps) => {
  const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let paylod = {
      JWT: store.user.JWT,
      data: {
        selected: [props?.row?._id],
        enabled: event.target.checked,
      },
    };
    const res = await axios.post(props.URL, paylod);
    const status = paylod.data.enabled ? "Enabled" : "Disabled";
    if (!res.data.Success) {
      store.Alert(`FAQ ${status} failed` + res.data?.payload?.message, "error");
      return ErrorX.Handle(res.data?.payload);
    }
    store.Alert(`Row ${props?.row?.alias}  ${status}`, "success");
    setTimeout(() => {
      props.fetchData && props.fetchData(true);
    }, 1000);
  };
  return <Switch checked={props.defaultValue} onChange={handleSwitchChange} disabled={props.disabled} />;
};
