import _ from "lodash";
import * as React from "react";
import { TFMultiSelect } from "../../FormizoSchema.interface";
import { FFAdvancedMultiSelect } from "./FFMultiSelect/FFAdvancedMultiSelect";
import { FFBasicMultiSelect } from "./FFMultiSelect/FFBasicMultiSelect";

interface IFFMultiSelectProps {
  ifieldStyle?: string;
  _onFieldFocus?: () => void;
  _onFieldBlur?: () => void;
  ischema: TFMultiSelect;
  _onValueChange?: (name: string, value: string[], criteria: any) => void;
  addOns?: any;
  iname: string;
  formError: any;
  _onBlurInlineSubmit?: any;
  errorsShowOnHelperText?: boolean;
  readOnly?: boolean;
}

export const FFMultiSelect: React.FC<IFFMultiSelectProps> = (props) => {
  switch (props.ischema.selectStyle) {
    case "autocomplete":
      return <FFAdvancedMultiSelect {...props} />;
    case "dropdown":
    default:
      return <FFBasicMultiSelect {...props} />;
  }
};
