import _ from "lodash";
import { ExampleGenerationByLang, QuestionDetails } from "./FAQCreate/ExampleGenerationByLang";
import { AvailLANG, CusAuth } from "interfaces";
import { useState } from "react";
import { Typography } from "@mui/material";
import { Authority, store } from "static";
import { HStack, VStack } from "Components";

interface ExampleGenerationInEditProps {
  _row: any;
  field: any;
  _onValueChange: any;
  lang: AvailLANG;
  display: string;
  readonly?: boolean;
}

export const ExampleGenerationInEdit = ({ _row, field, _onValueChange, lang, display, readonly }: ExampleGenerationInEditProps) => {
  const [existingGenerationsByLang, setExistingGenerationsByLang] = useState<string[]>([]);
  const [showError, setShowError] = useState(false);
  const defaultLang = (store.sysInfo.Language.default ?? "EN") as AvailLANG;
  const [question, setQuestion] = useState<string>(field?.[0]?.text ?? _row?.intent?.[defaultLang]?.examples?.[0]?.text ?? "");
  const answer = _row?.answer?.text?.[lang]?.[0];

  const unavailableText = "Sorry, question generation is not available at the moment.";
  const updateGeneratedQuestions = (lang: AvailLANG, add: boolean, newQuestions: QuestionDetails) => {
    setExistingGenerationsByLang((state) => {
      return add ? _.uniq([...state, newQuestions.question]) : state.filter((q) => q !== newQuestions.question);
    });
    const updateIntent = add ? _.uniq([...field, { text: newQuestions.question }]) : field.filter((q: { text: string }) => q.text !== newQuestions.question);
    _onValueChange(`intent.${lang}.examples`, updateIntent);
  };

  const renderExampleGeneration = () => {
    return (
      <VStack width={"100%"}>
        <Typography>Intent Generation ({display})</Typography>
        {showError ? (
          <Typography color="error">{unavailableText}</Typography>
        ) : (
          <HStack width={"100%"} gap={2} justifyContent="space-between">
            <ExampleGenerationByLang
              key={lang}
              lang={lang}
              question={question}
              setQuestion={setQuestion}
              answer={answer?.[lang]?.[0] ?? answer?.[defaultLang]?.[0]}
              existingGenerations={existingGenerationsByLang}
              setGeneratedQuestions={updateGeneratedQuestions}
              setShowError={setShowError}
            />
          </HStack>
        )}
      </VStack>
    );
  };

  if (readonly) {
    return <></>;
  }

  return Authority.IsAccessibleQ("FAQ", undefined, CusAuth.IntentGeneration) ? renderExampleGeneration() : <></>;
};
