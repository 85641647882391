import { Typography, Stack, Box, styled } from "@mui/material";
import { ColorX } from "static";

const StyledLegendContainer = styled(Stack)({
  border: "1px solid grey",
  boxShadow: "5px 5px 5px grey;",
  borderRadius: "5px",
  padding: "3px 12px",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  color: ColorX.GetColorCSS("primary"),
});

export const Legend: React.FC<{}> = ({}) => {
  return (
    <StyledLegendContainer className="legend-container">
      <Typography fontWeight={700}>Legend</Typography>
      <Stack direction="row" gap={3}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Box width="30px" height="20px" sx={{ backgroundColor: ColorX.GetColorCSS("Insert") }} />
          <Typography fontWeight={700}>Inserted</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Box width="30px" height="20px" sx={{ backgroundColor: ColorX.GetColorCSS("Delete") }} />
          <Typography fontWeight={700}>Deleted</Typography>
        </Stack>
      </Stack>
    </StyledLegendContainer>
  );
};
