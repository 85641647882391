import { useEffect, useState } from "react";
import { Accessor, ErrorX, store } from "static";
import { DATA_LINK, backendConnector } from "connectors";
import Formizo from "Components/LabIZO/Formizo";
import { schema } from "./schema";
const SysCaptcha = () => {
  const [info, setInfo] = useState<any>();

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    const { payload } = await backendConnector.post(DATA_LINK.SYSCaptchaInfo, {});
    setInfo(payload);
  };

  const handleSubmit = async (data: any) => {
    try {
      const res = await backendConnector.post(DATA_LINK.SYSCaptchaEdit, data);
      if (res.Success) {
        store.Alert("System Captcha updated successfully", "success");
      } else {
        store.Alert("System Captcha update fails.", "error");
      }
    } catch (e: any) {
      store.Alert(ErrorX.Handle(e), "error");
    }
  };

  return <Formizo height="100%" schema={schema} buttons={["Revert", "Submit"]} onSubmit={handleSubmit} defaultValue={info} />;
};

export { schema };
export default SysCaptcha;
