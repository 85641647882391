import _ from "lodash";

import styles from "./PieChartDistribution.module.css";
import { VStack } from "Components/LabIZO/Stackizo";
import { Typography } from "@mui/material";
import { GPie } from "../GPie/GPie";
import { GraphTitle } from "../GraphTitle/GraphTitle";

export interface PieChartDistributionProps {
  width?: string;
  title: string;
  data: { name: string; value: number }[];
  fallbackText?: string;
  take?: number; // taking the top X names
  style?: any;
}

export const PieChartDistribution: React.FC<PieChartDistributionProps> = ({ title, data, fallbackText = "No data available yet", take, style }) => {
  let sortedData = _.sortBy(data, (o) => -o.value);
  if (take) {
    sortedData = sortedData.slice(0, take);
  }

  return (
    <VStack className={styles.container} style={style}>
      <GraphTitle title={title} />
      {data && data.length > 0 ? (
        <GPie data={sortedData} />
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          <Typography style={{ fontStyle: "italic" }}>{fallbackText}</Typography>
        </div>
      )}
    </VStack>
  );
};
