import React, { useEffect, useState } from "react";
import { ButtonBase, Stack, Box, Paper, Fade } from "@mui/material";
import { SyvaLogo } from "Components/syva-logo/syva-logo";
import Messenger from "Components/Messenger/Messenger";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { DndContext, useSensors, useSensor, PointerSensor } from "@dnd-kit/core";
import { ChatDOMAIN, DOMAIN } from "config/config";
import { DATA_LINK, backendConnector } from "connectors";
import { store } from "static";
import { HStack, VStack } from "Components";
import "./messenger-with-avatar.scss";
import { HtmlParserWrapper } from "Components/HtmlParserWrapper";
import { AvailLANG, QAMode, IDisclaimerConfig } from "interfaces";
import { HeaderComponent } from "Components/HeaderComponent";

interface AvatarProps {
  position: { bottom: number; right: number };
  holding: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
interface DisclaimerProps {
  SC: string;
  EN: string;
  TC: string;
}

enum multiLangDisclaimer {
  EN = "Disclaimer",
  SC = "免责声明",
  TC = "免責聲明",
}
enum multiLangAgree {
  EN = "Agree",
  SC = "同意",
  TC = "同意",
}

const Avatar = ({ position, holding, onClick }: AvatarProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: "messengerDraggable",
  });

  const style = {
    transform: CSS.Translate.toString(transform),
  };

  const sx = {
    position: "fixed",
    zIndex: 10,
    bottom: position.bottom,
    right: position.right,
  };

  return (
    <Stack ref={setNodeRef} style={style} {...listeners} {...attributes} sx={sx}>
      <ButtonBase
        sx={{
          boxShadow: holding ? "10" : "2",
          height: 70,
          width: 70,
          borderRadius: "50%",
          backgroundColor: "white",
        }}
        onClick={onClick}
      >
        <SyvaLogo />
      </ButtonBase>
    </Stack>
  );
};

export const MessengerWithAvatar = () => {
  const [chatWindow, setChatWindow] = useState(false);
  const [position, setPosition] = useState({ bottom: 70, right: 20 });
  const [holding, setHolding] = useState(false);

  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [showMessenger, setShowMessenger] = useState(false);
  const [disclaimer, setDisclaimer] = useState<DisclaimerProps | null>(null);

  useEffect(() => {
    fetchDisclaimers().then((res) => {
      setDisclaimer(res);
    });
  }, []);

  const fetchDisclaimers = async () => {
    const response = await backendConnector.post<IDisclaimerConfig>(DATA_LINK.SYSDisclaimerInfo);
    return response.payload?.Content;
  };

  const handleDragStart = (_e: any) => {
    setHolding(true);
  };

  const handleDragEnd = (event: any) => {
    setPosition((prevPos) => ({
      right: prevPos.right - event.delta.x,
      bottom: prevPos.bottom - event.delta.y,
    }));
    setHolding(false);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 10,
      },
    })
  );
  const handleAgree = () => {
    setShowDisclaimer(false);
    setShowMessenger(true);
  };

  const handleExit = () => {
    setChatWindow(false);
  };

  const DisclaimerBox = () => {
    const defaultLang = (store.sysInfo.Language.default ?? "EN") as AvailLANG;
    return (
      <VStack sx={{ height: "100%", justifyContent: "flex-start" }}>
        <HeaderComponent chatbotName={"SYVA GENERATIVE VA"} />
        <div className="disclaimer-box">
          <h3 className="disclaimer-title">{multiLangDisclaimer[defaultLang]}</h3>
          <Box className="disclaimer-message">{<HtmlParserWrapper html={"<div>" + (disclaimer?.[defaultLang] ?? "") + "</div>"} />}</Box>
          <HStack>
            <button className="disclaimer-btn" onClick={handleAgree}>
              {multiLangAgree[defaultLang]}
            </button>
          </HStack>
        </div>
      </VStack>
    );
  };

  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors}>
      {chatWindow && (
        <Fade in={chatWindow} timeout={500}>
          <Box sx={{ height: 600, width: 400, position: "fixed", bottom: 100, right: 100, zIndex: 10 }}>
            <Paper sx={{ width: "100%", height: "100%", pb: 1 }}>
              {showDisclaimer && disclaimer && <DisclaimerBox />}
              {showMessenger && (
                <Messenger
                  header={<HeaderComponent chatbotName={"SYVA GENERATIVE VA"} />}
                  name={"SYVA GPT-QA"}
                  project={["All"]}
                  LiveChatEnabled={false}
                  qaMode={QAMode.HYBRID}
                  url={`${ChatDOMAIN}${DATA_LINK.Talk2GPT}`}
                  feedbackUrl={`${DOMAIN}${DATA_LINK.UserFeedback}`}
                />
              )}
            </Paper>
          </Box>
        </Fade>
      )}

      <Avatar
        position={position}
        holding={holding}
        onClick={() => {
          setChatWindow(!chatWindow);
        }}
      />
    </DndContext>
  );
};
