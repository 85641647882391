import { ConditionOperator, FieldType, Filterable } from "Components/LabIZO/Tablizo";
import { Accessor } from "static";

export const FILTERABLES: Filterable[] = [
  {
    field: "alias",
    label: "Document Name",
    type: FieldType.text,
  },
  {
    field: "knowledge_grps",
    label: "Knowledge Groups Name",
    keyName: "alias",
    type: FieldType.array,
  },
  {
    field: "status",
    label: "Status",
    type: FieldType.text,
  },
];
