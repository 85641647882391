import { DBAnswerSpec } from "interfaces";
import Formizo from "Components/LabIZO/Formizo";
import { VStack } from "Components/LabIZO/Stackizo";
import { store } from "static";
import _ from "lodash";
import schema from "Pages/FLE/constants/schema/schema";
import { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { validate } from "Validation";
import { FLEFormContext } from "../../hooks/FLEContexts";
import { IFLEDoc } from "interfaces/db.interfaces/fle.interface";

export interface IQuestionSetupProps {}

export const QuestionSetup = ({ ...props }: IQuestionSetupProps) => {
  const { watch, setValue, setError, clearErrors } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  const [formState, setFormState] = useState<IFLEDoc<DBAnswerSpec>>();
  function _validateForm(formValue: any) {
    const defaultLang = store.sysInfo.Language.default;
    const avilableLangs = store.sysInfo.Language.available;
    let feildErrors: any[] = [];
    feildErrors.push({
      field: "alias",
      ...validate(["required", "plain"], _.get(formValue, "alias")),
    });
    fleFormContext?.mode !== "Add" &&
      avilableLangs.forEach((_l) => {
        feildErrors.push({
          field: "intentDoc",
          ...validate(["required"], _.get(formValue, `intentDoc.${_l.value}.description`)),
        });
      });

    avilableLangs.forEach((_l) => {
      if (_l.value === defaultLang) {
        feildErrors.push({
          field: "intentDoc",
          ...validate(["required"], _.get(formValue, `intentDoc.${_l.value}.examples`)),
        });
      }

      let examples = _.get(formValue, `intentDoc.${_l.value}.examples`);
      if (examples) {
        examples.forEach((ex: { text: string } | null) => {
          feildErrors.push({
            field: "intentDoc",
            ...validate(["required"], ex?.text),
          });
        });
      }
    });

    feildErrors = feildErrors.filter((e) => e.error === true);
    if (feildErrors.length > 0) {
      setError("intentDoc", { type: "manual", message: feildErrors[0].message });
    } else {
      clearErrors("intentDoc");
    }
  }
  useEffect(() => {
    const form = watch();
    setFormState(form);
    _validateForm(form);
  }, []);

  const fleFormContext = useContext(FLEFormContext);

  return (
    <VStack width="100%">
      <Formizo
        schema={fleFormContext?.mode === "Add" ? schema.Questions.add : schema.Questions.info}
        onCancel={() => {}}
        buttons={[]}
        fieldStyle="grid"
        onChange={(formValue, name, value) => {
          _validateForm(formValue);
          setValue(name, value);
        }}
        defaultValue={formState}
        readOnly={fleFormContext?.mode === "Info"}
      />
    </VStack>
  );
};
