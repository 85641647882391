import { Person, Group } from "@mui/icons-material";
import SysUser from "./SysUser/SysUser";
import SysRole from "./SysRole/SysRole";

const tabs = [
  {
    label: "User Access Control",
    icon: <Person />,
    reqAuth: "UserControl.User",
    render: <SysUser />,
    iconPos: "left",
    noTransform: true,
    alignment: "left",
  },
  {
    label: "Role Based Access Control",
    icon: <Group />,
    reqAuth: "UserControl.Role",
    render: <SysRole />,
    iconPos: "left",
    noTransform: true,
    alignment: "left",
  },
];

export default tabs;
