import { HeaderComponent } from "Components/HeaderComponent";
import Messenger from "Components/Messenger/Messenger";
import { ChatDOMAIN, DOMAIN } from "config/config";
import { SettingPanel } from "./components/SettingPanel/SettingPanel";
import { ArenaChat } from "./components/Arena/ArenaChat";
import { QAMode } from "interfaces";
import { ArenaDetailsPanel } from "./components/Arena/ArenaDetailPanel";
import { T2EResponse } from "interfaces";
import { DATA_LINK, backendConnector } from "connectors";
import React, { useEffect, useState } from "react";
export interface ChatTab {
  label: string;
  name: string; // for auth check
  qaMode?: QAMode;
  reqAuth?: string;
  reqLevel?: number;
  reqFunc?: string;
  noTransform?: boolean;
  icon?: React.ReactElement;
  iconPos?: "left" | "right" | "top" | "bottom";
  spacing?: number;
  alignment?: "left" | "right";
  disabled?: boolean;
  allowSuggestedIntent?: boolean;
  minHeight?: string | number;
  minWidth?: string | number;
  settingComponent?: (addOns: any, setAddOns: any) => JSX.Element;
  detailsComponentOveride?: (addOns: any, setAddOns: any) => JSX.Element;
  render: ((addOns: any, setAddOns: any) => JSX.Element) | JSX.Element;
}

export const tabs: ChatTab[] = [
  {
    label: "SYVA FAQ",
    name: "FAQ",
    qaMode: QAMode.INTENT_CLASSIFICATION,
    reqAuth: "Talk2VA.FAQ",
    noTransform: true,
    allowSuggestedIntent: true,
    render: (addOns, setAddOns) => {
      return (
        <Messenger
          name={"SYVA FAQ"}
          LiveChatEnabled={true}
          viaBackend={false}
          qaMode={QAMode.INTENT_CLASSIFICATION}
          url={`${ChatDOMAIN}${DATA_LINK.Talk2GPT}`}
          onMsgSent={(msg: T2EResponse, id: string) => {
            setAddOns({ ...addOns, payload: msg });
          }}
          onMsgSelected={(record: T2EResponse) => {
            setAddOns({ ...addOns, payload: record });
          }}
        />
      );
    },
  },
  {
    label: "SYVA Hybrid",
    name: "Hybrid",
    qaMode: QAMode.HYBRID,
    reqAuth: "Talk2VA.Hybrid",
    noTransform: true,
    allowSuggestedIntent: true,
    render: (addOns, setAddOns) => {
      return (
        <Messenger
          name={"SYVA Hybrid"}
          project={addOns?.project}
          knowledgeGroup={addOns?.knowledgeGroup}
          LiveChatEnabled
          qaMode={QAMode.HYBRID}
          url={`${ChatDOMAIN}${DATA_LINK.Talk2GPT}`}
          feedbackUrl={`${DOMAIN}${DATA_LINK.UserFeedback}`}
          onMsgSent={(msg: T2EResponse, id: string) => {
            setAddOns({ ...addOns, payload: msg });
          }}
          onMsgSelected={(record: T2EResponse) => {
            setAddOns({ ...addOns, payload: record });
          }}
        />
      );
    },
    settingComponent: (addOns, setAddOns) => <SettingPanel addOns={addOns} setAddOns={setAddOns} tab={"Hybrid"} />,
  },
  {
    label: "SYVA GPT-QA",
    name: "GPTQA",
    qaMode: QAMode.LLM,
    reqAuth: "Talk2VA.GPTQA",
    noTransform: true,
    render: (addOns, setAddOns) => (
      <Messenger
        name={"SYVA GPT-QA"}
        project={addOns?.project}
        knowledgeGroup={addOns?.knowledgeGroup}
        qaMode={QAMode.LLM}
        clearHistoryUrl={`${ChatDOMAIN}${DATA_LINK.RemoveHistory}`}
        feedbackUrl={`${DOMAIN}${DATA_LINK.UserFeedback}`}
        regenerateUrl={`${ChatDOMAIN}${DATA_LINK.Talk2GPTRegenerate}`}
        url={`${ChatDOMAIN}${DATA_LINK.Talk2GPT}`}
        streamUrl={`${ChatDOMAIN}${DATA_LINK.Talk2GPTStream}`}
        streamOutput={true}
        intermediateResponseUrl={`${ChatDOMAIN}${DATA_LINK.Talk2GPTIntermediateResponse}`}
        onMsgSent={(msg: T2EResponse, id: string) => {
          setAddOns({ ...addOns, payload: msg });
        }}
        onMsgSelected={(record: T2EResponse) => {
          setAddOns({ ...addOns, payload: record });
        }}
      />
    ),
    settingComponent: (addOns, setAddOns) => <SettingPanel addOns={addOns} setAddOns={setAddOns} tab={"GPTQA"} />,
  },
  {
    label: "SYVA GPT-QA (Advanced Reasoning)",
    name: "AdvancedReasoning",
    qaMode: QAMode.LLM,
    reqAuth: "Talk2VA.AdvancedReasoning",
    noTransform: true,
    render: (addOns, setAddOns) => (
      <Messenger
        name={"SYVA GPT-QA"}
        project={addOns?.project}
        knowledgeGroup={addOns?.knowledgeGroup}
        LiveChatEnabled={false}
        viaBackend={false}
        qaMode={QAMode.LLM}
        url={`${ChatDOMAIN}${DATA_LINK.Talk2GPT}`}
        clearHistoryUrl={`${ChatDOMAIN}${DATA_LINK.RemoveHistory}`}
        feedbackUrl={`${DOMAIN}${DATA_LINK.UserFeedback}`}
        regenerateUrl={`${ChatDOMAIN}${DATA_LINK.Talk2GPTRegenerate}`}
        onMsgSent={(msg: T2EResponse, id: string) => {
          setAddOns({ ...addOns, payload: msg });
        }}
        onMsgSelected={(record: T2EResponse) => {
          setAddOns({ ...addOns, payload: record });
        }}
      />
    ),
    settingComponent: (addOns, setAddOns) => <SettingPanel addOns={addOns} setAddOns={setAddOns} tab={"AdvancedReasoning"} />,
  },
  {
    label: "Arena",
    name: "Arena",
    reqAuth: "Talk2VA.Arena",
    noTransform: true,
    render: (addOns, setAddOns) => <ArenaChat addOns={addOns} setAddOns={setAddOns} />,
    settingComponent: (addOns, setAddOns) => <SettingPanel addOns={addOns} setAddOns={setAddOns} tab={"Arena"} />,
    detailsComponentOveride: (addOns, setAddons) => <ArenaDetailsPanel addOns={addOns} />,
  },
];
