import { IWebsiteManagerPayload, WebsiteStatus, WebsiteURLType } from "../interfaces/website-manager-api.interface";
import { useFormContext } from "react-hook-form";
import { ControlledTextFieldWithIcon } from "Components/HookForm/ControlledTextFieldWithIcon";
import { VStack } from "Components/Layouts";
import { FormControl, InputLabel, Select, MenuItem, Grid, Typography } from "@mui/material";
import { Authority } from "static";
import { useState } from "react";
import { ColorX } from "static";

interface IProps {
  row?: IWebsiteManagerPayload;
  mode: "Add" | "Edit" | "Info";
}

export const WebsiteInfo = ({ mode, row }: IProps) => {
  const { control, setValue, getValues } = useFormContext<IWebsiteManagerPayload>();

  const [defaultURLType, setDefaultURLType] = useState(row?.urlSetting?.type || WebsiteURLType.EXPLICIT);
  const [displayedUrl, setDisplayedUrl] = useState(getValues("urlSetting.url") || "");

  const accessible = Authority.IsAccessibleQ(`GPTQA.WebsiteManager`, 999, "WebsiteStatus");
  mode = !accessible && (row?.status == WebsiteStatus.INDEXING || row?.status == WebsiteStatus.DELETING) ? "Info" : mode;

  return (
    <VStack width={"100%"}>
      <Grid container spacing={2} sx={{ marginLeft: "30px", marginRight: "30px" }}>
        <Grid item xs={12} sm={10}>
          {mode != "Add" && <ControlledTextFieldWithIcon name="_id" label="ID" control={control} variant="outlined" fullWidth disabled />}
        </Grid>
        <Grid item xs={12} sm={mode != "Add" ? 8 : 10}>
          <ControlledTextFieldWithIcon name="alias" label="Website Name" control={control} variant="outlined" fullWidth disabled={mode == "Info"} />
        </Grid>
        <Grid item xs={12} sm={2}>
          {mode != "Add" && <ControlledTextFieldWithIcon name="status" label="Status" control={control} variant="outlined" fullWidth disabled />}
        </Grid>
        <Grid item xs={12} sm={8}>
          <ControlledTextFieldWithIcon
            name="urlSetting.url"
            label="Website URL"
            control={control}
            variant="outlined"
            fullWidth
            required
            rules={{ required: "Required" }}
            disabled={mode == "Info"}
            value={displayedUrl}
            multiline={mode == "Add" && defaultURLType == WebsiteURLType.EXPLICIT}
            onChange={(event: any) => {
              setDisplayedUrl(event.target.value);
              setValue("urlSetting.url", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">URL Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={defaultURLType}
              label="urlSetting.type"
              disabled={mode == "Info"}
              onChange={(event: any) => {
                setValue("urlSetting.type", event.target.value as WebsiteURLType);

                // handle URL change from explicit to *
                console.log("displayedUrl", displayedUrl);
                let updatedURL = displayedUrl;
                if (event.target.value == WebsiteURLType.IMPLICIT) {
                  updatedURL = updatedURL.includes("\n") ? updatedURL.split("\n")[0] : updatedURL;
                  updatedURL = !updatedURL.endsWith("/*") ? (updatedURL.endsWith("/") ? updatedURL + "*" : updatedURL + "/*") : updatedURL;
                } else {
                  updatedURL = displayedUrl.endsWith("*") ? displayedUrl.replace(/\*$/, "") : displayedUrl;
                }
                setDisplayedUrl(updatedURL);
                setValue("urlSetting.url", updatedURL);
              }}
            >
              <MenuItem value={WebsiteURLType.IMPLICIT}>{WebsiteURLType.IMPLICIT}</MenuItem>
              <MenuItem value={WebsiteURLType.EXPLICIT}>{WebsiteURLType.EXPLICIT}</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5}>
          {mode != "Add" && <ControlledTextFieldWithIcon name="CREATED_AT" label="Created At" control={control} variant="outlined" fullWidth disabled />}
        </Grid>
        <Grid item xs={12} sm={5}>
          {mode != "Add" && <ControlledTextFieldWithIcon name="LASTMODIFIED" label="Last Modified Time" control={control} variant="outlined" fullWidth disabled />}
        </Grid>
        {/* Google URL pattern */}
        <Grid item xs={12} sm={10}>
          {
            <Typography style={{ color: ColorX.GetColorCSS("Primary"), width: Infinity, fontSize: 14 }}>
              * Website URL accepts: <br />
              Implicit (*) pages : www.example.com/page.html/ or www.example.com/docs/ <br />
              Explicit site: www.mysite.com/* or www.example.com/docs/*
            </Typography>
          }
        </Grid>
      </Grid>
    </VStack>
  );
};
