import { IDocumentManagerPayload } from "../../interfaces/document-manager-api.interface";
import { useFormContext } from "react-hook-form";
import { ControlledTextFieldWithIcon } from "Components/HookForm/ControlledTextFieldWithIcon";
import { Button, Grid, Typography } from "@mui/material";
import { DATA_LINK } from "connectors/datalink";
import { HStack, VStack } from "Components/Layouts";
import { DowlonadButton } from "Components/DownloadButton";

export const DocumentInfo = ({ mode }: { mode: "Add" | "Edit" | "Info" }) => {
  const { control, getValues } = useFormContext<IDocumentManagerPayload>();

  const renderDownloadButton = (filePath: "original_file" | "text_file") => {
    const filename = getValues("alias");
    const redirectURL = getValues(`${filePath}.location`);
    const disabled = !getValues(`${filePath}.available`);
    return (
      <DowlonadButton variant="contained" disabled={disabled} filename={filename} URL={DATA_LINK.DMDownload} redirectURL={redirectURL}>
        {<Typography> {filePath == "original_file" ? "Download Processed File" : "Download Original File"}</Typography>}
      </DowlonadButton>
    );
  };

  return (
    <VStack width={"100%"} sx={{ alignItems: "flex-start" }}>
      <Grid container spacing={2} sx={{ marginLeft: "30px", marginRight: "30px" }}>
        <Grid item xs={12} sm={2}>
          <ControlledTextFieldWithIcon name="_id" label="ID" control={control} variant="outlined" fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextFieldWithIcon
            name="alias"
            label="Document Name"
            control={control}
            variant="outlined"
            fullWidth
            disabled={mode === "Info"}
            rules={{
              required: "This field is required",
              validate: {
                notOnlyWhitespace: (value) => value.trim().length > 0 || "The field cannot be empty",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          {renderDownloadButton("text_file")}
        </Grid>
        <Grid item xs={12} sm={2}>
          {renderDownloadButton("original_file")}
        </Grid>
        {/* <Grid item xs={12} sm={2}>
          <Button variant="contained" color="secondary" fullWidth>
            Re-Upload Document
          </Button>
        </Grid> */}
      </Grid>
      <Typography variant="body1" color="primary.main" style={{ font: "normal normal normal 16px/21px Segoe UI", marginLeft: "50px", marginBottom: "10px" }}>
        Reference Settings
      </Typography>
      <Grid container spacing={2} sx={{ marginLeft: "30px", marginRight: "30px" }}>
        <Grid item xs={3} sm={3}>
          <ControlledTextFieldWithIcon name="reference.title" label="Reference Button Label" control={control} variant="outlined" fullWidth disabled={mode == "Info"} />
        </Grid>
        <Grid item xs={6} sm={6}>
          <ControlledTextFieldWithIcon name="reference.link" label="Reference URL" control={control} variant="outlined" fullWidth disabled={mode == "Info"} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginLeft: "30px", marginRight: "30px" }}>
        <Grid item xs={12} sm={10}>
          {mode != "Add" ? <ControlledTextFieldWithIcon name="CREATED_AT" label="Created At" control={control} variant="outlined" fullWidth disabled /> : <></>}
        </Grid>
        <Grid item xs={12} sm={10}>
          {mode != "Add" ? <ControlledTextFieldWithIcon name="LASTMODIFIED" label="Last Modified Time" control={control} variant="outlined" fullWidth disabled /> : <></>}
        </Grid>
      </Grid>
    </VStack>
  );
};
