import { Box } from "@mui/material";
import { DatePicker } from "antd";
import moment from "moment";
import { FLEOperator, ISystemContextDate } from "interfaces/db.interfaces/fle.interface";
import { BodyTitle } from "Components";
import { DropDownSelector } from "Components/DropDownSelector";
import { AddButton, DeleteButton } from "Components/IconButtons";
import { HStack, VStack } from "Components/Layouts";
import { ITypeSettingProps } from ".";

export const DateSetting = ({ setting, onChange, disabled, ...props }: ITypeSettingProps<ISystemContextDate>) => (
  <VStack width={"100%"} alignItems={"flex-start"}>
    <BodyTitle>Date Values</BodyTitle>
    <VStack width="100%">
      <>
        {setting.values &&
          setting.values.map((sVal, svInx) => {
            return (
              <HStack key={svInx} sx={{ maxWidth: "768px" }}>
                <DropDownSelector
                  value={sVal.operator}
                  options={[
                    { label: "Equals", value: FLEOperator.eq },
                    { label: "Greater Than", value: FLEOperator.gt },
                    { label: "Less Than", value: FLEOperator.lt },
                  ]}
                  handleChange={(value) => {
                    const { values } = setting;
                    if (values) {
                      values[svInx].operator = value as FLEOperator;
                      onChange({ ...setting, values });
                    }
                  }}
                  disabled={disabled}
                />
                <Box sx={{ width: "70%" }}>
                  <DateSelector
                    //
                    value={sVal.value}
                    handleChange={(value) => {
                      if (setting.values) setting.values[svInx].value = value;
                      onChange({ ...setting });
                    }}
                    disabled={disabled}
                  />
                </Box>

                <DeleteButton
                  onClick={() => {
                    setting.values && setting.values.splice(svInx, 1);
                    onChange({ ...setting });
                  }}
                  disabled={disabled}
                />
              </HStack>
            );
          })}
      </>
      <AddButton
        onClick={() => {
          let { values } = setting;
          if (!values) values = [];
          values.push({ value: moment().format("YYYY-MM-DD"), operator: FLEOperator.eq });
          onChange({ ...setting, values });
        }}
        disabled={disabled}
      />
    </VStack>
  </VStack>
);

const DateSelector = ({ value, handleChange, ...props }: { value: string; handleChange: (value: string) => void; disabled?: boolean }) => {
  return (
    <DatePicker
      value={moment(value)}
      picker={"date"}
      showTime={false}
      onChange={(v) => {
        handleChange(v ? moment(v).format("YYYY-MM-DD") : "");
      }}
      onFocus={(e) => {
        // _onFieldFocus();
      }}
      onBlur={(e) => {
        // _onFieldBlur();
        // _onBlurInlineSubmit(iname);
      }}
      disabledDate={() => false}
      allowClear={true}
      {...props}
    />
  );
};
