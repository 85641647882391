import { Component } from "react";

import PropsType from "prop-types";
import _ from "lodash";
import { AutoComplete } from "antd";

import { Accessor } from "static";
import ZCMsg from "Components/Messenger/components/ZChat/ZCMsg/ZCMsg";
import AnsLib from "static/AnsLib";
import { Popover, Typography, Box } from "@mui/material";

const { Option } = AutoComplete;
const styles = {
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "1px",
  },
};
/**
 * @augments {Component<Props, State>}
 */
class FFAutoComplete extends Component {
  static propTypes = {
    //data
    ischema: PropsType.object.isRequired,
    iname: PropsType.string.isRequired,
    addOns: PropsType.object.isRequired,

    //root func
    _onValueChange: PropsType.func.isRequired,
    _onBlurInlineSubmit: PropsType.func.isRequired,
    _onInlineSubmit: PropsType.func.isRequired,
    _onFieldFocus: PropsType.func.isRequired,
    _onFieldBlur: PropsType.func.isRequired,

    //disability
    errorsShowOnHelperText: PropsType.bool.isRequired,
    readOnly: PropsType.bool.isRequired,

    //runtime
    formValue: PropsType.object.isRequired,
    formError: PropsType.object.isRequired,

    //style
    ifieldStyle: PropsType.oneOf(["grid", "standard", "filled", "outlined"]).isRequired,
  };

  static defaultProps = {
    ischema: {},
    iname: "",
    addOns: {},

    _onValueChange: () => {},
    _onBlurInlineSubmit: () => {},
    _onInlineSubmit: () => {},
    _onFieldFocus: () => {},
    _onFieldBlur: () => {},

    errorsShowOnHelperText: true,
    readOnly: false,

    formValue: {},
    formError: {},

    fieldStyle: "grid",
  };

  constructor() {
    super();
    this.state = {
      allowPop: true,
    };
  }

  componentDidMount() {
    this._setAllStates();
    let { formValue, ischema, iname, addOns } = this.state;
    let ivalue = Accessor.Get(formValue, iname);
    if (ivalue) {
      let options;
      if (_.isArray(ischema.selectRef)) {
        options = ischema.selectRef;
      } else {
        options = Accessor.Get(addOns, ischema.selectRef);
      }
      let filtered = _.filter(options, (o, i) => Accessor.Get(o, ischema.selectVal) === ivalue);
      if (filtered.length === 1) {
        let capValue = Accessor.Get(filtered[0], ischema.selectCap);
        this.setState({
          value: capValue,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(FFAutoComplete.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      () => {
        let { formValue, ischema, iname, _Validate, _onValueChange, addOns, value } = this.state;
        let ivalue = Accessor.Get(formValue, iname);
        if (!_.isEmpty(ischema.validate)) {
          _Validate(iname, ivalue, ischema.validate);
        }
        if (!ivalue && ischema.defaultValue) {
          _onValueChange(iname, ischema.defaultValue, ischema.validate);
        }
        if (ivalue && !value) {
          let options = Accessor.Get(addOns, ischema.selectRef);
          let filtered = _.filter(options, (o, i) => Accessor.Get(o, ischema.selectVal) === ivalue);
          if (filtered.length === 1) {
            let capValue = Accessor.Get(filtered[0], ischema.selectCap);
            this.setState({
              value: capValue,
            });
          }
        }
      }
    );
  };

  onSelect = (data) => {
    let { ischema, iname, _onValueChange, addOns } = this.props;
    if (!data) {
      _onValueChange(iname, "", ischema.validate);
    } else {
      let options = Accessor.Get(addOns, ischema.selectRef);
      let filtered = _.filter(options, (o, i) => Accessor.Get(o, ischema.selectCap) === data);
      if (filtered.length === 1) {
        let trueValue = Accessor.Get(filtered[0], ischema.selectVal);
        _onValueChange(iname, trueValue, ischema.validate);
      }
    }

    this.setState({
      value: data,
    });
  };

  onSearch = (data) => {
    this.setState({
      value: data,
    });
  };

  onAmbiguousSearch = (inputValue, option) => {
    if (!option) return false;
    const match = option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
    const isMatchUnderscore = option.value.toUpperCase().indexOf(inputValue.toUpperCase().replace(/ /g, "_")) !== -1;
    return match || isMatchUnderscore;
  };
  onPopoverOpen = (e) => {
    let { disabled } = this.props;
    if (disabled) return;
    this.setState({
      anchorEl: e.currentTarget,
    });
  };

  onPopoverClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  customChildren = (value) => {
    if (value.startsWith("Context_Action_")) {
      return {
        children: _.cloneDeep(value).replace("Context_Action_", ""),
        sx: { backgroundColor: "rgba(0, 0, 0, 0.04)" },
      };
    }
    if (value.startsWith("FAQ_")) {
      return {
        children: _.cloneDeep(value).replace("FAQ_", ""),
        sx: { backgroundColor: "rgba(200, 10, 0, 0.04)" },
      };
    }

    return {
      children: value,
    };
  };

  checkPopoverOpen = (displayText, value) => {
    if (value.toLowerCase().includes(displayText.toLowerCase())) {
      return true;
    }
  };

  render() {
    let { ischema, value, anchorEl } = this.state;
    if (!ischema) return <div />;
    const { addOns } = this.props;
    let options = Accessor.Get(addOns, ischema.selectRef);

    let { classes } = this.props;
    return (
      <AutoComplete
        value={value}
        // options={ioptions}
        onSelect={() => {
          this.setState({ allowPop: false });
        }}
        onDropdownVisibleChange={() => {
          // this.setState({ disabled: false });
        }}
        onChange={this.onSelect}
        onSearch={this.onSearch}
        placeholder={ischema.placeholder || "Suggested FAQ"}
        filterOption={this.onAmbiguousSearch}
        style={{
          width: ischema.width,
          minWidth: ischema.width,
          maxWidth: ischema.width,
        }}
        notFoundContent={ischema.noMatchText || "No matches found."}
        allowClear
      >
        {_.map(options, (o, i) => {
          let ansObj = "";
          let value = Accessor.Get(o, ischema.selectCap);

          //upon no answer
          if (!o.ans)
            return (
              <Option key={i} value={value}>
                <Typography {...this.customChildren(value)} />
              </Option>
            );

          //uppon o.ans
          let { AnsLibLang } = addOns;
          if (!AnsLibLang) AnsLibLang = "EN";
          ansObj = AnsLib.Doc2Chat(o.ans, AnsLibLang);

          return (
            <Option key={i} value={value}>
              <Typography onMouseEnter={this.onPopoverOpen} onMouseLeave={this.onPopoverClose} onMouseDown={this.onPopoverClose} {...this.customChildren(value)}></Typography>

              {/* //upon hover */}
              <Popover
                id={"select-pop" + i}
                sx={{
                  ...styles.popover,
                  ...styles.paper,
                  width: "100%",
                }}
                open={Boolean(anchorEl) && this.checkPopoverOpen(anchorEl.outerText, value)}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                onClose={this.onPopoverClose}
                disableRestoreFocus
                disableEnforceFocus
                disableAutoFocus
              >
                <Box maxWidth="375px">
                  <ZCMsg cssp="zchat z" _onQuickReply={() => {}} pos="in" item={ansObj} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
                </Box>
              </Popover>
            </Option>
          );
        })}
      </AutoComplete>
    );
  }
}

export default FFAutoComplete;
