import { MiddlewareConnector } from "Pages/CombinedTalk2VA/connectors/middleware.connector";
import { IQuickReply, IT2EDetect, T2EStreamResponse, T2EResponse, ZChat as IZChat } from "interfaces";
import _ from "lodash";
import moment from "moment";
import { useState, useRef, useEffect, useMemo } from "react";
import { v4 } from "uuid";
import { MESSAGE_STATUS } from "../Messenger";
import { Analyzer } from "../classes/Analzyer";
import { UserId } from "../interfaces/messenger.interface";

export function useUrlParams(dependencies = []) {
  const urlParams = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParamsEntries = searchParams.entries();
    const _urlParams = Object.fromEntries([...searchParamsEntries].map(([key, value]) => (key.includes("[]") ? [key.replace("[]", ""), searchParams.getAll(key)] : [key, value])));
    return _urlParams;
  }, dependencies);

  return { urlParams };
}
