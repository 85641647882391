import { Typography } from "@mui/material";
import { HStack, VStack } from "Components";
import { ColorX } from "static";

import VersionList from "Components/VersionList";

export default function SysVersion() {
  const renderVersion = () => {
    let style = {
      color: ColorX.GetColorCSS("Primary"),
      fontSize: 12,
    };
    return (
      <VStack gap="1px" alignItems="left">
        <Typography variant="h5" style={{ color: ColorX.GetColorCSS("Primary") }}>
          SYVA Version
        </Typography>
        {VersionList(style)}
      </VStack>
    );
  };
  return (
    <HStack justifyContent="flex-start" height="100%" gap={3} paddingX={4}>
      <VStack width="100%" justifyContent={"flex-start"} height="100%" p={3}>
        {renderVersion()}
      </VStack>
    </HStack>
  );
}
