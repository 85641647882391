import { useState } from "react";
import Dropzone from "react-dropzone";
import { VStack } from "Components/LabIZO/Stackizo";
import { CloudUploadOutlined } from "@mui/icons-material";

//https://react-dropzone.js.org/#section-basic-example

const ImportDropzone = ({
  //
  onDrop = (acceptedFiles) => console.log(acceptedFiles),
  accept = [".gz"],
  onError = (error) => console.log(error),
  multiple = false,
}) => {
  const [acceptedFiles, setAcceptedFiles] = useState();
  const [over, setOver] = useState(false);

  const checkFileType = (acceptedFiles) => {
    const fileType = "." + acceptedFiles[0].name.split(".").pop();
    return accept.includes(fileType);
  };

  const _onDrop = (_acceptedFiles) => {
    const isFileValid = checkFileType(_acceptedFiles);
    if (!isFileValid) return onError("Sorry wrong type");
    setAcceptedFiles(_acceptedFiles);
    onDrop(_acceptedFiles);
  };

  return (
    <Dropzone onDrop={_onDrop} accept={accept} onDragEnter={() => setOver(true)} onDragLeave={() => setOver(false)}>
      {({ getRootProps, getInputProps }) => (
        <VStack className="Dropzone" width="100%">
          <div {...getRootProps({ className: "inner " + (over ? "over" : "") })}>
            <CloudUploadOutlined fontSize="large" />
            <input {...getInputProps({ multiple: multiple })} />
            <p>Drag and drop some files here, or click to select files</p>
            <em>{`(Only ${accept} will be accepted)`}</em>
          </div>
          <aside>
            <ul>{acceptedFiles && acceptedFiles.map((file) => <li key={file.path}>{file.path}</li>)}</ul>
          </aside>
        </VStack>
      )}
    </Dropzone>
  );
};

export default ImportDropzone;
