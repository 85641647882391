import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import ZCTouch from "../ZCTouch";
import _ from "lodash";
import { DeleteOutline } from "@mui/icons-material";

interface IProps {
  cssp?: string;
  onClearHistory?: () => void;
}

const ZCMQuit = ({ cssp, onClearHistory }: IProps) => {
  const renderQuitButton = () => {
    return (
      <IconButton onClick={onClearHistory}>
        <DeleteOutline />
      </IconButton>
    );
  };

  return <Box className={cssp}>{renderQuitButton()}</Box>;
};

export default ZCMQuit;
