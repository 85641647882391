import React, { useEffect, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

interface ModelSelectProps {
  option: { label: string; value: string }[];
  setModel: (value: string) => void;
  value?: string;
  setPanelStatus?: (status: boolean) => void;
}

export const ModelSelectHeader = ({ option, setModel, value, setPanelStatus }: ModelSelectProps) => {
  const [time, setTime] = useState<Date | null>(new Date());
  const [modelValue, setmodelValue] = useState<string>(value || "");
  const selectedOption = option.find((item) => item.value === value);
  const [chatbotName, setChatbotName] = useState<string>(selectedOption?.label || "");

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleChange = (value: string) => {
    setmodelValue(value);
    if (setModel) setModel(value);
    const selectedOption = option.find((item) => item.value === value);
    if (selectedOption) {
      setChatbotName(selectedOption.label);
    }
    setPanelStatus && setPanelStatus(true);
  };
  function formatDate(date: Date) {
    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    const day = date?.getDate();
    const month = months[date?.getMonth()];
    const weekday = days[date?.getDay()];

    return `${day} ${month} (${weekday})`;
  }
  function dropDownSelecter() {
    return (
      <FormControl variant="filled" fullWidth>
        <InputLabel style={{ color: "white" }} id="demo-simple-select-label">
          Model
        </InputLabel>
        <Select
          style={{ color: "white" }}
          id="modelselect"
          label="Model"
          value={modelValue}
          onChange={(e) => {
            if (handleChange) handleChange(e.target.value as string);
          }}
        >
          {option.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <Box className="headline">
      <Box style={{ width: "40%" }}>{dropDownSelecter()}</Box>
      <Box className="right">
        <div className="text">{chatbotName}</div>
        {time && <div className="time">{`${formatDate(time)} ${time?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false })}`}</div>}
      </Box>
    </Box>
  );
};
