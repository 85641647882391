import { FormizoSchema } from "Components/LabIZO/Formizo";

const defaultMessagesSchema: FormizoSchema[] = [
  {
    label: "Default message ID",
    name: "welcome._id",
    format: "text",
    variant: "outlined",
    readOnly: true,
  },
  {
    label: "Text (EN)",
    name: "welcome.text.EN",
    variant: "standard",
    format: "textarea",
  },
  {
    label: "Text (TC)",
    name: "welcome.text.TC",
    variant: "standard",
    format: "textarea",
  },
  {
    label: "Text (SC)",
    name: "welcome.text.SC",
    variant: "standard",
    format: "textarea",
  },
  {
    label: "Default message ID",
    name: "noAnswer._id",
    format: "text",
    variant: "outlined",
    readOnly: true,
  },
  {
    label: "Text (EN)",
    name: "noAnswer.text.EN",
    variant: "standard",
    format: "textarea",
  },
  {
    label: "Text (TC)",
    name: "noAnswer.text.TC",
    variant: "standard",
    format: "textarea",
  },
  {
    label: "Text (SC)",
    name: "noAnswer.text.SC",
    variant: "standard",
    format: "textarea",
  },
];

const disclaimerSchema: FormizoSchema[] = [
  {
    label: "Disclaimer Text (EN)",
    name: "disclaimer.Content.EN",
    variant: "outlined",
    format: "textarea",
  },
  {
    label: "Disclaimer Text (TC)",
    name: "disclaimer.Content.TC",
    variant: "outlined",
    format: "textarea",
  },
  {
    label: "Disclaimer Text (SC)",
    name: "disclaimer.Content.SC",
    variant: "outlined",
    format: "textarea",
  },
];

export const schema: FormizoSchema[] = [
  {
    reqAuth: "System.Texts",
    reqFunc: "DefaultMessages",
    accessizo: [
      {
        label: "Default Messages",
        foldStyle: "outlined",
        defaultShow: false,
        collapse: defaultMessagesSchema,
      },
    ],
  },
  {
    reqAuth: "System.Texts",
    reqFunc: "Disclaimer",
    accessizo: [
      {
        label: "Disclaimer",
        foldStyle: "outlined",
        defaultShow: false,
        collapse: disclaimerSchema,
      },
    ],
  },
];
