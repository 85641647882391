export enum WebsiteStatus {
  INDEXED = "indexed",
  INDEXING = "indexing",
  DELETING = "deleting",
  EDITING = "editing",
  ERROR = "error",
}

export enum WebsiteURLType {
  EXPLICIT = "explicit",
  IMPLICIT = "*",
}

interface WebsiteURL {
  type: WebsiteURLType;
  url: string;
}

export interface IWebsiteManagerPayload {
  _id: string;
  alias: string;
  enabled: boolean;
  urlSetting: WebsiteURL;
  status: WebsiteStatus;
  CREATED_AT: string;
  LASTMODIFIED: string;
  notice?: boolean;
}
