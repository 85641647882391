import React, { Component } from "react";

import { Box, Typography } from "@mui/material";

import schema from "./schema";
import datalink from "./datalink";
import axios from "axios";

import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import { VStack } from "Components/LabIZO/Stackizo";
import { Accessor, ColorX, Authority, store } from "static";
import { DOMAIN } from "config/config";

class FileHandler extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      title: "File Control",
      serverSidePagination: false,
      base: {
        title: "File Control",
        exportDoc: "files",
        schema: schema,
        reqAuth: "FileHandler",

        tablizo: {
          columnsToolbar: true,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
          showSelector: true,
        },

        Connect: {
          DBInfo: datalink.Request.DBInfo,
          List: datalink.Request.List,
          schema: schema.Table,
        },

        operations: {
          Add: {
            title: "Add File",
            url: datalink.Request.Add,
            success: "File Added Successfully",
            fail: "File Add Failed: ",
            schema: schema.Add,
            buttons: ["Clear", "Submit"],
            onSubmit: "Add",
            withFile: true,
          },
          Info: {
            title: "Image Preview",
            schema: schema.Info,
            readOnly: true,
          },
          Delete: {
            title: "Delete this File?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.Delete,
            success: "File Deleted Successfully.",
            fail: "File Delete Failed: ",
            onSubmit: "Delete",
          },
          DeleteBulk: {
            title: (n) => "Delete these " + n + " files?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.DeleteBulk,
            success: "Files Deleted Successfully.",
            fail: "Files Delete Failed: ",
            onSubmit: "DeleteBulk",
          },
        },

        buttons: {
          inline: [{ icon: "info", func: "Info", caption: "Details" }],
          inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete", reqLevel: 2 }],
          left: [{ icon: "add", func: "Add", caption: "Add File", reqFunc: "Add", reqLevel: 2 }],
          right: [{ icon: "deletebulk", func: "DeleteBulk", caption: (n) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution", reqLevel: 2 }],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("FileHandler");
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(FileHandler.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  render() {
    let { base, serverSidePagination, title } = this.state;
    return (
      <VStack>
        <Box padding={1} width="100%">
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: 25,
              color: ColorX.GetColorCSS("Primary"),
            }}
          >
            {title}
          </Typography>
        </Box>
        <Datumizo base={base} serverSidePagination={serverSidePagination} addOns={{}} onLoad={this._setAllStates} />
      </VStack>
    );
  }
}

export default FileHandler;
