import { MissingIcon } from "Components/IconButtons/MissingIcon";
import { Typography } from "@mui/material";
import { IFLEDoc, IFLERef } from "interfaces/db.interfaces/fle.interface";
import { HStack } from "Components/Layouts";
import { CopyButton } from "Components/IconButtons/CopyButton";
import { Control, Controller, useFormContext } from "react-hook-form";
import { DBAnswerSpec } from "interfaces";

interface IContextIdProps {
  lang?: string;
  showCopyButton?: boolean;
  name: `contexts.${number}.alias.id`;
}

export const ContextId = ({ lang = "EN", showCopyButton = true, ...props }: IContextIdProps) => {
  const { control, watch } = useFormContext<IFLEDoc<DBAnswerSpec, IFLERef>>();

  const contextID = watch(props.name);
  if (contextID == "") return <MissingIcon />;

  return (
    <HStack alignItems={"flex-start"}>
      <Typography
        color="primary"
        sx={{
          //overflow text
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {"#" + contextID}
      </Typography>
      {showCopyButton && <CopyButton onClickCopy={`{#${contextID}}`} />}
    </HStack>
  );
};
