import { MenuItem, Select } from "@mui/material";
import { CombinationOperator, ConditionOperator, DefaultFilterType, FieldType, Filterable } from "Components/LabIZO/Tablizo";
const Table = [
  {
    label: "Status",
    name: "Status",
    defaultSort: "desc",
    Cell: (data: object, field: { Progress: number; Message: string }) => {
      let color = "grey";
      if (!field || !field.Progress) return color;
      switch (field.Progress) {
        case 1:
          color = "black";
          break; //Unreviewed
        case 2:
          color = "green";
          break; //learn
        case 3:
          color = "#005F73"; //syva green
          break; //need discussion
        case 4:
          color = "#0A2F96"; // blue
          break; //already ok
        case 999:
          color = "#DA1E28"; // red
          break; //reject
        default:
          color = "grey";
          break;
      }
      return <span style={{ color: color }}>{field?.Message}</span>;
    },
  },
  {
    label: "Language",
    name: "language",
  },
  {
    label: "Input",
    name: "input",
  },
  {
    label: "Intent",
    name: "intent",
  },
  {
    label: "Suggested Intent",
    name: "comment.suggest_intent",
  },
  {
    label: "Remarks",
    name: "comment.remarks",
  },
];

const Add: object[] = [];

const Info: object[] = [];

const Edit = [...Info];

const Export = [
  {
    label: "Answer ID",
    name: "_id",
  },
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Domain",
    name: "domain",
  },
  {
    label: "Category",
    name: "category",
  },
  {
    label: "Sub-Category",
    name: "subcategory",
  },
  {
    label: "Sample (EN) sep.';;'",
    name: "text.EN",
    format: "array",
    separator: ";;",
  },
  {
    label: "Sample (TC) sep.';;'",
    name: "text.TC",
    format: "array",
    separator: ";;",
  },
  {
    label: "Sample (SC) sep.';;'",
    name: "text.SC",
    format: "array",
    separator: ";;",
  },
  {
    label: "Quick Replies (JSON)",
    name: "quickReplies",
    format: "json",
  },
];

const Import = [
  {
    name: "upload",
    label: "Upload file",
    format: "file",
    validate: ["required"],
    accept: ".xlsx, xls",
  },
];

const ImportFormat = [...Export];

const Filter = [
  {
    label: "AnsID",
    format: "text",
    fields: [{ name: "_id" }],
  },
  {
    label: "Answer",
    format: "text",
    fields: [
      { name: "text.EN", type: "array" },
      { name: "text.TC", type: "array" },
      { name: "text.SC", type: "array" },
    ],
  },
  {
    label: "Domain",
    format: "text",
    fields: [{ name: "domain", type: "text" }],
  },
  {
    label: "Category",
    format: "text",
    fields: [{ name: "category", type: "text" }],
  },
  {
    label: "SubCat",
    format: "text",
    fields: [{ name: "subcategory", type: "text" }],
  },
];

const AdvFilter = [
  {
    label: "FAQ ID",
    name: "_id",
    format: "text",
  },
  {
    label: "Answer EN",
    name: "text.EN",
    format: "text",
  },
  {
    label: "Answer TC",
    name: "text.TC",
    format: "text",
  },
  {
    label: "Answer SC",
    name: "text.SC",
    format: "text",
  },
  {
    inline: [
      {
        label: "Date From",
        name: "dateFrom",
        format: "datetime",
      },
      {
        label: "To",
        name: "dateTo",
        format: "datetime",
      },
    ],
  },
];

const Filterables: Filterable[] = [
  {
    field: "Status.Message",
    label: "Status",
    type: FieldType.text,
    CustomFilterValue: ({ field, width, fieldState }) => {
      return (
        <Select sx={{ width }} variant="standard" onChange={field && field.onChange} value={field && field.value} error={Boolean(fieldState?.error)}>
          <MenuItem value={"Unreviewed"}>Unreviewed</MenuItem>
          <MenuItem value={"Pass"}>Pass</MenuItem>
          <MenuItem value={"Reject"}>Reject</MenuItem>
          <MenuItem value={"Discuss"}>Discuss</MenuItem>
          <MenuItem value={"Learn"}>Learn</MenuItem>
        </Select>
      );
    },
  },
  {
    field: "language",
    label: "Language",
    type: FieldType.text,
    CustomFilterValue: ({ field, width, fieldState }) => {
      return (
        <Select sx={{ width }} variant="standard" onChange={field && field.onChange} value={field && field.value} error={Boolean(fieldState?.error)}>
          <MenuItem value={"EN"}>EN</MenuItem>
          <MenuItem value={"TC"}>TC</MenuItem>
          <MenuItem value={"SC"}>SC</MenuItem>
        </Select>
      );
    },
  },
];

const DefaultFilter: DefaultFilterType = {
  logicalOperator: CombinationOperator.and,
  conditions: [
    {
      field: "Status.Message",
      operator: ConditionOperator.eq,
      value: "Unreviewed",
    },
  ],
};

const schema = {
  Table,
  Info,
  Add,
  Edit,
  Export,
  Import,
  ImportFormat,
  Filter,
  AdvFilter,
  Filterables,
  DefaultFilter,
};

export default schema;
