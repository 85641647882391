import { IKnowledgeGrpManagementPayload, IDocList } from "../../interfaces/knowledge-grp-management-api.interface";
import { SetStateAction, useState } from "react";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { KnowledgeGrpDocList } from "./KnowledgeGrpDocList";

interface IProps {
  row?: IKnowledgeGrpManagementPayload;
  mode: "Add" | "Edit" | "Info";
}
export const KnowledgeGrpDocInfo = ({ mode, row }: IProps) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <KnowledgeGrpDocList infoView={mode == "Info"} />
      </Box>
    </Box>
  );
};
