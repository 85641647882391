import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { HStack, VStack } from "Components/Layouts";

interface ExpandableBoxProps {
  component: JSX.Element;
}

export default function ExpandableBox({ component }: ExpandableBoxProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HStack height="100%">
      {isOpen && component}
      <IconButton onClick={handleToggle}>{isOpen ? <ArrowBack /> : <ArrowForward />}</IconButton>
    </HStack>
  );
}
