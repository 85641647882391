import { FormizoSchema } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import { store } from "static";
import _ from "lodash";

export const QuickReply = (answerId: string): FormizoSchema => ({
  label: "Quick Reply Buttons",
  foldStyle: "outlined",
  defaultShow: false,
  collapse: [
    {
      label: "",
      name: `answerDocs.${answerId}.quickReplies`,
      canAdd: true,
      canDelete: true,
      headerStyle: "outlined",
      addStyle: "header",
      array: [
        {
          label: "Display",
          width: 50,
          name: "title",
          tabs: [
            {
              label: "TC",
              page: [
                {
                  label: "Display",
                  name: "title.TC",
                  format: "text",
                },
              ],
            },
            {
              label: "EN",
              page: [
                {
                  label: "Display",
                  name: "title.EN",
                  format: "text",
                },
              ],
            },
          ],
          validate: ["required"],
        },
        {
          label: "Button Payload",
          name: "payload",
          format: "select",
          selectStyle: "dropdown",
          selectRef: "intents",
          selectCap: "caption",
          selectVal: "_id",
          placeholder: "Intent",
          validate: ["required"],
          variant: "grid",
        },
      ],
    },
  ],
});

export const Answer = (answerId: string, format: string = "textarea"): FormizoSchema => ({
  tabs: store.sysInfo.Language.available
    .filter((lang) => lang.value != "SC")
    .reverse()
    .map((lang) => ({
      label: `${lang.display}`,
      page: () => [
        {
          label: "",
          name: `answerDocs.${answerId}.text.${lang.value}.0`,
          format: format,
          variant: "standard",
          validate: ["required"],
        },
      ],
    })),
});

export const ConfigAnswer = (answerId: string, format: string = "textarea"): FormizoSchema => ({
  tabs: store.sysInfo.Language.available
    .filter((lang) => lang.value != "SC")
    .reverse()
    .map((lang) => ({
      label: `${lang.display}`,
      page: () => [
        {
          label: "",
          name: `${answerId}.${lang.value}`,
          format: format,
          variant: "standard",
          validate: ["required"],
        },
      ],
    })),
});

const messages: FormizoSchema[] = [
  {
    label: "General Messages",
    foldStyle: "outlined",
    defaultShow: true,
    collapse: [
      {
        label: "Welcome message",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:WELCOME", "textarea"), QuickReply("master:WELCOME")],
      },
      {
        label: "Text input only warning",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:NON_TEXT_INPUT"), QuickReply("master:NON_TEXT_INPUT")],
      },
      {
        label: "Temporary failure message",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:ERROR"), QuickReply("master:ERROR")],
      },
      {
        label: "General menu",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:NOANSWER"), QuickReply("master:NOANSWER")],
      },
      {
        label: "Resolve (Talk2VA)",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:RESOLVE_Talk2VA"), QuickReply("master:RESOLVE_Talk2VA")],
      },
      {
        label: "Resolve (Web)",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:RESOLVE_Web"), QuickReply("master:RESOLVE_Web")],
      },
      {
        label: "Resolve (Wechat)",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:RESOLVE_Wechat"), QuickReply("master:RESOLVE_Wechat")],
      },
      {
        label: "Suggested Intent display message (Web)",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [ConfigAnswer("mwSettingsConfig.FollowUpMessage.Web")],
      },
      {
        label: "Suggested Intent display message (Wechat)",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [ConfigAnswer("mwSettingsConfig.FollowUpMessage.Wechat")],
      },
    ],
  },
];

const mwconfig: FormizoSchema[] = [
  {
    label: "General Config",
    foldStyle: "outlined",
    defaultShow: true,
    collapse: [
      {
        label: "Number of anything_else to trigger LiveChat QuickReply button",
        name: "mwSettingsConfig.noAnswerCount",
        format: "number",
      },
      {
        header: "Confidence Level",
      },
      {
        label: "High:",
        name: "mwSettingsConfig.CFLevel.HIGH",
        format: "number",
      },
      {
        label: "Vague:",
        name: "mwSettingsConfig.CFLevel.VAGUE",
        format: "number",
      },
      {
        label: "Mid:",
        name: "mwSettingsConfig.CFLevel.MID",
        format: "number",
      },
    ],
  },
];

export const schema: FormizoSchema[] = [...mwconfig, ...messages];
