export interface ISystem {
  _id: string;
  _rev?: string;
  type: string;
  Config: ISystemConfig;
}

export interface ISystemConfig {
  Name: string;
  Language: {
    default: string;
    available: ISLang[];
  };
  MenuMode: MenuMode;
}

interface ISLang {
  display: string;
  value: string;
}

export enum MenuMode {
  CLASSIC = "classic",
  STARTER = "starter",
}
