import { Box, Typography } from "@mui/material";
import { Component } from "react";
import moment from "moment";
import datalink from "./datalink";
import schema from "./schema";
import { HStack, VStack } from "Components";
import { Accessor, Authority, ColorX } from "static";
import Formizo from "Components/LabIZO/Formizo";
import { Spacer } from "Components/LabIZO/Stackizo";
import Datumizo from "Components/LabIZO/Datumizo";

type RequestLogProps = typeof RequestLog.defaultProps;

interface RequestLogStates {
  title: string;
  period: { DateRange: [string, string] };
  serverSidePagination: boolean;
  base: any;
}

class RequestLog extends Component<RequestLogProps, RequestLogStates> {
  static defaultProps = {};
  MountDatumizo: Record<string, (...args: any[]) => any> = {};

  constructor(props: RequestLogProps) {
    super(props);
    this.state = {
      title: "REQUEST LOG",
      period: { DateRange: [moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"), moment().endOf("month").format("YYYY-MM-DD HH:mm:ss")] },
      serverSidePagination: true,
      base: {
        title: "RequestLog",
        exportDoc: "RequestLog",
        schema,
        reqAuth: "RequestLog",

        tablizo: {
          showSelector: true,
          columnsToolbar: false,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          List: datalink.Request.List,
          schema: schema.Table,
        },

        operations: {
          Export: {
            url: datalink.Request.Export,
            schema: schema.Export,
          },

          Info: {
            title: "REQUEST LOG DETAILS",
            url: datalink.Request.Info,
            success: "Request Log Successful",
            fail: "Request Log Failed: ",
            schema: schema.Info,
            readOnly: true,
          },
        },

        buttons: {
          inline: [{ icon: "info", func: "Info", reqFunc: "Info", caption: "Details" }],
          right: [{ icon: "export", func: "Export", caption: (n: number) => `Export (${n === 0 ? "All" : n})`, reqFunc: "Export" }],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("RequestLog");
    this._setAllStates();
  }

  componentDidUpdate(prevProps: RequestLogProps, prevState: RequestLogStates) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(RequestLog.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback?: () => void) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks: Record<string, (...args: any[]) => any>) => {
    this.MountDatumizo = callbacks;
  };

  renderDatePicker = () => {
    const { period } = this.state;
    return (
      <Formizo
        width={350}
        height="100%"
        schema={[
          {
            label: "",
            name: "DateRange",
            format: "daterange",
            dateType: "datetime",
            dateFormat: "YYYY-MM-DD HH:mm:ss",
            noLabelGrid: true,
            defaultValue: period.DateRange,
          },
        ]}
        buttons={[]}
        onChange={(value, _n, _v, fetchData) => {
          this.setState((state) => {
            const from = moment(value.DateRange[0]).format("YYYYMMDD");
            const to = moment(value.DateRange[1]).format("YYYYMMDD");
            return {
              period: value,
              base: {
                ...state.base,
                exportDoc: `RequestLog_${from}_${to}`,
              },
            };
          });
          if (fetchData) {
            this.MountDatumizo.Reload();
          }
        }}
      />
    );
  };

  renderTable = () => {
    let { base, serverSidePagination, period } = this.state;
    return (
      <Datumizo
        base={base}
        addOns={{
          ...period,
        }}
        serverSidePagination={serverSidePagination}
        onMounted={this.onMountDatumizo}
        languageSelector={false}
      />
    );
  };

  render = () => {
    const { title } = this.state;
    return (
      <VStack height="100%" width="100%">
        <Box padding={1} width="100%">
          <HStack
            justifyContent="space-between"
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Typography
              style={{
                alignItems: "left",
                display: "flex",
                fontSize: 25,
                color: ColorX.GetColorCSS("Primary"),
              }}
            >
              {title}
            </Typography>
            {this.renderDatePicker()}
            <Spacer />
          </HStack>
        </Box>

        {this.renderTable()}
      </VStack>
    );
  };
}

export default RequestLog;
