import React, { useEffect } from "react";
import ZCMsg from "Components/Messenger/components/ZChat/ZCMsg/ZCMsg";
import { v1 } from "uuid";
import { VStack, HStack } from "Components/LabIZO/Stackizo";
import { Accessor, ColorX } from "static";
import _ from "lodash";
import axios from "axios";
import { store } from "static";
import { DOMAIN } from "config/config";

import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./ChatHistory.scss";

const ChatHistory = ({ contact }) => {
  const [prev, setPrev] = React.useState([]);
  useEffect(() => {
    const GetChatHistory = async () => {
      let url = DOMAIN + "/Tables/Convlog/ListByID";

      let payloadOut = {
        JWT: store.user.JWT,
        addons: contact,
      };
      try {
        let { data } = await axios.post(url, payloadOut);
        setPrev(data?.payload?.docs);
      } catch (e) {
        console.error(e);
      }
    };
    GetChatHistory();
  }, [contact]);

  const renderBubble = (o, pos, opacity) => {
    return <ZCMsg cssp="zchat z" key={v1()} _onQuickReply={() => {}} pos={pos} item={o} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} opacity={opacity} />;
  };

  let response = [];
  if (!contact) return <div />;
  if (!_.isEmpty(prev)) {
    if (!_.isArray(prev)) {
      setPrev([prev]);
    }
    _.map(prev, (o, i) => {
      let prevRes = Accessor.Get(o, "Response") || [];
      response.push(
        renderBubble(
          {
            msg: {
              text: Accessor.Get(o, "Input.Content") || "",
            },
          },
          "in",
          0.9
        )
      );

      _.map(prevRes, (v, x) => {
        response.push(renderBubble(v, "out", 0.9));
      });
    });
  }

  response = response.reverse();

  return (
    <Accordion>
      {/* Show history text  */}
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <HStack>
          <Typography variant="button">Show History</Typography>
        </HStack>
      </AccordionSummary>
      <AccordionDetails>
        {/*  History Component    */}
        <VStack
          width="100%"
          height={300}
          padding={2}
          style={{
            border: "1px solid " + ColorX.GetColorCSS("Primary", 0.2),
            overflow: "auto",
          }}
        >
          {response}
        </VStack>
      </AccordionDetails>
    </Accordion>
  );
};

export default ChatHistory;
