export enum Comparison {
  MODEL1 = "leftCorrect",
  MODEL2 = "rightCorrect",
  BothGood = "bothCorrect",
  BothBad = "bothNotCorrect",
}

export interface IArena {
  input: string;
  comparison: Comparison;
  winner: string;
  llm1: IModelInfo;
  llm2: IModelInfo;
  recTime: string; // Received Time
}

export interface IModelInfo {
  prompt: string;
  instruction: string;
  parameter: object;
  prevMsg: IMsg[];
  sessionId: string;
  messageId: string;
  id: string;
  name: string;
  model: string;
  elo: number;
}

export interface IMsg {
  createdAt: string;
  msg: {
    lang: string;
    text?: string;
    fallback?: {
      EN: string[];
      SC: string[];
      TC: string[];
    };
    linebreak?: boolean;
  };
  user: {
    _id: number;
  };
  _id: string;
}
