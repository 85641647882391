import { createContext } from "react";
import { UseFormRegister } from "react-hook-form";
import { ExtendedIFLEDoc } from "../FLESetup/FLESetup";
import { DBAnswerSpec } from "interfaces";
import { Intent } from "interfaces/db.interfaces/intent.interface";

//form error

//form info
export interface IFLEFormContext {
  mode: "Edit" | "Add" | "Info";
  register: UseFormRegister<ExtendedIFLEDoc<DBAnswerSpec, Intent>>;
}
export const FLEFormContext = createContext<IFLEFormContext | null>(null);
