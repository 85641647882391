import { FormizoSchema } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import _ from "lodash";
import { QuickReply, Answer } from "../SysGeneralSetting/schema";

const messages: FormizoSchema[] = [
  {
    label: "Billing Messages",
    foldStyle: "outlined",
    defaultShow: true,
    collapse: [
      {
        label: "Now checking",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:Billing_NowChecking"), QuickReply("master:Billing_NowChecking")],
      },
      {
        label: "Invalid terminate message",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:Billing_InvalidTerminate"), QuickReply("master:Billing_InvalidTerminate")],
      },
      {
        label: "Invalid retry general message",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:Billing_InvalidRetry"), QuickReply("master:Billing_InvalidRetry")],
      },
      {
        label: "Invalid retry length message",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:Billing_InvalidRetry_length"), QuickReply("master:Billing_InvalidRetry_length")],
      },
      {
        label: "Invalid retry address input message",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:Billing_InvalidRetry_address"), QuickReply("master:Billing_InvalidRetry_address")],
      },
      {
        label: "Invalid retry address input message 2",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:Billing_InvalidRetry_address_2"), QuickReply("master:Billing_InvalidRetry_address_2")],
      },
      {
        label: "Multiple entry warning",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:Billing_MultipleEntry"), QuickReply("master:Billing_MultipleEntry")],
      },
      {
        label: "Input contract number",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:Billing_EnterContractNumber"), QuickReply("master:Billing_EnterContractNumber")],
      },
      {
        label: "Contract number suggestion message",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:Billing_EnterContractChoices"), QuickReply("master:Billing_EnterContractChoices")],
      },
      {
        label: "Billing system failure message",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:Billing_TemporaryFailure"), QuickReply("master:Billing_TemporaryFailure")],
      },
      {
        label: "Auto pay message appears depend on API",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:Billing_AutopayDateMsg"), QuickReply("master:Billing_AutopayDateMsg")],
      },
    ],
  },
];

const mwconfig: FormizoSchema[] = [
  {
    label: "Billing Config",
    foldStyle: "outlined",
    defaultShow: true,
    collapse: [
      {
        label: "Number of request to terminate billing request",
        name: "billingConfig.stopCount",
        format: "number",
      },
      {
        label: "Words to QUIT contract number requests",
        name: "billingConfig.quitWords",
        format: "array",
        canAdd: true,
        canDelete: true,
        array: [
          {
            label: "",
            name: "",
            format: "text",
            validate: ["required"],
          },
        ],
      },
      {
        label: "Words to detect user input address",
        name: "billingConfig.addressWords",
        format: "array",
        canAdd: true,
        canDelete: true,
        array: [
          {
            label: "",
            name: "",
            format: "text",
            validate: ["required"],
          },
        ],
      },
    ],
  },
];

export const schema: FormizoSchema[] = [...mwconfig, ...messages];
