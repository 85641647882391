import { IKnowledgeGrpManagementPayload } from "../../interfaces/knowledge-grp-management-api.interface";
import { useFormContext } from "react-hook-form";
import { ControlledTextFieldWithIcon } from "Components/HookForm/ControlledTextFieldWithIcon";
import { VStack } from "Components/Layouts";

export const KnowledgeGrpInfo = ({ mode }: { mode: "Add" | "Edit" | "Info" }) => {
  const { control, getValues } = useFormContext<IKnowledgeGrpManagementPayload>();

  return (
    <VStack width={"100%"}>
      {mode != "Add" ? <ControlledTextFieldWithIcon name="_id" label="ID" control={control} variant="outlined" fullWidth disabled /> : <></>}
      <ControlledTextFieldWithIcon
        name="alias"
        label="Knowledge Group Name"
        control={control}
        variant="outlined"
        fullWidth
        disabled={mode == "Info"}
        rules={
          mode == "Edit"
            ? {
                required: "This field is required",
                validate: {
                  notOnlyWhitespace: (value) => value.trim().length > 0 || "The field cannot be empty",
                },
              }
            : {}
        }
      />
      {mode != "Add" ? <ControlledTextFieldWithIcon name="CREATED_AT" label="Created At" control={control} variant="outlined" fullWidth disabled /> : <></>}
      {mode != "Add" ? <ControlledTextFieldWithIcon name="LASTMODIFIED" label="Last Modified Time" control={control} variant="outlined" fullWidth disabled /> : <></>}
    </VStack>
  );
};
