const Request = {
  DBInfo: "/Tables/GPTConvlog/Info",
  List: "/Tables/GPTConvlog/List",
  //Info: "/Tables/Convlog/Get",
  //Add: "/Tables/Convlog/Add",
  //Delete: "/Tables/Convlog/Delete",
  //Edit: "/Tables/Convlog/Edit",
  Export: "/Tables/GPTConvlog/Export",
};

let exports = {
  Request,
};

export default exports;
