// import { Player } from "video-react";
import { Typography } from "@mui/material";
import { FormatSchema, FormizoSchema } from "Components/LabIZO/Formizo";
import { HStack, VStack } from "Components/LabIZO/Stackizo";
import { TablizoSchema } from "Components/LabIZO/Tablizo";
import _ from "lodash";
import AnsLib from "static/AnsLib";
import AniTest from "Components/ZAvatar/AniTest";
import ZCMsg from "Components/Messenger/components/ZChat/ZCMsg/ZCMsg";
import ZEye from "Components/ZEye/ZEye";

const Table: TablizoSchema[] = [
  {
    label: "ID",
    name: "_id",
    width: 90,
  },
  {
    label: "Title",
    name: "title",
  },
  {
    label: "Message (EN)",
    name: "EN",
  },
  {
    label: "Message (TC)",
    name: "TC",
  },
  // {
  //   label: "Message (SC)",
  //   name: "SC",
  // },
  {
    label: "Start Date",
    name: "startDate",
  },
  {
    label: "End Date",
    name: "endDate",
  },
  {
    label: "Preview",
    name: "",
    width: 240,
    Cell: (row, field, addOns) => {
      let ansObj = {
        EN: AnsLib.Doc2Chat(row, "EN"),
        TC: AnsLib.Doc2Chat(row, "TC"),
        // SC: AnsLib.Doc2Chat(row, "SC"),
      };
      let rtn: any = [];
      _.map(ansObj, (o, i) => {
        rtn.push(
          <HStack gap={0} padding={0} key={i}>
            <Typography>{i}</Typography>
            <ZEye>
              <VStack>
                <ZCMsg message_id="" cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o as any} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
              </VStack>
            </ZEye>
          </HStack>
        );
      });
      return <HStack gap={0}>{rtn}</HStack>;
    },
  },
];

const Preview: FormizoSchema[] = [
  {
    label: "Preview",
    width: 50,
    tabs: [
      {
        label: "EN",
        page: [
          {
            name: "EN",
            format: "display",
            noLabelGrid: true,
            Custom: (row, field, addOns) => {
              let o = AnsLib.Doc2Chat(row, "EN");
              return (
                <HStack gap={0}>
                  <VStack>
                    <ZCMsg message_id="" cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o as any} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
                  </VStack>
                </HStack>
              );
            },
          },
        ],
      },
      {
        label: "TC",
        page: [
          {
            name: "TC",
            format: "display",
            noLabelGrid: true,
            Custom: (row, field, addOns) => {
              let o = AnsLib.Doc2Chat(row, "TC");
              return (
                <HStack gap={0}>
                  <VStack>
                    <ZCMsg message_id="" cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o as any} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
                  </VStack>
                </HStack>
              );
            },
          },
        ],
      },
      // {
      //   label: "SC",
      //   page: [
      //     {
      //       name: "SC",
      //       format: "display",
      //       noLabelGrid: true,
      //       Custom: (row, field, addOns) => {
      //         let o = AnsLib.Doc2Chat(row, "SC");
      //         return (
      //           <HStack gap={0}>
      //             <VStack>
      //               <ZCMsg message_id="" cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o as any} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
      //             </VStack>
      //           </HStack>
      //         );
      //       },
      //     },
      //   ],
      // },
    ],
  },
];

const PlainText: FormizoSchema[] = [
  {
    columns: [
      {
        page: [
          {
            header: "Text (EN)",
          },
          {
            label: "Text (EN)",
            name: "EN",
            format: "textarea",
            variant: "outlined",
          },
        ],
      },
      {
        page: [
          {
            header: "Text (TC)",
          },
          {
            label: "Text (TC)",
            name: "TC",
            format: "textarea",
            variant: "outlined",
          },
        ],
      },
      // {
      //   page: [
      //     {
      //       header: "Text (SC)",
      //     },
      //     {
      //       label: "Text (SC)",
      //       name: "SC",
      //       format: "textarea",
      //       variant: "outlined",
      //     },
      //   ],
      // },
    ],
  },
];

const Tail: FormizoSchema[] = [
  {
    tabs: [
      {
        label: "Message",
        page: PlainText,
      },
    ],
  },
];

const Add: FormizoSchema[] = [
  {
    columns: [
      {
        page: [
          {
            label: "Title",
            name: "title",
            format: "text",
          },
          {
            label: "Start Date",
            name: "startDate",
            format: "date",
            dateType: "datetime",
            validate: ["required"],
          },
          {
            label: "End Date",
            name: "endDate",
            format: "date",
            dateType: "datetime",
            validate: ["required"],
          },
          ...Tail,
        ],
      },
      {
        width: 300,
        page: Preview,
      },
    ],
  },
];

const Info: FormizoSchema[] = [
  {
    columns: [
      {
        page: [
          {
            label: "Message ID",
            name: "_id",
            format: "text",
            validate: ["required"],
            readOnly: true,
          },
          {
            label: "Title",
            name: "title",
            format: "text",
          },
          {
            label: "Start Date",
            name: "startDate",
            format: "date",
            dateType: "datetime",
            validate: ["required"],
          },
          {
            label: "End Date",
            name: "endDate",
            format: "date",
            dateType: "datetime",
            validate: ["required"],
          },
          ...Tail,
        ],
      },
      {
        width: 300,
        page: Preview,
      },
    ],
  },
];

const Edit: FormizoSchema[] = [...Info];

const Filterables = [
  {
    field: "_id",
    label: "ID",
    type: "text",
  },
  {
    label: "Title",
    field: "title",
    type: "text",
  },
  {
    label: "Message (EN)",
    field: "EN",
    type: "text",
  },
  {
    label: "Message (TC)",
    field: "TC",
    type: "text",
  },
  // {
  //   label: "Message (SC)",
  //   field: "SC",
  //   type: "text",
  // },
];

const schema = {
  Table,
  Info,
  Add,
  Edit,
  Filterables,
};

export default schema;
