const Request = {
  List: "/Tables/Arena/List",
  Info: "/Tables/Arena/Info",
  Export: "/Tables/Arena/Export",
  Delete: "/Tables/Arena/Delete",
};

export default {
  Request,
};
