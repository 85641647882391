import { IDocumentManagerPayload } from "../../interfaces/document-manager-api.interface";
import FormMDEditor from "Components/FormMDEditor/FormMDEditor";
import { HStack, VStack } from "Components/Layouts";
import { useFormContext } from "react-hook-form";
import { useState, useEffect } from "react";
import { backendConnector, DATA_LINK } from "connectors";
import { EmbeddingsViewer } from "./EmbeddingsViewer";
import { FormControlLabel, Grid, Switch, ToggleButton } from "@mui/material";
import { useText } from "../hooks/useText";
import { useProcessedText } from "../hooks/useProcessedText";

export interface IEmbeddingObject {
  text: string[];
  embeddings: number[][];
  filename: string[];
  metadata: any[];
}

export const ContextEditors = ({ mode }: { mode: "Add" | "Edit" | "Info" }) => {
  const { setValue, getValues, control } = useFormContext<IDocumentManagerPayload>();
  const [showProcessedText, setShowProcessedText] = useState(true);
  const [showEditor, setShowEditor] = useState(true);
  const docId = getValues("_id");
  const { text, setText } = useText(docId);
  const { processedText } = useProcessedText(text || "", [text]);

  const onChangeContext = (_newContext: string | undefined) => {
    setText(_newContext);
    setValue("context", { text: _newContext });
  };
  const handleChangeShowPPText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowProcessedText(event.target.checked);
  };
  const handleChangeShowEditor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowEditor(event.target.checked);
  };

  return (
    <VStack width={"80vw"} alignItems="flex-start">
      <HStack alignItems="flex-start">
        <FormControlLabel
          //
          control={<Switch checked={showEditor} onChange={handleChangeShowEditor} />}
          label="Show Editor"
          labelPlacement="start"
        />
        <FormControlLabel
          //
          control={<Switch checked={showProcessedText} onChange={handleChangeShowPPText} />}
          label="Show Processed Text"
          labelPlacement="start"
        />
      </HStack>

      <Grid
        container
        spacing={1}
        sx={{
          width: "100%",
          alignItems: "flex-start",
          height: "80vh",
          marginTop: "10px",
        }}
      >
        {showEditor && (
          <Grid item className="md-container" sx={{ width: "100%", height: "100%" }} md={showProcessedText ? 6 : 12}>
            <FormMDEditor value={text} onChange={onChangeContext} allowEdit={mode === "Info"} />
          </Grid>
        )}

        {showProcessedText && (
          <Grid item className="embedding-container" sx={{ width: "100%", height: "100%" }} md={showEditor ? 6 : 12}>
            {processedText && <EmbeddingsViewer processedText={processedText} />}{" "}
          </Grid>
        )}
      </Grid>
    </VStack>
  );
};
