import { Box, Typography } from "@mui/material";
import { VStack } from "Components/Layouts";

export interface GPTDetailsProps {
  extractedContext?: string;
}

export const GPTDetails: React.FC<GPTDetailsProps> = ({ extractedContext }) => {
  const ExtractedContext = () => {
    const docList = extractedContext?.split("\n\n").filter((item) => item.trim() !== "");
    return (
      <VStack width={"100%"} height="100%" justifyContent="flex-start">
        {docList?.map((doc, index) => {
          const docName = doc.split("\nChunks:\n")[0].trim();
          let details = doc.split("\nChunks:")[1];
          if (details) details = details.trimEnd().replace(/(\(\d+\))/g, "\n$1");

          return (
            <Box
              key={index}
              width={"90%"}
              style={{
                background: "#FFFFFF",
                backgroundPosition: "0% 0%",
                backgroundRepeat: "no-repeat",
                padding: "box",
                border: "1px solid #C9C9C9",
                borderRadius: "10px",
                wordBreak: "break-word",
              }}
            >
              <Typography style={{ font: "normal normal bold 14px/19px Segoe UI", color: "#005F73", padding: "10px" }}>Document Name: {docName}</Typography>
              <Typography style={{ font: "normal normal normal 12px/16px Segoe UI", color: "#005F73", padding: "10px", whiteSpace: "pre-line" }}>Chunks:{details} </Typography>
            </Box>
          );
        })}
      </VStack>
    );
  };

  return (
    <VStack width={"100%"} className="talk2gpt-details" height={"100%"} overflow={"auto"} justifyContent="flex-start" p={1}>
      <Typography style={{ font: "normal normal bold 24px/32px Segoe UI" }} color={"primary.main"}>
        {"Prediction Details"}
      </Typography>

      <Typography style={{ font: "normal normal normal 14px/19px Segoe UI" }} color={"primary.main"}>{`Extracted Context`}</Typography>
      <ExtractedContext />
    </VStack>
  );
};
