import React, { useState } from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";

import { DOMAIN } from "config/config";

import axios from "axios";
import { store } from "static";
import Validation from "./Validation";
import { HtmlParserWrapper } from "Components/HtmlParserWrapper";
import PasswordFeild from "./PasswordFeild";

const { Criteria } = Validation;

const Popup = (props) => {
  const { open, onClose, onSubmit, onAlert } = props;
  const [prevPw, setPrevPw] = useState("");
  const [newPw, setNewPw] = useState("");
  const [newPwConfirm, setNewPwConfirm] = useState("");

  return (
    <Dialog open={open ? true : false} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
      <DialogContent>
        <DialogContentText>{<HtmlParserWrapper html={Criteria.join("<br>")} />}</DialogContentText>
        <PasswordFeild autoFocus id="prevPw" label="Previous Password" onInput={(value) => setPrevPw(value)} />
        <PasswordFeild id="newPw" label="New Password" criteria={["required", "minimum8", "comprise"]} onInput={(value) => setNewPw(value)} />
        <PasswordFeild id="newPwConfirm" label="New Password Confirm" criteria={["required", "minimum8", "comprise"]} onInput={(value) => setNewPwConfirm(value)} />
        {onAlert}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!(prevPw && newPw && newPwConfirm)}
          onClick={() => {
            onSubmit({ prevPw, newPw, newPwConfirm });
          }}
          color="primary"
          type="submit"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PasswordChanger = ({ username, jwt }) => {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(<div />);

  const onClickOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setAlert(<div />);
    setOpen(false);
  };
  const onSubmit = async ({ prevPw, newPw, newPwConfirm }) => {
    try {
      let url = DOMAIN + "/Base/User/ChangePassword";

      let payload = {
        data: {
          UserName: username,
          OldPassword: prevPw,
          NewPassword: newPw,
          NewPasswordConfirm: newPwConfirm,
        },
        JWT: jwt,
      };

      let { data } = await axios.post(url, payload);
      let { Success } = data;

      if (!Success) return setAlert(<Alert severity="error">{data?.payload?.message}</Alert>);

      store.Alert(data?.payload?.message);
      setAlert(<div />);

      setOpen(false);
    } catch (e) {
      setAlert(<Alert severity="error">{JSON.stringify(e)}</Alert>);
    }
  };

  return (
    <div className="PasswordChanger">
      <Button variant="outlined" color="primary" onClick={onClickOpen}>
        Change Password
      </Button>
      <Popup open={open} onClose={onClose} onSubmit={onSubmit} onAlert={alert} />
    </div>
  );
};

export default PasswordChanger;
