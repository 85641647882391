import _ from "lodash";

import ZCTouch from "../ZCTouch";

import "./ZCMsg.css";
import { IButton } from "interfaces";

interface IProps {
  buttons: IButton[];
  disabled?: boolean;
  cssp?: string;
  _onQuickReply?: any;
  onPhoneClick?: any;
  onWebClick?: any;
  buttonWidthFitContent?: boolean;
  iaddOns?: any;
}
const ZCMButtons = ({ buttons, disabled, cssp, _onQuickReply, onPhoneClick, onWebClick, buttonWidthFitContent }: IProps) => {
  const renderButtons = () => {
    let rendered: any[] = [];
    _.map(buttons, (o, i) => {
      let func = null;
      switch (o.type) {
        case "web":
          func = () => onWebClick(o.payload);
          break;
        case "phone":
          func = () => onPhoneClick(o.payload);
          break;
        default:
          func = () => _onQuickReply(o);
          break;
      }

      rendered.push(
        <div key={i} className={cssp + " msg-btn" + (disabled ? " disabled" : "") + (buttonWidthFitContent ? " fitcontent" : "")}>
          <ZCTouch cssPrefix={cssp} onPress={func} disabled={disabled}>
            <div className={cssp + " msg-btn-text" + (disabled ? " disabled" : "")}>{o.title}</div>
          </ZCTouch>
        </div>
      );
    });

    return rendered;
  };

  return <div className={cssp + " msg-btnlist"}>{renderButtons()}</div>;
};

export default ZCMButtons;
