import { AvailLANG } from "interfaces/available-lang.interface";
import { MultiLangText } from "interfaces/multi-lang.interface";

//ZChat format
export interface ZChat {
  _id?: string; // message id
  pipeline?: string;
  live_chat?: boolean;
  createdAt?: string;
  text?: string;
  lapseTime?: number;
  showEvaluate?: boolean;
  user: {
    _id?: string | number;
    name?: string;
    avatar?: string;
  };

  msg: T2EMessage;
  status?: string;
  retryCount?: number;
}

// standard T2E message format
export interface T2EMessage {
  system?: string;
  title?: string;
  text?: string;
  image?: string | null;
  video?: IVideo;
  quickReplies?: IQuickReply[];

  chipTitle?: string;
  chips?: IChips[];
  fallback?: ILang;
  channel?: string;
  buttons?: IButton[] | any[];
  imgbuttons?: IImgButton[] | any[];
  templates?: ITemplate[] | any[];
  table?: ITable;
  action?: string | null;
  audio?: string | null;
  webnav?: string | null;
  LCQueue?: string | null;
  withCitation?: boolean;
  showQuickReplies?: boolean;
  linkbreak?: boolean;
  pipeline?: Pipeline;
  modifier?: any;
  lang?: AvailLANG;
  cards?: any[];
}

export enum Pipeline {
  INTENT_CLASSIFIER = "intentClassifier",
  GPT_QA = "gptQa",
}

export interface ILang {
  [AvailLANG.EN]: string[];
  [AvailLANG.SC]: string[];
  [AvailLANG.TC]: string[];
}

export interface IVideo {
  src: string;
  poster?: string;
}

export interface IQuickReply {
  title: string;
  payload: string | null;
  type?: "web" | "phone" | "postback";
  isFollowUp?: boolean;
}

export interface IChips {
  id: string;
  alias: string;
  value: string;
  link?: string;
  titleName?: string;
  target?: string;
}

export interface IButton {
  title: string | null;
  payload: string | null;
  type?: "web" | "phone" | "postback";
  stype?: string;
}

export interface IImgButton {
  image: string;
  title: string;
  payload: string;
  type: "web" | "phone" | "postback";
  showText?: boolean;
}

export interface ITemplate {
  title?: string | null;
  subtitle?: string | null;
  image?: string | null;
  video?: IVideo | null;
  text?: string | null;
  buttons?: IButton[] | null;
}

export interface ITable {
  columns: TColumn[];
  rows: Record<string, string | number>[];
}

interface TColumn {
  Header: string;
  accessor: string;
}

export interface IT2EDetect {
  Entities: any[];
  Semantics: any[];
  ContextAction?: string;
  Context?: T2EContext[];
}

export interface T2EResponse {
  Success: boolean;
  isFinished?: boolean;
  live_chat: boolean;
  message: ZChat[];
  ws: string;
  watson_response: any;
  intent: string;
  confidence: number;
  detect: IT2EDetect;
  ansRes: {
    __ans: string;
  };
  language: string;
  recTime: string;
  outTime: string | null;
  processTime: number;
  doc_chunks?: IDocChunk[];
  solved?: boolean;
  extracted_context?: string;
  input?: {
    content: string;
  };
  pipeline?: Pipeline;
  model?: string;
  apiTokenLimit?: number;
}

export interface T2EStreamResponse {
  Success: boolean;
  response_id: string;
  model?: string;
  errorMsg?: string;
}

export interface IDocChunk {
  id: string;
  filename: string;
  alias: string;
  meta?: {
    title: string;
    link: string;
  };
  chunks: string[];
}

export interface T2EContext {
  alias: string;
  title: MultiLangText;
  value: any;
}
//sample response
export const T2ESampleResponse: T2EResponse = {
  Success: true,
  live_chat: false,
  message: [
    {
      user: {
        _id: 2,
        name: "",
        avatar: "https://placeimg.com/140/140/any",
      },
      live_chat: false,
      createdAt: "2022-03-16T08:03:06.976Z",
      msg: {
        text: "Text Message - ENGLISH",
        image: "https://images.unsplash.com/photo-1472214103451-9374bd1c798e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
        video: {
          src: "https://media.w3.org/2010/05/sintel/trailer_hd.mp4",
          poster: "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/blue-welcome-church-video-poster-template-df977a81710d4201a1a2a6546958ea54_screen.jpg?ts=1567088234",
        },
        table: {
          columns: [
            {
              Header: "Column1",
              accessor: "data1",
            },
          ],
          rows: [
            {
              data1: "Record 0",
              data2: 0.33958326486495194,
            },
          ],
        },
        buttons: [
          {
            title: "Web Button",
            payload: "https://www.google.com",
            type: "web",
          },
          {
            title: "Phone Button",
            payload: "+852 66666666",
            type: "phone",
          },
          {
            title: "Postback Button",
            payload: "postbackbtn",
            type: "postback",
          },
        ],
        imgbuttons: [
          {
            image: "https://placeimg.com/140/140/any",
            title: "Web Button",
            payload: "https://www.google.com",
            type: "web",
          },
          {
            image: "https://placeimg.com/140/140/any",
            title: "Phone Button",
            payload: "+852 66666666",
            type: "phone",
          },
          {
            image: "https://placeimg.com/140/140/any",
            title: "Postback Button 1",
            payload: "postbackbtn1",
            type: "postback",
          },
          {
            image: "https://static.vecteezy.com/system/resources/previews/000/042/491/non_2x/yin-yang-vector.jpg",
            title: "Postback Button 2",
            payload: "postbackbtn2",
            type: "postback",
          },
        ],
        templates: [
          {
            text: "template text template text template text template text template text template text<br/>template text<br/>template text<br/>template text<br/>template text<br/>template text<br/>template text<br/>template text",
            title: "Template Title",
            subtitle: "template subtitle",
            image: "https://img.delicious.com.au/ttEaxwsa/del/2018/12/new-york-usa-97371-2.jpg",
            video: {
              src: "https://media.w3.org/2010/05/sintel/trailer_hd.mp4",
              poster: "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/blue-welcome-church-video-poster-template-df977a81710d4201a1a2a6546958ea54_screen.jpg?ts=1567088234",
            },
            buttons: [
              {
                title: "Web Button",
                payload: "https://www.google.com",
                type: "web",
              },
              {
                title: "Phone Button",
                payload: "+852 66666666",
                type: "phone",
              },
              {
                title: "Postback Button",
                payload: "postbackbtn",
                type: "postback",
              },
            ],
          },
          {
            text: "template text template text template text template text template text template text<br/>template text<br/>template text<br/>template text<br/>template text<br/>template text<br/>template text<br/>template text",
            title: "Template Title 2",
            subtitle: "template subtitle",
            image: "https://pix10.agoda.net/hotelImages/1165961/-1/a13cf8a8d161ed74d60f38ccd4aa8b92.jpg?s=1024x768",
            buttons: [
              {
                title: "Web Button",
                payload: "https://www.google.com",
                type: "web",
              },
              {
                title: "Phone Button",
                payload: "+852 66666666",
                type: "phone",
              },
              {
                title: "Postback Button",
                payload: "postbackbtn",
                type: "postback",
              },
            ],
          },
          {
            text: "template text template text template text template text template text template text<br/>template text<br/>template text<br/>template text<br/>template text<br/>template text<br/>template text<br/>template text",
            title: "Template Title 3",
            subtitle: "template subtitle",
            image: "https://pix10.agoda.net/hotelImages/1165961/-1/a13cf8a8d161ed74d60f38ccd4aa8b92.jpg?s=1024x768",
            buttons: [
              {
                title: "Web Button",
                payload: "https://www.google.com",
                type: "web",
              },
              {
                title: "Phone Button",
                payload: "+852 66666666",
                type: "phone",
              },
              {
                title: "Postback Button",
                payload: "postbackbtn",
                type: "postback",
              },
            ],
          },
        ],
        quickReplies: [
          {
            title: "Button 1",
            payload: "HI1",
          },
          {
            title: "Button 2",
            payload: "HI2",
          },
          {
            title: "Button 3",
            payload: "HI3",
          },
          {
            title: "Button 4",
            payload: "HI4",
          },
        ],
      },
    },
  ],
  ws: "master",
  watson_response: {
    master: {
      intents: [
        {
          intent: "FAQ_Greetings",
          confidence: 1,
        },
        {
          intent: "FAQ_Farewell",
          confidence: 0,
        },
        {
          intent: "LiveChat",
          confidence: 0,
        },
      ],
      entities: [],
      input: {
        text: "hi",
      },
      output: {
        generic: [],
        text: [],
        nodes_visited: ["HIGH_CONFIDENCE", "FAQ_Greetings"],
        nodes_visited_details: [
          {
            dialog_node: "HIGH_CONFIDENCE",
            title: "HIGH_CONFIDENCE",
            conditions: "intents[0].confidence>=$_CFLevel.HIGH",
            visit_reason: "branch_start",
          },
          {
            dialog_node: "FAQ_Greetings",
            title: "FAQ_Greetings",
            conditions: "#FAQ_Greetings",
          },
        ],
        log_messages: [],
      },
      context: {
        init: true,
        _CFLevel: {
          HIGH: 0.7,
          MID: 0.4,
          CHANGE: 0.5,
          VAGUE: 0.005,
        },
        conversation_id: "123",
        system: {
          initialized: true,
          dialog_stack: [
            {
              dialog_node: "root",
            },
          ],
          dialog_turn_counter: 1,
          dialog_request_counter: 1,
          branch_exited: true,
          branch_exited_reason: "completed",
        },
        __ans: "FAQ_Greetings",
        metadata: {
          user_id: "123",
        },
      },
      alternate_intents: true,
      user_id: "123",
    },
  },
  intent: "FAQ_Greetings",
  confidence: 1,
  detect: {
    Entities: [],
    Semantics: [],
  },
  ansRes: {
    __ans: "NEVER",
  },
  language: "EN",
  recTime: "2022-03-16T08:03:05.746Z",
  outTime: null,
  processTime: 0,
};
