import React, { Component } from "react";
import PropsType from "prop-types";
import moment from "moment";

import "./ZChat.css";

class ZCTouch extends Component {
  static propTypes = {
    cssPrefix: PropsType.string,
    onHold: PropsType.func,
    onPress: PropsType.func,
    onLongPress: PropsType.func,

    longPressInterval: PropsType.number,
    onHoldInterval: PropsType.number,

    forceLongPress: PropsType.bool,
    stopPropagation: PropsType.bool,
  };

  static defaultProps = {
    cssPrefix: "",
    onHold: null,
    onPress: null,
    onLongPress: null,

    longPressInterval: 2000,
    onHoldInterval: 100,

    forceLongPress: false,
    stopPropagation: true,
  };

  constructor() {
    super();
    this.state = {
      pressed: false,
      startTime: null,
    };
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  handlePress = (e) => {
    let { longPressInterval, disabled, onHold, onHoldInterval, forceLongPress, stopPropagation } = this.state;
    const { onPress } = this.props;
    if (stopPropagation) {
      e.stopPropagation();
    }

    clearInterval(this.repeat);

    if (onHold) {
      onHold();
      this.repeat = setInterval(onHold, onHoldInterval);
    }
    if (disabled) return;
    this.setState({
      pressed: true,
      startTime: moment(),
    });
    if (forceLongPress) {
      this.timer = setTimeout(this.forceLongPress, longPressInterval);
    }
    if (onPress) {
      onPress();
    }
  };

  forceLongPress = () => {
    let { onPress, disabled } = this.state;
    if (disabled) return;
    this.setState({
      pressed: false,
    });
    if (onPress) {
      onPress();
    }
  };

  handleRelease = (e) => {
    let { pressed, startTime, onPress, disabled } = this.state;

    if (!pressed) return;
    if (disabled) return;

    this.setState({
      pressed: false,
    });
    if (onPress) onPress();

    clearTimeout(this.timer);
    clearInterval(this.repeat);
  };

  handleLeave = (e) => {
    let { pressed, disabled } = this.state;

    if (!pressed) return;
    if (disabled) return;
    this.setState({
      pressed: false,
    });
    clearTimeout(this.timer);
    clearInterval(this.repeat);
  };

  render() {
    let { children, cssPrefix, pressed, disabled } = this.state;
    return (
      <div
        className={cssPrefix + " touch" + (disabled ? " disabled" : "")}
        // onTouchStart={(e) => this.handlePress(e)}
        // onTouchMove={(e) => this.handleLeave(e)}
        // onTouchEnd={(e) => this.handleRelease(e)}
        onClick={(e) => this.handlePress(e)}
        // onMouseUp={(e) => this.handleRelease(e)}
        // onMouseLeave={(e) => this.handleLeave(e)}
      >
        {children}
      </div>
    );
  }
}

export default ZCTouch;
