import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Checkbox, TextField, Button } from "@mui/material";
import { DBAnswerSpec } from "interfaces";
import { IFLEDoc, IAPIParam } from "interfaces/db.interfaces/fle.interface";
import { DeleteButton } from "Components/IconButtons";
import { useFormContext } from "react-hook-form";
import { AddButton } from "Components/IconButtons";
import React, { useState } from "react";
interface IProps {
  disabled?: boolean;
}

export function ParamsTable({ disabled }: IProps) {
  const { getValues, setValue, control } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  const params = getValues(`integrations.api.request`);
  const [data, setData] = useState<IAPIParam[]>(params.body || []);

  const handleDelete = (index: number) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });
    setValue(`integrations.api.request.body`, data);
  };
  const handleAddRow = () => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData.push({ key: "", value: "" });
      return updatedData;
    });
  };

  const handleFieldChange = (index: number, field: "key" | "value", event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index][field] = value;
      return updatedData;
    });
    setValue(`integrations.api.request.body`, data);
  };
  return (
    <TableContainer style={{ width: "100%" }} component={Paper}>
      <Table>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField label="Key" fullWidth value={row.key} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(index, "key", event)} disabled={disabled} />
              </TableCell>
              <TableCell>
                <TextField label="Value" fullWidth value={row.value} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(index, "value", event)} disabled={disabled} />
              </TableCell>
              <TableCell>
                <DeleteButton onClick={() => handleDelete(index)} disabled={disabled} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!disabled && <AddButton onClick={handleAddRow} />}
    </TableContainer>
  );
}
