import { FormizoSchema } from "Components/LabIZO/Formizo";
import { FormizoCustom, IFGColumn } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import { store } from "static";
import _ from "lodash";
import { QuickReply } from "../schema";
import { Box, Chip, Typography } from "@mui/material";
import FAQ from "../FAQ";
import { FAQType } from "interfaces/db.interfaces/faq.interface";
import { QRedirectToGPTSource } from "interfaces/db.interfaces/intent.interface";
import { ExampleGenerationInEdit } from "../ExampleGenerationInEdit";
import { AvailLANG } from "interfaces/available-lang.interface";

/**
 * button caption qill be generated if not given uppon add, but will be later required during edit
 */
const ButtonCaptiionSchema = {
  add: (lang: { display: string; value: string }) => ({
    label: "Button Caption",
    name: `intent.${lang.value}.description`,
    format: "text",
  }),
  info: (lang: { display: string; value: string }) => ({
    label: "Button Caption",
    name: `intent.${lang.value}.description`,
    format: "text",
    validate: ["required"],
  }),
};

const FaqFormSchema = (formValue: any, addOns: any, fLang: { display: string; value: string }, buttonCapSchema: any) => {
  return [
    {
      label: "ID",
      name: "name",
      validate: ["required"],
      format: "text",
      readOnly: true,
    },
    {
      label: "Created At",
      name: "CREATED_AT",
      format: "text",
      readOnly: true,
    },
    {
      label: "Last Modified",
      name: "LASTMODIFIED",
      format: "text",
      readOnly: true,
    },
    {
      label: "Alias",
      name: "alias",
      validate: ["required"],
      format: "text",
    },
    {
      label: "Description",
      name: "description",
      format: "text",
    },
    {
      label: "Follow Up Message",
      name: "answer.__followUp",
      format: "bool",
    },
    {
      label: "func",
      name: "answer.__func",
      format: "text",
      readOnly: true,
    },
    {
      label: "Used By",
      name: "inUse",
      format: "display",
      Custom: (_row: any, field: any, _addOns: any) => {
        return (
          <Box className="usedBy" sx={{ alignItems: "center", justifyContent: "start", width: "100%" }}>
            {field?.length ? (
              field.map((qrbFaqId: string) => {
                return <Chip label={qrbFaqId.split(":")[1]} />;
              })
            ) : (
              <Typography variant="body1" color="#a6aaaa" fontFamily="Roboto, Helvetica, Arial, sans-serif">
                None
              </Typography>
            )}
          </Box>
        );
      },
    },
    {
      columns: [
        QuestionColumn(formValue, addOns, fLang, buttonCapSchema), //
        AnswerColumn(formValue, addOns, fLang, buttonCapSchema),
        QRColumn(formValue, addOns, fLang, buttonCapSchema),
      ],
    },
  ];
};

const QuestionColumn = (formValue: any, addOns: any, fLang: { display: string; value: string }, buttonCapSchema: any) => {
  const defaultLang = store.sysInfo.Language.available.filter((lang) => lang.value == fLang.value)[0];
  const availableLang = [defaultLang, ...store.sysInfo.Language.available.filter((lang) => lang.value !== fLang.value)];
  return {
    width: "48%",
    page: [
      {
        header: "Questions",
      },
      {
        tabs: availableLang.map((lang) => ({
          label: `${lang.display}`,
          error: (formValue: any, addOns: any, { formError }: any) => {
            let examplesErrors = _.get(formError, `intent.${lang.value}.examples`) || [];
            const captionError = _.get(formError, `intent.${lang.value}.description`) || [];
            const error = [...examplesErrors, ...captionError].filter((_e: any) => !_.isEmpty(_e));
            return error.length > 0;
          },
          page: () => {
            // const defaultLang = store.sysInfo.Language.default;
            // const minItems = defaultLang === lang.value ? 1 : 0;

            return [
              buttonCapSchema(lang),
              {
                label: `Examples (${lang.display})`,
                name: `intent.${lang.value}.examples`,
                canAdd: true,
                canDelete: true,
                minItems: 0,
                array: [
                  {
                    label: "",
                    name: "text",
                    format: "text",
                    validate: ["required"],
                  },
                ],
              },
              {
                name: `intent.${lang.value}.examples`,
                format: "display",
                noLabelGrid: true,
                width: "100%",
                Custom: (_row: any, field: any, _addOns: any, _onValueChange: any, iname: any, readOnly: any) => {
                  return <ExampleGenerationInEdit _row={_row} field={field} _onValueChange={_onValueChange} lang={lang.value as AvailLANG} display={lang.display} readonly={readOnly} />;
                },
              },
            ];
          },
        })),
      },
    ],
  };
};

const AnswerColumn = (formValue: any, addOns: any, fLang: { display: string; value: string }, buttonCapSchema: any): IFGColumn => ({
  width: "48%",
  page: [
    {
      header: "Answer",
    },
    {
      label: "Type",
      name: "type",
      format: "select",
      selectStyle: "radio",
      selectDirection: "row",
      options: [
        { label: "FAQ/FLE", value: FAQType.STATIC },
        { label: "Redirect to GPT (Hybrid)", value: FAQType.REDIRECT_TO_GPT, reqAuth: "FAQ", reqFunc: "RedirectToGpt" },
      ],
      defaultValue: "static",
    },
    {
      control: "type",
      controlFunc: (doc: any, field: any) => field === FAQType.STATIC,
      fold: FAQStaticAnswer(formValue, addOns, fLang, buttonCapSchema),
    },
    {
      control: "type",
      controlFunc: (doc: any, field: any) => field === FAQType.REDIRECT_TO_GPT,
      fold: FAQGPTRedirect(formValue, addOns, fLang, buttonCapSchema),
    },
  ],
});

const FAQStaticAnswer = (formValue: any, addOns: any, fLang: { display: string; value: string }, buttonCapSchema: any): FormizoSchema[] => {
  const defaultLang = store.sysInfo.Language.available.slice().filter((o) => o.value === fLang.value)[0].display;
  return [
    {
      label: "Answer ID",
      name: "answer._id",
      format: "text",
      readOnly: true,
    },
    {
      label: `${fLang.display}(${defaultLang})`,
      name: `answer.text.${fLang.value}.0`,
      format: "textarea",
      rows: 5,
      variant: "outlined",
      validate: ["required"],
    },
    {
      label: "Other Languages",
      defaultShow: true,
      foldStyle: "none",
      collapse: store.sysInfo.Language.available.map((o) => {
        if (o.value === fLang.value) return {};
        return {
          label: `${o.display}`,
          name: `answer.text.${o.value}.0`,
          format: "textarea",
          rows: 5,
          variant: "outlined",
        } as FormizoSchema;
      }),
    },
  ];
};

const FAQDocumentAnswer = (formValue: any, addOns: any, fLang: { display: string; value: string }, buttonCapSchema: any): FormizoSchema[] => [
  {
    label: "Documents",
    name: "answer.documents",
    format: "multiSelect",
    selectStyle: "autocomplete",
    selectRef: "documents",
    selectCap: "_id",
    selectVal: "_id",
  },
];

const FAQGPTRedirect = (formValue: any, addOns: any, fLang: { display: string; value: string }, buttonCapSchema: any): FormizoSchema[] => [
  {
    label: "Data Source",
    name: "intent.trigger.__param.source",
    format: "select",
    selectStyle: "radio",
    selectDirection: "row",
    options: [
      { label: "Document", value: QRedirectToGPTSource.DOCUMENT },
      { label: "Knowledge Group", value: QRedirectToGPTSource.KNOWLEDGE_GROUP },
    ],
    defaultValue: QRedirectToGPTSource.DOCUMENT,
  },
  {
    control: "intent.trigger.__param.source",
    controlFunc: (doc: any, field: any) => field === QRedirectToGPTSource.DOCUMENT,
    fold: [
      {
        label: "Document",
        name: "intent.trigger.__param.document",
        format: "select",
        selectStyle: "dropdown",
        selectRef: "documents",
        selectCap: "alias",
        selectVal: "_id",
        // format: "multiSelect",
        // selectStyle: "autocomplete",
        // optionType: "addOns",
      },
    ],
  },
  {
    control: "intent.trigger.__param.source",
    controlFunc: (doc: any, field: any) => field === QRedirectToGPTSource.KNOWLEDGE_GROUP,
    fold: [
      {
        label: "Group",
        name: "intent.trigger.__param.knowledgeGroup",
        format: "select",
        selectStyle: "dropdown",
        selectRef: "knowledgeGroups",
        selectCap: "alias",
        selectVal: "_id",
      },
    ],
  },
];

const QRColumn = (formValue: any, addOns: any, fLang: { display: string; value: string }, buttonCapSchema: any): IFGColumn => ({
  control: "type",
  controlFunc: (_doc: any, field: any) => field === FAQType.STATIC,
  width: "48%",
  page: [
    {
      header: "Quick Reply",
    },
    QuickReply(fLang.value),
  ],
});

export const Info: FormizoCustom = (formValue, addOns, fLang: { display: string; value: string }) => {
  return FaqFormSchema(formValue, addOns, fLang, ButtonCaptiionSchema.info);
};
export const Add: FormizoCustom = (formValue, addOns, fLang: { display: string; value: string }) => {
  return FaqFormSchema(formValue, addOns, fLang, ButtonCaptiionSchema.add);
};

export const Edit = Info;
