/**
 * database document for Flow Editor
 */

export interface IFLERef {
  _id: string;
}

export interface IFLEDoc<A = IFLERef, I = IFLERef> {
  _id: string;
  alias?: string;
  description?: string;
  intentDoc: I;
  contexts?: TFLEContext[];
  integrations?: {
    api?: IAPIIntegration<A>;
  };
  answers?: IAllFLEAnswers<A>;
  defaultMessage?: IDefaultMessage;
}

export interface IDefaultMessage {
  enabled: boolean;
  msg: MultiLangText;
}

export interface IAllFLEAnswers<A = IFLERef> {
  defaultAnsDoc: A;
  conditionals?: IFLEAnswer<A>[];
}

//common
export enum FLEOperator {
  "eq" = "$eq",
  "neq" = "$neq",
  "gt" = "$gt",
  "gte" = "$gte",
  "lt" = "$lt",
  "lte" = "$lte",
}
export interface MultiLangText {
  EN?: string;
  TC?: string;
  SC?: string;
  [key: string]: string | undefined;
}

// context
export type TFLEContext = ISystemContext | IEntityContext;
interface IBaseContext {
  alias: {
    id: string;
    value: MultiLangText;
  };
  question: MultiLangText;
  fallback?: MultiLangText;
  required?: boolean;
}

export interface IBaseSetting {
  values?: IEntityValue[] | IRegexValue[] | INumericValue[];
}
//system context
export interface ISystemContext extends IBaseContext {
  type: "system";
  setting: TSystemContextSetting;
}
export type TSystemContextSetting = ISystemContextDate | ISystemContextBoolean | ISystemContextNumeric | ISystemContextRegex | ISystemContextString;
export enum TSystemContextType {
  "date" = "date",
  "boolean" = "boolean",
  "numeric" = "numeric",
  "regex" = "regex",
  "string" = "string",
}

export interface ISystemContextDate extends IBaseSetting {
  type: TSystemContextType.date;
  values?: IDateValue[];
}
export interface IDateValue {
  value: string;
  operator: FLEOperator;
}

export interface ISystemContextBoolean extends IBaseSetting {
  type: TSystemContextType.boolean;
}

export interface ISystemContextNumeric extends IBaseSetting {
  type: TSystemContextType.numeric;
  values?: INumericValue[];
  operator?: FLEOperator;
}
export interface INumericValue {
  value: number;
  operator: FLEOperator;
}

export interface ISystemContextRegex extends IBaseSetting {
  type: TSystemContextType.regex;
  values: IRegexValue[];
}
export interface IRegexValue {
  value: string;
}

export interface ISystemContextString extends IBaseSetting {
  type: TSystemContextType.string;
}

//entity context
export interface IEntityContext extends IBaseContext {
  type: "entity";
  setting: IEntityContextSetting;
}
export interface IEntityContextSetting extends IBaseSetting {
  type: "entity";
  values: IEntityValue[];
}
export interface IEntityValue {
  entity: string;
  values: string[];
}

// API Integration interface
export interface IAPIParam {
  key: string;
  value: string;
}
export interface IReplaceable {
  toReplace: string;
  fromResponse: string;
}
export interface IAPIIntegration<A = IFLERef> {
  enabled: boolean;
  url: string;
  method: "POST" | "GET";
  request: {
    body?: IAPIParam[];
    params?: IAPIParam[];
  };
  responses: {
    default: IAPIResponseConfig<A>;
    conditionals?: IConditionalAPIResponse<A>[];
  };
}

export interface IConditionalAPIResponse<A = IFLERef> {
  conditions: {
    key: string;
    value: string;
  }[];
  config: IAPIResponseConfig<A>;
}

export interface IAPIResponseConfig<A = IFLERef> {
  answerDoc: A;

  replaceables?: IReplaceable[];
}

// answers
export interface IFLECondition {
  key: string;
  value: string | number | boolean;
  operator?: FLEOperator;
}
export interface IFLEAnswer<A = IFLERef> {
  conditions: IFLECondition[];
  answerDoc: A;
}
