import PropsType from "prop-types";
import React, { Component, useContext } from "react";
import ZCTouch from "../ZCTouch";
import ZCMButtons from "./ZCMButtons";
import ZCMCards from "./ZCMCards";
import ZCMFooter from "./ZCMFooter";
import ZCMHeader from "./ZCMHeader";
import ZCMImage from "./ZCMImage";
import ZCMImgButtons from "./ZCMImgButtons";
import "./ZCMsg.css";
import ZCMSysMsg from "./ZCMSysMsg";
import ZCMTable from "./ZCMTable";
import ZCMTemplates from "./ZCMTemplates";
import ZCMText from "./ZCMText";
import ZCMVideo from "./ZCMVideo";
import ZCMChips from "./ZCMHover";
import ZCFeedback from "./ZCFeedback";
import ZCRegenerate from "./ZCRegenerate";
import ModifiedText from "./ZCMModifiedText";
import { ZChatContext } from "Components/Messenger/hooks/messenger.context";
import { HStack, VStack } from "Components/Layouts";
import { AvailLANG, ZChat } from "interfaces";

interface IItemInterface extends ZChat {
  addOns?: any;
}

interface IZCMsgProps {
  message_id: string | number;
  showQuickRepliesAsButtons?: boolean;
  disableButtonsAfterSend?: boolean;
  disableTemplateButtonsAfterSend?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  item?: IItemInterface;
  lastItem?: any;
  pos?: string;
  intermediateResponse?: any;
  typingBubbles?: boolean;
  typingIndicator?: any;
  onMsgPress?: any;
  onCardBTNPress?: any;
  last?: boolean;
  cssp?: string;
  canClickOnIn?: boolean;
  canClickOnOut?: boolean;
  opacity?: number;
  bubbleColor?: string;
  _onQuickReply?: any;
  addOns?: any;
  HTMLEnabled?: boolean;
}

function ZCMsg(props: IZCMsgProps) {
  const zChatContext = useContext(ZChatContext);
  function renderHeader() {
    let { showHeader, pos, typingBubbles } = props;
    let { user } = props.item || {};

    if (pos !== "in") return;
    if (!showHeader || typingBubbles) return;

    return <ZCMHeader {...props} sender={user} />;
  }

  function renderMsg() {
    let { showQuickRepliesAsButtons, disableButtonsAfterSend, disableTemplateButtonsAfterSend, typingBubbles, typingIndicator, last, cssp } = props;
    let { msg, addOns, status } = props.item || {};

    let rendered = [];

    if (props.typingBubbles) {
      rendered.push(
        typingIndicator || (
          <div className={cssp + " zcmtyping"} key={"loading"}>
            <div className={cssp + " zcmtyping-circles"} />
            <div className={cssp + " zcmtyping-circles"} />
            <div className={cssp + " zcmtyping-circles"} />
          </div>
        )
      );
      // rendered.push(renderIntermediateResponse());
      return rendered;
    }
    if (!msg) return;

    if (msg.title) {
      rendered.push(<ZCMText {...props} key={"title"} text={msg.title} iaddOns={addOns} />);
    }

    if (msg.image) {
      rendered.push(<ZCMImage {...props} key={"image"} src={msg.image} iaddOns={addOns} />);
    }

    if (msg.video) {
      rendered.push(<ZCMVideo {...props} key={"video"} video={msg.video} iaddOns={addOns} />);
    }

    if (msg.text) {
      if (msg.modifier && msg.modifier.length > 0) {
        rendered.push(<ModifiedText key={"text"} text={msg.text} modifier={msg.modifier} />);
      } else {
        rendered.push(<ZCMText {...props} key={"text"} text={msg.text} iaddOns={addOns} />);
      }
    }

    if (msg.fallback) {
      let lang: AvailLANG = msg.lang || AvailLANG.EN;
      let text = msg.fallback[lang][0];
      rendered.push(<ZCMText {...props} key="fallback" text={text} iaddOns={addOns} textColor={"red"} />);
    }

    if (msg.table) {
      rendered.push(<ZCMTable {...props} key={"table"} table={msg.table} iaddOns={addOns} />);
    }

    if (msg.quickReplies) {
      if (showQuickRepliesAsButtons || msg.showQuickReplies) {
        rendered.push(<ZCMButtons {...props} key={"quickreplies"} buttons={msg.quickReplies} disabled={disableButtonsAfterSend && !last} iaddOns={addOns} />);
      }
    }

    if (msg.buttons) {
      rendered.push(<ZCMButtons {...props} key={"buttons"} buttons={msg.buttons} disabled={disableButtonsAfterSend && !last} iaddOns={addOns} />);
    }

    if (msg.imgbuttons) {
      rendered.push(<ZCMImgButtons {...props} key={"imgbuttons"} buttons={msg.imgbuttons} disabled={disableButtonsAfterSend && !last} iaddOns={addOns} />);
    }

    if (msg.templates) {
      rendered.push(<ZCMTemplates {...props} key={"template"} templates={msg.templates} disabled={disableTemplateButtonsAfterSend && !last} iaddOns={addOns} />);
    }

    if (msg.cards) {
      rendered.push(<ZCMCards {...props} key={"cards"} cards={msg.cards} disabled={disableTemplateButtonsAfterSend && !last} iaddOns={addOns} onCardBTNPress={props.onCardBTNPress} />);
    }

    if (msg.chips && msg.chips.length > 0) {
      rendered.push(<ZCMChips cssp={props.cssp!} key={"chips"} chipKey={"Hover"} chips={msg.chips} chipTitle={msg.chipTitle} modifier={msg.modifier} />);
    }

    // if (intermediateResponse) {
    //   rendered.push(renderIntermediateResponse());
    // }

    return rendered;
  }

  function renderFooter() {
    let { showFooter, typingBubbles } = props;
    let { createdAt, status, lapseTime } = props.item || {};

    if (!showFooter || typingBubbles) return;

    const { cssp, pos } = props;
    return <ZCMFooter createdAt={createdAt} status={status} lapseTime={lapseTime} cssp={cssp} pos={pos} />;
  }

  const renderEvaluate = () => {
    const { feedbackUrl, regenerateUrl, onSetMessages, messages, connector } = zChatContext || {};
    const { item } = props;
    const { _id } = item || {};
    return (
      <HStack sx={{ justifyContent: "flex-start" }}>
        {regenerateUrl && <ZCRegenerate connector={connector} onSetMessages={onSetMessages} regenerateUrl={regenerateUrl} item={item} messages={messages} />}
        {feedbackUrl && <ZCFeedback cssp={props.cssp!} message_id={_id!} feedbackUrl={feedbackUrl} />}
      </HStack>
    );
  };

  const _onMsgPress = (_id?: string | number, msg?: any) => {
    console.log("_onMsgPress: ", _id, msg);
    let { onMsgPress } = props;
    if (onMsgPress) {
      onMsgPress(_id, msg);
    }
  };

  let { cssp, pos, canClickOnIn, canClickOnOut, opacity } = props;

  const { item, bubbleColor, last } = props;
  const { _id, msg, showEvaluate } = item || {};

  if (msg && msg.system) {
    return <ZCMSysMsg {...props} key={_id} text={msg.system} />;
  }
  let disabled = !((pos === "in" && canClickOnIn) || (pos === "out" && canClickOnOut));
  return (
    <VStack width={"100%"}>
      <ZCTouch {...props} cssPrefix={cssp} key={_id} onPress={() => _onMsgPress(_id, msg)} disabled={disabled}>
        <div className={cssp + " bubble " + pos} style={{ opacity: opacity, backgroundColor: bubbleColor }}>
          {renderHeader()}
          {renderMsg()}
          {renderFooter()}
        </div>
      </ZCTouch>
      {showEvaluate && last && renderEvaluate()}
    </VStack>
  );
}

ZCMsg.defaultProps = {
  _onQuickReply: () => {},
  onCardBTNPress: () => {},
  pos: "in",
  item: { msg: { text: "" } },
  last: false,
  typingBubbles: false,
  opacity: 1,
  showFooter: true,
};

export default ZCMsg;
