import { useEffect, useState } from "react";
import { Accessor, ErrorX, store } from "static";
import { IMWConfig } from "interfaces";
import { DATA_LINK, backendConnector } from "connectors";
import Formizo from "Components/LabIZO/Formizo";
import { schema } from "./schema";
const SysChatbot = () => {
  const [model, setModel] = useState<any>();
  const [chatbotInfo, setChatbotInfo] = useState<IMWConfig>();

  useEffect(() => {
    getMWSettings();
    getAvailableModels();
  }, []);

  const getMWSettings = async () => {
    const { payload } = await backendConnector.post(DATA_LINK.SYSMiddlewareInfo, {});
    setChatbotInfo(payload);
  };

  const getAvailableModels = async () => {
    const { payload } = await backendConnector.post(DATA_LINK.SYSModelInfo, {});
    const sortedModels = payload.models.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name));
    setModel(payload.models);
  };

  const handleSubmit = async (chatbotData: any) => {
    try {
      const res = await backendConnector.post(DATA_LINK.SYSMiddlewareEdit, chatbotData);
      if (res.Success) {
        store.Alert("System Chatbot updated successfully", "success");
      } else {
        store.Alert("System Chatbot update fails.", "error");
      }
    } catch (e: any) {
      store.Alert(ErrorX.Handle(e), "error");
    }
  };

  return (
    <Formizo
      height="100%"
      schema={schema}
      buttons={["Revert", "Submit"]}
      onSubmit={handleSubmit}
      defaultValue={chatbotInfo}
      addOns={{
        models: model,
      }}
    />
  );
};

export { schema };
export default SysChatbot;
