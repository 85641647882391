import { Typography } from "@mui/material";
import { HStack, VStack } from "Components";
import { Accessor, store, ColorX } from "static";
import Version from "config/version";
import { DOMAIN } from "config/config";

import { CSSProperties } from "react";

export default function VersionList(style: CSSProperties | undefined) {
  let env = Accessor.Get(store.server, "Env");
  let envStr = env ? env.toUpperCase() : "";
  return (
    <div>
      <Typography style={style}>{"[" + envStr + "]"}</Typography>
      <Typography style={style}>{"UI Version: " + Version}</Typography>
      <Typography style={style}>{"Backend Version: " + store.server.backendVersion}</Typography>
      <Typography style={style}>{"Chatbot Version: " + store.chatbot.chatbotVersion}</Typography>
      {store.server.gptQaVersion && <Typography style={style}>{"GPT Service: " + store.server.gptQaVersion}</Typography>}
      <Typography style={style}>{"Backend Domain: " + DOMAIN}</Typography>
    </div>
  );
}
