import { ConditionOperator, FieldType, Filterable } from "Components/LabIZO/Tablizo";
import { Accessor } from "static";
import { CustomFilterValueProps } from "Components/LabIZO/Tablizo";
import { NativeSelect } from "@mui/material";
import { WebsiteStatus, WebsiteURLType } from "../../../interfaces/website-manager-api.interface";

export const FILTERABLES: Filterable[] = [
  {
    field: "alias",
    label: "Website Name",
    type: FieldType.text,
  },
  {
    field: "urlSetting.url",
    label: "URL",
    type: FieldType.text,
  },
  {
    field: "urlSetting.type",
    label: "URL Type",
    type: FieldType.select,
    CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => {
      return (
        <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
          <option value={""}></option>
          <option value={WebsiteURLType.EXPLICIT}>{WebsiteURLType.EXPLICIT}</option>
          <option value={WebsiteURLType.IMPLICIT}>{WebsiteURLType.IMPLICIT}</option>
        </NativeSelect>
      );
    },
  },
  {
    field: "status",
    label: "Status",
    type: FieldType.select,
    CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => {
      return (
        <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
          <option value={""}></option>
          <option value={WebsiteStatus.INDEXING}>{WebsiteStatus.INDEXING}</option>
          <option value={WebsiteStatus.INDEXED}>{WebsiteStatus.INDEXED}</option>
        </NativeSelect>
      );
    },
  },
  {
    field: "last_index_time",
    label: "Last Index Time",
    type: FieldType.text,
  },
  // {
  //   field: "enabled",
  //   label: "Enabled",
  //   type: FieldType.select,
  // },
];
