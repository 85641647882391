import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Badge, Box, Button, IconButton, NativeSelect, Popover, TextField } from "@mui/material";
import { Property } from "csstype";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Control, Controller, ControllerFieldState, ControllerRenderProps, FieldPath, UseFormResetField, useFieldArray, useForm, useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";
import { MultiFilterHeaders } from "./MultiFilterHeaders";
import { Authority } from "static";

export type CustomFilterValueProps = FilterValueFieldProps & { width: Property.Width };
export interface Filterable {
  label: string;
  field: string;
  type: FieldType;
  keyName?: string; //For array type, the keyName is the key of the object in the array
  reqAuth?: string;
  reqLevel?: number;
  reqFunc?: string;
  CustomFilterValue?: React.FC<CustomFilterValueProps>;
  CustomFilterOperator?: OperatorsType;
  CustomCondition?: (condition: Condition) => {};
  CustomFilter?: (baseData: any, value: string) => {};
}

// TODO: boolean fieldtype does not cater for server side filter
// current workaround: use select with custom options
export enum FieldType {
  text = "text",
  number = "number",
  boolean = "boolean",
  date = "date",
  datetime = "datetime",
  array = "array",
  select = "select",
  custom = "custom",
}

const sampleFilterables: Filterable[] = [
  {
    field: "name",
    label: "Answer ID",
    type: FieldType.text,
  },
  {
    field: "date",
    label: "Date",
    type: FieldType.date,
  },
  {
    field: "bool",
    label: "Bool",
    type: FieldType.boolean,
  },
  {
    field: "num",
    label: "Number",
    type: FieldType.number,
  },
];

export enum CombinationOperator {
  and = "$and",
  or = "$or",
  not = "$not",
  nor = "$nor",
  all = "$all",
  elemMatch = "$elemMatch",
  allMatch = "$allMatch",
  keyMapMatch = "$keyMapMatch",
}

export enum ConditionOperator {
  lt = "$lt",
  lte = "$lte",
  eq = "$eq",
  ne = "$ne",
  gte = "$gte",
  gt = "$gt",
  exists = "$exists",
  type = "$type",
  in = "$in",
  nin = "$nin",
  size = "$size",
  mod = "$mod",
  regex = "$regex",
  contains = "$elemMatch",
  notExists = "$notExists",
}

export type OperatorsType = Record<string, ConditionOperator>;

const TextOperators: OperatorsType = {
  contains: ConditionOperator.regex,
  match: ConditionOperator.eq,
  // "is not": ConditionOperator.ne,
};

const DateOperators: OperatorsType = {
  "is on": ConditionOperator.eq,
  "is not on": ConditionOperator.ne,
  "is on or earlier than": ConditionOperator.lte,
  "is earlier than": ConditionOperator.lt,
  "is on or later than": ConditionOperator.gte,
  "is later than": ConditionOperator.gt,
};

const NumberOperators: OperatorsType = {
  "=": ConditionOperator.eq,
  "≠": ConditionOperator.ne,
  "≤": ConditionOperator.lte,
  "<": ConditionOperator.lt,
  "≥": ConditionOperator.gte,
  ">": ConditionOperator.gt,
};

const BooleanOperators: OperatorsType = {
  is: ConditionOperator.eq,
};
const ArrayOperators: OperatorsType = {
  contains: ConditionOperator.contains,
  notExists: ConditionOperator.notExists,
};

const SelectOperators: OperatorsType = {
  is: ConditionOperator.eq,
};

type SelectorValue = string | number | boolean | Date | string[] | number[] | object;

export interface Condition {
  field: string;
  operator: string;
  value: SelectorValue;
}

export interface ConditionsExtended extends Condition {
  type?: FieldType;
  keyValue?: string;
  CustomCondition?: (condition: Condition) => any;
  CustomFilter?: (baseData: any, value: string) => any;
}

export interface DefaultFilterType {
  logicalOperator: CombinationOperator.and | CombinationOperator.or;
  conditions: Condition[];
}

const defaultCondition: Condition = {
  field: "",
  operator: "",
  value: "",
};

interface FilterFormProps {
  conditions: Condition[];
}

export interface NavState {
  pageSize: number;
  curPage: number;
  totalEntries: number;
  inQuery: boolean;
  hasNextPage: boolean;
  selector: {};
}

interface MultiFilterProps {
  _onFilterChange: (arg: any) => void;
  filterables: Filterable[];
  DefaultFilter: DefaultFilterType;
  activeSelector: any;
}

interface FilterRowProps {
  filterables: Filterable[];
  control: Control<any, any>;
  name: string;
  width?: Property.Width;
  resetField?: UseFormResetField<FilterFormProps>;
}

export type IDefaultFilter = Record<string, Record<string, Record<string, SelectorValue>>[]>;

export const parseDefaultFilter = (defaultFilter: DefaultFilterType): IDefaultFilter => {
  const selector: Record<string, Record<string, Record<string, SelectorValue>>[]> = {};
  selector[defaultFilter.logicalOperator as string] = parseCondition(defaultFilter.conditions);
  return selector;
};

export const parseCondition = (conditions: ConditionsExtended[]) => {
  const selectors = conditions.map((condition) => {
    const selector: Record<string, Record<string, SelectorValue>> = {};

    // backend query modification
    if (condition.type === "custom" && _.isFunction(condition.CustomCondition)) {
      let res = condition.CustomCondition(condition);
      res.customFilter = condition.CustomFilter;
      return res;
    }

    if (condition.type === "array") {
      if (condition.keyValue) {
        selector[condition.field] = {
          [condition.operator]: {
            [condition.keyValue]: `(?i)${condition.value}`,
          },
        };
      } else {
        selector[condition.field] = {
          [condition.operator]: `(?i)${condition.value}`,
        };
      }
      return selector;
    }

    //boolean condition modification
    if (condition.type === "boolean") {
      selector[condition.field] = {
        $eq: condition.value == "true",
      };
      return selector;
    }

    //case insensitive for "contains"
    if (condition.operator === ConditionOperator.regex) {
      selector[condition.field] = {
        [ConditionOperator.regex]: `(?i)${condition.value}`,
      };
      return selector;
    }

    //else
    selector[condition.field] = {
      [condition.operator]: condition.value,
    };
    return selector;
  });
  const cleanedSelectors = selectors.filter((s) => {
    const field = Object.keys(s)[0];
    const operator = Object.keys(s[field])[0];
    const value = s[field][operator];
    return field && operator && value != undefined;
  });
  return cleanedSelectors;
};

const detectFilter = (activeSelector: any) => {
  let ttlLength = 0;
  if (activeSelector && activeSelector["$and"] && activeSelector["$and"].length > 0) {
    ttlLength = activeSelector["$and"].length;
  }
  if (activeSelector && activeSelector["$or"] && activeSelector["$or"].length > 0) {
    ttlLength = activeSelector["$or"].length;
  }
  return ttlLength;
};

const ControllerPlus = <TInput extends string, TOutput>({
  control,
  transform,
  name,
  defaultValue,
  type,
  variant = "standard",
}: {
  transform: {
    input: (value: TOutput) => TInput;
    output: (value: React.ChangeEvent<HTMLInputElement | HTMLInputElement | HTMLTextAreaElement>) => TOutput;
  };
  name: FieldPath<any>;
  control: Control<any>;
  defaultValue?: any;
  type?: React.InputHTMLAttributes<unknown>["type"];
  variant?: "standard" | "filled" | "outlined";
}) => {
  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <TextField {...field} variant={variant} type={type} error={Boolean(fieldState?.error)} onChange={(e) => field.onChange(transform.output(e))} value={transform.input(field.value)} />
      )}
    />
  );
};

const FilterField = ({ filterables, name, control, resetField, width = "100px" }: FilterRowProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>, field: ControllerRenderProps<any, `${string}.field`>) => {
    if (!resetField) throw new Error("ResetField not found in component props");
    let _name = name as `conditions.${number}`;
    resetField(`${_name}.operator`);
    field.onChange(e);
  };

  return (
    <Controller
      control={control}
      name={`${name}.field`}
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState.error)} onChange={(e) => handleChange(e, field)}>
          <option value={""}></option>
          {filterables.reduce<JSX.Element[]>((acc, f) => {
            if (f.reqAuth && !Authority.IsAccessibleQ(f.reqAuth, f.reqLevel, f.reqFunc)) return acc;
            acc.push(
              <option key={`${name}.field-${f.label}`} value={f.field}>
                {f.label}
              </option>
            );
            return acc;
          }, [])}
        </NativeSelect>
      )}
    />
  );
};

const FilterOperator = ({ filterables, control, name, width = "100px" }: FilterRowProps) => {
  // detect type for corresponding filter field
  const filterField = useWatch({
    control,
    name: `${name}.field`,
  });
  const fieldType = filterables.find((f) => f.field === filterField);
  let options: OperatorsType | undefined;
  if (fieldType?.CustomFilterOperator) {
    options = fieldType?.CustomFilterOperator;
  } else {
    switch (fieldType?.type) {
      case FieldType.text:
        options = TextOperators;
        break;
      case FieldType.datetime:
      case FieldType.date:
        options = DateOperators;
        break;
      case FieldType.number:
        options = NumberOperators;
        break;
      case FieldType.boolean:
        options = BooleanOperators;
        break;
      case FieldType.array:
        options = ArrayOperators;
        break;
      case FieldType.select:
        options = SelectOperators;
        break;
      default:
        break;
    }
  }
  return (
    <Controller
      control={control}
      name={`${name}.operator`}
      rules={{ required: true }}
      render={({ field, fieldState }) => {
        if (!field.value && options) {
          const firstOption = _.map(options, (v) => v)[0];
          field.onChange(firstOption);
        }
        return (
          <NativeSelect {...field} sx={{ width }} error={Boolean(fieldState.error)}>
            {options &&
              _.map(options, (option, key) => {
                return (
                  <option key={`${name}-${key}`} value={option}>
                    {key}
                  </option>
                );
              })}
          </NativeSelect>
        );
      }}
    />
  );
};

export const BooleanValues = {
  true: true,
  false: false,
};

export interface FilterValueFieldProps {
  field?: ControllerRenderProps<any, `${string}.value`>;
  fieldState?: ControllerFieldState;
}

const FilterValue = ({ filterables, control, name, width = "100px" }: FilterRowProps) => {
  // detect type for corresponding filter field
  const filterField = useWatch({
    control,
    name: `${name}.field`,
  });
  const filterOperatorValue = useWatch({
    control,
    name: `${name}.operator`,
  });

  const fieldType = filterables.find((f) => f.field === filterField);
  const CustomFilterValue = fieldType?.CustomFilterValue;
  let FilterValueField: React.FC<FilterValueFieldProps>;
  if (CustomFilterValue) {
    // use the custome filter value component if exist
    FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => <CustomFilterValue field={field} width={width} fieldState={fieldState} />;
  } else {
    switch (fieldType?.type) {
      case FieldType.text:
        FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => <TextField sx={{ width }} variant="standard" error={Boolean(fieldState?.error)} {...field} />;
        break;
      case FieldType.number:
        FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => (
          <NumberFormat
            sx={{ width }}
            customInput={TextField}
            error={Boolean(fieldState?.error)}
            variant="standard"
            onValueChange={({ floatValue }) => field?.onChange(floatValue)}
            value={field?.value}
          />
        );
        break;
      case FieldType.date:
        FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => <TextField sx={{ width }} variant="standard" type="date" error={Boolean(fieldState?.error)} {...field} />;
        break;
      case FieldType.datetime:
        // FilterValueField = ({ field }: FilterValueFieldProps) => <TextField sx={{ width }} variant="standard" type="datetime-local" {...field} />;
        FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => (
          <ControllerPlus<any, any>
            transform={{
              input: (value) => {
                // return value && new Date(value).toISOString().substring(0, 19);
                return value && moment(value).format("YYYY-MM-DDTHH:mm:ss");
              },
              output: (e) => {
                return moment(e.target.value).toISOString();
                // console.log(date.toISOString().substring(0, 19));
                // return date.toISOString().substring(0, 19);
              },
            }}
            control={control}
            name={field?.name!}
            type="datetime-local"
          />
        );
        break;
      case FieldType.boolean:
        FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => (
          <NativeSelect {...field} sx={{ width }} error={Boolean(fieldState?.error)}>
            <option value={""}></option>
            <option value={"true"}>true</option>
            <option value={"false"}>false</option>
          </NativeSelect>
        );
        break;
      default:
        FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => (
          <TextField sx={{ width }} variant="standard" error={Boolean(fieldState?.error)} {...field} disabled={filterOperatorValue == "$notExists"} />
        );
        break;
    }
  }

  return (
    <Controller
      //
      control={control}
      name={`${name}.value`}
      rules={{ required: true }}
      render={({ field, fieldState }) => <FilterValueField field={field} fieldState={fieldState} />}
    />
  );
};

const MultiFilter = ({ filterables = sampleFilterables, DefaultFilter, _onFilterChange, activeSelector }: MultiFilterProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { control, reset, resetField, handleSubmit } = useForm<FilterFormProps>({
    defaultValues: {
      conditions: DefaultFilter ? DefaultFilter.conditions : [defaultCondition],
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: "conditions",
    control: control,
  });

  const onAdd = () => append(defaultCondition);
  const onReset = () => {
    reset({ conditions: [defaultCondition] });
    _onFilterChange({});
  };
  const onDelete = (fields: any[], index: number) => {
    if (fields.length && fields.length > 0) {
      remove(index);
    }
  };
  const onApply = (data: FilterFormProps) => {
    const conditions = data.conditions;

    //append type to each condition
    const conditionsExtended = conditions.map((condition) => {
      const field = filterables.find((f) => f.field === condition.field);
      if (field) {
        return { ...condition, keyValue: field.keyName, type: field.type, CustomCondition: field.CustomCondition, CustomFilter: field.CustomFilter };
      }
      return condition;
    });

    const selectors = parseCondition(conditionsExtended);
    console.log(selectors);
    let and: Record<string, typeof selectors> = {};
    if (selectors.length > 0) {
      and[CombinationOperator.and] = selectors;
    }
    _onFilterChange(and);
    handleClose();
  };

  return (
    <Box>
      <Button
        onClick={handleClick}
        size="small"
        startIcon={
          <Badge badgeContent={detectFilter(activeSelector)} color="error">
            <FilterListIcon />
          </Badge>
        }
      >
        Filters
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Button onClick={onAdd}>Add</Button>
        <Button onClick={onReset}>Reset</Button>

        <MultiFilterHeaders />
        {/* filter opptions */}
        {fields.map((field, index) => (
          <Box key={`filter-${index}`} display="flex" gap={2} alignItems="flex-end" p={1}>
            <IconButton onClick={() => onDelete(fields, index)}>
              <DeleteIcon />
            </IconButton>
            <FilterField control={control as Control<any, any>} resetField={resetField} filterables={filterables} name={`conditions.${index}`} width="150px" />
            <FilterOperator control={control as Control<any, any>} filterables={filterables} name={`conditions.${index}`} width="200px" />
            <FilterValue control={control as Control<any, any>} filterables={filterables} name={`conditions.${index}`} width="250px" />
          </Box>
        ))}
        <Button onClick={handleSubmit(onApply)}>Apply</Button>
      </Popover>
    </Box>
  );
};

export default MultiFilter;
