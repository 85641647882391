export interface FAQ {
  _id: string;
  _rev?: string;
  disabled?: boolean;
  type: FAQType;
  name: string;
  alias: string;
  domain: string;
  description: string;
  category?: string;
  subcategory?: string;
  answerRef: string;
  intentRef: string;
  dialog_node: string;
  inUse?: string[]; // used by what other FAQs as quick reply payload
  CREATED_AT?: string;
  LASTMODIFIED?: string;
}

export enum FAQType {
  STATIC = "static",
  REDIRECT_TO_GPT = "redirectToGPT",
}
