import { Chip, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Typography, Checkbox, Pagination } from "@mui/material";
import { Box } from "@mui/system";

import { Container, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { VStack } from "Components";
import { backendConnector, DATA_LINK } from "connectors";
import { Accessor, Authority, ColorX } from "static";
import { CusAuth } from "interfaces";

const StyleFormLabel = styled(FormLabel)({
  textAlign: "center",
  font: "normal normal normal 20px/27px Segoe UI",
  padding: "12px",
  color: "#005F73",
});

const StyledPaper = styled(Paper)({
  p: 2,
  height: "100%",
  width: "100%",
  border: "1px solid #ccc",
  borderRadius: "4px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  padding: "2px",
});

const StyledTableCell = styled(TableCell)({
  padding: "4px",
  maxWidth: "200px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  "&:hover": {
    overflow: "visible",
    whiteSpace: "normal",
    position: "relative",
    zIndex: 1,
    maxWidth: "200px",
    wordBreak: "break-all",
    maxHeight: "5px",
  },
});

interface ISearchProps {
  addOns?: { project?: string; knowledgeGroup?: string };
  setAddOns?: (addOns: any) => void;
  page: string;
}

const StyledWarningChip = styled(Chip)({
  mx: "10px",
  height: "auto",
  "& .MuiChip-label": {
    whiteSpace: "normal",
    padding: "5px 12px",
  },
});

const SearchBar = ({ addOns, setAddOns, page }: ISearchProps) => {
  const [data, setData] = useState<{ _id: string; alias: string; docs?: [] }[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    (async () => {
      if (page == "Documents") {
        const DMRespone = await backendConnector.post(DATA_LINK.DMList, { filterControl: true });
        const projects = DMRespone.payload.docs;
        const sortedDcouments = projects.slice().sort((a: any, b: any) =>
          b.reference.title.localeCompare(a.reference.title)
        );
        const filteredData = sortedDcouments.filter((item: { enabled: any }) => item.enabled);
        setData(filteredData);
        setAddOns && setAddOns({ ...addOns, project: null, knowledgeGroup: null });
        setSelectedRowIds([]);
      }
      if (page == "Groups") {
        const KGRespone = await backendConnector.post(DATA_LINK.KGList, { filterControl: true });
        const knowledgeBase = KGRespone.payload.docs;
        const filteredData = knowledgeBase.filter((item: { enabled: any }) => item.enabled);
        setData(filteredData);
        setAddOns && setAddOns({ ...addOns, project: null, knowledgeGroup: null });
        setSelectedRowIds([]);
      }
      if (page == "All") {
        setAddOns && setAddOns({ ...addOns, project: ["All"], knowledgeGroup: null });
      }
    })();
  }, [page]);

  const filteredData = data.filter((row) => row.alias.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleChange = (event: { target: { value: SetStateAction<string> } }) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, row: { _id: any; alias?: string }) => {
    if (event.target.checked) {
      setSelectedRowIds([...selectedRowIds, row._id]);
    } else {
      setSelectedRowIds(selectedRowIds.filter((id) => id !== row._id));
    }
    if (setAddOns) {
      if (page === "Documents") {
        const projectSet = new Set([...(addOns?.project ?? []), row._id]);
        setAddOns({ ...addOns, project: [...projectSet], knowledgeGroup: [] });
      }
      if (page === "Groups") {
        const knowledgeGroupSet = new Set([...(addOns?.knowledgeGroup ?? []), row._id]);
        setAddOns({ ...addOns, knowledgeGroup: [...knowledgeGroupSet], project: [] });
      }
    }
  };
  const handlePageChange = (event: any, value: SetStateAction<number>) => {
    setCurrentPage(value);
  };

  const getPageData = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  };

  return (
    <VStack width={"100%"} height={"100%"} sx={{ overflow: "hidden", flexDirection: "column" }}>
      <Container maxWidth="md" sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          id="search"
          type="search"
          label="Search Document / group name"
          value={searchTerm}
          onChange={handleChange}
          variant="standard"
          disabled={page === "All" ? true : false}
          sx={{ "& label": { color: "grey" }, width: "90%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Container>
      <StyledPaper
        sx={{
          backgroundColor: page !== "All" ? "#ffffff" : "#f5f5f5",
          color: page !== "All" ? "#000000" : "#333333",
        }}
      >
        {page === "All" && (
          <Typography align="center" style={{ font: "normal normal normal 16px/21px Segoe UI", color: "#C9C9C9" }}>
            All Documents are now being used...
          </Typography>
        )}
        {page != "All" && (
          <>
            {!selectedRowIds && (
              <Typography variant="subtitle2" sx={{ textAlign: "center", color: "red" }}>
                Select a Document or Group
              </Typography>
            )}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: "5px" }}>Enabled</TableCell>
                  <TableCell style={{ padding: "5px" }}> {page == "Documents" ? "Document Name" : "Knowledge Group Name"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getPageData().map((row) => (
                  <TableRow key={row._id}>
                    <TableCell sx={{ padding: "4px" }}>
                      <Checkbox checked={selectedRowIds.includes(row._id)} onChange={(event) => handleCheckboxChange(event, row)} disabled={(!row.docs || row.docs.length == 0) && page == "Groups"} />
                    </TableCell>
                    <StyledTableCell>
                      {(!row.docs || row.docs.length == 0) && page == "Groups" ? (
                        <span>
                          {row?.alias}
                          <br />
                          <span style={{ color: "red" }}>No docs enabled</span>
                        </span>
                      ) : (
                        row?.alias
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box sx={{ mt: "auto" }}>
              <Pagination count={Math.ceil(filteredData.length / pageSize)} page={currentPage} onChange={handlePageChange} />
            </Box>
          </>
        )}
      </StyledPaper>
    </VStack>
  );
};

interface ISettingProps {
  addOns?: { project?: string; knowledgeGroup?: string };
  setAddOns?: (addOns: any) => void;
  tab: string;
}

export const SettingPanel = ({ addOns, setAddOns, tab }: ISettingProps) => {
  const [page, setPage] = useState("All");

  useEffect(() => {
    setAddOns && setAddOns({ ...addOns, project: ["All"], knowledgeGroup: null });
  }, []);

  const handleRadioChange = (event: { target: { value: SetStateAction<string> } }) => {
    setPage(event.target.value); // Update the state variable with the selected value
  };

  const renderTabPanels = () => {
    let render = [];
    render.push(<FormControlLabel key={"all"} style={{ color: "#005F73" }} labelPlacement="start" value="All" control={<Radio />} label="ALL" />);
    if (Authority.IsAccessibleQ(`Talk2VA.${tab}`, 999, CusAuth.DocsManagement)) {
      render.push(<FormControlLabel key={"documents"} style={{ color: "#005F73" }} labelPlacement="start" value="Documents" control={<Radio />} label="Documents" />);
    }
    if (Authority.IsAccessibleQ(`Talk2VA.${tab}`, 999, CusAuth.GroupManagement)) {
      render.push(<FormControlLabel key={"groups"} style={{ color: "#005F73" }} labelPlacement="start" value="Groups" control={<Radio />} label="Group" />);
    }

    return render;
  };

  const warning = "Document / Group settings can be overwritten by the FAQ settings if the FAQ is redirected to GPT";

  return (
    <VStack width={"100%"} height={"100%"} sx={{ justifyContent: "start" }}>
      <FormControl>
        <StyleFormLabel>Select Documents</StyleFormLabel>
        <RadioGroup row sx={{ width: "100%" }} value={page} onChange={handleRadioChange}>
          {renderTabPanels()}
        </RadioGroup>
      </FormControl>
      <StyledWarningChip icon={<InfoIcon />} label={warning} />
      <SearchBar page={page} addOns={addOns} setAddOns={setAddOns} />
    </VStack>
  );
};
