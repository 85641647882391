import { DataEventLog } from "./DataEventLog.interface";

export enum AuditAction {
  CREATE_ONE = 0,
  UPDATE_ONE = 1,
  DELETE_ONE = 2,
  GET_ONE = 3,

  IMPORT = 8,
  EXPORT = 9,
  UPLOAD = 10,

  CREATE_BULK = 50,
  UPDATE_BULK = 51,
  DELETE_BULK = 52,
  LIST_MULTIPLE = 53,
  ANALYSE = 54,
  ENABLE_DISABLE = 55,

  INIT = 100,
  LOGIN = 101,
  RESET_PASSWORD = 104,
  CHECK_USER_NAME = 106,

  BACKUP = 201,
  RESTORE = 202,
  SYNC = 203,

  TRAIN = 301,
  OTHER = 9999, //??
}

export enum AuditActionDisplay {
  CREATE = "Create",
  UPDATE = "Update",
  DELETE = "Delete",
  LIST = "List",
  IMPORT = "Import",
  EXPORT = "Export",
  UPLOAD = "Upload",
  BACKUP = "Backup",
  RESTORE = "Restore",
  SYNC = "Sync",

  ANALYSE = "Analyse",
  ENABLE_DISABLE = "Enable / Disable",

  INIT = "Initialize",
  LOGIN = "Log In",
  RESET_PASSWORD = "Reset Password",
  CHECK_USER_NAME = "Check User Name",

  TRAIN = "Train",
  OTHER = "Other",
}

export enum SYVAComponent {
  FAQ = "FAQ",
  FLE = "FLE",
  Intent = "Intent",
  Entity = "Entity",
  CounterExample = "CounterExample",
  Answer = "Answer",
  Talk2VATrainer = "Talk2VATrainer",
  DocumentManager = "DocumentManager",
  KnowledgeGroup = "KnowledgeGroup",
  UserAccessControl = "UserAccessControl",
  RoleAccessControl = "RoleAccessControl",
  Training = "Training",
  ConvLog = "ConvLog",
  RequestLog = "RequestLog",
  GPTConvLog = "GPTConvLog",
  AuditLog = "AuditLog",
  DataEventLog = "DataEventLog",
  Arena = "Arena",
  SystemSetting = "SystemSetting",
  Dashboard = "Dashboard",
  StarterDashboard = "StarterDashboard",
  GPTQADashboard = "GPTQADashboard",
  System = "System",
  BackupRestore = "Backup & Restore",
  NLP = "NLP",
}

export interface AuditLog {
  _id: string;
  recTime: string;
  user: {
    username?: string;
    userDisplayName?: string;
    role?: string;
  };
  action: ActionInfo;
  api: {
    url: string;
    method: "POST" | "GET" | "PUT";
    cat: string;
    subcat: string;
    action?: string;
    body: string; // stringified JSON object
    host?: string;
    origin?: string;
    referrer?: string;
    ip?: string;
  };
  outcome: {
    success: boolean;
    message?: string; // e.g. token expired
  };
  data_events: string[];
}

export type AuditLogWithDataEventDetails = Omit<AuditLog, "data_events"> & {
  data_events: DataEventLog[];
};

export interface ActionInfo {
  component: SYVAComponent;
  type: AuditAction;
  displayType: AuditActionDisplay;
}

export type FlatActionInfo = [ActionInfo["component"], ActionInfo["type"]];
