import { backendConnector, DATA_LINK } from "connectors";
import { useState, useEffect } from "react";
export interface ModelOption {
  label: string;
  value: string;
}
export function useModelOption() {
  const [modelOption, setModelOption] = useState<ModelOption[]>();
  useEffect(() => {
    getModelInfo();
  }, []);
  const getModelInfo = async () => {
    const { payload } = await backendConnector.post<{
      models: {
        id: string;
        name: string;
      }[];
    }>(DATA_LINK.ArenaInfo, {});
    const res = payload.models.map((model) => {
      return {
        label: model.name,
        value: model.id,
      };
    });
    setModelOption(res);
  };
  return {
    modelOption,
  };
}
