const Request = {
  //User
  Info: "/Base/User/Info",
  List: "/Base/User/List",
  Add: "/Base/User/Add",
  Delete: "/Base/User/Delete",
  Edit: "/Base/User/Edit",
  Export: "/Base/User/Export",
  Import: "/Base/User/Import",
  DeleteBulk: "/Base/User/DeleteBulk",

  //Role
  RoleList: "/Base/Role/List",
  RoleInfo: "/Base/Role/Info",
  RoleAdd: "/Base/Role/Add",
  RoleEdit: "/Base/Role/Edit",
  RoleDelete: "/Base/Role/Delete",
  RoleDeleteBulk: "/Base/Role/DeleteBulk",
  RoleConfig: "/Base/Role/RolesConfig",
};

let exports = {
  Request,
};

export default exports;
