const Request = {
  DBInfo: "/Tables/FileHandler/Info",
  List: "/Tables/FileHandler/List",
  Add: "/Tables/FileHandler/Add",
  Delete: "/Tables/FileHandler/Delete",  
  DeleteBulk: "/Tables/FileHandler/DeleteBulk",  
};

let exports = {
  Request,
};

export default exports;
