import { Box, Tooltip, ButtonBase } from "@mui/material";
import { PushPin } from "@mui/icons-material";
import { HStack, VStack } from "Components/LabIZO/Stackizo";
import { ColorX } from "static";
import { useState } from "react";
import styles from "./Menu.module.css";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import ClassicMenuLayout from "./ClassicLayout/ClassicLayout";
import StarterMenuLayout from "./StarterLayout/StarterLayout";
import { MenuMode } from "interfaces/db.interfaces/config.interfaces";

interface MenuProps {
  location: Location;
  mode: MenuMode;
}

const Menu: React.FC<MenuProps> = ({ location, mode }) => {
  const [mini, setMini] = useState<boolean>(true);

  const toggleMini = (): void => setMini(!mini);

  const Pin = () => {
    return (
      <ButtonBase
        sx={{
          width: "100%",
          color: ColorX.GetColorCSS("Primary"),
          background: ColorX.GetColorCSS("Secondary"),
          "&:hover": {
            color: ColorX.GetColorCSS("Secondary"),
            background: ColorX.GetColorCSS("Primary"),
          },
        }}
        onClick={toggleMini}
        aria-label="label"
      >
        <HStack width={"100%"} justifyContent={"flex-end"} py="5px">
          <Box
            sx={{
              display: "flex", //
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              color: "inherit",
            }}
          >
            <Tooltip title={mini ? "Show Label" : "Hide Label"} aria-label="label" arrow={true} placement="right">
              <PushPin style={{ rotate: mini ? "90deg" : undefined }} />
            </Tooltip>
          </Box>
        </HStack>
      </ButtonBase>
    );
  };

  return (
    <Box className={clsx(styles.menu, { [styles.mini]: mini })} width="140px" height="100%" bgcolor={ColorX.GetColorCSS("Secondary")} position="fixed" zIndex="10">
      <VStack width="100%" sx={{ paddingTop: "50px", alignItems: "flex-end" }}>
        <Pin />
        {mode === MenuMode.CLASSIC ? ( //
          <ClassicMenuLayout location={location} mini={mini} />
        ) : (
          <StarterMenuLayout location={location} mini={mini} />
        )}
      </VStack>
    </Box>
  );
};

export default observer(Menu);
