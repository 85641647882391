import { VStack } from "Components";
import { ITableRow, TableContent, TableHeader } from "Components/DetailsTable";

export const Section: React.FC<{ header: string; items: ITableRow[] }> = ({ header, items }) => {
  return (
    <VStack width="100%" gap={2} height="auto">
      <TableHeader label={header} />
      <TableContent items={items} />
    </VStack>
  );
};
