import { useEffect, useState } from "react";
import { Accessor, ErrorX, store } from "static";
import { VStack, HStack, Spacer } from "Components/LabIZO/Stackizo";
import { DATA_LINK, backendConnector } from "connectors";
import Formizo from "Components/LabIZO/Formizo";
import { schema } from "./schema";

interface IEmailConfig {
  host: string;
  port: string;
  secure: boolean;
  auth: {
    user: string;
    pass: string;
  };
  sender: string;
  receiver: string;
  loginURL: string;
  editPath?: string;
}

const SysEmail = () => {
  const [emailInfo, setEmailInfo] = useState<{ email: IEmailConfig }>();

  useEffect(() => {
    getEmailInfo();
  }, []);

  const handleSubmit = async (emailData: IEmailConfig) => {
    try {
      const res = await backendConnector.post(DATA_LINK.SYSEmailEdit, emailData);
      if (res.Success) {
        store.Alert("System Email updated successfully", "success");
      } else {
        store.Alert("System Email update fails.", "error");
      }
    } catch (e: any) {
      store.Alert(ErrorX.Handle(e), "error");
    }
  };

  const getEmailInfo = async () => {
    const { payload } = await backendConnector.post(DATA_LINK.SYSEmailInfo, {});
    setEmailInfo(payload);
  };

  const renderEmailSetting = () => {
    return <Formizo schema={schema} buttons={["Revert", "Submit"]} onSubmit={handleSubmit} defaultValue={emailInfo} />;
  };

  return (
    <HStack justifyContent="flex-start" height="100%" gap={3} paddingX={4}>
      <VStack width="100%">{renderEmailSetting()}</VStack>
    </HStack>
  );
};

export { schema };
export default SysEmail;
