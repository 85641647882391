import { styled } from "@mui/material";
import { ChatTab } from "../../tabs";
import { HStack } from "Components";

export const StyledPageHStack = styled(HStack)(({ tabs }: { tabs: ChatTab[] }) => ({
  marginTop: tabs.length > 1 ? 0 : "5px",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  flexGrow: 1,
  flexShrink: 1,
  minHeight: 0,
}));
