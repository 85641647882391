import { Box, Grid } from "@mui/material";
import { AvailLANG, IT2EDetect, T2EContext, T2EMessage, ZChat } from "interfaces";
import { useState, useEffect, useContext } from "react";
import { ZCContextButton } from "./ZCContextButton";
import { ZChatContext } from "Components/Messenger/hooks/messenger.context";

export interface IZCContextProps {
  messages: ZChat[];
  quickReplies: any[];
  detect?: {
    Entities: any[];
    Semantics: any[];
    Context?: T2EContext[];
    ContextAction: string;
  };
  lang: AvailLANG;
  children?: React.ReactNode;
}

export function useStateContext(detect?: IT2EDetect) {
  let [stateContexts, setStateContexts] = useState<T2EContext[]>([]);
  useEffect(() => {
    if (!detect?.Context) return setStateContexts([]);

    const { Context } = detect;
    const contextToDisplay = Context.filter((c) => c.value);
    setStateContexts(contextToDisplay);
  }, [detect?.Context]);

  return { stateContexts, setStateContexts };
}

export function ZCContextPanel({ children, lang = AvailLANG.EN }: IZCContextProps) {
  const { detect, onContextUpdate } = useContext(ZChatContext) ?? {};
  const { stateContexts, setStateContexts } = useStateContext(detect);
  if (!detect) return <></>;
  if (!stateContexts.length) return <></>;

  async function handleDelete(context: T2EContext) {
    const actionId = detect?.ContextAction;
    const contexts = stateContexts.filter((c) => c.alias !== context.alias);
    if (!actionId) throw new Error("No actionId found");
    if (onContextUpdate) await onContextUpdate(actionId, contexts);
    //remove from state
    setStateContexts(contexts);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 1,
        width: "100%",
        py: 1,
      }}
    >
      {stateContexts.map((_c, i) => {
        return <ZCContextButton context={_c} onDelete={handleDelete} key={i} lang={lang} />;
      })}
    </Box>
  );
}
