import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Switch, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DeleteButton, MultiLangTabs } from "Components";
import { store } from "static";
import { DBAnswerSpec } from "interfaces";
import { IFLEDoc, MultiLangText } from "interfaces/db.interfaces/fle.interface";
import { useFormContext } from "react-hook-form";
interface IContextCardProps {
  onDelete: () => void;
}

export const DefaultMsgCard = ({ onDelete }: IContextCardProps) => {
  const [expandedState, setExpandedState] = useState(false);
  const { getValues, setValue } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  const defaultMessage = getValues("defaultMessage.msg") as MultiLangText;
  const enableDefaultMsg = getValues("defaultMessage.enabled");
  const defaultLang = store.sysInfo.Language.default || "EN";

  function deleteContext() {
    setValue("defaultMessage", undefined);
    return onDelete();
  }

  const renderSummary = () => {
    const setDefMsgEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(`defaultMessage.enabled`, e.target.checked);
    };
    return (
      <Grid container p={2} item xs={11}>
        <Grid item xs={1}>
          <Switch checked={enableDefaultMsg} onChange={setDefMsgEnabled} />
        </Grid>
        <Grid item xs={5}>
          <Typography sx={{ textAlign: "center" }}>DefaultMessage</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography sx={{ textAlign: "center" }}> {defaultMessage && defaultMessage[defaultLang]}</Typography>
        </Grid>
        <Grid container item xs={1}>
          <DeleteButton onClick={deleteContext} />
        </Grid>
      </Grid>
    );
  };
  const renderContext = () => {
    const setDefMsg = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, lang: string) => {
      setValue(`defaultMessage.msg.${lang}`, e.target.value);
    };
    return (
      <MultiLangTabs>
        {(_lang, _index) => {
          const defMsg = defaultMessage ? defaultMessage[_lang] : "";
          return <TextField fullWidth value={defMsg} label="Default Message" onChange={(e) => setDefMsg(e, _lang)} />;
        }}
      </MultiLangTabs>
    );
  };
  return (
    <Accordion sx={{ width: 0.97, paddingX: "5px" }} expanded={expandedState} onChange={() => setExpandedState(!expandedState)}>
      <AccordionSummary expandIcon={<ExpandMore />}>{renderSummary()}</AccordionSummary>
      <AccordionDetails>{renderContext()}</AccordionDetails>
    </Accordion>
  );
};
