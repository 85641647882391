import { FormizoSchema } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import _ from "lodash";
import { Answer } from "../SysGeneralSetting/schema";
import { QuickReply } from "../SysGeneralSetting/schema";

const messages: FormizoSchema[] = [
  {
    label: "Livechat Messages",
    foldStyle: "outlined",
    defaultShow: true,
    collapse: [
      {
        label: "Livechat start message",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:Livechat_StartChat"), QuickReply("master:Livechat_StartChat")],
      },
      {
        label: "Livechat not available",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:Livechat_NotAvailable"), QuickReply("master:Livechat_NotAvailable")],
      },
    ],
  },
];

const mwconfig: FormizoSchema[] = [
  {
    label: "Livechat Config",
    foldStyle: "outlined",
    defaultShow: true,
    collapse: [
      {
        label: "Livechat Available",
        name: "mwSettingsConfig.LiveChat.enabled",
        format: "bool",
      },
    ],
  },
];

export const schema: FormizoSchema[] = [...mwconfig, ...messages];
