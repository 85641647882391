import { FormizoCustom, FormizoSchema } from "Components/LabIZO/Formizo";
import { store } from "static";
import _ from "lodash";
import { TABLE_SCHEMA, FILTERABLES } from "./";

const Info: FormizoCustom = (formValue: any, addOns: any, fLang: { display: string; value: string }) => [];

const Add: FormizoSchema[] = [];

const Edit = Info;

const Settings: FormizoSchema[] = [];

const schema = {
  Table: TABLE_SCHEMA,
  Info,
  Add,
  Edit,
  Settings,
  Filterables: FILTERABLES,
};

export default schema;
