import { HMarquee } from "Components/LabIZO/Animatizo";
import _ from "lodash";
import React, { useEffect } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import styles from "./GPie.module.css";

export const GPie = ({ data, toPercent = false, cx = "50%", cy = "50%" }) => {
  let pieColor = ["#1D20E8", "#1DAAE8", "#E2334B", "#EE21A3", "#CF1DE8", "#821DE8", "#1DE8E3", "#1DE884", "#CAE81D", "#E8BF1D"];
  const labelKeyRange = 10;
  const [alternateLegend, setAlternateLegend] = React.useState(false);
  const [pieData, setPieData] = React.useState(data);
  useEffect(() => {
    let alternateData = _.map(data, (o) => {
      if (!o.alternateNames || o.alternateNames.length === 0) return o;
      //swap o.name with o.alternateName
      let alternateNames = [...o.alternateNames, o.name];
      o.name = o.alternateNames[0];
      alternateNames.shift();
      o.alternateNames = alternateNames;
      return o;
    });
    setPieData(alternateData);
  }, [alternateLegend, data]);

  if (!data) return <div />;

  const maxLegend = 0;
  const sumValue = _.sumBy(data, (o) => o.value) || 1;
  const Round = (o) => Math.round(o * 100);
  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <ResponsiveContainer className={styles.PieContainer} height="100%" width="100%">
          <PieChart className={styles.PieChart} width={"100%"} height={"100%"}>
            <Tooltip
              isAnimationActive={false}
              formatter={(value, name, props) => {
                if (toPercent) {
                  return [Round(value / sumValue) + "%", name];
                } else {
                  return [value, name];
                }
              }}
            />
            <Pie data={pieData} nameKey="name" innerRadius={"50%"} outerRadius={"80%"} fill="#8884d8" paddingAngle={5} dataKey="value" cx={cx} cy={cy}>
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={pieColor[index % pieColor.length]} />
              ))}
            </Pie>
            <Legend
              layout="vertical"
              align="right"
              iconSize="10"
              verticalAlign={"top"}
              wrapperStyle={{ right: "10px", top: "10%" }}
              onClick={(e) => {
                setAlternateLegend(!alternateLegend);
              }}
              width={"35%"}
              payload={pieData.slice(0, labelKeyRange).map((entry, index) => ({ value: entry.name, type: "square", color: pieColor[index % pieColor.length] }))}
              formatter={(value, keyColor) => {
                const { color } = keyColor;
                if (value && value.length >= maxLegend) {
                  return (
                    <HMarquee width="120px" style={{ overflowY: "hidden", display: "inline-flex", position: "absolute" }}>
                      <p className={styles.layout} style={{ color }}>
                        {value}
                      </p>
                    </HMarquee>
                  );
                }
                return <span style={{ color }}>{value}</span>;
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
