export enum CusAuth {
  Menu = "Menu",
  Info = "Info",
  Add = "Add",
  Edit = "Edit",
  Export = "Export",
  Delete = "Delete",
  InitTrain = "InitTrain",
  NlpInfo = "NlpInfo",
  Tag = "Tag",
  Train = "Train",
  Verify = "Verify",
  Details = "Details",
  Restore = "Restore",
  Analyse = "Analyse",
  Import = "Import",
  Sync = "Sync",
  Enable = "Enable",
  DocsManagement = "DocsManagement",
  GroupManagement = "GroupManagement",
  Backup = "Backup",

  // system texts
  DefaultMessages = "DefaultMessages",
  Disclaimer = "Disclaimer",

  // NLP Config
  Watson = "Watson",
  Talk2GPT = "Talk2GPT",
  EmbeddingModel = "EmbeddingModel",
  CompletionModel = "CompletionModel",

  //FLE API integrator
  APIIntegrator = "APIIntegrator",

  WebsiteStatus = "WebsiteStatus",

  LLMQuotaLimit = "LLMQuotaLimit",
  "3DView" = "3DView",

  IntentGeneration = "IntentGeneration",
  RedirectToGpt = "RedirectToGpt",
}

export enum SYS_AUTH {
  All = "*",
  Access = "Access",
}
type DEFAULT_AUTH = SYS_AUTH.All[] | SYS_AUTH.Access[] | [];

export interface IAuthority {
  Initialize?: DEFAULT_AUTH;
  Dashboard?: DEFAULT_AUTH;
  Convlog?: DEFAULT_AUTH | Extract<CusAuth, "Info" | "Export" | "InitTrain" | "NlpInfo">[];
  RequestLog?: DEFAULT_AUTH | Extract<CusAuth, "Info" | "Export">[];
  AuditLog?: DEFAULT_AUTH | Extract<CusAuth, "Info" | "Export">[];
  Talk2VA?: {
    FAQ?: DEFAULT_AUTH;
    Hybrid?: DEFAULT_AUTH | Extract<CusAuth, "DocsManagement" | "GroupManagement" | "Details">[];
    GPTQA?: DEFAULT_AUTH | Extract<CusAuth, "DocsManagement" | "GroupManagement" | "Details">[];
    AdvancedReasoning?: DEFAULT_AUTH | Extract<CusAuth, "DocsManagement" | "GroupManagement" | "Details">[];
    Arena?: DEFAULT_AUTH | Extract<CusAuth, "DocsManagement" | "GroupManagement" | "Details">[];
  };
  Training?: DEFAULT_AUTH | Extract<CusAuth, "Delete" | "Tag" | "Train" | "Verify" | "InitTrain">[];
  FAQ?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Analyse" | "Export" | "Import" | "Enable" | "RedirectToGpt">[];
  FLE?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Export" | "Import" | "APIIntegrator">[];
  AWSLiveChat?: DEFAULT_AUTH;
  Avatar?: DEFAULT_AUTH;
  Workspace?: {
    Intent?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Export" | "Import" | "Sync">[];
    Entity?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Sync">[];
    CounterExample?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Export" | "Import" | "Sync">[];
  };
  FAQTrainer?: DEFAULT_AUTH | Extract<CusAuth, "Delete">[];
  Simulator?: DEFAULT_AUTH;
  Answer?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Export">[];
  Config?: {
    DBNAME?: DEFAULT_AUTH;
    ContentDB?: DEFAULT_AUTH;
    Watsons?: DEFAULT_AUTH;
    Middleware?: DEFAULT_AUTH;
    MWSettings?: DEFAULT_AUTH;
    SpamCheck?: DEFAULT_AUTH;
  };
  Tools?: {};
  DevLab?: {};
  UserControl?: {
    User?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add">[];
    Role?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add">[];
  };
  System?: {
    General?: DEFAULT_AUTH | Extract<CusAuth, "Menu">[];
    BnR?: DEFAULT_AUTH | Extract<CusAuth, "Export" | "Import" | "Restore" | "Edit" | "Delete" | "Backup">[];
    User?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add">[];
    NLP?: DEFAULT_AUTH | Extract<CusAuth, "Watson" | "Talk2GPT" | "EmbeddingModel" | "CompletionModel">[];
    Chatbot?: DEFAULT_AUTH | Extract<CusAuth, "LLMQuotaLimit">[];
    Texts?: DEFAULT_AUTH | Extract<CusAuth, "DefaultMessages" | "Disclaimer">[];
    Spam?: DEFAULT_AUTH;
    Model?: DEFAULT_AUTH;
    Email?: DEFAULT_AUTH;
    GeneralSetting?: DEFAULT_AUTH;
    Billing?: DEFAULT_AUTH;
    Livechat?: DEFAULT_AUTH;
    Captcha?: DEFAULT_AUTH;
    TermsAndConditions?: DEFAULT_AUTH;
  };
  TechnologyPreviews?: DEFAULT_AUTH;
  DeepLearning?: DEFAULT_AUTH;
  GPTQA?: {
    Dashboard?: DEFAULT_AUTH;
    DocumentManager?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Sync" | "3DView" | "Enable">[];
    ConversationLog?: DEFAULT_AUTH | Extract<CusAuth, "Info" | "Export">[];
    TestChat?: {
      Hybrid?: DEFAULT_AUTH | Extract<CusAuth, "DocsManagement" | "GroupManagement">[];
      GPTQA?: DEFAULT_AUTH | Extract<CusAuth, "DocsManagement" | "GroupManagement">[]; // ["ModelConfig"]
      AdvancedReasoning?: DEFAULT_AUTH | Extract<CusAuth, "DocsManagement" | "GroupManagement">[];
      Arena?: DEFAULT_AUTH | Extract<CusAuth, "DocsManagement" | "GroupManagement">[];
    };
    KnowledgeGroupManagement?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Delete" | "Enable">[];
    WebsiteManager?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Delete" | "Enable" | "WebsiteStatus">[];
  };
  StarterDashboard?: DEFAULT_AUTH;
  ArenaLog?: DEFAULT_AUTH;
}
