import { Button, Grid } from "@mui/material";
import { DBAnswerSpec, QuickReply } from "interfaces";

import _ from "lodash";
import { BodyTitle } from "Components";
import { DeleteButton } from "Components/IconButtons";
import { HStack, VStack } from "Components/Layouts";
import { IAllFLEAnswers, IFLEAnswer, IFLECondition, IFLEDoc } from "interfaces/db.interfaces/fle.interface";
import { MultilangAnswerFeilds } from "../../../../components/MultilangAnswerFeilds";
import { ControlledMultilangAnswerFeilds } from "Pages/FLE/components";
import { useFormContext } from "react-hook-form";
import { AnsQRBTextField } from "./AnsQRBTextField";

interface IAnsContentProps {
  answers: IAllFLEAnswers<DBAnswerSpec>;
  selectedConditions: IFLECondition[];
  setSelectedConditions: (cond: IFLECondition[]) => void;
  onChangeAnswers: (answers: IAllFLEAnswers<DBAnswerSpec>) => void;
}

export function AnsContent(props: IAnsContentProps) {
  const { answers, setSelectedConditions, selectedConditions, onChangeAnswers } = props;
  const selectedAnswer = answers.conditionals?.find((ans) => _.isEqual(ans.conditions, selectedConditions));
  const { control, getValues, setValue } = useFormContext<IFLEDoc<DBAnswerSpec>>();

  const GridProps = {
    item: true,
    container: true,
    xs: 6,
    md: 4,
    lg: 2,
    justifyContent: "center",
    alignItems: "center",
  };

  const renderAnswerSelectionButtons = () => {
    const buttons = {
      conditional: (ans: IFLEAnswer<DBAnswerSpec>, ansIdx: number): JSX.Element => {
        const answerID = (ans.answerDoc._id.match(/answer_\d+/) || [ans.answerDoc._id])[0];

        return (
          <Grid {...GridProps} key={ansIdx}>
            <HStack>
              <Button
                onClick={() => {
                  setSelectedConditions(ans.conditions);
                }}
                variant={_.isEqual(selectedConditions, ans.conditions) ? "contained" : "text"}
              >
                {/* show last 8 characters */}
                {answerID}
              </Button>
              <DeleteButton
                onClick={() => {
                  answers.conditionals?.splice(ansIdx, 1);
                  onChangeAnswers(answers);
                }}
              />
            </HStack>
          </Grid>
        );
      },
      default: (): JSX.Element => {
        const noCondsSet = selectedConditions.length === 0;
        const allDefaultConds = selectedConditions.filter((cond) => cond.value === "default").length === selectedConditions.length;
        const isDefaultSelected = noCondsSet || allDefaultConds;

        return (
          <Grid {...GridProps}>
            <Button
              onClick={() => {
                setSelectedConditions(selectedConditions.map((cond) => ({ ...cond, value: "default" })));
              }}
              variant={isDefaultSelected ? "contained" : "text"}
            >
              Default
            </Button>
          </Grid>
        );
      },
    };

    return (
      <Grid container>
        {buttons.default()}
        {answers.conditionals?.map(buttons.conditional) || <></>}
      </Grid>
    );
  };
  const renderDefaultAnsConfig = () => {
    const editable = !selectedAnswer;
    const quickReply = getValues("answers.defaultAnsDoc.quickReplies");
    const handleChange = (quickReplyValue: QuickReply[]) => {
      setValue("answers.defaultAnsDoc.quickReplies", quickReplyValue);
    };
    return (
      <VStack width="50%">
        <BodyTitle>Default Answer</BodyTitle>
        <HStack gap={2}>
          <ControlledMultilangAnswerFeilds<IFLEDoc<DBAnswerSpec>>
            //
            name={"answers.defaultAnsDoc"}
            control={control}
            disabled={!editable}
          />
        </HStack>
        <HStack>
          <AnsQRBTextField quickReplyValue={quickReply} handleQRChange={handleChange} />
        </HStack>
      </VStack>
    );
  };

  const renderSelectedAnsConfig = (selectedAnswer: IFLEAnswer<DBAnswerSpec>) => {
    const selectedCondtionIndex = answers.conditionals?.findIndex((ans) => _.isEqual(ans.conditions, selectedConditions));
    const value = selectedAnswer?.answerDoc?.quickReplies ? selectedAnswer.answerDoc.quickReplies : undefined;
    const handleChange = (quickReplyValue: QuickReply[]) => {
      if (selectedCondtionIndex !== undefined && answers.conditionals) {
        selectedAnswer.answerDoc.quickReplies = quickReplyValue;
        answers.conditionals[selectedCondtionIndex] = selectedAnswer;
        onChangeAnswers(answers);
      }
    };
    return (
      <VStack width="50%">
        <BodyTitle>Conditional Answers</BodyTitle>
        <HStack>
          <MultilangAnswerFeilds
            answerDoc={selectedAnswer.answerDoc}
            onChangeAnswerDoc={(ansDoc) => {
              const selectedCondtionIndex = answers.conditionals?.findIndex((ans) => _.isEqual(ans.conditions, selectedConditions));

              if (selectedCondtionIndex !== undefined && answers.conditionals) {
                selectedAnswer.answerDoc = ansDoc;
                answers.conditionals[selectedCondtionIndex] = selectedAnswer;
                onChangeAnswers(answers);
              }
            }}
          />
        </HStack>
        <HStack width="100%">
          <AnsQRBTextField quickReplyValue={value} handleQRChange={handleChange} />
        </HStack>
      </VStack>
    );
  };
  return (
    <VStack width="100%">
      {renderAnswerSelectionButtons()}
      <HStack alignItems={"flex-start"}>
        {renderDefaultAnsConfig()}
        {(selectedAnswer && renderSelectedAnsConfig(selectedAnswer)) || <></>}
      </HStack>
    </VStack>
  );
}
