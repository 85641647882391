import { ConditionOperator, FieldType, Filterable } from "Components/LabIZO/Tablizo";
import { Accessor } from "static";
export const Filterables: Filterable[] = [
  {
    field: "alias",
    label: "FAQ ID",
    type: FieldType.text,
  },
  {
    field: `intent.EN.description`,
    label: "Button Caption",
    type: FieldType.custom,
    CustomFilterOperator: {
      contains: ConditionOperator.contains,
    },
    CustomCondition: (condition) => {
      return {
        $custom: {
          field: condition.field,
          operator: "contains",
          value: condition.value,
        },
      };
    },
    CustomFilter: (baseData: any, value: string) => {
      let filteredData = baseData;
      // let keyOfLang = [`intent.EN.description`, `intent.TC.description`, `intent.SC.description`];
      let keyOfLang = [`intent.EN.description`, `intent.TC.description`];

      filteredData = filteredData.filter((_d: any) => {
        for (var key of keyOfLang) {
          const dataValue = Accessor.Get(_d, key);
          if (dataValue && typeof dataValue === "string" && new RegExp(value.toLowerCase()).test(dataValue.toLowerCase())) {
            return true;
          }
        }
      });
      return filteredData;
    },
  },
  {
    field: `intent.EN.examples.0.text`,
    label: "Question",
    type: FieldType.custom,
    CustomFilterOperator: {
      contains: ConditionOperator.contains,
    },
    CustomCondition: (condition) => {
      return {
        $custom: {
          field: condition.field,
          operator: "contains",
          value: condition.value,
        },
      };
    },
    CustomFilter: (baseData: any, value: string) => {
      let filteredData = baseData;
      // let keyOfLang = [`intent.EN.examples.0.text`, `intent.TC.examples.0.text`, `intent.SC.examples.0.text`];
      let keyOfLang = [`intent.EN.examples.0.text`, `intent.TC.examples.0.text`];

      filteredData = filteredData.filter((_d: any) => {
        for (var key of keyOfLang) {
          const dataValue = Accessor.Get(_d, key);
          if (dataValue && typeof dataValue === "string" && new RegExp(value.toLowerCase()).test(dataValue.toLowerCase())) {
            return true;
          }
        }
      });
      return filteredData;
    },
  },
  {
    field: `answer.text.EN.0`,
    label: "Answer",
    type: FieldType.custom,
    CustomFilterOperator: {
      contains: ConditionOperator.contains,
    },
    CustomCondition: (condition) => {
      return {
        $custom: {
          field: condition.field,
          operator: "contains",
          value: condition.value,
        },
      };
    },
    CustomFilter: (baseData: any, value: string) => {
      let filteredData = baseData;
      // let keyOfLang = [`answer.text.EN.0`, `answer.text.TC.0`, `answer.text.SC.0`];
      let keyOfLang = [`answer.text.EN.0`, `answer.text.TC.0`];

      filteredData = filteredData.filter((_d: any) => {
        for (var key of keyOfLang) {
          const dataValue = Accessor.Get(_d, key);
          if (dataValue && typeof dataValue === "string" && new RegExp(value.toLowerCase()).test(dataValue.toLowerCase())) {
            return true;
          }
        }
      });
      return filteredData;
    },
  },
  {
    field: "answer.__followUp",
    label: "Follow Up Msg",
    type: FieldType.boolean,
  },
];
