import { FLEFormContext } from "Pages/FLE/hooks/FLEContexts";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { ControlledTextField } from "Components/HookForm/ControlledTextField";
import { HStack } from "Components/Layouts";
import { TFLEContext } from "interfaces/db.interfaces/fle.interface";
export const QnFallback = ({ lang, name }: { lang: string; onChange: (context: TFLEContext, index: number) => void; index: number; name: `contexts.${number}` }) => {
  const { control } = useFormContext();
  const formCtxt = useContext(FLEFormContext);

  return (
    <HStack width={"100%"} gap={2}>
      <ControlledTextField
        //
        name={`${name}.question.${lang}`}
        control={control}
        rules={{ required: "Question is required" }}
        label="Question"
        fullWidth
        multiline
        disabled={formCtxt?.mode == "Info"}
      />

      <ControlledTextField
        //
        name={`${name}.fallback.${lang}`}
        control={control}
        label="Fallback"
        fullWidth
        multiline
        disabled={formCtxt?.mode == "Info"}
      />
    </HStack>
  );
};
