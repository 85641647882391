import { Box, Typography } from "@mui/material";
import { TSystemContextType } from "interfaces/db.interfaces/fle.interface";

export const ContextTypeTypography = ({ children }: { children: "system" | "entity" | TSystemContextType }) => {
  let bgColor = "prai";

  switch (children) {
    case "system":
      bgColor = "primary.main";
      break;
    case "entity":
      bgColor = "secondary.main";
      break;
    case TSystemContextType.date:
      bgColor = "info.main";
      break;
    case TSystemContextType.boolean:
      bgColor = "success.main";
      break;
    case TSystemContextType.numeric:
      bgColor = "warning.main";
      break;
    case TSystemContextType.string:
      bgColor = "error.main";
      break;
    case TSystemContextType.regex:
      bgColor = "purple";
      break;
    default:
      break;
  }

  return (
    <Box sx={{ backgroundColor: bgColor, color: "white", borderRadius: 1, mx: 1 }}>
      <Typography sx={{ textAlign: "center" }}>{children}</Typography>
    </Box>
  );
};
