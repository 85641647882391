import { Button, ButtonGroup, Stack } from "@mui/material";
import { useState } from "react";
import { DATA_LINK, backendConnector } from "connectors";
import { West, East } from "@mui/icons-material";
import { store } from "static";
import { ZChat } from "interfaces";
enum Comparison {
  LeftCorrect = "leftCorrect",
  RightBetter = "rightCorrect",
  BothGood = "bothCorrect",
  BothBad = "bothNotCorrect",
}

interface IEvalutation {
  payload: {
    llm1: {
      prevMsg?: ZChat[];
      sessionId?: string;
      messageId?: string;
    };
    llm2: {
      prevMsg?: ZChat[];
      sessionId?: string;
      messageId?: string;
    };
    input: string;
  };
  model1?: string;
  model2?: string;
  panelStatus: boolean;
  setPanelStatus: (status: boolean) => void;
}

export const EvaluationPanel = ({ payload, panelStatus, setPanelStatus, model1, model2 }: IEvalutation) => {
  const handleComparisonClick = async (comparison: Comparison) => {
    if (!model1 || !model2) return store.Alert("Please select model", "error");
    if (model1 == model2) return store.Alert("Please select different model", "error");
    const evaluatePayload = {
      comparison: comparison,
      model1: model1,
      model2: model2,
      ...payload,
    };
    const res = await backendConnector.post(DATA_LINK.Evaluate, evaluatePayload);
    if (res?.Success) {
      store.Alert(`Evaulate ${comparison}  Successfully`, "success");
      setPanelStatus(true);
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      <ButtonGroup disabled={panelStatus} variant="outlined" aria-label="outlined button group">
        <Button onClick={() => handleComparisonClick(Comparison.LeftCorrect)}>
          <West sx={{ marginRight: "4px" }} /> Left is Better
        </Button>
        <Button onClick={() => handleComparisonClick(Comparison.RightBetter)}>
          Right is Better <East sx={{ marginLeft: "4px" }} />
        </Button>
        <Button onClick={() => handleComparisonClick(Comparison.BothGood)}>Both are Good</Button>
        <Button onClick={() => handleComparisonClick(Comparison.BothBad)}>Both are Bad</Button>
      </ButtonGroup>
    </Stack>
  );
};
