import { FormizoSchema } from "Components/LabIZO/Formizo";
import { IFGTab, IFPageSchema } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import { T2EResponse } from "interfaces";
import { GPTDetails } from "../DetailsPanel/GPTDetails/GPTDetails";

function generateModelDetail(objectName: string, modelName: string) {
  return {
    label: `${modelName} Details`,
    page: [
      {
        label: "Conversation ID",
        name: `${objectName}.msg_id`,
        format: "text",
        readOnly: true,
      },
      {
        label: "Session ID",
        name: `${objectName}.sessionID`,
        format: "text",
      },
      {
        label: "User ID",
        name: `${objectName}.user_id`,
        format: "text",
      },
      {
        label: "Chatbot Version",
        name: `${objectName}.chatbotVersion`,
        format: "text",
      },
      {
        label: "Receive Time",
        name: `${objectName}.recTime`,
        format: "text",
      },
      {
        label: "Proccess Time (s)",
        name: `${objectName}.processTime`,
        format: "text",
      },
      {
        label: "Channel",
        name: `${objectName}.channel`,
        format: "text",
      },
      {
        label: "Message Content",
        name: `${objectName}.input.content`,
        format: "textarea",
        rows: 1,
        maxRows: 3,
      },
      {
        label: "Message Type",
        name: `${objectName}.input.type`,
        format: "text",
      },
      {
        label: "Language",
        name: `${objectName}.language`,
        format: "text",
      },
      {
        label: "Pipeline",
        name: `${objectName}.pipeline`,
        format: "text",
      },
      {
        label: "API Token Limit",
        name: `${objectName}.apiTokenLimit`,
        format: "number",
        optional: true,
      },
    ],
  };
}

const model1Detail = generateModelDetail("firstModel", "Model 1");
const model2Detail = generateModelDetail("secondModel", "Model 2");
const model1GptDetails: IFGTab = {
  label: "Model 1 GPT",
  page: [],
  componentOverride: (
    data: {
      firstModel: T2EResponse;
      secondModel: T2EResponse;
    },
    _addOns: any
  ) => <GPTDetails extractedContext={data?.firstModel?.extracted_context} />,
};

const model2GptDetails: IFGTab = {
  label: "Model 2 GPT",
  page: [],
  componentOverride: (
    data: {
      firstModel: T2EResponse;
      secondModel: T2EResponse;
    },
    _addOns: any
  ) => <GPTDetails extractedContext={data?.secondModel?.extracted_context} />,
};

export const ArenaDetailsPanelSchema: FormizoSchema[] = [
  {
    tabs: [model1Detail, model1GptDetails, model2Detail, model2GptDetails],
    fitemHeight: "100%",
  },
];
