import { Box, Typography } from "@mui/material";
import { Component } from "react";
import moment from "moment";
import datalink from "./datalink";
import schema from "./schema";
import { HStack, VStack } from "Components";
import { Accessor, Authority, ColorX } from "static";
import Formizo from "Components/LabIZO/Formizo";
import { Spacer } from "Components/LabIZO/Stackizo";
import Datumizo from "Components/LabIZO/Datumizo";

type APIUsageProps = typeof APIUsage.defaultProps;

interface APIUsageStates {
  title: string;
  period: { DateRange: [string, string] };
  serverSidePagination: boolean;
  base: any;
  LangFilter: string;
  FormLang: string;
}

class APIUsage extends Component<APIUsageProps, APIUsageStates> {
  static defaultProps = {};
  MountDatumizo: Record<string, (...args: any[]) => any> = {};

  constructor(props: APIUsageProps) {
    super(props);
    this.state = {
      title: "APIUsage Report",
      period: { DateRange: [moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"), moment().endOf("month").format("YYYY-MM-DD HH:mm:ss")] },
      serverSidePagination: true,
      LangFilter: "All",
      FormLang: "All",
      base: {
        title: "APIUsage",
        exportDoc: "APIUsage",
        schema,
        reqAuth: "APIUsage",

        tablizo: {
          showSelector: true,
          columnsToolbar: false,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          List: datalink.Request.List,
          schema: schema.Table,
        },

        operations: {
          Export: {
            url: datalink.Request.Export,
            schema: schema.Export,
          },

          Info: {
            title: "AUDIT LOG DETAILS",
            url: datalink.Request.Info,
            success: "Audit Log Successfully",
            fail: "Audit Log Failed: ",
            schema: schema.Info,
            readOnly: true,
          },
        },

        buttons: {
          //inline: [{ icon: "info", func: "Info", reqFunc: "Info", caption: "Details" }],
          right: [{ icon: "export", func: "Export", caption: (n: number) => `Export (${n === 0 ? "All" : n})`, reqFunc: "Export" }],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("APIUsage");
    this._setAllStates();
  }

  componentDidUpdate(prevProps: APIUsageProps, prevState: APIUsageStates) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(APIUsage.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback?: () => void) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks: Record<string, (...args: any[]) => any>) => {
    this.MountDatumizo = callbacks;
  };

  renderDatePicker = () => {
    const { period } = this.state;
    return (
      <Formizo
        width={350}
        height="100%"
        schema={[
          {
            label: "",
            name: "DateRange",
            format: "daterange",
            dateType: "datetime",
            dateFormat: "YYYY-MM-DD HH:mm:ss",
            noLabelGrid: true,
            defaultValue: period.DateRange,
          },
        ]}
        buttons={[]}
        onChange={(value, _n, _v, fetchData) => {
          this.setState((state) => {
            const from = moment(value.DateRange[0]).format("YYYYMMDD");
            const to = moment(value.DateRange[1]).format("YYYYMMDD");
            return {
              period: value,
              base: {
                ...state.base,
                exportDoc: `APIUsage_${from}_${to}`,
              },
            };
          });
          if (fetchData) {
            this.MountDatumizo.Reload();
          }
        }}
      />
    );
  };

  renderLangFilter = () => {
    return (
      <Formizo
        width={350}
        height="100%"
        schema={[
          {
            label: "Language",
            name: "LangFilter",
            format: "select",
            selectStyle: "dropdown",
            options: [
              { value: "All", label: "All" },
              { value: "TC", label: "TC" },
              //{ value: "SC", label: "SC" },
              { value: "EN", label: "EN" },
            ],
            defaultValue: this.state.LangFilter,
          },
        ]}
        buttons={[]}
        onChange={(formLang, _n, _v, fetchData) => {
          if (formLang.LangFilter !== this.state.LangFilter) {
            this.setState({
              LangFilter: formLang.LangFilter,
            });
            if (fetchData) {
              this.MountDatumizo.Reload();
            }
          }
        }}
      />
    );
  };

  renderTable = () => {
    let { base, serverSidePagination, period, LangFilter } = this.state;
    return (
      <Datumizo
        base={base}
        addOns={{
          ...period,
          LangFilter,
        }}
        serverSidePagination={serverSidePagination}
        onMounted={this.onMountDatumizo}
        languageSelector={false}
      />
    );
  };

  render = () => {
    const { title } = this.state;
    return (
      <VStack height="100%" width="100%">
        <Box padding={1} width="100%">
          <HStack
            justifyContent="space-between"
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Typography
              style={{
                alignItems: "left",
                display: "flex",
                fontSize: 25,
                color: ColorX.GetColorCSS("Primary"),
              }}
            >
              {title}
            </Typography>
            {this.renderDatePicker()}
            <Spacer />
          </HStack>
          <Typography
            style={{
              alignItems: "left",
              display: "flex",
              fontSize: 5,
            }}
          ></Typography>
        </Box>
        {this.renderTable()}
      </VStack>
    );
  };
}

export default APIUsage;
