import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { store } from "static";
import { AvailLANG } from "interfaces";

export const HeaderComponent = ({ chatbotName = "", modelName = "" }) => {
  const [time, setTime] = useState<Date | null>(new Date());
  const defaultLang = (store.sysInfo.Language.default ?? "EN") as AvailLANG;

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  function formatDate(date: Date) {
    if (defaultLang == "SC" || defaultLang == "TC") {
      const month = date.getMonth() + 1; // getMonth is zero-based
      const day = date.getDate();
      const weekday = date.toLocaleDateString("zh-CN", { weekday: "long" });
      return `${month}月${day}日(${weekday})`;
    } else {
      const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
      const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

      const day = date?.getDate();
      const month = months[date?.getMonth()];
      const weekday = days[date?.getDay()];

      return `${day} ${month} (${weekday})`;
    }
  }

  return (
    <Box className="headline" sx={{ width: "100%", display: "flex" }}>
      <Box className="left">
        <div className="syva_logo"></div>
        {modelName ? <Typography className="modelName">{`Model : ${modelName}`}</Typography> : <></>}
      </Box>

      <Box className="right">
        <div className="text">{chatbotName}</div>
        {time && <div className="time">{`${formatDate(time)} ${time?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false })}`}</div>}
      </Box>
    </Box>
  );
};
