import { DBAnswerSpec } from "interfaces";
import { useFormContext, Controller } from "react-hook-form";
import { RadioButtonsGroup } from "Components";
import { IFLEDoc, TFLEContext, TSystemContextType } from "interfaces/db.interfaces/fle.interface";
import { ContextOpts } from "../ContextOpts";

export function ContextTypeSelector({ index, ...props }: { index: number; disabled?: boolean }) {
  const { watch, setValue } = useFormContext<IFLEDoc<DBAnswerSpec>>();

  const context = watch(`contexts.${index}`) as TFLEContext;

  const handleChange = (value: any) => {
    context.type = value;
    setValue(`contexts.${index}.type`, value);

    if (context.type === "system") {
      setValue(`contexts.${index}.setting`, {
        type: TSystemContextType.date,
      });
    }

    if (context.type == "entity") {
      setValue(`contexts.${index}.setting`, {
        type: "entity",
        values: [],
      });
    }
  };
  return <RadioButtonsGroup label="Context Type" value={context.type} options={ContextOpts} onChange={handleChange} disabled={props.disabled} />;
}
