import { Typography, Tooltip } from "@mui/material";
import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

interface IModifiedText {
  text: string;
  modifier: {
    value: string;
    display: number;
    filename: string;
    error: boolean;
  }[];
}

const ModifiedText = ({ text, modifier }: IModifiedText) => {
  let displayComponents: any[] = [];

  const pattern = /\[(.*?)\]/g;

  let citationIndex = 0;
  const [isTitleHovered, setIsTitleHovered] = useState(Array.from({ length: modifier.length }, () => true));

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "10px",
            maxWidth: 400,
          },
        },
      },
    },
  });

  const handleTitleMouseOver = (index: number) => {
    setIsTitleHovered((prevIsTitleHovered) => {
      const updatedIsTitleHovered = [...prevIsTitleHovered];
      updatedIsTitleHovered[index] = false;
      return updatedIsTitleHovered;
    });
  };

  const handleTitleMouseLeave = (index: number) => {
    setIsTitleHovered((prevIsTitleHovered) => {
      const updatedIsTitleHovered = [...prevIsTitleHovered];
      updatedIsTitleHovered[index] = true;
      return updatedIsTitleHovered;
    });
  };
  const renderTooltip = (index: number, filename: string, display: number, value: string) => {
    const hoverInfo = (
      <Typography>
        <span style={{ color: "#005F73", fontSize: "0.9em", display: "block" }}>{filename} :</span>
        <div style={{ maxHeight: "180px", overflowY: "auto", overflowX: "hidden" }}>
          <Typography
            style={{ color: "#847C7C", textAlign: "left", fontSize: "0.7em", whiteSpace: isTitleHovered[index] ? "nowrap" : "pre-line" }}
            onMouseOver={() => handleTitleMouseOver(index)}
            onMouseLeave={() => handleTitleMouseLeave(index)}
          >
            {value}
          </Typography>
        </div>
      </Typography>
    );
    const newTooltip = (
      <ThemeProvider key={index} theme={theme}>
        <Tooltip title={<Typography>{hoverInfo}</Typography>} key={display}>
          <sup>{display}</sup>
        </Tooltip>
      </ThemeProvider>
    );
    return newTooltip;
  };

  try {
    let prevHoverTitle: string | null = null;
    let prevDisplay: number | null = null;
    text.split(pattern).forEach((t, i) => {
      if (i % 2 === 0) {
        if (t === "") return;
        prevHoverTitle = null;
        prevDisplay = null;
        displayComponents.push(t);
      } else {
        const count = t.split(",").length;
        for (let j = citationIndex; j < citationIndex + count; j++) {
          const element = modifier[j];
          const { value, display, filename, error } = element;
          if (error) {
            citationIndex += 1;
            return;
          }
          const prevElement = displayComponents[displayComponents.length - 1];
          if (typeof prevElement !== "string" && prevDisplay && prevDisplay === display) {
            const newHover = `${prevHoverTitle}\n\n${value}`;
            displayComponents[displayComponents.length - 1] = renderTooltip(j, filename, display, newHover);
            prevHoverTitle = newHover;
          } else {
            const newTooltip = renderTooltip(j, filename, display, value);
            displayComponents.push(newTooltip);
            prevDisplay = display;
            prevHoverTitle = value;
          }
        }
        citationIndex += count;
      }
    });
  } catch (error) {
    displayComponents.push(text);
  }

  return (
    <div>
      <div className={"msg-text"} style={{ overflowWrap: "break-word", whiteSpace: "pre-line" }}>
        {" "}
        {displayComponents}
      </div>
    </div>
  );
};

export default ModifiedText;
