import { DatumizoBase } from "Components/LabIZO/Datumizo/DatumizoProps.interface";
import { DATA_LINK } from "connectors";
import schema from "./constants/schema/schema";
import WebsiteManagerForm from "./WebsiteManagerForm";
import { backendConnector } from "connectors/backend-connector";
import { ErrorX, store, Authority } from "static";
import { IWebsiteManagerPayload } from "../interfaces/website-manager-api.interface";

export interface IDefaultProps {
  title?: string;
  base?: DatumizoBase;
}
export const defaultProps: IDefaultProps = {
  title: "Website Manager",
  base: {
    title: "Website Manager",
    exportDoc: "Websites",
    schema: schema,
    reqAuth: "GPTQA.WebsiteManager",

    tablizo: {
      showSelector: true,
      columnsToolbar: true,
      filterToolbar: true,
      densityToolbar: true,
      exportToolbar: false,
    },

    Connect: {
      // DBInfo: DATA_LINK.WebsiteDBInfo,
      List: DATA_LINK.WebsiteList,
      schema: schema.Table,
    },

    operations: {
      Add: {
        title: "Create Website",
        url: DATA_LINK.WebsiteAdd,
        success: "Website Added Successfully",
        fail: "Website Add Failed: ",
        schema: schema.Add,
        buttons: ["Clear", "Submit"],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <WebsiteManagerForm {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Add" }} />,
      },
      Delete: {
        title: "Delete this Website?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.WebsiteDelete,
        success: "Website Deleted Successfully.",
        fail: "Website Delete Failed: ",
        // onSubmit: "Delete",
        onClick: async (id: string, row: IWebsiteManagerPayload, onQuit: () => void, onQuitRefresh: (_id: string) => void, renderFormizo: any) => {
          const accessible = Authority.IsAccessibleQ(`GPTQA.WebsiteManager`, 999, "WebsiteStatus");
          try {
            let _newRow = {
              _id: row._id,
              notice: accessible ? false : true,
            };
            const res = await backendConnector.post(DATA_LINK.WebsiteDelete, _newRow);
            if (!res.Success) {
              const errorMsg = "Website Delete Failed:  ";
              store.Alert(errorMsg + res.payload?.message, "error");
              return ErrorX.Handle(res.payload);
            }
            const successMsg = "Website Deleted Successfully";
            store.Alert(successMsg, "success");
          } catch (err: any) {
            ErrorX.Handle(err);
          }
        },
      },
      Edit: {
        title: "Edit Website",
        url: DATA_LINK.WebsiteEdit,
        success: "Website Edited Successfully",
        fail: "Website Edit Failed: ",
        schema: schema.Edit,
        buttons: ["Revert", "Submit"],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <WebsiteManagerForm {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Edit" }} />,
      },
      Info: {
        title: "Website DETAILS",
        schema: schema.Info,
        readOnly: true,
        buttons: [],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <WebsiteManagerForm {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Info" }} />,
      },

      DeleteBulk: {
        title: (n: number) => "Delete these " + n + " Websites?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.WebsiteDeleteBulk,
        success: "Websites Deleted Successfully.",
        fail: "Websites Delete Failed: ",
        // onSubmit: "DeleteBulk",
        onClick: async (selected: string[]) => {
          const accessible = Authority.IsAccessibleQ(`GPTQA.WebsiteManager`, 999, "WebsiteStatus");
          try {
            let _newRows: { selected: string[]; notice?: boolean } = {
              selected: selected,
              notice: accessible ? false : true,
            };

            const res = await backendConnector.post(DATA_LINK.WebsiteDeleteBulk, _newRows);
            if (!res.Success) {
              const errorMsg = "Website Delete Failed:  ";
              store.Alert(errorMsg + res.payload?.message, "error");
              return ErrorX.Handle(res.payload);
            }
            const successMsg = "Website Deleted Successfully";
            store.Alert(successMsg, "success");
          } catch (err: any) {
            ErrorX.Handle(err);
          }
        },
      },
      Enable: {
        title: (n: number) => "Enable these " + n + " Websites?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.WebsiteEnable,
        success: "Enable All Websites Successfully.",
        fail: "Enable All Websites Failed: ",
        onSubmit: "Enable",
      },
      Disable: {
        title: (n: number) => "Disable these " + n + " Websites?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.WebsiteEnable,
        success: "Disable All Websites Successfully.",
        fail: "Disable All Websites Failed: ",
        onSubmit: "Disable",
      },
      Export: {
        url: DATA_LINK.WebsiteExport,
        // schema: schema.Export,
        exportFileType: "xlsx",
      },
      Import: {
        title: "Website Import",
        content:
          "<p>* Website URL accepts:<br>Implicit (*) pages : www.example.com/page.html/ or www.example.com/docs/<br>Explicit site: www.mysite.com/* or www.example.com/docs/<br><br>(File size cannot exceed 10MB, only accept .xlsx and .xls)</p>",
        url: DATA_LINK.WebsiteImport,
        success: "Website Imported Successfully.",
        fail: "Website Import Failed: ",
        accept: [".xlsx", ".xls"],
      },
      Sync: {
        success: "Website Sync Successfully",
        fail: "Website Sync Failed: ",
        url: DATA_LINK.WebsiteSync,
        title: "Sync Website from Google Search",
        content: "Caution: This is irrevertable.",
      },
    },

    buttons: {
      inline: [
        { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
        { icon: "info", func: "Info", caption: "Details" },
      ],
      inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" }],
      left: [{ icon: "add", func: "Add", caption: "Add Website", reqFunc: "Add" }],
      right: [
        { icon: "deletebulk", func: "DeleteBulk", caption: (n: number) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" },
        { icon: "syncto", func: "Sync", caption: "Sync", reqFunc: "Sync" },
        { icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
        { icon: "import", func: "Import", caption: "Import", reqFunc: "Import" },
        // { icon: "enable", func: "Enable", caption: (n: number) => "Enable (" + n + ")", reqFunc: "Enable" },
        // { icon: "disable", func: "Disable", caption: (n: number) => "Disable (" + n + ")", reqFunc: "Enable" },
      ],
    },
  },
};
