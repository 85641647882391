import {
  AccountTree,
  AttachFile,
  DocumentScanner,
  Dvr,
  Extension,
  Forum,
  Groups,
  Leaderboard,
  ManageAccounts,
  Message,
  ModelTraining,
  NotInterested,
  Settings,
  Sms,
  SpaceDashboard,
  Try,
  Addchart,
} from "@mui/icons-material";
import { IMenuItemSchema } from "../Menu.interface";
import WebIcon from "@mui/icons-material/Web";

export const schema: IMenuItemSchema[][] = [
  [
    {
      caption: "Dashboard",
      id: "menu-starter-dashboard",
      link: "/StarterDashboard",
      icon: <SpaceDashboard />,
      reqAuth: "StarterDashboard",
    },
    {
      caption: "File Handler",
      id: "menu-file-handler",
      link: "/FileHandler",
      icon: <AttachFile />,
      reqAuth: "FileHandler",
    },
    {
      caption: "Promotion",
      id: "menu-promotion",
      link: "/Promotion",
      icon: <Try />,
      reqAuth: "Promotion",
    },
  ],
  [
    {
      caption: "FAQs",
      id: "menu-faq",
      link: "/FAQ",
      icon: "fas fa-comments fa-lg",
      reqAuth: "FAQ",
      subMenu: [
        {
          caption: "Counter Examples",
          id: "menu-counter-examples",
          link: "/FAQ/CounterExample",
          icon: <NotInterested />,
          reqAuth: "Workspace.CounterExample",
        },
      ],
    },
    {
      caption: "Flow Editor",
      id: "menu-fle",
      link: "/FLE",
      icon: <AccountTree />,
      reqAuth: "FLE",
    },
    {
      caption: "Entities",
      id: "menu-entities",
      link: "/Workspace/Entity",
      icon: <Extension />,
      reqAuth: "Workspace.Entity",
    },
    {
      caption: "Answers",
      id: "menu-answers",
      link: "/Answer",
      icon: <Message />,
      reqAuth: "Answer",
    },
  ],
  [
    {
      caption: "Documents",
      id: "menu-document-manager",
      link: "/gpt-qa/document-manager",
      icon: <DocumentScanner />,
      reqAuth: "GPTQA.DocumentManager",
    },
    {
      caption: "Groups",
      id: "menu-knowlege-group-management",
      link: "/gpt-qa/knowledge-grp-management",
      icon: <Groups />,
      reqAuth: "GPTQA.KnowledgeGroupManagement",
    },
    {
      caption: "Website Manager",
      id: "menu-website-manager",
      link: "/gpt-qa/website-manager",
      icon: <WebIcon />,
      reqAuth: "GPTQA.WebsiteManager",
    },
  ],
  [
    {
      caption: "T2VA",
      id: "menu-talk-to-virtual-assistant-beta",
      link: "/Talk2VA",
      icon: "syva_logo",
      reqAuth: "Talk2VA",
      subMenu: [
        {
          caption: "FAQTrainer",
          id: "menu-t2vatrainer",
          link: "/T2VATrainer",
          icon: <ModelTraining />,
          reqAuth: "FAQTrainer",
        },
      ],
    },
    {
      caption: "API Usage Report",
      id: "menu-APIUsage",
      link: "/APIUsage",
      icon: <Addchart />,
      reqAuth: "APIUsage",
    },
    {
      caption: "Conversation",
      id: "menu-conversation",
      link: "/Convlog",
      icon: <Forum />,
      reqAuth: "Convlog",
    },
    {
      caption: "Document Conversation",
      id: "menu-conversation-log",
      link: "/gpt-qa/conversation-log",
      icon: <Sms />,
      reqAuth: "GPTQA.ConversationLog",
    },
    {
      caption: "Training",
      id: "menu-training",
      link: "/Training",
      icon: "fas fa-robot fa-lg",
      reqAuth: "Training",
    },
  ],
  [
    {
      caption: "Request Logs",
      id: "request-log",
      link: "/RequestLog",
      icon: <Dvr />,
      reqAuth: "RequestLog",
    },
    {
      caption: "User Control",
      id: "menu-user",
      link: "/UserControl",
      icon: <ManageAccounts />,
      reqAuth: "UserControl",
    },
    {
      caption: "System",
      id: "menu-system",
      link: "/System",
      icon: <Settings />,
      reqAuth: "System",
    },
    {
      caption: "Arena Log",
      id: "menu-arena-log",
      link: "/arena/log",
      icon: <Leaderboard />,
      reqAuth: "ArenaLog",
    },
  ],
];

export default schema;
