import React, { useEffect, useState } from "react";

import { IAuthority, SYS_AUTH } from "interfaces";
import { useFormContext } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import { IRole } from "interfaces/db.interfaces/role.interface";

interface IProps {
  keyName: string;
  mode: "Add" | "Edit" | "Info";
  dropDownList: string[];
}

export const AuthorityDropDown = ({ keyName, mode, dropDownList }: IProps) => {
  const { setValue, getValues, setError, clearErrors } = useFormContext<IRole>();
  const modifiedDropDownList: string[] = dropDownList.length > 0 ? ["*", "Access", ...dropDownList] : ["*"];
  const authorityList = getValues("authority");
  //Set default as GPTQA-Dashboard -> * Login page
  if (mode === "Add" && authorityList === undefined) {
    setValue("authority", {
      Dashboard: [SYS_AUTH.All],
    });
  }
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const getNestedPropertyValue = (obj: any, keys: string[]): any => {
    if (!obj) return undefined;
    const [firstKey, ...remainingKeys] = keys;
    const value = obj[firstKey];
    if (remainingKeys.length === 0) return value;
    return getNestedPropertyValue(value, remainingKeys);
  };
  const keys = keyName?.split(".");
  const nestedValue = getNestedPropertyValue(authorityList, keys);

  const updateValue = (data: any | undefined, keyList: string[], newValue: string[]) => {
    let currentObj = data || {};
    for (let i = 0; i < keyList.length - 1; i++) {
      const key = keyList[i];
      if (!(key in currentObj)) {
        currentObj[key] = {};
      }
      currentObj = currentObj[key];
    }
    currentObj[keyList[keyList.length - 1]] = newValue;
  };

  const validForm = (value: string[], nestedKey: string) => {
    const key = `authority.${nestedKey}`;
    if (value.includes("*") && value.length > 1) {
      setError(key as keyof IRole, {
        type: "manual",
        message: "Cannot select other options when All is selected",
      });
    } else {
      clearErrors(key as keyof IRole);
    }
  };

  const removeEmptyValue = (authority: IAuthority): IAuthority => {
    Object.keys(authority).forEach((key) => {
      const valueKey = key as keyof IAuthority;
      const value = authority[valueKey];

      if (Array.isArray(value) && value.length === 0) {
        delete authority[valueKey];
      } else if (value && typeof value === "object") {
        const nestedObject = removeEmptyValue(value as unknown as IAuthority);

        if (Object.keys(nestedObject).length === 0) {
          delete authority[valueKey];
        }
      }
    });

    return authority;
  };

  const handleDropdownChange = (event: any, value: any[]) => {
    const authorityList = getValues("authority");
    validForm(value, keyName);
    setSelectedValues(value);
    let updatedAuthorityList: IAuthority = { ...authorityList };
    updateValue(updatedAuthorityList, keys, value);
    updatedAuthorityList = removeEmptyValue(updatedAuthorityList);
    setValue("authority", updatedAuthorityList);
  };
  return (
    <Autocomplete
      multiple
      disabled={mode === "Info"}
      sx={{ width: 300 }}
      options={modifiedDropDownList}
      defaultValue={nestedValue}
      onChange={handleDropdownChange}
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Permission"
          error={(selectedValues.includes("*") || selectedValues.includes("Access")) && selectedValues.length > 1}
          helperText={
            selectedValues.length > 1 &&
            (selectedValues.includes("*") ? "Cannot select other options when All is selected" : selectedValues.includes("Access") ? "Cannot select other options when Access is selected" : null)
          }
        />
      )}
    />
  );
};
