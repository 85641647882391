import { Denied } from "Components/_PageComponents/Fallback";
import {
  Answer,
  AWSLiveChat,
  Convlog,
  Dashboard,
  FAQ,
  FLE,
  Home,
  Initialize,
  RequestLog,
  System,
  T2VATrainer,
  Training,
  Workspace,
  UserControl,
  StarterDashboard,
  AuditLog,
  FileHandler,
  Promotion,
} from "Pages";
import { GPTConvlog, GPTQADocumentManager, GPTQADashboard, GPTQAKnowledgeGrpManagement, GPTQAWebsiteManager, ArenaLog, APIUsage } from "Pages";
import { CounterExample, Entity, Intent } from "Pages/Workspace";
import Talk2VA from "Pages/CombinedTalk2VA/Talk2VA";
interface IRoute {
  path: string;
  element: JSX.Element;
  key: string;
}
export const CMS_ROUTES: IRoute[] = [
  { key: "", path: "", element: <Home /> },
  { key: "denied", path: "Denied", element: <Denied /> },
  { key: "dashboard", path: "/Dashboard", element: <Dashboard /> },
  { key: "convlog", path: "Convlog", element: <Convlog /> },
  { key: "requestLog", path: "RequestLog", element: <RequestLog /> },
  { key: "talk2va", path: "Talk2VA", element: <Talk2VA /> },
  { key: "faq", path: "FAQ", element: <FAQ /> },
  { key: "training", path: "Training", element: <Training /> },
  { key: "answer", path: "Answer", element: <Answer /> },
  { key: "workspace", path: "Workspace", element: <Workspace /> },
  { key: "intent", path: "Workspace/Intent", element: <Intent /> },
  { key: "entity", path: "Workspace/Entity", element: <Entity /> },
  { key: "counterExample", path: "FAQ/CounterExample", element: <CounterExample /> },
  { key: "userControl", path: "UserControl", element: <UserControl /> },
  { key: "system", path: "System", element: <System /> },
  { key: "t2vaTrainer", path: "T2VATrainer", element: <T2VATrainer /> },
  { key: "aws", path: "AWSLiveChat", element: <AWSLiveChat /> },
  { key: "fle", path: "FLE", element: <FLE /> },
  { key: "initialize", path: "Initialize", element: <Initialize /> },
  { key: "documentManager", path: "/gpt-qa/document-manager", element: <GPTQADocumentManager /> },
  { key: "knowledgeGroupManagement", path: "/gpt-qa/knowledge-grp-management", element: <GPTQAKnowledgeGrpManagement /> },
  { key: "websiteManager", path: "/gpt-qa/website-manager", element: <GPTQAWebsiteManager /> },
  { key: "conversationLog", path: "/gpt-qa/conversation-log", element: <GPTConvlog /> },
  { key: "APIUsage", path: "/APIUsage", element: <APIUsage /> },
  { key: "gptQaDashboard", path: "/gpt-qa/gpt-qa-dashboard", element: <GPTQADashboard /> },
  { key: "starterDashboard", path: "/StarterDashboard", element: <StarterDashboard /> },
  { key: "arenaLog", path: "/arena/log", element: <ArenaLog /> },
  { key: "auditLog", path: "AuditLog", element: <AuditLog /> },
  { key: "fileHandler", path: "FileHandler", element: <FileHandler /> },
  { key: "promotion", path: "Promotion", element: <Promotion /> },
];
