import React, { useEffect, useState } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import _ from "lodash";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { HStack } from "Components";
import { ZChat } from "interfaces";
import { store } from "static";
import { AvailLANG } from "interfaces";
import { MiddlewareConnector } from "Pages/CombinedTalk2VA/connectors/middleware.connector";

interface IProps {
  cssp?: string;
  regenerateUrl: string;
  onSetMessages?: (_messages: ZChat[]) => void;
  item?: ZChat;
  messages?: Array<any>;
  connector: MiddlewareConnector;
}

enum waitAMoment {
  EN = "Just a moment...",
  SC = "请稍等...",
  TC = "請稍等...",
}

enum regenerateCaption {
  EN = "Please select from the below options",
  SC = "请从下面选项中选择",
  TC = "請從下面選項中選擇",
}

const ZCRegenerate = ({ connector, cssp, onSetMessages, regenerateUrl, messages, item }: IProps) => {
  const [count, setCount] = useState(0);
  const handleRegenerateAns = async () => {
    if (!onSetMessages || !messages || !item) return;
    const lang: AvailLANG = item?.msg?.lang ?? AvailLANG.EN;
    messages.push({
      msg: {
        text: waitAMoment[lang],
      },
      _id: "Retry_" + item?._id,
      user: { _id: 0 },
      createdAt: new Date(),
      pipeline: "gptQa",
    });
    onSetMessages([...messages]);
    try {
      const res = await connector.getRegeneratedResponse(regenerateUrl, item);
      if (res) {
        setCount(res.retryCount);
        const suggestedQue = res.options.map((item: string) => {
          return {
            payload: item,
            title: item,
          };
        });
        appendQuickReply(regenerateCaption[lang], suggestedQue);
      }
    } catch {
      store.Alert("Regenerate Answer went wrong", "error");
      appendQuickReply("There is some issue with the server. Please try again later.");
    }
  };

  const appendQuickReply = (text: string, quickReply?: { payload: string; title: string }[]) => {
    if (messages && onSetMessages) {
      const newMessage = messages.map((msg) => {
        if (msg._id === "Retry_" + item?._id) {
          return {
            ...msg,
            msg: {
              text: text,
              quickReplies: quickReply && quickReply.length > 0 ? quickReply : undefined,
              showQuickReplies: true,
            },
          };
        }
        return msg;
      });
      onSetMessages([...newMessage]);
    }
  };

  const renderGenerateButton = () => {
    return (
      <IconButton className="icon" onClick={handleRegenerateAns}>
        <RotateRightIcon style={{ fontSize: 16 }} />
      </IconButton>
    );
  };

  return (
    <HStack className={cssp + " msg-regenerate"} sx={{ paddingX: "5px", width: "10%" }}>
      {renderGenerateButton()}
    </HStack>
  );
};

export default ZCRegenerate;
