import { Box, Button, Typography } from "@mui/material";
import { Component } from "react";
import moment from "moment";
import datalink from "./datalink";
import schema from "./schema";
import { HStack, VStack } from "Components";
import { Accessor, Authority, ColorX } from "static";
import Formizo from "Components/LabIZO/Formizo";
import { Spacer } from "Components/LabIZO/Stackizo";
import Datumizo from "Components/LabIZO/Datumizo";
import { LeaderboardView } from "./LeaderBoard";
import "./ArenaLog.scss";
import ArenaLogInfo from "./ArenaLogInfo";

type ArenaLogProps = typeof ArenaLog.defaultProps;

interface ArenaLogStates {
  title: string;
  period: { DateRange: [string, string] };
  serverSidePagination: boolean;
  base: any;
  view: "Table" | "Leaderboard";
}

function ViewSelector(props: { view: "Table" | "Leaderboard"; setView: any }) {
  const { view, setView } = props;
  return (
    <>
      <Button variant={view === "Table" ? "contained" : "outlined"} onClick={() => setView("Table")}>
        Table View
      </Button>
      <Button variant={view === "Leaderboard" ? "contained" : "outlined"} onClick={() => setView("Leaderboard")}>
        Leaderboard
      </Button>
    </>
  );
}

class ArenaLog extends Component<ArenaLogProps, ArenaLogStates> {
  static defaultProps = {};
  MountDatumizo: Record<string, (...args: any[]) => any> = {};

  constructor(props: ArenaLogProps) {
    super(props);
    this.state = {
      view: "Leaderboard",
      title: "Arena LOG",
      period: { DateRange: [moment().subtract(60, "d").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")] },
      serverSidePagination: true,
      base: {
        title: "ArenaLog",
        exportDoc: "ArenaLog",
        schema,
        reqAuth: "ArenaLog",

        tablizo: {
          showSelector: true,
          columnsToolbar: false,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          List: datalink.Request.List,
          schema: schema.Table,
        },

        operations: {
          Export: {
            url: datalink.Request.Export,
            schema: schema.Export,
          },

          Info: {
            title: "ARENA LOG DETAILS",
            url: datalink.Request.Info,
            success: "ARENA Log Successfully",
            fail: "ARENA Log Failed: ",
            schema: schema.Info,
            readOnly: true,
            Custom: (id: string, row: any, onQuit: any, onQuitRefresh: any, renderFormizo: any) => <ArenaLogInfo {...{ id, row, onQuit, onQuitRefresh, renderFormizo }} />,
          },

          Delete: {
            title: "Delete Arena Log",
            url: datalink.Request.Delete,
            success: "ARENA Log Successfully",
            fail: "ARENA Log Failed: ",
            schema: schema.Info,
            readOnly: true,
          },
        },

        buttons: {
          inline: [{ icon: "info", func: "Info", reqFunc: "Info", caption: "Details" }],
          // inlineOpposite: [{ icon: "delete", func: "Delete", reqFunc: "Delete", caption: "Delete" }],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("ArenaLog");
    this._setAllStates();
  }

  componentDidUpdate(prevProps: ArenaLogProps, prevState: ArenaLogStates) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(ArenaLog.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback?: () => void) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks: Record<string, (...args: any[]) => any>) => {
    this.MountDatumizo = callbacks;
  };

  renderDatePicker = () => {
    const { period } = this.state;
    return (
      <Formizo
        width={350}
        height="100%"
        schema={[
          {
            label: "",
            name: "DateRange",
            format: "daterange",
            dateType: "datetime",
            dateFormat: "YYYY-MM-DD HH:mm:ss",
            noLabelGrid: true,
            defaultValue: period.DateRange,
          },
        ]}
        buttons={[]}
        onChange={(value, _n, _v, fetchData) => {
          this.setState((state) => {
            const from = moment(value.DateRange[0]).format("YYYYMMDD");
            const to = moment(value.DateRange[1]).format("YYYYMMDD");
            return {
              period: value,
              base: {
                ...state.base,
                exportDoc: `ArenaLog_${from}_${to}`,
              },
            };
          });
          if (fetchData) {
            this.MountDatumizo.Reload();
          }
        }}
      />
    );
  };

  renderTable = () => {
    let { base, serverSidePagination, period, view } = this.state;

    const setView = (view: "Table" | "Leaderboard") => {
      this.setState({ view });
    };
    return (
      <Datumizo
        base={base}
        addOns={{
          ...period,
        }}
        serverSidePagination={serverSidePagination}
        onMounted={this.onMountDatumizo}
        languageSelector={false}
        injectRightButtons={<ViewSelector view={view} setView={setView} />}
        noDefaultTable={view == "Leaderboard"}
      >
        {view == "Leaderboard" ? (
          <div className="LeaderboardViewContainer">
            <LeaderboardView period={period} />
          </div>
        ) : (
          <></>
        )}
      </Datumizo>
    );
  };

  render = () => {
    const { title } = this.state;
    return (
      <VStack height="100%" width="100%">
        <Box padding={1} width="100%">
          <HStack
            justifyContent="space-between"
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Typography
              style={{
                alignItems: "left",
                display: "flex",
                fontSize: 25,
                color: ColorX.GetColorCSS("Primary"),
              }}
            >
              {title}
            </Typography>
            {/* {this.renderDatePicker()} */}
            <Spacer />
          </HStack>
        </Box>

        {this.renderTable()}
      </VStack>
    );
  };
}

export default ArenaLog;
