import { DownloadOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import axios from "axios";
import { DOMAIN } from "config/config";
import { store } from "static";
import fileDownload from "js-file-download";

interface DownloadButtonProps {
  URL: string;
  redirectURL: string;
  filename: string;
  disabled?: boolean;
  children?: React.ReactNode;
  variant?: "text" | "outlined" | "contained";
}

export const DowlonadButton = (props: DownloadButtonProps) => {
  const buttonContent = props.children || <DownloadOutlined />;
  const handleDownload = async () => {
    const payloadOut = {
      JWT: store.user.JWT,
      data: {
        redirectURL: props.redirectURL,
      },
    };
    const url = DOMAIN + props.URL;
    store.isLoading(true);
    try {
      const res = await axios.post(url, payloadOut, {
        responseType: "blob",
      });
      console.log(res);
      store.isLoading(false);
      const contentType = res.data.type;
      const fileTypeMap: { [key: string]: string } = {
        txt: "text/plain",
        pdf: "application/pdf",
        docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      };
      const fileType = Object.keys(fileTypeMap).find((key) => fileTypeMap[key] === contentType);
      if (!fileType) {
        store.Alert("Download Failed.", "error");
        return;
      }
      store.isLoading(false);
      const blob = new Blob([res.data], { type: contentType });
      fileDownload(blob, `${props.filename}.${fileType}`);
    } catch (e) {
      store.isLoading(false);
      store.Alert("Download Failed.", "error");
    }
  };
  return (
    <Button fullWidth variant={props.variant} component="label" disabled={props.disabled} onClick={handleDownload} sx={{ width: "100%", height: "100%" }}>
      {buttonContent}
    </Button>
  );
};
