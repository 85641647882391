import React, { Component } from "react";

import schema from "./schema";
import datalink from "./datalink";
import { Box, Typography } from "@mui/material";

import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import { VStack } from "Components/LabIZO/Stackizo";
import { Accessor, ColorX, Authority, store } from "static";
import _ from "lodash";

class Entity extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      title: "ENTITIES",
      serverSidePagination: false,
      base: {
        title: "ENTITIES",
        exportDoc: "Entities",
        schema: schema,
        reqAuth: "Workspace.Entity",

        tablizo: {
          showSelector: true,
          columnsToolbar: true,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          DBInfo: datalink.Request.DBInfo,
          List: datalink.Request.List,
          schema: schema.Table,
        },

        operations: {
          Add: {
            title: "Add Entity",
            url: datalink.Request.Add,
            success: "Entity Added Successfully",
            fail: "Entity Add Failed: ",
            schema: schema.Add,
            buttons: ["Clear", "Submit"],
            onSubmit: "Add",
          },
          Delete: {
            url: datalink.Request.Delete,
            success: "Entity Deleted Successfully.",
            fail: "Entity Delete Failed: ",
            onSubmit: "Delete",
            onClick: (id, row, operations) => {
              //chekc if used in context
              let usedInContextAction = row.usedInContextAction;

              if (_.isArray(usedInContextAction) && usedInContextAction.length > 0) {
                const aliasForm = usedInContextAction.map((item) => {
                  const [workspace, alias] = item.split(":");
                  return alias;
                });
                store.Ask(
                  "Warning!",
                  `This entity is used in context action: <b>${aliasForm.join(", ")}</b>. <br>Deleting it is prohibited. Please remove it from related context actions first.`,
                  (res) => {},
                  () => {},
                  true,
                  ["Cancel"]
                );
              } else {
                store.Ask(
                  "Delete this Entity?",
                  " Caution: This is irrevertable.",
                  (res) => {
                    operations.onSubmit(id, row);
                  },
                  () => {},
                  true,
                  ["OK", "Cancel"]
                );
              }
            },
          },
          Edit: {
            title: "Edit Entity ",
            url: datalink.Request.Edit,
            success: "Entity Edited Successfully",
            fail: "Entity Edit Failed: ",
            schema: schema.Edit,
            buttons: ["Revert", "Submit"],
            onSubmit: "Edit",
          },
          Info: {
            title: "Entity ",
            url: datalink.Request.Info,
            success: "Entity Load Successfully",
            fail: "Entity Load Failed: ",
            schema: schema.Info,
            readOnly: true,
          },
          Sync: {
            title: "Sync from NLP",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.Sync,
            success: "Entity Sync Successfully.",
            fail: "Entity Sync Failed: ",
            onSubmit: "Sync",
          },
          DeleteBulk: {
            title: (n) => "Delete these " + n + " Entities?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.DeleteBulk,
            success: "Entities Deleted Successfully.",
            fail: "Entities Delete Failed: ",
            onSubmit: "DeleteBulk",
          },
          Export: {
            url: datalink.Request.Export,
            schema: schema.Export,
          },
          Import: {
            title: "Entity Import",
            content: "(File size cannot exceed 10MB, only accept .xlsx and .xls)",
            url: datalink.Request.Import,
            success: "Entity Imported Successfully.",
            fail: "Entity Import Failed: ",
            schema: schema.ImportFormat,
            accept: [".xlsx", ".xls"],
            replace: false,
          },
        },

        buttons: {
          inline: [
            { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
            { icon: "info", func: "Info", caption: "Details" },
            { icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" },
          ],
          left: [{ icon: "add", func: "Add", caption: "Add Entity", reqFunc: "Add" }],
          right: [
            { icon: "deletebulk", func: "DeleteBulk", caption: (n) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" },
            { icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
            { icon: "import", func: "Import", caption: "Import", reqFunc: "Import" },
            { icon: "syncfrom", func: "Sync", caption: "Sync", reqFunc: "Sync" },
          ],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("Workspace.Entity");
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Entity.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks) => {
    this.MountDatumizo = callbacks;
  };

  render() {
    let { base, serverSidePagination, title } = this.state;
    return (
      <VStack>
        <Box padding={1} width="100%">
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: 25,
              color: ColorX.GetColorCSS("Primary"),
            }}
          >
            {title}
          </Typography>
        </Box>
        <Datumizo
          base={base}
          serverSidePagination={serverSidePagination}
          onMounted={this.onMountDatumizo}
          addOns={{
            workspace: "master",
          }}
        />
      </VStack>
    );
  }
}

export default Entity;
