import { Box, Typography } from "@mui/material";
import Datumizo from "Components/LabIZO/Datumizo";
import { DatumizoBase } from "Components/LabIZO/Datumizo/DatumizoProps.interface";
import { ColorX, store } from "static";
import { observer } from "mobx-react-lite";
import datalink from "./constants/datalink";
import { FLESetUp } from "./FLESetup";
import { useState } from "react";
import { VStack } from "Components/Layouts";
import schema from "./constants/schema/schema";

interface IFLEProps {
  title?: string;
  base?: DatumizoBase;
}

const defaultProps: IFLEProps = {
  title: "Flow Editor",
  base: {
    title: "FLE",
    exportDoc: "FLE",
    schema: schema,
    reqAuth: "FLE",

    tablizo: {
      showSelector: true,
      columnsToolbar: true,
      filterToolbar: true,
      densityToolbar: true,
      exportToolbar: false,
    },

    Connect: {
      DBInfo: datalink.Request.DBInfo,
      List: datalink.Request.List,
      schema: schema.Table,
    },

    operations: {
      Add: {
        title: "Create Flow",
        url: datalink.Request.Add,
        success: "Flow Added Successfully",
        fail: "Flow Add Failed: ",
        schema: schema.Add,
        buttons: ["Clear", "Submit"],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <FLESetUp {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Add" }} />,
      },
      Delete: {
        title: "Delete this Flow?",
        content: "Caution: This is irrevertable.",
        url: datalink.Request.Delete,
        success: "Flow Deleted Successfully.",
        fail: "Flow Delete Failed: ",
        onSubmit: "Delete",
      },
      Edit: {
        title: "Edit Flow",
        url: datalink.Request.Edit,
        success: "Flow Edited Successfully",
        fail: "Flow Edit Failed: ",
        schema: schema.Edit,
        buttons: ["Revert", "Submit"],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <FLESetUp {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Edit" }} />,
      },
      Info: {
        title: "Flow DETAILS",
        schema: schema.Info,
        readOnly: true,
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <FLESetUp {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Info" }} />,
        buttons: [],
      },

      DeleteBulk: {
        title: (n: number) => "Delete these " + n + " FLE?",
        content: "Caution: This is irrevertable.",
        url: datalink.Request.DeleteBulk,
        success: "FLE Deleted Successfully.",
        fail: "FLE Delete Failed: ",
        onSubmit: "DeleteBulk",
      },
      Export: {
        url: datalink.Request.Export,
        schema: schema.Export,
        exportFileType: "json",
      },
      Import: {
        title: "FLE Import",
        content: "(File size cannot exceed 10MB, only accept .json file type)",
        url: datalink.Request.Import,
        success: "FLE Imported Successfully.",
        fail: "FLE Import Failed: ",
        accept: [".json"],
      },
      // ImportConfirm: {
      //   title: "Import Summary",
      //   Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <ImportConfirm />,
      // },
    },

    buttons: {
      inline: [
        { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
        { icon: "info", func: "Info", caption: "Details" },
      ],
      inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" }],
      left: [{ icon: "add", func: "Add", caption: "Add Flow", reqFunc: "Add" }],
      right: [
        { icon: "deletebulk", func: "DeleteBulk", caption: (n: number) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" },
        { icon: "export", func: "Export", caption: (n: number) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
        {
          icon: "import",
          func: "Import",
          caption: "Import",
          reqFunc: "Import",
        },
      ],
    },
  },
};

const FLE: React.FC<IFLEProps> = (props) => {
  const { title, base } = props;
  const [MountDatumizo, setMountDatumizo] = useState<any>(false);
  const [data, setData] = useState<any>(false);

  function onMountDatumizo(callback: any) {
    setMountDatumizo(callback);
  }

  async function onQuitInner() {
    await MountDatumizo?.Reload();
  }

  async function confirmImport(payload: any) {
    const { uuid } = payload;
    await MountDatumizo?.CustomInner("ImportConfirm", uuid, payload);
    store.clearAsk();
  }

  return (
    <VStack height="100%" justifyContent="flex-start">
      <Box padding={1} width="100%">
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 25,
            color: ColorX.GetColorCSS("Primary"),
          }}
        >
          {title}
        </Typography>
      </Box>
      <Datumizo
        //
        base={base}
        addOns={{ workspace: "master", data }}
        languageSelector={false}
        onMounted={onMountDatumizo}
        onQuitInner={onQuitInner}
        serverSidePagination={true}
        //onLoad={this._SetAllAutoSoftReload}
      />
    </VStack>
  );
};
FLE.defaultProps = defaultProps;

export default observer(FLE);
