import { UseControllerProps, useFormContext } from "react-hook-form";
import { ControlledTextFieldWithIcon } from "Components/HookForm/ControlledTextFieldWithIcon";
import { ControlledSelectWithIcon } from "Components/HookForm/ControlledSelectWithIcon";
import { VStack } from "Components/Layouts";
import { IRole } from "interfaces/db.interfaces/role.interface";

export enum LandingPageSetting {
  Dashboard = "Dashboard",
  StarterDashboard = "StarterDashboard",
  TALK2VA = "Talk2VA",
  FAQ = "FAQ",
  Intent = "Workspace/Intent",
  Conversation = "Convlog",
  RequestLog = "RequestLog",
  Traning = "Training",
  FLE = "FLE",
  UserControl = "UserControl",
  System = "System",
  GPTDashboard = "gpt-qa/gpt-qa-dashboard",
  TechnologyPreviews = "TechnologyPreviews",
  Talk2GPT = "TestChat",
  DocumentManager = "gpt-qa/document-manager",
  GPTConversation = "gpt-qa/conversation-log",
  KnowledgeGroupManagement = "gpt-qa/knowledge-grp-management",
  GPTSetting = "gpt-qa/gpt-setting",
}

interface IProps {
  row?: IRole;
  mode: "Add" | "Edit" | "Info";
}
export const RoleInfo = (props: IProps) => {
  const { control, getValues } = useFormContext<IRole>();
  const mode = props.mode;
  const options = Object.entries(LandingPageSetting).map(([key, value]) => ({
    label: key,
    value: value,
  }));
  const defaultValue = mode == "Add" ? LandingPageSetting.Dashboard : null;

  return (
    <VStack width={"100%"}>
      {mode != "Add" && (
        <ControlledTextFieldWithIcon
          name="_id"
          label="ID"
          rules={{
            required: "This field is required",
          }}
          control={control}
          variant="outlined"
          fullWidth
          disabled
        />
      )}
      <ControlledTextFieldWithIcon
        name="role"
        label="Role Name"
        control={control}
        variant="outlined"
        rules={{
          required: "This field is required",
        }}
        fullWidth
        disabled={mode == "Info"}
      />
      <ControlledTextFieldWithIcon
        name="level"
        label="Role Level"
        rules={{
          validate: (value) => (!isNaN(value) && value >= 0 && value <= 999) || "Please enter a number between 0 and 999",
        }}
        control={control}
        variant="outlined"
        fullWidth
        disabled={mode == "Info"}
      />
      <ControlledSelectWithIcon name="landingPage" control={control} label="Choose Landing page" options={options} defaultValue={defaultValue} fullWidth />
      {mode != "Add" ? <ControlledTextFieldWithIcon name="CREATED_AT" label="Created At" control={control} variant="outlined" fullWidth disabled /> : <></>}
      {mode != "Add" ? <ControlledTextFieldWithIcon name="LASTMODIFIED" label="Last Modified Time" control={control} variant="outlined" fullWidth disabled /> : <></>}
    </VStack>
  );
};
