import { Box, Typography } from "@mui/material";
import { LocalOffer, OfflinePin, School } from "@mui/icons-material";
import axios from "axios";
import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import { VStack } from "Components/LabIZO/Stackizo";
import { Accessor, ColorX, ErrorX, store } from "static";
import _ from "lodash";
import { observer } from "mobx-react";
import moment from "moment";
import { Component } from "react";
import { DOMAIN } from "config/config";
import datalink from "./datalink";
import schema from "./schema";
import TrainTagging from "./Tagging/TrainTagging";
import TrainCreate from "./TrainCreate";
import VerifyTagging from "./Verifying/VerifyTagging";
import { DATE_FORMAT } from "./constants/date-format";
class Training extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      title: "Training Scenarios",
      serverSidePagination: false,
      addOns: {
        Redirect: this._Redirect,
      },
      base: {
        title: "",
        exportDoc: "",
        schema: schema,
        reqAuth: "",
        tablizo: {
          showSelector: false,

          columnsToolbar: false,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          DBInfo: datalink.Request.DBInfo,
          List: datalink.Request.List,
          Get: datalink.Request.Get,
          schema: schema && schema.Table,
        },

        operations: {
          Delete: {
            title: "Delete this Training Scenario?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.Delete,
            success: "Training Scenario Deleted Successfully.",
            fail: "Training Scenario Delete Failed: ",
            onSubmit: "Delete",
          },

          Training: {
            title: "Initiate Training",
            Custom: (id, row, onQuit, onQuitRefresh, renderFormizo, addOns) => {
              return (
                <TrainCreate
                  onQuit={onQuit}
                  onQuitRefresh={onQuitRefresh}
                  DateRange={[
                    //
                    moment().subtract(1, "month").startOf("month").format(DATE_FORMAT),
                    moment().subtract(1, "month").endOf("month").format(DATE_FORMAT),
                  ]}
                />
              );
            },
          },

          Tagging: {
            title: "Tagging Phase",
            Custom: (id, row, onQuit, onQuitRefresh, renderFormizo, addOns) => <TrainTagging docID={id} doc={row} onQuit={onQuit} onQuitRefresh={onQuitRefresh} addOns={addOns} />,
            QuitReload: true,
          },

          Verifying: {
            title: "Training Verification",
            Custom: (id, row, onQuit, onQuitRefresh, renderFormizo, addOns) => <VerifyTagging docID={id} doc={row} onQuit={onQuit} onQuitRefresh={onQuitRefresh} addOns={addOns} />,
            QuitReload: true,
          },
        },

        buttons: {
          inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete", reqLevel: 0 }],
          inline: [
            {
              icon: <LocalOffer />,
              func: this._ToTagging,
              caption: "Proceed to Tagging",
              reqFunc: "Tag",
              theme: { label: ColorX.GetColorCSS("Primary"), hover: "orange", background: "transparent" },
              disableFunc: (id, row) => row.Status !== "Tagging" && row.Status !== "Tagged",
            },
            {
              icon: <School />,
              func: this._StartTraining.onSubmit,
              caption: "Start Training",
              reqFunc: "Train",
              reqLevel: 0,
              theme: { label: ColorX.GetColorCSS("Decorate2"), hover: ColorX.GetColorCSS("Decorate2"), background: "transparent" },
              disableFunc: (id, row) => row.Status !== "Tagging" && row.Status !== "Tagged",
            },
            {
              icon: <OfflinePin />,
              func: this._ToVerify,
              caption: "Proceed to Verify",
              reqFunc: "Verify",
              theme: { label: "green", hover: "green", background: "transparent" },
              disableFunc: (id, row) => row.Status !== "Verifying" && row.Status !== "Verified",
            },
          ],
          left: [
            //{ icon: "add", func: "Add", caption: "Add Training", reqFunc: "Add" }
          ],
          right: [
            {
              icon: "initTrain",
              func: this._initTraining,
              caption: "Initiate Training",
              reqFunc: "InitTrain",
              theme: {
                label: "white",
                background: ColorX.GetColorCSS("Decorate1"),
                hover: {
                  background: "#115293",
                },
              },
            },
          ],
        },
      },
    };
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Training.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks) => {
    this.MountDatumizo = callbacks;
  };

  _initTraining = () => {
    console.log("init training");
    this.MountDatumizo.CustomInner("Training");
  };

  _ToTagging = (id, row) => {
    console.log("_ToTagging", id, row);
    this.MountDatumizo.CustomInner("Tagging", row._id, row);
  };

  _StartTraining = {
    onSubmit: async (id, row) => {
      console.log("_StartTraining", id, row);
      let url = DOMAIN + datalink.Request.Train;
      let payloadOut = {
        JWT: store.user.JWT,
        data: row,
      };

      try {
        console.log(datalink.Request.Train, payloadOut);

        store.isLoading(true);
        let res = await axios.post(url, payloadOut);
        store.isLoading(false);
        console.log(datalink.Request.Train, res.data);

        let { Success, payload } = res.data;

        if (Success === true) {
          this._StartTraining.onSuccess(res);
          return { Success: true };
        } else {
          this._StartTraining.onFail(payload);
          return { Success: false };
        }
      } catch (e) {
        store.isLoading(false);
        this._StartTraining.onFail(ErrorX.Handle(e));
        return { Success: false };
      }
    },

    onSuccess: (res) => {
      store.Alert("Training started successfully", "success");
      this.MountDatumizo.Reload();
    },

    onFail: (msg) => {
      store.Alert(msg, "error");
    },
  };

  _SetAllAutoSoftReload = () => {
    console.log("_SetAllAutoSoftReload");

    setTimeout(() => {
      let data = this.MountDatumizo.GetData();

      _.map(data, (o, i) => {
        if (o.Status === "Trained" || o.Status === "Training" || o.Status === "Preparing") {
          setTimeout(() => {
            this._SetAutoSoftReload(o._id);
          }, 3000);
        }
      });
    }, 1000);
  };

  _SetAutoSoftReload = (id) => {
    console.log("_SetAutoSoftReload", id);
    let doc = this.MountDatumizo.GetDoc(id);
    if (doc) {
      if (doc.Status === "Trained" || doc.Status === "Training" || doc.Status === "Preparing") {
        this.MountDatumizo.SoftReload(id);
        setTimeout(() => {
          this._SetAutoSoftReload(id);
        }, 3000);
      }
    }
  };

  _ToVerify = (id, row) => {
    console.log("_ToVerify", id, row);
    this.MountDatumizo.CustomInner("Verifying", row._id, row);
  };

  _ToResult = (id, row) => {
    console.log("_ToResult", id, row);
  };

  _onQuitInner = async (id, callback) => {
    console.log(id, callback);
    console.log("_onQuitInner", id);
    let url = DOMAIN + datalink.Request.UpdateSummary;
    let payload = {
      JWT: store.user.JWT,
      data: {
        alias: id,
      },
    };
    await axios.post(url, payload);
    this.setState({ innerOpen: false });
    if (callback) callback();
  };

  _onDataChange = (_data, payload) => {
    let fail = payload?.failedCreation;
    if (fail) {
      store.Alert(`Scenario [${fail.scenarioAlias}] not created: ${fail.msg}`, "error");
    }
  };

  render() {
    let { base, serverSidePagination, title, addOns, innerOpen } = this.state;
    return (
      <VStack>
        <Box padding={1} width="100%">
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: 25,
              color: ColorX.GetColorCSS("Primary"),
            }}
          >
            {title}
          </Typography>
        </Box>
        {/* <TrainingOverview innerOpen={innerOpen}></TrainingOverview> */}
        <Datumizo
          base={base}
          serverSidePagination={serverSidePagination}
          addOns={addOns}
          onMounted={this.onMountDatumizo}
          onQuitInner={this._onQuitInner}
          onLoad={this._SetAllAutoSoftReload}
          languageSelector={false}
          onDataChange={this._onDataChange}
        />
      </VStack>
    );
  }
}

export default observer(Training);
