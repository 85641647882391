import { Button, Grid, Paper, TextField } from "@mui/material";
import { DBAnswerSpec } from "interfaces";
import { MultilangAnswerFeilds } from "Pages/FLE/components";
import { IFLEDoc } from "interfaces/db.interfaces/fle.interface";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BodyTitle } from "Components";
import { AddButton, DeleteButton } from "Components/IconButtons";
import HStack from "Components/Layouts/HStack";
import VStack from "Components/Layouts/VStack";

import PayloadContentReplacement from "./PayloadContentReplacement";

interface IProps {
  disabled?: boolean;
}

export function APIPayloadSets({ ...props }: IProps) {
  const [payloadSet, setPayloadSet] = useState<"default" | number>("default");
  const { control } = useFormContext<IFLEDoc<DBAnswerSpec>>();

  const GridItemProps = {
    xs: 6,
    md: 4,
    lg: 2,

    item: true,
    container: true,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Controller
      name="integrations.api"
      control={control}
      render={({ field: { value: apiConfig, onChange } }) => {
        if (!apiConfig) return <></>;
        if (!apiConfig.responses)
          apiConfig.responses = {
            default: {
              answerDoc: {
                _id: "",
                text: {
                  EN: [""],
                },
                domain: "master",
                name: "",
              },
            },
          };

        return (
          <VStack width="100%">
            <Paper
              sx={{
                width: "100%",
                p: 2,
              }}
            >
              <Grid container>
                <Grid {...GridItemProps}>
                  <Button
                    variant={payloadSet == "default" ? "contained" : "text"}
                    onClick={() => {
                      setPayloadSet("default");
                    }}
                    {...props}
                  >
                    Default
                  </Button>
                </Grid>

                {(apiConfig.responses?.conditionals &&
                  apiConfig.responses.conditionals.map((cond, condInx) => {
                    return (
                      <Grid {...GridItemProps} key={`cond-${condInx}`}>
                        <HStack>
                          <Button
                            variant={payloadSet == condInx ? "contained" : "text"}
                            key={condInx}
                            onClick={() => {
                              setPayloadSet(condInx);
                            }}
                            {...props}
                          >{`Payload Set ${condInx + 1}`}</Button>
                          <DeleteButton
                            onClick={() => {
                              apiConfig.responses.conditionals && apiConfig.responses.conditionals.splice(condInx, 1);
                              setPayloadSet("default");
                              onChange(apiConfig);
                            }}
                            {...props}
                          />
                        </HStack>
                      </Grid>
                    );
                  })) || <></>}

                <Grid {...GridItemProps} lg={1}>
                  <AddButton
                    onClick={() => {
                      if (!apiConfig.responses?.conditionals) apiConfig.responses.conditionals = [];
                      apiConfig.responses.conditionals.push({
                        conditions: [],
                        config: {
                          answerDoc: { _id: "", text: { EN: [""] }, domain: "master", name: "" },
                          replaceables: [],
                        },
                      });
                      onChange(apiConfig);
                      setPayloadSet(apiConfig.responses.conditionals.length - 1);
                    }}
                    {...props}
                  />
                </Grid>
              </Grid>

              {apiConfig.responses.conditionals && payloadSet !== "default" && (
                <VStack alignItems={"flex-start"}>
                  <BodyTitle>API Payload Sets</BodyTitle>

                  <Paper sx={{ width: "100%", p: 1 }}>
                    {apiConfig.responses.conditionals[payloadSet].conditions.map((condition, cIdx) => {
                      return (
                        <APIConditionSetting
                          key={cIdx}
                          conditionKey={condition.key}
                          conditionVal={condition.value}
                          onChange={(key, value) => {
                            if (!apiConfig.responses?.conditionals) apiConfig.responses.conditionals = [];
                            if (!key && !value) apiConfig.responses.conditionals[payloadSet].conditions.splice(cIdx, 1);
                            apiConfig.responses.conditionals[payloadSet].conditions[cIdx] = { key, value };
                            onChange(apiConfig);
                          }}
                        />
                      );
                    })}
                    <AddButton
                      onClick={() => {
                        if (!apiConfig.responses?.conditionals) apiConfig.responses.conditionals = [];
                        apiConfig.responses.conditionals[payloadSet].conditions.push({
                          key: "",
                          value: "",
                        });
                        onChange(apiConfig);
                      }}
                      {...props}
                    />
                  </Paper>
                </VStack>
              )}
            </Paper>

            <HStack>
              <PayloadContentReplacement
                apiConfig={apiConfig}
                onChangeAPIConfig={(apiConfig) => {
                  onChange(apiConfig);
                }}
                payloadSet={payloadSet}
              />

              <Paper sx={{ width: "100%", p: 2, height: "100%" }}>
                <VStack width="100%">
                  <BodyTitle>Answer Content</BodyTitle>
                  {payloadSet == "default" && (
                    <MultilangAnswerFeilds
                      answerDoc={apiConfig.responses.default.answerDoc}
                      onChangeAnswerDoc={(answerDoc) => {
                        apiConfig.responses.default.answerDoc = answerDoc;
                        onChange(apiConfig);
                      }}
                      {...props}
                    />
                  )}

                  {payloadSet !== "default" && apiConfig.responses.conditionals && apiConfig.responses.conditionals[payloadSet] && apiConfig.responses.conditionals[payloadSet].config && (
                    <MultilangAnswerFeilds
                      answerDoc={apiConfig.responses.conditionals[payloadSet].config.answerDoc}
                      onChangeAnswerDoc={(answerDoc) => {
                        if (!apiConfig.responses?.conditionals) apiConfig.responses.conditionals = [];
                        apiConfig.responses.conditionals[payloadSet].config.answerDoc = answerDoc;
                        onChange(apiConfig);
                      }}
                      {...props}
                    />
                  )}
                </VStack>
              </Paper>
            </HStack>
          </VStack>
        );
      }}
    />
  );
}
function APIConditionSetting({ conditionKey, conditionVal, onChange }: { conditionKey: string; conditionVal: string; onChange: (key: string, value: string) => void }) {
  return (
    <HStack>
      <TextField
        fullWidth
        variant="outlined"
        label="Key"
        value={conditionKey}
        onChange={(e) => {
          onChange(e.target.value, conditionVal);
        }}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Value"
        value={conditionVal}
        onChange={(e) => {
          onChange(conditionKey, e.target.value);
        }}
      />
      <DeleteButton
        onClick={() => {
          onChange("", "");
        }}
      />
    </HStack>
  );
}
