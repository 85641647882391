import { store } from "static";

import axios from "axios";
import { Button } from "@mui/material";
import { GetApp } from "@mui/icons-material";

import { DOMAIN } from "config/config";
import { HStack } from "Components/LabIZO/Stackizo";
import fileDownload from "js-file-download";

import { StarterDashboardState } from "../StarterDashboard";

export const Report: React.FC<{ base: any; dateRange: StarterDashboardState["dateRange"] }> = ({ base, dateRange }) => {
  const DownloadBtn = {
    Request: async () => {
      try {
        store.isLoading(true);
        let DocName = base.exportDoc + dateRange.from.format("YYYYMMDD") + "-" + dateRange.to.format("YYYYMMDD") + ".xlsx";

        store.Alert("Downloading Report:" + DocName);

        let url = DOMAIN + base.url.DownloadReport;

        let payloadOut = {
          JWT: store.user.JWT,
          data: {
            format: null,
            schema: base.schema.Export,
            skip: 0,
            limit: 9999,
          },
          addOns: {
            dateRange,
          },
        };

        console.log("[>] Download Request post to:", url, payloadOut);

        let res = await axios.post(url, payloadOut, { responseType: "blob" });
        store.isLoading(false);
        const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        fileDownload(blob, DocName);
      } catch (e: any) {
        DownloadBtn.onFail(e.message);
      }
    },

    onClick: async () => {
      await DownloadBtn.Request();
    },

    onSuccess: () => {},
    onFail: (msg: string) => {
      store.Alert("Failed to download report: " + msg, "error");
    },
  };

  return (
    <HStack className="Buttons">
      <Button variant="contained" color="primary" onClick={DownloadBtn.onClick} style={{ visibility: "hidden" }}>
        <GetApp fontSize="small" />
        Download
      </Button>
    </HStack>
  );
};
