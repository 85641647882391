import { backendConnector, DATA_LINK } from "connectors";
import { useState, useEffect } from "react";
import { IEmbeddingObject } from "../DocumentManagerForm/ContextEditors";

export function useProcessedText(text: string, deps?: React.DependencyList) {
  const [processedText, setProcessedText] = useState<string[]>([]);
  useEffect(() => {
    async function getProcessedTexts() {
      if (!text) {
        setProcessedText([]);
        return;
      }
      const res = await backendConnector.post<string[]>(DATA_LINK.DMGetPrcessedText, { text: text });
      setProcessedText(res.payload);
    }
    getProcessedTexts();
  }, deps);
  return { processedText, setProcessedText };
}
