import React, { Component } from "react";

import { Box, IconButton, Popover } from "@mui/material";
import { Visibility } from "@mui/icons-material";

import { Accessor, ColorX } from "static";

/**
 *
 * @augments {Component<Props, State>}
 */
class ZEye extends Component {
  static defaultProps = {
    disabled: false,
    children: <></>,
  };

  constructor() {
    super();
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(ZEye.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onPopoverOpen = (e) => {
    let { disabled } = this.props;
    if (disabled) return;
    this.setState({
      anchorEl: e.currentTarget,
    });
  };

  onPopoverClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    let { anchorEl } = this.state;
    let { classes, children, disabled } = this.props;
    return (
      <div>
        <IconButton
          type="button"
          onMouseEnter={this.onPopoverOpen}
          onMouseLeave={this.onPopoverClose}
          style={{ color: ColorX.GetColorCSS(disabled ? ColorX.GetColorCSS("grey") : ColorX.GetColorCSS("Edit"), disabled ? 0.4 : 1) }}
        >
          <Visibility />
        </IconButton>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
            padding: "1px",
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={this.onPopoverClose}
          disableRestoreFocus
        >
          <Box style={{ maxWidth: 500 }}>{children}</Box>
        </Popover>
      </div>
    );
  }
}

export default ZEye;
