import { FormizoSchema } from "Components/LabIZO/Formizo";
import { IFGTab } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import { QAMode } from "interfaces";
import { T2EResponse } from "interfaces";
import { GPTDetails } from "./GPTDetails/GPTDetails";

// NOTE: refer to T2EResponse for field name
const basicInfoTabSchema: IFGTab = {
  label: "Basic Info",
  page: [
    {
      label: "Conversation ID",
      name: "msg_id",
      format: "text",
      readOnly: true,
    },
    {
      label: "Session ID",
      name: "sessionID",
      format: "text",
    },
    {
      label: "User ID",
      name: "user_id",
      format: "text",
    },
    {
      label: "Chatbot Version",
      name: "chatbotVersion",
      format: "text",
    },
    {
      label: "Receive Time",
      name: "recTime",
      format: "text",
    },
    {
      label: "Proccess Time (s)",
      name: "processTime",
      format: "text",
    },
    {
      label: "Channel",
      name: "channel",
      format: "text",
    },
    {
      label: "Message Content",
      name: `input.content`,
      format: "textarea",
      rows: 1,
      maxRows: 3,
    },
    {
      label: "Message Type",
      name: "input.type",
      format: "text",
    },
    {
      label: "Language",
      name: "language",
      format: "text",
    },
    {
      label: "Pipeline",
      name: "pipeline",
      format: "text",
    },
    {
      label: "Intent",
      name: "intent",
      format: "text",
      optional: true,
    },

    {
      label: "API Token Limit",
      name: "apiTokenLimit",
      format: "number",
      optional: true,
    },
    {
      label: "LiveChat",
      name: "live_chat",
      format: "bool",
      optional: true,
    },
    {
      reqLevel: 0,
      accessizo: [
        {
          label: "Direct Answer",
          name: "__ans",
          format: "text",
          optional: true,
        },
        {
          label: "Change Topic",
          name: "__change",
          format: "bool",
          optional: true,
        },
        {
          label: "Template",
          name: "__tans",
          format: "text",
          optional: true,
        },
        {
          label: "Function",
          name: "__func",
          format: "text",
          optional: true,
        },
        {
          label: "Parameters",
          name: "__param",
          format: "text",
          optional: true,
        },
      ],
    },
  ],
};

const faqDetailsTabSchema: IFGTab = {
  label: "FAQ / FLE Details",
  page: [
    {
      label: "Intents",
      name: "intents",
      headerStyle: "outlined",
      array: [
        {
          label: "Intent",
          name: "intent",
          format: "text",
        },
        {
          label: "Confidence",
          name: "confidence",
          format: "text",
        },
      ],
    },
    {
      label: "Entities",
      name: "entities",
      headerStyle: "outlined",
      array: [
        {
          label: "",
          name: "location",
          format: "display",
          Custom: (data: T2EResponse, field: any, state: any) => {
            let { cssPrefix } = state;
            return <div className={cssPrefix + " zrf-field-data"}>{data.input?.content && field && data.input.content.substring(field[0], field[1])}</div>;
          },
        },
        {
          label: "Entity",
          name: "entity",
          format: "text",
        },
        {
          label: "Value",
          name: "value",
          format: "text",
        },
        {
          label: "Confidence",
          name: "confidence",
          format: "text",
        },
      ],
    },
    {
      reqLevel: 0,
      accessizo: [
        {
          label: "Nodes Visited",
          name: "nodesVisited",
          headerStyle: "outlined",
          array: [
            {
              label: "",
              name: "",
              format: "text",
            },
          ],
        },
        {
          label: "Detected Keywords",
          name: "detect.Entities",
          headerStyle: "outlined",
          array: [
            {
              label: "",
              name: "",
              format: "display",
              Custom: (data: T2EResponse, field: any, state: any) => {
                let { cssPrefix } = state;
                // return <div className={cssPrefix + " zrf-field-data"}>{data.input && field && data.input.substring(field.from, field.to)}</div>;
                return <div className={cssPrefix + " zrf-field-data"}>{data.input?.content && field && data.input.content.substring(field.from, field.to)}</div>;
              },
            },
            {
              label: "Name",
              name: "name",
              format: "text",
            },
            {
              label: "Pattern",
              name: "pattern",
              format: "text",
            },
            {
              label: "Value",
              name: "value",
              format: "display",
              Custom: (data: any, field: any, state: any) => {
                return field ? JSON.stringify(field) : "";
              },
            },
          ],
        },
        {
          label: "Detected Semantics",
          name: "detect.Semantics",
          headerStyle: "outlined",
          array: [
            {
              label: "",
              name: "",
              format: "display",
              Custom: (data: T2EResponse, field: any, state: any) => {
                let { cssPrefix } = state;
                return <div className={cssPrefix + " zrf-field-data"}>{data.input?.content && field && data.input.content.substring(field.from, field.to)}</div>;
              },
            },
            {
              label: "Name",
              name: "name",
              format: "text",
            },
            {
              label: "Pattern",
              name: "pattern",
              format: "text",
            },
            {
              label: "Value",
              name: "value",
              format: "display",
              Custom: (data: any, field: any, addOns: any) => {
                return field ? JSON.stringify(field) : "";
              },
            },
          ],
        },
      ],
    },
  ],
};

const gptDetailsTabSchema: IFGTab = {
  label: "GPT Details",
  page: [],
  componentOverride: (data: T2EResponse, _addOns: any) => <GPTDetails extractedContext={data.extracted_context} />,
};

export const getDetailsPanelSchema = (qaMode: QAMode): FormizoSchema[] => {
  const tabs = [basicInfoTabSchema];
  switch (qaMode) {
    case QAMode.INTENT_CLASSIFICATION:
      tabs.push(faqDetailsTabSchema);
      break;
    case QAMode.LLM:
      tabs.push(gptDetailsTabSchema);
      break;
    case QAMode.HYBRID:
      tabs.push(faqDetailsTabSchema);
      tabs.push(gptDetailsTabSchema);
      break;
  }
  return [{ tabs, fitemHeight: "100%" }];
};
