import { IOptionalSendToServerParam, MiddlewareConnector } from "Pages/CombinedTalk2VA/connectors/middleware.connector";
import { IQuickReply, IT2EDetect, T2EStreamResponse, T2EResponse, ZChat as IZChat, QAMode, T2EContext } from "interfaces";
import _ from "lodash";
import moment from "moment";
import { useState, useRef, useEffect } from "react";
import { v4 } from "uuid";
import { MESSAGE_STATUS } from "../Messenger";
import { Analyzer } from "../classes/Analzyer";
import { UserId } from "../interfaces/messenger.interface";
import { useUrlParams } from "./useUrlParams";
import { ErrorX } from "static";

export function useMessages({
  onMsgSent, //
  connector,
  intermediateResponseUrl,
  enableLiveChat = false,
}: {
  onMsgSent?: (answer: T2EResponse, msgId: string) => void; //
  connector: MiddlewareConnector;
  intermediateResponseUrl?: string;
  enableLiveChat?: boolean;
}) {
  const [messages, setMessages] = useState<IZChat[]>([]);
  const [quickReplies, setQuickReplies] = useState<IQuickReply[]>([]); // for quick reply bar
  const [typing, setTyping] = useState<boolean>(false);
  const [detect, setDetect] = useState<IT2EDetect>();
  const [model, setModel] = useState<string>();
  const [livechat, setLivechat] = useState<boolean>(false);
  const analyzerRef = useRef(new Analyzer());
  const { urlParams } = useUrlParams();
  function getDateStr() {
    return moment().toISOString();
  }

  /**
   * High level methods to update the states in various ways
   */
  const stateMethod = {
    setStatus(msgId: string, status: MESSAGE_STATUS, optional: { userId?: number } = { userId: 1 }) {
      setMessages((messages) => {
        let msgIndex = messages.findIndex((msg) => msg._id === msgId && msg.user._id === optional.userId);
        if (msgIndex === -1) return messages;
        messages[msgIndex].status = status;
        return [...messages];
      });
    },

    sendText: async (
      sessionId: string,
      text: string,
      optional: {
        hideInput?: boolean;
        displayText?: string;
        payloadMeta?: IOptionalSendToServerParam;
      } = {}
    ) => {
      try {
        if (text.trim() === "") return;
        const msgId = optional?.payloadMeta?.message_id || v4();
        delete optional?.payloadMeta?.message_id;
        const isSystemCommand = _.includes(text, "CMD_");
        //set state
        setTyping(true);
        let msgObj: IZChat = {
          _id: msgId,
          msg: { text: optional?.displayText ?? text },
          status: MESSAGE_STATUS.PENDING,
          user: { _id: 1 },
          createdAt: getDateStr(),
        };
        if (!optional.hideInput) setMessages((messages) => [...messages, msgObj]);

        //clear quick replies
        setQuickReplies([]);

        //send message
        const answerPromise = connector.sendText(text, {
          sessionId: sessionId,
          viaBackend: false,
          message_id: msgId,
          project: optional?.payloadMeta?.project,
          knowledgeGroup: optional?.payloadMeta?.knowledgeGroup,
          livechat: livechat,
          urlParams: urlParams,
          userId: "talk2va",
          ...optional.payloadMeta, // override
        });
        stateMethod.setStatus(msgId, MESSAGE_STATUS.SENT);
        let { isStream, response: answerResponse } = await answerPromise;

        const answer = answerResponse as T2EResponse;

        setModel(answer.model);

        //handle streaming
        if (isStream) {
          console.log("[-] Streaming");
          return await stateMethod.handleStream(msgId, answerResponse as T2EStreamResponse, optional?.payloadMeta?.streamUrl ?? "", {
            getIntermediateResponse: !isSystemCommand,
            intermediateResponseUrl: intermediateResponseUrl,
            payloadMeta: optional?.payloadMeta,
            input: optional?.displayText ?? text,
          });
        }

        //handle LiveChat
        if (answer.live_chat && enableLiveChat) stateMethod.startLiveChatPoll(sessionId, optional?.payloadMeta ?? {});
        else stateMethod.stopLiveChatPoll();

        //need to append msg_id somehow
        const msgsToAppend = answer.message.map((_msg) => {
          return {
            ..._msg,
            _id: msgId, // keep the same id as the original message (user's message)
            text: optional?.displayText ?? text,
            user: { _id: 0 },
            createdAt: getDateStr(),
            showEvaluate: isSystemCommand ? false : true,
            pipeline: answer.pipeline,
          };
        });
        setMessages((messages) => [...messages, ...msgsToAppend]);

        if (onMsgSent) onMsgSent(answer, msgId);
        analyzerRef.current.addRecord(msgId, answer);

        // set quick replies
        if (answer?.message.length > 0) {
          const lastMessage = answer.message[answer.message.length - 1];
          if (lastMessage.msg.quickReplies) {
            const quickReplies = lastMessage.msg.quickReplies;
            setQuickReplies(quickReplies);
          }
        }

        //set detected context
        if (answer?.detect) {
          setDetect(answer.detect);
        }

        // update status
        setTyping(false);
        stateMethod.setStatus(msgId, MESSAGE_STATUS.READ);
      } catch (e) {
        setTyping(false);
        console.error(e);
      }
    },

    sendWelcomeMessage: async (sessionId: string, payloadMeta?: IOptionalSendToServerParam) => {
      await stateMethod.sendText(sessionId, "CMD_WELCOME", { hideInput: true, payloadMeta });
    },

    addLineBreak: (text: string = "Session Cleared") => {
      setMessages((messages) => [
        ...messages,
        {
          _id: v4(), //
          msg: { linkbreak: true, text: text },
          status: MESSAGE_STATUS.SENT,
          user: { _id: 1 },
          createdAt: getDateStr(),
        } as IZChat,
      ]);
    },

    handleStream: async (
      messageId: string,
      streamResponse: T2EStreamResponse,
      streamUrl: string,
      optional?: {
        thinkingText?: string;
        getIntermediateResponse?: boolean;
        intermediateResponseUrl?: string;
        payloadMeta?: IOptionalSendToServerParam;
        input?: string;
      }
    ) => {
      const { response_id } = streamResponse;
      setTyping(false);

      stateMethod.setStatus(messageId, MESSAGE_STATUS.SENT);

      if (streamResponse.errorMsg) {
        //Handle error Msg
        setMessages((messages) => [
          ...messages,
          {
            _id: messageId, //
            msg: { text: streamResponse.errorMsg },
            user: { _id: 0 },
            createdAt: getDateStr(),
          } as IZChat,
        ]);
        return;
      }

      // set intermediate response placeholder
      if (optional?.getIntermediateResponse && optional?.intermediateResponseUrl) {
        setMessages((messages) => [
          ...messages,
          {
            _id: `${messageId}_intermediate`,
            msg: { text: "Searching For: " },
            user: { _id: 2 },
            createdAt: getDateStr(),
          } as IZChat,
        ]);
      }

      // append a new response message. This will be updated during the stream
      setMessages((messages) => [
        ...messages,
        {
          _id: messageId, //
          msg: { text: optional?.thinkingText ?? "Thinking..." },
          status: MESSAGE_STATUS.PENDING,
          user: { _id: 0 },
          createdAt: getDateStr(),
        } as IZChat,
      ]);

      // async start streaming
      const finalStreamResponsePromise = connector.getGPTResponseChunk(messageId, response_id, streamUrl, async (msgs: IZChat[]) => {
        setMessages((messages) => {
          //pop the last chatbot message
          const lastChatbotMessageIndex = messages.findIndex((msg) => msg.user._id == UserId.chatbot && msg._id === messageId);
          if (lastChatbotMessageIndex === -1) return msgs;
          // insert the new messages

          msgs.map((_msg) => {
            _msg._id = messageId;
            _msg.user._id = UserId.chatbot;
            _msg.createdAt = getDateStr();
            _msg.text = optional?.input;
            _msg.pipeline = "gptQa";
          });
          messages.splice(lastChatbotMessageIndex, 1, ...msgs);
          return [...messages];
        });
      });

      // get intermediate response, update the placeholder
      if (optional?.getIntermediateResponse && intermediateResponseUrl) {
        const itmRes = await connector.getIntermediateResponse(intermediateResponseUrl, { message_id: messageId, model: optional?.payloadMeta?.model });
        const itmMsg: IZChat[] = itmRes.map((_msg) => {
          return {
            _id: `${messageId}_intermediate`,
            msg: { text: _msg.text },
            user: { _id: 2 },
            createdAt: getDateStr(),
            pipeline: "gptQa",
          } as IZChat;
        });
        setMessages((messages) => {
          //find the intermediate response placeholder
          let itmResIdx = messages.findIndex((msg) => msg.user._id == UserId.user && msg._id === `${messageId}_intermediate`);
          if (itmResIdx === -1) return messages;
          // insert the new messages
          messages.splice(itmResIdx, 1, ...itmMsg);
          return [...messages];
        });
      }

      //wait for streaming to finish
      const finalStreamResponse: T2EResponse | undefined = await finalStreamResponsePromise;

      setMessages((messages) => {
        let msgIndex = messages.findIndex((msg) => msg._id === messageId && msg.user._id == UserId.chatbot);
        if (msgIndex === -1) return messages;
        messages[msgIndex].showEvaluate = optional?.getIntermediateResponse ? true : false;
        //Chips only can be added to the last response
        messages[msgIndex].msg.chips = finalStreamResponse?.message[0].msg.chips;
        messages[msgIndex].msg.chipTitle = finalStreamResponse?.message[0].msg.chipTitle;
        return [...messages];
      });

      if (finalStreamResponse != undefined) {
        // quick replies??
        const msgs = finalStreamResponse.message;
        if (msgs?.length > 0) {
          const lastMessage = msgs[msgs.length - 1];
          if (lastMessage.msg.quickReplies) {
            const quickReplies = lastMessage.msg.quickReplies;
            setQuickReplies(quickReplies);
          }
        }
      }

      stateMethod.setStatus(messageId, MESSAGE_STATUS.READ);

      if (!finalStreamResponse) return;
      if (onMsgSent && finalStreamResponse) onMsgSent(finalStreamResponse, messageId);
      analyzerRef.current.addRecord(messageId, finalStreamResponse);
    },
    liveChatShoot: async (sessionId: string, optional?: IOptionalSendToServerParam) => {
      const msgId = v4();
      //if (!livechat) return;
      console.log(livechat);
      try {
        const livechatPromise = connector.sendLiveChat({
          sessionId: sessionId,
          viaBackend: false,
          message_id: msgId,
          qaMode: optional?.qaMode,
          project: optional?.project,
        });
        let { response: livechatResponse } = await livechatPromise;

        const answer = livechatResponse as T2EResponse;
        console.log(answer);
        if (answer.Success) {
          const msgToAppend = answer.message.map((_msg) => {
            return {
              ..._msg,
              _id: msgId,
              user: { _id: 0 },
              createdAt: getDateStr(),
            };
          });
          setMessages((messages) => [...messages, ...msgToAppend]);
          if (answer.live_chat) {
            await stateMethod.liveChatShoot(sessionId, optional);
          }
        } else {
          stateMethod.stopLiveChatPoll();
        }
      } catch (e) {
        setTyping(false);
        console.error(e);
        stateMethod.stopLiveChatPoll();
      }
    },

    startLiveChatPoll: (sessionId: string, optinal: IOptionalSendToServerParam) => {
      console.log("[-] Live Chat Start Polling");
      setLivechat(true);
      stateMethod.liveChatShoot(sessionId, optinal);
    },
    stopLiveChatPoll: () => {
      console.log("[-] Live Chat Stop Polling");
      setLivechat(false);
    },

    /**
     * Update Context and set the new message after context update
     * @param sessionId
     * @param actionId
     * @param contexts
     */
    updateContext: async (sessionId: string, actionId: string, contexts: T2EContext[]) => {
      try {
        const res = await connector.updateContext(sessionId, actionId, contexts);
        if (!res) throw new Error("Update Context Failed");
        const messagesToAppend = res.message;
        setMessages((messages) => [...messages, ...messagesToAppend]);
      } catch (e: any) {
        ErrorX.HandleError(e);
      }
    },
  };

  return {
    messages,
    quickReplies,
    typing,
    detect,
    analyzerRef,
    stateMethod,
    setMessages,
    model,
  };
}
