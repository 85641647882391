import React, { Component } from "react";
import PropsType from "prop-types";

import { Box, Typography } from "@mui/material";
import axios from "axios";
import schema from "./schema";
import datalink from "../datalink";
import { DOMAIN } from "config/config";
import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import { VStack } from "Components/LabIZO/Stackizo";
import { Accessor, ColorX, Authority, store, ErrorX } from "static";
import { Denied } from "Components/_PageComponents/Fallback";
import _ from "lodash";
import { backendConnector } from "connectors/backend-connector";
import { DATA_LINK } from "connectors";

/**
 * @augments {Component<Props, State>}
 */
class SysUser extends Component {
  static propTypes = {
    addOns: PropsType.object,
  };

  static defaultProps = {
    addOns: {},
  };

  constructor() {
    super();
    this.state = {
      title: "User Access Control",
      serverSidePagination: false,
      base: {
        title: "User",
        exportDoc: "users",
        schema: schema,
        reqAuth: "UserControl.User",

        noDefaultTable: false,
        noDefaultButtons: false,

        tablizo: {
          columnsToolbar: true,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
          density: "standard", //compact, standard, comfortable
          defaultPageSize: 50,
          showSelector: true,
        },

        formizo: {},

        Connect: {
          DBInfo: datalink.Request.DBInfo,
          List: datalink.Request.List,
          schema: schema.Table,
        },

        operations: {
          Add: {
            title: "Add User",
            url: datalink.Request.Add,
            success: "User Added Successfully",
            fail: "User Add Failed: ",
            schema: schema.Add,
            buttons: ["Clear", "Submit"],
            onSubmit: "Add",
          },
          Delete: {
            title: "Delete this User?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.Delete,
            success: "User Deleted Successfully.",
            fail: "User Delete Failed: ",
            onSubmit: "Delete",
          },
          Edit: {
            title: "Edit User ",
            url: datalink.Request.Edit,
            success: "User Edited Successfully",
            fail: "User Edit Failed: ",
            schema: schema.Edit,
            buttons: ["Revert", "Submit"],
            onSubmit: "Edit",
          },
          Info: {
            title: "User Access Control ",
            url: datalink.Request.Info,
            success: "User Access Control Load Successfully",
            fail: "User Access Control Load Failed: ",
            schema: schema.Info,
            readOnly: true,
          },
          Import: {
            title: "User Import",
            content: "",
            url: datalink.Request.Import,
            success: "User Imported Successfully.",
            fail: "User Import Failed: ",
            schema: schema.ImportFormat,
            replace: false,
          },
          Export: {
            url: datalink.Request.Export,
            schema: schema.Export,
          },
          DeleteBulk: {
            title: (n) => "Delete these " + n + " User?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.DeleteBulk,
            success: "User Deleted Successfully.",
            fail: "User Delete Failed: ",
            onSubmit: "DeleteBulk",
          },
        },

        buttons: {
          inline: [
            { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
            { icon: "info", func: "Info", caption: "Details" },
            { icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" },
          ],
          left: [{ icon: "add", func: "Add", caption: "Add User", reqFunc: "Add" }],
          right: [
            { icon: "deletebulk", func: "DeleteBulk", caption: (n) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" },
            //{ icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
            //{ icon: "import", func: "Import", caption: "Import", reqFunc: "Import" },
          ],
        },
      },
    };
  }

  componentDidMount() {
    this._setAllStates();
    this.getRoleList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(SysUser.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  getRoleList = async () => {
    try {
      const rtn = await backendConnector.post(DATA_LINK.RoleList, {}, {}, { skipAudit: true });
      if (rtn.Success) {
        let docs = rtn.payload.docs;
        let roleList = _.map(docs, (o, i) => {
          return {
            _id: o._id,
            name: o.role,
          };
        });
        this.setState({ roleList: roleList });
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  onMountDatumizo = (callbacks) => {
    this.MountDatumizo = callbacks;
  };

  render() {
    let { addOns } = this.props;
    let { base, serverSidePagination, title, roleList } = this.state;
    if (!Authority.IsAccessibleQ("UserControl.User")) return <Denied />;
    return (
      <VStack>
        <Box padding={1} width="100%">
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: 25,
              color: ColorX.GetColorCSS("Primary"),
            }}
          >
            {title}
          </Typography>
        </Box>
        <Datumizo base={base} serverSidePagination={serverSidePagination} onMounted={this.onMountDatumizo} addOns={{ roleList }} languageSelector={false} />
      </VStack>
    );
  }
}

export default SysUser;
