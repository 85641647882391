import { Component } from "react";
import _ from "lodash";
import axios from "axios";
import moment from "moment";

import { DOMAIN } from "config/config";
import { Authority, store, ErrorX } from "static";
import DashboardHook from "./DashboardHook";

export interface IFAQDashboardState {
  data: {
    DailyConv: { name: string; count: number }[];
    Overview: { title: string; values: number; remarks?: string }[];
    IntentAccuracy: { name: string; value: number }[];
    IntentDistribution: { name: string; value: number }[];
    CustomerBehavior: { title: string; values: string | number; remarks?: string }[];
  };
  base: {
    title: string;
    titles: {
      Usage: string;
      Performance: string;
      CutomerBehaviour: string;
    };
    graphTitles: {
      DailyCov: string;
      IntentAccuracy: string;
      IntentDistribution: string;
    };
    url: Record<string, string>;
  };
  dateRange: {
    from: moment.Moment;
    to: moment.Moment;
  };
}

export class Dashboard extends Component<{}, IFAQDashboardState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      data: {
        DailyConv: [],
        Overview: [],
        IntentAccuracy: [],
        IntentDistribution: [],
        CustomerBehavior: [],
      },
      base: {
        title: "",
        titles: {
          Usage: "USAGE OVERVIEW",
          Performance: "PERFORMANCE",
          CutomerBehaviour: "CUSTOMER BEHAVIOUR",
        },
        graphTitles: {
          DailyCov: "DAILY CONVERSATION",
          IntentAccuracy: "ACCURACY",
          IntentDistribution: "Top 10 FAQ DISTRIBUTION",
        },
        url: {
          Info: "/Data/Analytics/Dashboard",
        },
      },
      dateRange: {
        from: moment().startOf("month"),
        to: moment().endOf("day"),
      },
    };
  }

  componentDidMount() {
    Authority.Require("Dashboard");
    this.fetchData();
  }

  async fetchData(): Promise<void> {
    const { base, dateRange } = this.state;
    const url = `${DOMAIN}${base.url.Info}`;
    const payload = {
      JWT: store.user.JWT,
      DateRange: dateRange,
      timezone: moment.tz.guess(),
    };
    const { data } = await axios.post<{ Success: boolean; payload: IFAQDashboardState["data"] }>(url, payload);

    if (data.Success) {
      this.setState({ data: data.payload });
    } else {
      ErrorX.Handle(data as any);
    }
  }

  onChangeDateRange(dates: [moment.Moment | null, moment.Moment | null] | null, _formatString?: [string, string]) {
    console.log("triggered");
    if (!dates || !dates[0] || !dates[1]) {
      throw new Error("Dates cannot be null");
    }
    this.setState(
      {
        dateRange: { from: moment(dates[0]).startOf("day"), to: moment(dates[1]).endOf("day") },
      },
      () => {
        this.fetchData();
      }
    );
  }

  render(): React.ReactNode {
    const { base, dateRange, data } = this.state;
    return <DashboardHook base={base} data={data} dateRange={dateRange} onChangeDateRange={this.onChangeDateRange.bind(this)} fetchData={this.fetchData} />;
  }
}

export default Dashboard;
