import { Box, Typography } from "@mui/material";
import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import Formizo from "Components/LabIZO/Formizo/Formizo";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import { Accessor, Authority, ColorX } from "static";
import _ from "lodash";
import moment from "moment";
import { Component } from "react";
import schema from "./schema";
import datalink from "./datalink";
import ConvlogDetail from "./ConvlogDetail/ConvlogDetail";
import { backendConnector, DATA_LINK } from "connectors";

class GPTConvlog extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      title: "CHATGPT CONVERSATION OVERVIEW",
      period: { DateRange: [moment().startOf("month"), moment().endOf("month")] },
      serverSidePagination: true,
      base: {
        title: "Convlog",
        exportDoc: "GPTQA_Conversation_Log",
        schema: schema,
        reqAuth: "GPTQA.ConversationLog",

        tablizo: {
          showSelector: true,
          columnsToolbar: false,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          DBInfo: datalink.Request.DBInfo,
          List: datalink.Request.List,
          schema: schema.Table,
        },

        operations: {
          Export: {
            url: datalink.Request.Export,
            schema: schema.Export,
          },

          Info: {
            title: "CONVERSATION DETAILS",
            url: datalink.Request.Add,
            success: "Convlog Load Successfully",
            fail: "Convlog Load Failed: ",
            schema: schema.Info,
            readOnly: true,
            Custom: (id, doc, onQuit, onQuitRefresh, renderFormizo, addOns) => {
              return <ConvlogDetail id={id} doc={doc} period={this.state.period} />;
            },
          },
        },

        buttons: {
          inline: [{ icon: "info", func: "Info", reqFunc: "Info", caption: "Details" }],
          right: [{ icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" }],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("GPTQA.ConversationLog");
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(GPTConvlog.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks) => {
    this.MountDatumizo = callbacks;
  };

  _initTraining = () => {
    console.log("init training");
    this.MountDatumizo.CustomInner("Training");
  };

  renderDatePicker = () => {
    let { period } = this.state;
    return (
      <Formizo
        width={350}
        height="100%"
        schema={[
          {
            label: "",
            name: "DateRange",
            format: "daterange",
            dateType: "datetime",
            dateFormat: "YYYY-MM-DD HH:mm:ss",
            // disabledDate: (current) => {
            //   return (current && current < moment(StartDate)) || current > moment().endOf("month");
            // },
            noLabelGrid: true,
            defaultValue: period.DateRange,
          },
        ]}
        buttons={[]}
        onChange={(value, _n, _v, fetchData) => {
          const DateRange = [moment(value.DateRange[0]).format("YYYY-MM-DDTHH:mm:ss.SSS"), moment(value.DateRange[1]).format("YYYY-MM-DDTHH:mm:ss.SSS")];
          this.setState({ period: { DateRange } });
          if (fetchData) {
            this.MountDatumizo.Reload();
          }
        }}
      />
    );
  };

  getDoc = (docID) => {
    return this.MountDatumizo.GetDoc(docID);
  };

  renderTable = () => {
    let { base, serverSidePagination, period } = this.state;
    return (
      <Datumizo
        base={base}
        addOns={{
          ...period,
          getDoc: this.getDoc,
        }}
        serverSidePagination={serverSidePagination}
        onMounted={this.onMountDatumizo}
        languageSelector={false}
      />
    );
  };

  render() {
    let { title } = this.state;
    return (
      <VStack>
        <Box padding={1} width="100%">
          <HStack
            justifyContent="space-between"
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Typography
              style={{
                alignItems: "left",
                display: "flex",
                fontSize: 25,
                color: ColorX.GetColorCSS("Primary"),
              }}
            >
              {title}
            </Typography>
            {this.renderDatePicker()}
            <Spacer />
          </HStack>
        </Box>

        {this.renderTable()}
      </VStack>
    );
  }
}
export default GPTConvlog;
