import { useEffect, useState } from "react";
import { Env, ErrorX, store, ColorX } from "static";
import { VStack, HStack } from "Components/LabIZO/Stackizo";
import { DATA_LINK, backendConnector } from "connectors";
import { IUser } from "./Interface/user.interface";
import EmailChanger from "./EmailChanger/EmailChanger";
import PasswordChanger from "./PasswordChanger/PasswordChanger";
import { Container, Card, CardContent, Avatar, Typography, TextField, Box, Grid, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          marginTop: 50,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: "80%",
          margin: "auto",
          borderRadius: 15,
          boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          textAlign: "center",
          padding: "30px 20px",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 100,
          height: 100,
          margin: "auto",
          marginBottom: 15,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: "10px 0",
        },
      },
    },
  },
});

const SysUserProfile = () => {
  const [sysProfile, setSysProfile] = useState<IUser>();
  const [displayName, setDisplayName] = useState<string>("");
  const [emailChanged, setEmailChanged] = useState(false);

  const getProfileInfo = async () => {
    let payloadOut = {
      JWT: store.user.JWT,
      username: store.user.Username,
    };

    try {
      if (store.user.Username == "syva@Dev") {
        return;
      }
      const data = await backendConnector.post(DATA_LINK.SYSUserProfile, payloadOut);
      setSysProfile(data.payload);
      setDisplayName(data.payload.UserDisplayName);
      setEmailChanged(false);
    } catch (e) {
      store.Alert("Cannot get user info", "error");
    }
  };
  useEffect(() => {
    getProfileInfo();
  }, [emailChanged]);

  const onSubmit = async (userData: any) => {
    try {
      let data = await backendConnector.post(DATA_LINK.UserEdit, userData);
      await getProfileInfo();
      store.isLoading(false);
      if (!data.Success) {
        ErrorX.Handle(data.payload);
        return;
      }

      //updon success
      store.Alert("System Config updated successfully", "success");
      await Env.GetSysInfo();
    } catch (e) {
      store.Alert("Cannot set system config", "error");
    }
  };

  const renderProfile = () => {
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <Card style={{ width: "100%" }}>
            <CardContent>
              <Avatar alt={sysProfile?.username} src="/static/images/avatar.jpg" />
              <Typography variant="h5" component="div">
                User Info
              </Typography>

              <Box my={1}>
                <TextField id="username" label="Username" defaultValue={sysProfile?.username} fullWidth disabled />
              </Box>

              <Box my={1}>
                <TextField id="display-name" label="Display Name" value={displayName} fullWidth onChange={(e) => setDisplayName(e.target.value)} />
              </Box>

              <Box my={1}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <TextField id="email" label="Email" value={sysProfile?.email} fullWidth disabled />
                  </Grid>
                  <Grid item xs={4}>
                    <EmailChanger docID={sysProfile?._id} username={sysProfile?.UserDisplayName} jwt={store.user.JWT} setEmailChanged={setEmailChanged} />
                  </Grid>
                </Grid>
              </Box>

              <Box my={1}>
                <TextField id="role" label="Role" defaultValue={sysProfile?.role} fullWidth disabled />
              </Box>

              <Box my={1}>
                <TextField id="last-login" label="Last Login" defaultValue={sysProfile?.lastLogin} fullWidth disabled />
              </Box>

              <Box my={1}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <TextField id="password" label="Password" type="password" fullWidth disabled />
                  </Grid>
                  <Grid item xs={4}>
                    <PasswordChanger username={sysProfile?.username} jwt={store.user.JWT} />
                  </Grid>
                </Grid>
              </Box>
              <Button variant="contained" color="primary" onClick={() => onSubmit({ ...sysProfile, UserDisplayName: displayName })}>
                Save
              </Button>
            </CardContent>
          </Card>
        </Container>
      </ThemeProvider>
    );
  };
  const renderRootUser = () => {
    return (
      <HStack>
        <Typography variant="h5" component="div">
          Not available for Root user
        </Typography>
      </HStack>
    );
  };
  return (
    <HStack justifyContent="flex-start" height="100%" gap={3} paddingX={4}>
      <VStack width="100%">{sysProfile ? renderProfile() : renderRootUser()}</VStack>
    </HStack>
  );
};
export default SysUserProfile;
