import { DatumizoBase } from "Components/LabIZO/Datumizo/DatumizoProps.interface";
import { DATA_LINK } from "connectors";
import schema from "./constants/schema/schema";
import KnowledgeGrpManagementForm from "./KnowledgeGrpManagementForm/KnowledgeGrpManagementForm";

export interface IDefaultProps {
  title?: string;
  base?: DatumizoBase;
}
export const defaultProps: IDefaultProps = {
  title: "Knowledge Group Manager",
  base: {
    title: "Knowledge Group Manager",
    exportDoc: "GPTQA.DocumentManager",
    schema: schema,
    reqAuth: "GPTQA.DocumentManager",

    tablizo: {
      showSelector: true,
      columnsToolbar: true,
      filterToolbar: true,
      densityToolbar: true,
      exportToolbar: false,
    },

    Connect: {
      DBInfo: DATA_LINK.KGDBInfo,
      List: DATA_LINK.KGList,
      schema: schema.Table,
    },

    operations: {
      Add: {
        title: "Create Knowledge Group",
        url: DATA_LINK.KGAdd,
        success: "Knowledge Group Added Successfully",
        fail: "Knowledge Group Add Failed: ",
        schema: schema.Add,
        buttons: ["Clear", "Submit"],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <KnowledgeGrpManagementForm {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Add" }} />,
      },
      Delete: {
        title: "Delete this Knowledge Group?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.KGDelete,
        success: "Knowledge Group Deleted Successfully.",
        fail: "Knowledge Group Delete Failed: ",
        onSubmit: "Delete",
      },
      Edit: {
        title: "Edit Knowledge Group",
        url: DATA_LINK.KGEdit,
        success: "Knowledge Group Edited Successfully",
        fail: "Knowledge Group Edit Failed: ",
        schema: schema.Edit,
        buttons: ["Revert", "Submit"],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <KnowledgeGrpManagementForm {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Edit" }} />,
      },
      Info: {
        title: "Knowledge Group DETAILS",
        schema: schema.Info,
        readOnly: true,
        buttons: [],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <KnowledgeGrpManagementForm {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Info" }} />,
      },

      DeleteBulk: {
        title: (n: number) => "Delete these " + n + " Documents?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.KGDeleteBulk,
        success: "Documents Deleted Successfully.",
        fail: "Documents Delete Failed: ",
        onSubmit: "DeleteBulk",
      },
      Enable: {
        title: (n: number) => "Enable these " + n + " Knowledge Groups?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.KGEnable,
        success: "Enable All Knowledge Groups Successfully.",
        fail: "DEnable All Knowledge Groups Failed: ",
        onSubmit: "Enable",
      },
      Disable: {
        title: (n: number) => "Disable these " + n + " Knowledge Groups?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.KGEnable,
        success: "Disable All Knowledge Groups Successfully.",
        fail: "Disable All Knowledge Groups Failed: ",
        onSubmit: "Disable",
      },
    },

    buttons: {
      inline: [
        { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
        { icon: "info", func: "Info", caption: "Details" },
      ],
      inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" }],
      left: [{ icon: "add", func: "Add", caption: "Add Knowledge Group", reqFunc: "Add" }],
      right: [
        { icon: "deletebulk", func: "DeleteBulk", caption: (n: number) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" },
        { icon: "enable", func: "Enable", caption: (n: number) => "Enable (" + n + ")", reqFunc: "Enable" },
        { icon: "disable", func: "Disable", caption: (n: number) => "Disable (" + n + ")", reqFunc: "Enable" },
        // { icon: "export", func: "Export", caption: (n: number) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
        // {
        //   icon: "import",
        //   func: "Import",
        //   caption: "Import",
        //   reqFunc: "Import",
        // },
      ],
    },
  },
};
