import { SwitchButton } from "Components/SwitchButton";
import { Typography } from "@mui/material";
import { TablizoCustom } from "Components/LabIZO/Tablizo";
import { DATA_LINK } from "connectors";
import { DOMAIN } from "config/config";

export const WebsiteStatus = {
  indexing: "indexing",
  indexed: "indexed",
  editing: "editing",
  deleting: "deleting",
  error: "error",
};

export const TABLE_SCHEMA: TablizoCustom = (tbLang) => [
  // {
  //   label: "Enabled",
  //   name: "enabled",
  //   width: 100,
  //   reqAuth: "GPTQA.WebsiteManager",
  //   reqLevel: 999,
  //   reqFunc: "Enable",
  //   Cell(row, field, addOns, fetchData) {
  //     return <SwitchButton defaultValue={field} URL={DOMAIN + DATA_LINK.WebsiteEdit} row={row} fetchData={fetchData} />;
  //   },
  // },
  {
    label: "Website Name",
    name: "alias",
    filterable: true,
  },
  {
    label: "URL",
    name: "urlSetting.url",
    filterable: true,
  },
  {
    label: "URL Type",
    name: "urlSetting.type",
    filterable: true,
  },
  {
    label: "Status",
    name: "status",
    Cell(row, field, addOns) {
      return (
        <Typography
          color={field == WebsiteStatus.deleting ? "orange" : field == WebsiteStatus.indexed ? "green" : field == WebsiteStatus.indexing ? "gray" : field == WebsiteStatus.editing ? "purple" : "red"}
        >
          {field}
        </Typography>
      );
    },
    width: 100,
    filterable: true,
  },
  {
    label: "Last Index Time",
    name: "last_index_time",
    valueGetter(row) {
      let displayedIndexTime = row?.last_index_time > row?.LASTMODIFIED ? row?.last_index_time : row?.LASTMODIFIED;
      return displayedIndexTime;
    },
    filterable: true,
  },
  {
    label: "Created At",
    name: "CREATED_AT",
    filterable: true,
  },
  {
    label: "Last Modified Time",
    name: "LASTMODIFIED",
    filterable: true,
  },
];
