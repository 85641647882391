import { Box, Paper, styled } from "@mui/material";
import Formizo from "Components/LabIZO/Formizo";
import { Authority } from "static";
import { getDetailsPanelSchema } from "./schema";
import { ITalk2VAAddOns } from "Pages/CombinedTalk2VA/interfaces/combined-talk2va-addons.interface";
import _ from "lodash";
import { QAMode } from "interfaces";
import SuggestedIntent from "./SuggestedIntent/SuggestedIntent";

export interface DetailsPanelProps {
  addOns: ITalk2VAAddOns;
  reqAuth?: string;
  reqFunc?: string;
  reqLevel?: number;
  qaMode: QAMode;
  allowSuggestedIntent?: boolean;
}

const StyledPanelContainer = styled(Paper)({
  height: "100%",
  width: "100%",
  padding: 0,
  display: "flex",
  flexDirection: "column",
});

const StyledBox = styled(Box)({
  width: "100%",
  flex: "1 1 auto",
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

export const DetailsPanel: React.FC<DetailsPanelProps> = ({ addOns, reqAuth, reqLevel, reqFunc, qaMode, allowSuggestedIntent }) => {
  if (!Authority.IsAccessibleQ(reqAuth, reqLevel, reqFunc)) return <></>;

  const joinC = (v: any) => (v && (_.isArray(v) ? v.join(",") : v)) || "";
  let messageData: any = addOns.payload;
  if (messageData !== undefined) {
    if (messageData.ansRes) {
      const { __ans, __tans, __func, __param, __change } = messageData.ansRes;
      const ansRes = {
        __ans: joinC(__ans),
        __tans: joinC(__tans),
        __func: joinC(__func),
        __param: joinC(__param),
        __change: joinC(__change),
      };
      messageData.ansRes = ansRes;
    }
    if (messageData.ws) {
      messageData.intents = messageData.watson_response[messageData.ws]?.intents ?? [];
      messageData.entities = messageData.watson_response[messageData.ws]?.entities ?? [];
      messageData.nodesVisited = (messageData.watson_response[messageData.ws].output && messageData.watson_response[messageData.ws].output.nodes_visited) ?? [];
    }
  }

  //width={750}
  return (
    <StyledPanelContainer>
      <StyledBox>
        {allowSuggestedIntent && addOns.payload && <SuggestedIntent addOns={addOns} />}
        <Box style={{ flex: "1 1 auto", overflow: "auto" }}>
          <Formizo
            height={"100%"}
            overflow="hidden"
            schema={getDetailsPanelSchema(qaMode)}
            readOnly={true}
            defaultValue={messageData}
            fieldSize={"small"}
            buttons={[]}
            customStyles={{ formSchemaContainer: { overflow: "auto" } }}
          />
        </Box>
      </StyledBox>
    </StyledPanelContainer>
  );
};
