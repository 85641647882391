import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { IUIEntity } from "Pages/FLE/interfaces/IUIEntity";
import { useEffect, useState } from "react";

import { ContextDetail } from "./ContextDetail/ContextDetail";
import { SummaryContainer } from "./ContextSummary/SummaryContainer";

interface IContextCardProps {
  index: number;
  entities: IUIEntity[];
  name: `contexts.${number}`;
  onDelete: (index: number) => void;
  canDelete: boolean;
  canEdit: boolean;
  expanded: boolean;
}

export const ContextCard = ({ index, entities, canDelete, canEdit, expanded, ...props }: IContextCardProps) => {
  const [expandedState, setExpandedState] = useState(expanded);

  useEffect(() => {
    setExpandedState(expanded);
  }, [expanded]);

  return (
    <Accordion sx={{ width: 0.95 }} expanded={expandedState} onChange={() => setExpandedState(!expandedState)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <SummaryContainer index={index} canDelete={canDelete} {...props} />
      </AccordionSummary>
      <AccordionDetails>
        <ContextDetail index={index} entities={entities} disabled={!canEdit} {...props} />
      </AccordionDetails>
    </Accordion>
  );
};
