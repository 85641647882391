import { FormControlLabel, TextField } from "@mui/material";
import { IDocumentManagerPayload } from "../../interfaces/document-manager-api.interface";
import { PdfUploader } from "Components/Chat/_gears/SlideMenu/pdf-uploader";
import { VStack } from "Components/Layouts";
import { useState } from "react";
interface IProps {
  id: string;
  row?: IDocumentManagerPayload;
  onQuit: () => void;
  onQuitRefresh: () => void;
  renderFormizo: (schema: any, buttons: any, onSubmit: any) => JSX.Element;
  mode?: "Add" | "Edit" | "Info";
}
export const DocumentUploadForm = (props: IProps) => {
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [documnetAlias, setDocumnetAlias] = useState<string>("");
  const handleUpdate = () => {};
  return (
    <VStack width={"80vh"}>
      <PdfUploader onUpdate={handleUpdate} onQuitRefresh={props.onQuitRefresh} />
    </VStack>
  );
};
