import { useEffect, useState } from "react";
import { Accessor, ErrorX, store } from "static";
import { DATA_LINK, backendConnector } from "connectors";
import Formizo from "Components/LabIZO/Formizo";
import { schema } from "./schema";
import _ from "lodash";

const SysBilling = () => {
  const [info, setInfo] = useState<any>();
  const [intents, setIntents] = useState<any[]>();

  useEffect(() => {
    getInfo();
    getQuickReplies([]);
  }, []);

  const getInfo = async () => {
    const { payload } = await backendConnector.post(DATA_LINK.SYSBillingInfo, {});
    setInfo(payload);
  };

  const getQuickReplies = async (faqDocs: any[]) => {
    try {
      const data = {
        skip: 0,
        limit: 9999,
        fields: ["_id"],
      };
      const addOns = {
        workspace: "ALL",
        allIntents: true,
      };
      const rtn: any = await backendConnector.post(DATA_LINK.IntentList, data, addOns, { skipAudit: true });
      console.log("/Tables/Intent/List", rtn);

      if (rtn.Success === true) {
        let docs = rtn.payload.docs;

        const intentsToHide = faqDocs.reduce((accu, curr) => {
          if (curr.disabled) accu.push(curr.intentRef);
          return accu;
        }, []);

        const filteredDocs = docs.filter((doc: any) => doc._id !== "" && !intentsToHide.includes(doc._id));
        let intents = _.map(filteredDocs, (o) => {
          let [workspace, alias] = o._id.split(":");

          return {
            _id: `CMD_BTN ${alias}`,
            caption: alias,
          };
        });

        setIntents(intents);
      } else {
        store.Alert(ErrorX.Handle(rtn), "error");
      }
    } catch (e: any) {
      store.Alert(ErrorX.Handle(e), "error");
    }
  };

  const handleSubmit = async (data: any) => {
    try {
      const res = await backendConnector.post(DATA_LINK.SYSBillingEdit, data);
      if (res.Success) {
        store.Alert("System Billing updated successfully", "success");
      } else {
        store.Alert("System Billing update fails.", "error");
      }
    } catch (e: any) {
      store.Alert(ErrorX.Handle(e), "error");
    }
  };

  return <Formizo height="100%" schema={schema} buttons={["Revert", "Submit"]} onSubmit={handleSubmit} defaultValue={info} addOns={{ intents: intents }} />;
};

export { schema };
export default SysBilling;
