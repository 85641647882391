import { TextField } from "@mui/material";
import { Control, Controller, UseControllerProps, FieldValues, Path } from "react-hook-form";
import { HStack } from "../Layouts";
export interface ControlledTextFieldProps<TFieldValues extends FieldValues = FieldValues> {
  name: Path<TFieldValues>;
  control: Control<TFieldValues, any>;
  rules?: UseControllerProps["rules"];
  icon?: JSX.Element;
  [key: string]: any;
}

export function ControlledTextFieldWithIcon<T extends FieldValues = FieldValues>({ name, control, rules, ...props }: ControlledTextFieldProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => {
        // may throw error if the array of obj does not hv attribute alias
        if (Array.isArray(field.value)) {
          field.value = field.value.map((item: { alias: any }) => item?.alias).join(", ")
          console.log(field.value);
        }
        return (
          <HStack>
            <TextField {...field} {...props} error={!!error} helperText={error?.message} />
            {props.icon}
          </HStack>
        );
      }}
      rules={rules}
    />
  );
}
