import { ArrowForwardIos } from "@mui/icons-material";
import { Box, Button, Collapse, Fade } from "@mui/material";
import { useState, useEffect } from "react";
import { VStack } from "Components";
import { MessengerWithAvatar } from "../components/chat";

export function BaseHomePage() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 1000);
  }, []);

  return (
    <>
      <MessengerWithAvatar />
      <VStack
        sx={{
          //
          position: "fixed",
          height: "100vh",
          width: "100vw",
          borderRadius: "50px",
          fontSize: "1.5rem",
        }}
      >
        <Collapse in={showButton} orientation="horizontal" timeout={1000}>
          <Button
            variant="contained"
            sx={{
              width: "480px",
              borderRadius: "50px",
              fontSize: "1.5rem",
            }}
            onClick={() => {
              window.location.href = "/cms";
            }}
            endIcon={<ArrowForwardIos />}
          >
            Start Your Journey with SYVA
          </Button>
        </Collapse>
      </VStack>
    </>
  );
}
