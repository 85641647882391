import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Control, Controller, UseControllerProps, FieldValues, Path } from "react-hook-form";
import { HStack } from "../Layouts";

export interface ControlledSelectProps<TFieldValues extends FieldValues = FieldValues> {
  name: Path<TFieldValues>;
  control: Control<TFieldValues, any>;
  rules?: UseControllerProps["rules"];
  options: { label: string; value: any }[];
  defaultValue?: any;
  [key: string]: any;
}

export function ControlledSelectWithIcon<T extends FieldValues = FieldValues>({ name, control, rules, options, defaultValue, ...props }: ControlledSelectProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error }, formState }) => {
        return (
          <HStack>
            <FormControl variant="outlined" error={!!error} fullWidth={props.fullWidth}>
              <InputLabel id={`${name}-label`}>{props.label}</InputLabel>
              <Select {...field} labelId={`${name}-label`} id={`${name}-select`} label={props.label}>
                {options.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {props.icon}
          </HStack>
        );
      }}
      rules={rules}
    />
  );
}
