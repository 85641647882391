export class Analyzer {
  records: any[] = [];
  addRecord = (id: string, record: any) => {
    this.records.push({ _id: id, addOns: record });
  };

  findRecord = (msgId: string) => {
    let record = this.records.filter((e) => e?._id === msgId);
    if (record.length === 0) return;
    let rec = record[0];
    return rec;
  };
}
