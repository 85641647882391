import styles from "./ScoreCard.module.css";

import { HStack } from "Components/LabIZO/Stackizo";
import { ColorX } from "static";
import { HtmlParserWrapper } from "Components/HtmlParserWrapper";
import _ from "lodash";

export interface ScoreCardProps {
  title?: string | number;
  values?: string | number;
  remarks?: string | number;
}

export const ScoreCard: React.FC<ScoreCardProps> = ({ title, values, remarks }) => {
  return (
    <div className={styles.ScoreCard} style={{ background: ColorX.GetColorCSS("CardBG1") }}>
      <h3 className={styles.largeContent}>{values && <HtmlParserWrapper html={`${values ?? ""}`} />}</h3>
      <HStack width="100%">
        <p className={styles.title}>{title && <HtmlParserWrapper html={`${title ?? ""}`} />}</p>
        {remarks && <p className={styles.title} style={{ color: ColorX.GetColorCSS("Primary"), paddingLeft: 5 }}>{`  -  ${remarks}`}</p>}
      </HStack>
    </div>
  );
};
