import { FormizoCustom, FormizoSchema } from "Components/LabIZO/Formizo";
import { store } from "static";
import _ from "lodash";
import { IExportSchemaEntry } from "interfaces/be.interfaces/file-export-interface";
import { TABLE_SCHEMA, FILTERABLES } from "./";

const Info: FormizoCustom = (formValue: any, addOns: any, fLang: { display: string; value: string }) => [];

const Add: FormizoSchema[] = [];

const Edit = Info;

const Export: IExportSchemaEntry[] = [
  {
    Header: "FLA ID",
    accessor: "_id",
  },
  {
    Header: "Alias",
    accessor: "alias",
  },
  {
    Header: "Intent",
    accessor: "intentDoc",
  },
  {
    Header: "Default Message",
    accessor: "defaultMessage",
  },
  {
    Header: "Context",
    accessor: "contexts",
  },
  {
    Header: "Answers",
    accessor: "answers",
  },
  {
    Header: "API Integration",
    accessor: "integrations",
  },
];

const questionSchema = [
  {
    label: "FLA ID",
    name: "_id",
    format: "text",
    readOnly: true,
  },
  {
    label: "Alias",
    name: "alias",
    validate: ["required", "plain"],
    format: "text",
  },
  {
    label: "Description",
    name: `description`,
    format: "text",
  },
  {
    label: "Created At",
    name: "CREATED_AT",
    format: "text",
    readOnly: true,
  },
  {
    label: "Last Modified",
    name: "LASTMODIFIED",
    format: "text",
    readOnly: true,
  },
];

const Questions = {
  add: [
    ...questionSchema,
    {
      // TODO: lazy load the map function; otherwise store will not get the latest value
      tabs: store.sysInfo.Language.available.map((o) => {
        return {
          label: `${o.display}`,
          error: (formValue: any, addOns: any, { formError }: any) => {
            let errors = _.get(formError, `intentDoc.${o.value}.examples`) || [];
            errors = errors.filter((_e: any) => !_.isEmpty(_e));
            return errors.length > 0 ? "Required" : "";
          },
          page: () => {
            const defaultLang = store.sysInfo.Language.default;
            const minItems = defaultLang === o.value ? 1 : 0;
            return [
              {
                label: "Button Caption",
                name: `intentDoc.${o.value}.description`,
                format: "text",
              },
              {
                label: "Examples",
                name: `intentDoc.${o.value}.examples`,
                canAdd: true,
                canDelete: true,
                minItems,
                array: [
                  {
                    label: "",
                    name: "text",
                    format: "text",
                    validate: ["required"],
                  },
                ],
              },
            ];
          },
        };
      }),
    },
  ],
  info: [
    ...questionSchema,
    {
      tabs: store.sysInfo.Language.available.map((o) => {
        return {
          label: `${o.display}`,
          error: (formValue: any, addOns: any, { formError }: any) => {
            let examplesErrors = _.get(formError, `intentDoc.${o.value}.examples`) || [];
            const captionError = _.get(formError, `intentDoc.${o.value}.description`) || [];
            const errors = [...examplesErrors, ...captionError].filter((_e: any) => !_.isEmpty(_e));
            return errors.length > 0 ? "Required" : "";
          },
          page: () => {
            const defaultLang = store.sysInfo.Language.default;
            const minItems = defaultLang === o.value ? 1 : 0;
            return [
              {
                label: "Button Caption",
                name: `intentDoc.${o.value}.description`,
                format: "text",
                validate: ["required"],
              },
              {
                label: "Examples",
                name: `intentDoc.${o.value}.examples`,
                canAdd: true,
                canDelete: true,
                minItems,
                array: [
                  {
                    label: "",
                    name: "text",
                    format: "text",
                    validate: ["required"],
                  },
                ],
              },
            ];
          },
        };
      }),
    },
  ],
};
const Context: FormizoCustom = (formValue: any, addOns: any, fLang) => [
  {
    tabs: store.sysInfo.Language.available.map((o) => {
      return {
        label: `${o.display}`,
        page: [
          {
            label: "Question",
            name: `context.settings.name`,
            format: "text",
          },
          {
            label: "Context Description",
            name: `context.${o.value}.description`,
            format: "text",
          },
        ],
      };
    }),
  },
];

const Answers: FormizoCustom = (formValue: any, addOns: any, fLang) => [];

const Settings: FormizoSchema[] = [];

const schema = {
  Table: TABLE_SCHEMA,
  Info,
  Add,
  Edit,
  Export,
  Questions,
  Context,
  Answers,
  Settings,
  Filterables: FILTERABLES,
};

export default schema;
