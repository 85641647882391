import { Edit, FiberNew, NewReleases, WarningOutlined } from "@mui/icons-material";

import schema from "./schema";

import Tablizo from "Components/LabIZO/Tablizo";

const tabs = [
  {
    label: (data) => "Warnings (" + ((data.warn && data.warn.length) || 0) + ")",
    icon: <WarningOutlined />,
    render: (data) => <Tablizo width="100%" height="100%" schema={schema.Warnings} data={data.warn || []} rowIdAccessor="id" density="compact" showSelector={false} />,
  },
  {
    label: (data) => "New FAQs (" + ((data.newData && data.newData.length) || 0) + ")",
    icon: <FiberNew />,
    render: (data) => <Tablizo width="100%" height="100%" schema={schema.New} data={data.newData || []} rowIdAccessor="id" density="compact" showSelector={false} />,
  },
  {
    label: (data) => "Update FAQs (" + ((data.updateData && data.updateData.length) || 0) + ")",
    icon: <Edit />,
    render: (data) => <Tablizo width="100%" height="100%" schema={schema.Update} data={data.updateData || []} rowIdAccessor="id" density="compact" showSelector={false} />,
  },
  {
    label: (data) => "Errors (" + ((data.error && data.error.length) || 0) + ")",
    icon: <NewReleases />,
    render: (data) => <Tablizo width="100%" height="100%" schema={schema.Errors} data={data.error || []} rowIdAccessor="id" density="compact" showSelector={false} />,
  },
];

export default tabs;
