import { FormizoSchema } from "Components/LabIZO/Formizo";
import { QAMode } from "interfaces";
import { MenuMode } from "interfaces/db.interfaces/config.interfaces";

const initial: FormizoSchema[] = [
  {
    label: "Menu Mode",
    name: "MenuMode",
    format: "select",
    selectStyle: "dropdown",
    validate: ["required"],
    options: [
      { value: MenuMode.CLASSIC, label: "Classic" },
      { value: MenuMode.STARTER, label: "Starter" },
    ],
    defaultValue: MenuMode.CLASSIC,
  },

  {
    label: "Allowed QA Modes",
    name: "AllowedQAModes",
    format: "multiSelect",
    selectStyle: "autocomplete",
    optionType: "static",
    options: [
      { value: QAMode.INTENT_CLASSIFICATION, label: "Intent Classification" },
      { value: QAMode.LLM, label: "LLM" },
      { value: QAMode.HYBRID, label: "HYBRID" },
    ],
    placeholder: "QA Mode",
    validate: ["required"],
  },
  {
    label: "Initial Watsons also?",
    name: "initialwatsons",
    format: "bool",
    boolStyle: "checkbox",
    defaultValue: true,
  },
  {
    control: "initialwatsons",
    fold: [
      {
        label: "URL",
        name: "Watsons.URL",
        format: "text",
      },
      {
        label: "APIKEY",
        name: "Watsons.APIKEY",
        format: "text",
      },
      {
        label: "Version",
        name: "Watsons.VERSION",
        format: "text",
      },
      {
        label: "EN",
        name: "Watsons.EN",
        format: "text",
      },
      {
        label: "TC",
        name: "Watsons.TC",
        format: "text",
      },
      {
        label: "SC",
        name: "Watsons.SC",
        format: "text",
      },
    ],
  },
  {
    label: "Initialise Logger?",
    name: "initialLogger",
    format: "bool",
    boolStyle: "checkbox",
    defaultValue: true,
  },
  {
    control: "initialLogger",
    fold: [
      {
        label: "Logger Database",
        name: "Logger.type",
        format: "select",
        selectStyle: "dropdown",
        selectRef: "LoggerTypes",
        selectCap: "caption",
        selectVal: "_id",
        placeholder: "Database Type",
        validate: ["required"],
        options: [
          { value: "CouchDB", label: "BaseDB" },
          { value: "ElasticSearch", label: "ElasticSearch / OpenSearch" },
        ],
        defaultValue: "ElasticSearch",
      },
      {
        control: "Logger.type",
        controlFunc: (doc, field) => doc?.Logger?.type === "ElasticSearch",
        fold: [
          {
            label: "Username",
            name: "Logger.username",
            format: "text",
          },
          {
            label: "Password",
            name: "Logger.password",
            format: "text",
          },
          {
            label: "URL",
            name: "Logger.url",
            format: "text",
          },
          {
            label: "Conversation Log Index",
            name: "ConvDB.index",
            format: "text",
            defaultValue: "conv-log",
          },
          {
            label: "Request Log Index",
            name: "RequestLogger.index",
            format: "text",
            defaultValue: "request-log",
          },
          {
            label: "Audit Log Index",
            name: "AuditLogger.index",
            format: "text",
            defaultValue: "audit-log",
          },
          {
            label: "Data Event Index",
            name: "DataEventLogger.index",
            format: "text",
            defaultValue: "data-event-log",
          },
          {
            label: "Initialise GPT Conversation Logger?",
            name: "GPTQAConvDB.init",
            format: "bool",
            boolStyle: "checkbox",
            defaultValue: true,
          },
          {
            control: "GPTQAConvDB.init",
            fold: [
              {
                label: "GPT Conversation Log Index",
                name: "GPTQAConvDB.index",
                format: "text",
                defaultValue: "openai_conversation_log",
              },
            ],
          },
          {
            label: "Index Timeframe",
            name: "Logger.indexTimeframe",
            format: "select",
            selectStyle: "dropdown",
            selectRef: "IndexTimeframeTypes",
            selectCap: "caption",
            selectVal: "_id",
            placeholder: "Index Timeframe Type",
            validate: ["required"],
            defaultValue: "YYYYMM",
          },
        ],
      },
    ],
  },
  {
    label: "Initial GPT",
    name: "initialGptQa",
    format: "bool",
    defaultValue: false,
  },
  {
    control: "initialGptQa",
    fold: [
      {
        label: "Type",
        name: "GPTQA.type",
        format: "select",
        options: [
          { value: "openai", label: "OpenAI" },
          { value: "azure", label: "Azure" },
        ],
        defaultValue: "azure",
      },
      {
        label: "control",
        name: "GPTQA.type",
        controlFunc: (doc, field) => doc?.GPTQA?.type === "openai",
        fold: [
          { label: "APIKEY", name: "GPTQA.openai.apiKey", format: "text" },
          { label: "Embedding Model", name: "GPTQA.openai.embedding_model", format: "text", defaultValue: "text-embedding-ada-002" },
          { label: "Generation Model", name: "GPTQA.openai.generation_model", format: "text", defaultValue: "gpt-3.5-turbo" },
        ],
      },
      {
        label: "control",
        name: "GPTQA.type",
        controlFunc: (doc, field) => doc?.GPTQA?.type === "azure",
        fold: [
          { label: "APIKEY", name: "GPTQA.azure.apiKey", format: "text" },
          { label: "URL", name: "GPTQA.azure.url", format: "text" },
          { label: "Embedding Model", name: "GPTQA.azure.embedding_model", format: "text" },
          { label: "Generation Model", name: "GPTQA.azure.generation_model", format: "text" },
        ],
      },
    ],
  },
];
export const schema = {
  initial,
};
