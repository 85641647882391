import { Box, Button, Divider, Typography } from "@mui/material";
import _, { set } from "lodash";
import PropsType from "prop-types";
import React, { Component, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import ZCAvatar from "./ZCAvatar";
import "./ZChat.css";
import ZCInput from "./ZCInput/ZCInput";
import ZCMsg from "./ZCMsg/ZCMsg";
import ZCQReplies from "./ZCQReplies/ZCQReplies";
import { ZCContextPanel } from "./ZCContext";
import ZCMQuit from "./ZCMsg/ZCMQuit";
import { HStack, VStack } from "Components/Layouts";
import { defaultProps, defaultState } from "./constants";
import { IZChatProps, IZChatState } from "./interfaces";
import { AvailLANG } from "interfaces";
import { ZChatContext } from "../../hooks/messenger.context";

/**
 *
 * @augments {Component<Props, State>}
 */
function ZChat(props: IZChatProps) {
  const [state, setState] = useState<IZChatState>(defaultState);
  const [typing, setTyping] = useState<boolean>(false);
  const [messageEnd, setMessageEnd] = useState<HTMLDivElement | null>(null);
  const msgIDGen = v4;

  //add scroll to bottom
  useEffect(() => {
    if (messageEnd) {
      messageEnd.scrollIntoView(false);
    }
  }, [props.messages]);

  useEffect(() => {
    if (props.typing) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [props.typing]);

  const _setSomeState = (newState: any, callback?: any) => {
    setState((state) => {
      return { ...state, ...newState };
    });
  };

  const method = {
    _setDetect: (detect: any) => {
      _setSomeState({ detect });
    },

    _setLang: (lang: AvailLANG) => {
      _setSomeState({ responseLang: lang });
    },

    _setQuickReplies: (quickReplies: any[]) => {
      _setSomeState({
        quickReplies: quickReplies,
        inQR: quickReplies.length > 0,
      });
    },

    _setTypingDisabled: (b: boolean) => {
      _setSomeState({
        typingDisabled: b,
      });
    },

    _resetInput: () => {
      _setSomeState({
        input: undefined,
      });
    },

    _onQuickReply: (quickReply: any) => {
      let { user, onQuickReply, appendTextAfterSend } = props;

      let msg = {
        user: user,
        createdAt: new Date(),
        status: "sent",
        _id: msgIDGen(),
        msg: {
          text: quickReply.title,
        },
      };

      method._setTypingDisabled(true);
      method._resetInput();

      if (onQuickReply) {
        onQuickReply(quickReply, msg._id);
      }

      setTimeout(() => {
        method._setTypingDisabled(false);
      }, 100);
    },

    _onInputChange: (input: string) => {
      let { typingDisabled } = state;
      const { onInputChange } = props;
      if (typingDisabled) return;

      if (onInputChange) onInputChange(input);

      _setSomeState({
        input,
      });
    },

    renderMsgs: () => {
      const { messages } = props;
      const { cssp } = props;
      let { user, showInAvatar, showOutAvatar, avatarAtTop, hideSameAvatar, animated, showQuickRepliesAsButtons, disableButtonsAfterSend } = props;

      let rtn = [];
      rtn = _.map(messages, (o, i) => {
        let last = i === messages.length - 1;
        let pos = o.user._id === user._id ? "out" : "in";
        let lastItem = messages[i - 1];
        let hideImg = false;
        if (hideSameAvatar) {
          if (avatarAtTop) {
            let prevMsg = messages[i - 1];
            hideImg = prevMsg && prevMsg.user._id === o.user._id;
          } else {
            let nextMsg = messages[i + 1];
            hideImg = nextMsg && nextMsg.user._id === o.user._id;
          }
        }
        if (o.msg?.linkbreak) {
          return (
            <HStack>
              <Divider sx={{ width: "30%" }} />
              <Typography variant="body1" sx={{ color: "#E7EBF0" }}>
                {o.msg.text}
              </Typography>
              <Divider sx={{ width: "30%" }} />
            </HStack>
          );
        }
        return (
          <div className={cssp + " msgrow" + (avatarAtTop ? " attop" : "")} key={i}>
            {showInAvatar && pos === "in" && !o.msg.system && <ZCAvatar {...state} iuser={o.user} hideImg={hideImg} />}
            <ZCMsg
              {...state}
              key={i}
              cssp={cssp}
              message_id={o?._id ?? msgIDGen()}
              _onQuickReply={method._onQuickReply}
              pos={pos}
              item={o}
              last={last}
              onMsgPress={props.onMsgPress}
              onCardBTNPress={props.onCardBTNPress}
              lastItem={lastItem}
              showQuickRepliesAsButtons={showQuickRepliesAsButtons}
              disableButtonsAfterSend={disableButtonsAfterSend}
              bubbleColor={o.pipeline === "gptQa" ? "#B9EDDD" : "#ecf8f9"}
            />
            {showOutAvatar && pos === "out" && !o.msg.system && <ZCAvatar {...state} iuser={o.user} hideImg={hideImg} />}
          </div>
        );
      });

      return rtn;
    },

    renderMsgList: () => {
      let { inQR } = state;
      const { cssp } = props;

      const { showQuickRepliesStack } = props;
      let boxHeight = inQR ? "0px" : "50px";
      return (
        <div className={cssp + " msglist"}>
          {method.renderMsgs()}
          {/* Typing */}
          {typing ? <ZCMsg message_id={v4()} cssp={"zchat"} pos={"in"} last={false} typingBubbles={true} onMsgPress={props.onMsgPress} /> : null}
          {showQuickRepliesStack && method.renderQuickReplyStack()}
          <Box height={boxHeight} className={"bottom"} ref={(e: any) => setMessageEnd(e)} />
        </div>
      );
    },

    renderQuickReplyStack: () => {
      const { quickReplies } = props;
      const { showInAvatar } = props;
      if (!quickReplies || quickReplies.length === 0) return null;
      return (
        <HStack justifyContent="flex-start">
          {showInAvatar && <ZCAvatar {...state} hideImg={false} visibility={"hidden"} />}
          <VStack alignItems="flex-start" sx={{ margin: "0px 10px", height: "auto", width: "100%" }}>
            {quickReplies.map((_qr, i) => (
              <Button
                key={`suggested-button-${i}`}
                variant="outlined"
                onClick={() => method._onQuickReply(_qr)}
                sx={{ textTransform: "none", textAlign: "left", justifyContent: "flex-start", borderRadius: "15px", width: "100%" }}
              >
                {_qr.title}
              </Button>
            ))}
          </VStack>
        </HStack>
      );
    },

    renderQuickReplyBar: () => {
      const { cssp, quickReplies } = props;
      if (!quickReplies || quickReplies.length === 0) return null;
      return <ZCQReplies _onQuickReply={method._onQuickReply} disabled={false} {...{ cssp, quickReplies }} />;
    },

    renderInputBar: () => {
      const { customInputbar, onClearHistory } = props;
      const { cssp } = props;

      if (customInputbar != undefined) return customInputbar({ _onInputChange: method._onInputChange, _onSend: props.onSend });

      return (
        <HStack>
          <ZCMQuit {...{ onClearHistory, cssp }} />
          <ZCInput cssp={cssp} lang={"EN"} _onSend={props.onSend} />
        </HStack>
      );
    },

    renderContextBar: () => {
      const { responseLang } = state;
      const lang = (props.lang || responseLang) as AvailLANG;
      return <ZCContextPanel messages={props.messages} quickReplies={props.quickReplies} lang={lang} />;
    },
  };

  const { cssp } = props;

  return (
    <ZChatContext.Provider
      value={{
        typingDisabled: state.typingDisabled,
        inQR: state.inQR,
        messages: props.messages,
        quickReplies: props.quickReplies,
        typing: props.typing,
        detect: props.detect,
        responseLang: state.responseLang,
        input: state.input,
        sessionID: state.sessionID,
        feedbackUrl: props?.feedbackUrl,
        regenerateUrl: props?.regenerateUrl,
        //
        onSend: props.onSend,
        onQuickReply: props.onQuickReply,
        onMsgPress: props.onMsgPress,
        onClearHistory: props.onClearHistory,
        onSetMessages: props.onSetMessages,
        onContextUpdate: props.onContextUpdate,
        connector: props.connector,
      }}
    >
      <div className={cssp + " outermost"}>
        {method.renderMsgList()}
        {method.renderContextBar()}
        {props.quickReplyBar ? method.renderQuickReplyBar() : null}
        {!props.hideInputbar ? method.renderInputBar() : null}
      </div>
    </ZChatContext.Provider>
  );
}

ZChat.defaultProps = defaultProps;
export default ZChat;
