import { AddBox } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { ColorX } from "static";

interface IProps {
  onClick: () => void;
  disabled?: boolean;
  visible?: boolean;
  children?: React.ReactNode;
  startIcon?: JSX.Element;
  variant?: "text" | "outlined" | "contained";
}

export const AddButton = ({ visible = true, startIcon = <AddBox />, variant = "contained", ...props }: IProps) => {
  return (
    <Button sx={{ visibility: visible ? "visible" : "hidden" }} startIcon={startIcon} variant="contained" {...props}>
      {props.children ? props.children : "Add"}
    </Button>
  );
};
