import axios from "axios";
import { ThumbUp, ThumbDownAlt } from "@mui/icons-material";
import { DOMAIN } from "config/config";
import { DATA_LINK } from "connectors";
import { store } from "static";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { HStack } from "Components/Layouts";

interface IProps {
  cssp: string;
  lastItem?: boolean;
  defaultLike?: boolean | null;
  feedbackUrl: string;
  fetchData?: () => void;
  message_id: string;
}

const ZCFeedback = (props: IProps) => {
  const { cssp, defaultLike, feedbackUrl, fetchData, message_id } = props;
  const [likeClicked, setLikeClicked] = useState(defaultLike === true);
  const [dislikeClicked, setDislikeClicked] = useState(defaultLike === false);
  let payloadOut = {
    JWT: store.user.JWT,
    data: {
      messageID: message_id,
      like: false,
      feedback_by: store.user.Username,
      originalLike: defaultLike,
    },
  };

  const handleFeedback = async (like: boolean) => {
    if (!store.user.Username) {
      store.Alert("Please log in to like the answer", "error");
      return;
    }
    payloadOut.data = {
      ...payloadOut.data,
      like: like,
    };
    try {
      const res = await axios.post(feedbackUrl, payloadOut);
      if (res) {
        setLikeClicked(like);
        setDislikeClicked(!like);
        store.Alert(`You ${like ? "liked" : "disliked"} Answer`, "success");
        setTimeout(() => {
          fetchData && fetchData();
        }, 1000);
      }
    } catch {
      store.Alert("There are some error when giving feedback", "error");
    }
  };

  return (
    <HStack className={cssp + " msg-feedback"} sx={{ width: "60px" }}>
      <IconButton
        className="icon"
        onClick={() => {
          if (!likeClicked) {
            handleFeedback(true);
          }
        }}
        sx={{ width: "10%", marginRight: "12px" }}
      >
        <ThumbUp sx={{ fontSize: 16, color: likeClicked ? "#3d6eff" : "grey" }} />
      </IconButton>
      <IconButton
        className="icon"
        onClick={() => {
          if (!dislikeClicked) {
            handleFeedback(false);
          }
        }}
        sx={{ width: "10%" }}
      >
        <ThumbDownAlt sx={{ fontSize: 16, color: dislikeClicked ? "red" : "grey" }} />
      </IconButton>
    </HStack>
  );
};

export default ZCFeedback;
