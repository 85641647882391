import sanitizeHtml from "sanitize-html";
import htmlParser from "html-react-parser";
import { HTMLReactParserOptions } from "html-react-parser";
import domToReact from "html-react-parser/lib/dom-to-react";

function isHTMLStringValid(htmlString: string) {
  try {
    // make a stack for checking if the tags are balanced
    let stack: string[] = [];

    for (let i = 0; i < htmlString.length; i++) {
      if (htmlString[i] === "<") {
        if (stack.length !== 0) return false;
        stack.push(htmlString[i]);
      } else if (htmlString[i] === ">") {
        if (stack.length > 1 || stack.length === 0) return false;
        else if (stack.length === 1) {
          stack.pop();
        }
      }
    }
    return stack.length === 0;
  } catch (error) {
    return false;
  }
}

export function HtmlParserWrapper(html: { html: string }, options?: HTMLReactParserOptions) {
  let htmlString = html.html;

  if (isHTMLStringValid(html.html)) {
    htmlString = sanitizeHtml(html.html, {
      allowedAttributes: { "*": ["*"] },
      parseStyleAttributes: false,
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
    });
    return <>{htmlParser(htmlString, options ? options : {})}</>;
  } else {
    return <>{htmlString}</>;
  }
}

export function ColumnHtmlParser(html: { html: string }, options?: HTMLReactParserOptions): ReturnType<typeof domToReact> {
  let htmlString = html.html;

  if (isHTMLStringValid(html.html)) {
    htmlString = sanitizeHtml(html.html, {
      allowedAttributes: { "*": ["*"] },
      parseStyleAttributes: false,
      allowedTags: ["a", "b", "u", "i"],
    });
    return htmlParser(htmlString, options ? options : {});
  } else {
    return htmlString;
  }
}
