import { IWebsiteManagerPayload, WebsiteStatus, WebsiteURLType } from "../interfaces/website-manager-api.interface";
import { useState } from "react";
import { HStack, VStack } from "Components/Layouts";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { WebsiteManagerFormContext } from "./hooks/contexts-hook";
import { WebsiteInfo } from "./WebsiteInfo";
import { Button } from "@mui/material";
import { ErrorSummary } from "Pages/FLE/components";
import { DATA_LINK } from "../../../connectors/datalink";
import { ErrorX, store, Authority } from "static";
import { backendConnector } from "connectors/backend-connector";
import { v4 } from "uuid";
import _ from "lodash";

interface IProps {
  id: string;
  row?: IWebsiteManagerPayload;
  onQuit: () => void;
  onQuitRefresh: (_id: string) => void;
  renderFormizo: (schema: any, buttons: any, onSubmit: any) => JSX.Element;
  mode?: "Add" | "Edit" | "Info";
}
export default function WebsiteManagerForm(props: IProps) {
  const methods = useForm<IWebsiteManagerPayload>({ defaultValues: props.row, mode: "all" });
  const [errors, setErrors] = useState<FieldErrors<IWebsiteManagerPayload> | null>(null);

  const mode = props.mode || "Info";

  const handleSubmit = (quitForm: boolean = true) => {
    const submitStatus = {
      valid: async (_newRow: IWebsiteManagerPayload) => {
        setErrors(null);

        try {
          let url = props.mode == "Add" ? DATA_LINK.WebsiteAdd : DATA_LINK.WebsiteEdit;

          // if user does not enter alias, generate one
          if (props.mode == "Add") {
            _newRow._id = `website-${v4()}`;
            _newRow.status = WebsiteStatus.INDEXING;
            if (!_newRow.alias) {
              const timestamp = Date.now();
              _newRow.alias = `Website ${timestamp}`;
            }
          }

          const accessible = Authority.IsAccessibleQ(`GPTQA.WebsiteManager`, 999, "WebsiteStatus");
          _newRow.notice = accessible ? false : true;

          if (!_newRow.urlSetting.type) {
            _newRow.urlSetting.type = WebsiteURLType.EXPLICIT;
          }

          // check if it ends with "*" when implicit
          if (_newRow.urlSetting.type == WebsiteURLType.IMPLICIT && !_newRow.urlSetting.url.endsWith("/*")) {
            return store.Alert("Implicit (*) URL should end(s) with '/*'");
          } else if (_newRow.urlSetting.type == WebsiteURLType.EXPLICIT) {
            // check if it ends with "/"
            let urls: string[] = [];
            urls = _newRow.urlSetting.url.includes("\n") ? _newRow.urlSetting.url.split("\n") : [_newRow.urlSetting.url];
            const isValid = urls.every((url) => url.endsWith("/"));
            if (!isValid) {
              return store.Alert("Explicit URL(s) should end(s) with '/'");
            }
            // check any duplicated url
            const duplicated = _.uniq(urls).length !== urls.length;
            if (duplicated) {
              return store.Alert("There are duplicated explicit URLs.");
            }
          }

          const res = await backendConnector.post(url, _newRow);
          if (!res.Success) {
            const errorMsg = props.mode == "Add" ? "Website Add Failed:  " : "Website Edit Failed:  ";
            store.Alert(errorMsg + res.payload?.message, "error");
            return ErrorX.Handle(res.payload);
          }
          const successMsg = props.mode == "Add" ? "Website Added Successfully" : "Website Edited Successfully";
          store.Alert(successMsg, "success");
          if (quitForm) props.onQuitRefresh(_newRow._id);
        } catch (err: any) {
          ErrorX.Handle(err);
        }
        console.log(`Valid Submit: ${JSON.stringify(_newRow)}`);
      },
      invalid: (errors: FieldErrors<IWebsiteManagerPayload>) => {
        setErrors(errors);
      },
    };

    methods.handleSubmit(submitStatus.valid, submitStatus.invalid)();
  };

  return (
    <VStack sx={{ pt: 3, width: "100%" }}>
      {errors && <ErrorSummary errors={errors} />}
      <FormProvider {...methods}>
        <WebsiteManagerFormContext.Provider value={{ mode }}>
          <ButtonPannel mode={mode} onSubmit={handleSubmit} />
          <WebsiteInfo mode={mode} row={props.row} />
        </WebsiteManagerFormContext.Provider>
      </FormProvider>
    </VStack>
  );
}

const ButtonPannel = ({ mode, onSubmit }: { mode: "Add" | "Edit" | "Info"; onSubmit: (quitForm?: boolean) => void }) => {
  if (mode === "Info") return <></>;

  return (
    <HStack width={"100%"} justifyContent={"flex-end"} sx={{ position: "absolute", zIndex: 5, top: 40, right: 100 }}>
      <Button
        //
        variant="contained"
        color="secondary"
        onClick={() => {
          onSubmit(true);
        }}
      >
        Submit
      </Button>
    </HStack>
  );
};
