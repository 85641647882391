import { Box, Button, Typography } from "@mui/material";
import Datumizo from "Components/LabIZO/Datumizo";
import { ColorX } from "static";
import { observer } from "mobx-react-lite";
import { useState, useCallback } from "react";
import { HStack, VStack } from "Components/Layouts";
import { IDefaultProps } from "./defaultProps";

const KnowledgeGrpManagement: React.FC<IDefaultProps> = (props) => {
  const { title, base } = props;
  const [MountDatumizo, setMountDatumizo] = useState<any>(false);
  const [data, setData] = useState<any>(false);

  function onMountDatumizo(callback: any) {
    setMountDatumizo(callback);
  }

  async function onQuitInner() {
    await MountDatumizo?.Reload(true);
  }

  return (
    <VStack height="100%" justifyContent="flex-start">
      <Box padding={1} width="100%">
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 25,
            color: ColorX.GetColorCSS("Primary"),
          }}
        >
          {title}
        </Typography>
      </Box>

      <Datumizo
        //
        base={base}
        addOns={{ workspace: "master", data }}
        languageSelector={false}
        onMounted={onMountDatumizo}
        onQuitInner={onQuitInner}
        serverSidePagination={false}
        //onLoad={this._SetAllAutoSoftReload}
      >
      </Datumizo>
    </VStack>
  );
};

export default observer(KnowledgeGrpManagement);
