import { FormizoSchema } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import _ from "lodash";
import { Answer, QuickReply } from "../SysGeneralSetting/schema";
import { CancelPresentation } from "@mui/icons-material/";

const messages: FormizoSchema[] = [
  {
    label: "Terms & Conditions Messages",
    foldStyle: "outlined",
    defaultShow: true,
    collapse: [
      {
        label: "Terms and conditions",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:TnC_Message", "textarea"), QuickReply("master:TnC_Message")],
      },
      {
        label: "Confirm message",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [Answer("master:TnC_Confirmed"), QuickReply("master:TnC_Confirmed")],
      },
    ],
  },
];

const config: FormizoSchema[] = [
  {
    label: "Terms & Conditions Config",
    foldStyle: "outlined",
    defaultShow: true,
    collapse: [
      {
        label: "Wordings confirm T&C",
        name: "disclaimerConfig.acceptWord",
        format: "array",
        canAdd: true,
        canDelete: true,
        array: [
          {
            label: "",
            name: "",
            format: "text",
            validate: ["required"],
          },
        ],
      },
      {
        label: "Clear ALL Terms & Condition sessions",
        name: "clearTnCSession",
        format: "button",
        buttonColor: "red",
        tooltipTitle: "Clear",
        iconComponent: <CancelPresentation style={{ fontSize: "26px" }} />,
      },
    ],
  },
];

export const schema: FormizoSchema[] = [...config, ...messages];
