import { Typography, Button, Checkbox, FormControlLabel, FormGroup, Alert, TextField, Switch } from "@mui/material";
import Dropzone from "./Dropzone";
import { useState } from "react";
import axios from "axios";
import { HStack, VStack } from "Components";
import { store } from "static";
import { GPTQA_API_PATHS } from "./constants/api-paths";
import { FileUpload } from "@mui/icons-material";
import { Box } from "@mui/system";
import { backendConnector, DATA_LINK } from "connectors";
import { NotVoid } from "lodash";
interface IProps {
  onUpdate?: () => void;
  onUpload?: () => void;
  onQuitRefresh?: () => void;
}

export const PdfUploader = (props: IProps) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [fileTypes, setFileTypes] = useState<string[]>([]);
  const [refLabel, setRefLabel] = useState<string>("");
  const [refUrl, setRefUrl] = useState<string>("");
  const [ocr, setOcr] = useState(false);
  const [alert, setAlert] = useState<{ message: string; type: "success" | "error" | "info" }>();
  const [readByPage, setReadByPage] = useState(false);

  const handleUpload = async () => {
    if (uploadedFiles.length === 0) {
      setAlert({ message: "No files to upload", type: "error" });
    } else {
      setAlert({ message: "Uploading", type: "info" });

      for (let i = 0; i < uploadedFiles.length; i++) {
        const file = uploadedFiles[i];
        const fileName = fileNames[i];
        const fileType = fileTypes[i];

        try {
          const addOns = {
            ocr: ocr,
            filename: fileName.trim(),
            filetype: fileType,
            refLabel: refLabel,
            refUrl: refUrl,
            readByPage: readByPage,
          };
          const { Success } = await backendConnector.upload<any>(DATA_LINK.DMUpload, file, addOns);
          if (!Success) {
            setAlert({ message: "Upload Failed", type: "error" });
          }
        } catch (e: any) {
          console.error(e);
          setAlert({ message: JSON.stringify(e?.message ?? e), type: "error" });
        }
      }

      setAlert({ message: "Uploaded", type: "success" });
      if (props.onUpdate) props.onUpdate();
      if (props.onQuitRefresh) props.onQuitRefresh();
    }
  };

  const onDropError = (errorMsg: string) => {
    setAlert({ message: "Please check the file type", type: "error" });
  };

  const onDropFiles = (acceptedFiles: File[]) => {
    const files = acceptedFiles;
    const fileNames = files.map((file) => file.name.split(".").slice(0, -1).join("."));
    const fileTypes = files.map((file) => file.name.split(".").pop() || "");

    setFileNames(fileNames);
    setFileTypes(fileTypes);
    setUploadedFiles(files);
  };

  const renderDocSettingForOneFile = () => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      if (name === "fileName") {
        setFileNames([value]);
      } else if (name === "refLabel") {
        setRefLabel(value);
      } else if (name === "refUrl") {
        setRefUrl(value);
      }
    };
    return (
      <VStack width={"100%"}>
        <TextField fullWidth label="Document Name" name="fileName" onChange={handleChange} value={fileNames[0]} />
        <Typography variant="body1" color="primary.main" style={{ font: "normal normal normal 16px/21px Segoe UI", marginLeft: "50px", marginBottom: "10px" }}>
          Reference Settings
        </Typography>
        <TextField fullWidth label="Reference Button Label" name="refLabel" onChange={handleChange} value={refLabel} />
        <TextField fullWidth label="Reference URL" name="refUrl" onChange={handleChange} value={refUrl} />
      </VStack>
    );
  };

  const renderByPagesSetting = () => {
    return (
      <VStack>
        <FormControlLabel control={<Switch checked={readByPage} onChange={(e) => setReadByPage(e.target.checked)} />} label="Read By Pages" labelPlacement="start" />
        <Typography fontSize={"0.8rem"} color={"grey"} fontStyle={"italic"}>
          *** Only PDF and Word files can be read by Pages. ***
        </Typography>
        <Typography fontSize={"0.8rem"} color={"grey"} fontStyle={"italic"}>
          *** Word files will be read with page breaks. ***
        </Typography>
      </VStack>
    );
  };

  return (
    <VStack width={"100%"} height="100%">
      <Typography fontWeight={"bold"}>Upload a document to begin</Typography>
      <Dropzone multiple={true} accept={[".pdf", ".txt", ".doc", ".docx"]} onDrop={onDropFiles} onError={onDropError} />
      <Typography fontSize={"0.5rem"} color={"grey"} fontStyle={"italic"}>
        *** The recommended file size is less than 10MB ***
      </Typography>
      {uploadedFiles.length > 0 && renderByPagesSetting()}
      {uploadedFiles.length == 1 && renderDocSettingForOneFile()}
      {alert && (
        <Alert sx={{ width: "100%" }} severity={alert.type}>
          {alert.message}
        </Alert>
      )}
      <Box width={"100%"} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button disabled={uploadedFiles.length === 0} variant={"outlined"} onClick={handleUpload} startIcon={<FileUpload />}>
          Upload
        </Button>
      </Box>
    </VStack>
  );
};
