import React, { Component } from "react";
import { store } from "static";
import "./ZCInput.css";
import { AvailLANG } from "interfaces";
import { ZChatContext } from "Components/Messenger/hooks/messenger.context";
import { Box, InputBase, TextField } from "@mui/material";

interface IInputProps {
  cssp?: string;
  lang?: string;
  sendBtn?: () => JSX.Element;
  disabled?: boolean;
  _onSend?: (text: { text: string }) => void;
  pressEnterToSend?: boolean;
}

enum InputPlaceholders {
  EN = "Type a message...",
  TC = "輸入訊息...",
  SC = "输入讯息...",
}

function ZCInput(props: IInputProps) {
  const [input, setInput] = React.useState("");
  const defaultLang = store.sysInfo.Language.default ?? "EN";
  const inputPlaceHolder = InputPlaceholders[defaultLang as keyof typeof InputPlaceholders];

  const decorateInput = (input?: string): string => input?.trim().replace(/[\r\n]+/g, " ") || "";

  const sendMessage = ({ text }: { text?: string }) => {
    if (props?._onSend) props._onSend({ text: decorateInput(input) });
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      sendMessage({ text: decorateInput(input) });
      e.preventDefault();
      setInput("");
    }
  };

  const _onInputChange = (input: string) => {
    setInput((prev) => input);
  };

  const { cssp, lang, sendBtn } = props;

  const renderInputBox = () => {
    return (
      <Box className={cssp + " inputbox"}>
        <Box className={cssp + " inputfield " + lang}>
          <InputBase
            sx={{ width: "100%" }}
            fullWidth
            id="message-input-field"
            multiline
            onKeyDown={(e) => onKeyDown(e)}
            onChange={(e) => _onInputChange(e.target.value)}
            placeholder={inputPlaceHolder}
            value={input || ""}
            disabled={props.disabled}
            onInput={(e) => e.target}
          />
        </Box>
        <Box
          className={cssp + " inputsend"}
          onClick={() => {
            sendMessage({ text: input });
            setInput("");
          }}
        >
          {sendBtn ? sendBtn() : <SendButton />}
        </Box>
      </Box>
    );
  };

  return <Box className={cssp + " input"}>{renderInputBox()}</Box>;
}

function SendButton(props: { onClick?: () => void } = {}) {
  return <i className="fas fa-paper-plane" />;
}

export default ZCInput;
