import { Typography, Box, styled, Stack, Chip } from "@mui/material";
import { DataDiffType, DataEventLog } from "interfaces/db.interfaces/DataEventLog.interface";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import InfoIcon from "@mui/icons-material/Info";
import { ColorX } from "static";
import { DiffTable } from "../DiffTable/DiffTable";
import { InsertTable } from "../InsertTable/InsertTable";

const StyledDocChangeContainer = styled(Box)({
  display: "grid",
  gridAutoFlow: "row",
  border: "1px solid grey",
  backgroundColor: "grey",
  boxShadow: "5px 5px 5px grey;",
  borderRadius: "5px",
  gap: "1px",
  width: "100%",
  padding: "1px",
});

const StyledDocChangeTitle = styled(Stack)({
  flexDirection: "row",
  gap: "8px",
  justifyContent: "center",
  color: ColorX.GetColorCSS("Primary"),
  textTransform: "capitalize",
  backgroundColor: "#f9ffff",
  padding: "6px",
});

const StyledDocChangeContent = styled(Stack)({
  backgroundColor: "#f9ffff",
});

export const DocumentDataDiff: React.FC<{ dataEvent: DataEventLog }> = ({ dataEvent }) => {
  const getDocNameColor = (type: DataDiffType) => {
    switch (type) {
      case DataDiffType.CREATE_DOC:
        return ColorX.GetColorCSS("Insert");
      case DataDiffType.DELETE_DOC:
        return ColorX.GetColorCSS("Delete");
      default:
        return "inherit";
    }
  };

  const Explanation: React.FC<{}> = () => {
    const type = dataEvent.changes[0].type;
    if (type !== DataDiffType.CREATE_DOC && type !== DataDiffType.DELETE_DOC) {
      return <></>;
    }
    const label = type === DataDiffType.CREATE_DOC ? "This document was newly created" : "This document was deleted";
    return <Chip icon={<InfoIcon />} label={label} sx={{ mx: "10px" }} />;
  };

  const getTable = (type: DataDiffType) => {
    switch (type) {
      case DataDiffType.CREATE_DOC:
        return <InsertTable dataEvent={dataEvent} />;
      case DataDiffType.DELETE_DOC:
        return <></>;
      default:
        return <DiffTable dataEvent={dataEvent} />;
    }
  };

  return (
    <StyledDocChangeContainer className="doc-change-container">
      <StyledDocChangeTitle className="diff-title" fontWeight="700">
        <Typography fontWeight="700">{dataEvent.tableName}</Typography>
        <DoubleArrowIcon />
        <Typography fontWeight="700" sx={{ backgroundColor: getDocNameColor(dataEvent.changes[0]?.type) }}>
          {dataEvent.docName}
        </Typography>
      </StyledDocChangeTitle>
      <StyledDocChangeContent>
        <Explanation />
        {getTable(dataEvent.changes[0]?.type)}
      </StyledDocChangeContent>
    </StyledDocChangeContainer>
  );
};
