import { AvailLANG } from "..";

export enum INTENT_TYPE {
  FAQ = "faq",
  CONTEXT_ACTION = "context_action",
}
export interface Intent {
  _id: string;
  _rev?: string;
  domain?: string;
  name?: string;
  category?: any;
  subcategory?: any;
  type: INTENT_TYPE;
  description?: string;
  [AvailLANG.EN]: SkillIntent;
  [AvailLANG.TC]: SkillIntent;
  [AvailLANG.SC]: SkillIntent;
  trigger: TIntentTrigger;
  CREATED_AT?: string;
  LASTMODIFIED?: string;
}

export interface SkillIntent {
  intent?: string;
  examples: Example[];
  description?: string;
}

export interface Example {
  text: string;
}

export interface IntentUpdateParams {
  description?: string;
  examples: Example[];
}

export enum INTENT_TRIGGER_TYPE {
  CHANGE_INTENT = "__change",
  DIRECT_ANSWER = "__ans",
  DIRECT_TEMPLATE = "__tans",
  QMODULES = "__func",
  CONTEXT_ACTION = "__context_action",
}

interface IChangeIntentTrigger {
  type: INTENT_TRIGGER_TYPE.CHANGE_INTENT;
  __change: string;
}

interface IIntentContextActionTrigger {
  type: INTENT_TRIGGER_TYPE.CONTEXT_ACTION;
  __context_action: string;
}

interface IIntentDirectAnswerTrigger {
  type: INTENT_TRIGGER_TYPE.DIRECT_ANSWER;
  __ans: string;
}

interface IIntentDirectTemplateTrigger {
  type: INTENT_TRIGGER_TYPE.DIRECT_TEMPLATE;
  __tans: string;
}

interface IIntentFunctionTrigger {
  type: INTENT_TRIGGER_TYPE.QMODULES;
  __func: string;
  __param?: any;
}

export enum QRedirectToGPTSource {
  DOCUMENT = "document",
  KNOWLEDGE_GROUP = "knowledgeGroup",
}

export type TIntentTrigger = IChangeIntentTrigger | IIntentContextActionTrigger | IIntentDirectAnswerTrigger | IIntentDirectTemplateTrigger | IIntentFunctionTrigger;
