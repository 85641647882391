import React, { Component } from "react";

import PropsType from "prop-types";
import axios from "axios";
import { Box, Typography } from "@mui/material";

import schema from "./schema";
import datalink from "./datalink";
import { DOMAIN } from "config/config";

import Formizo from "Components/LabIZO/Formizo";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import { Accessor, store, ErrorX, ColorX } from "static";
import { StyledButton } from "Components/LabIZO/Stylizo";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} location={location} navigate={navigate} params={params} />;
  }
  return ComponentWithRouterProp;
}
class TrainCreate extends Component {
  static propTypes = {
    DateRange: PropsType.array,
  };

  static defaultProps = {
    DateRange: ["", ""],
  };

  constructor() {
    super();
    this.state = {
      steps: [
        {
          label: "Create Scenario",
        },
        {
          label: "Data Tagging",
        },
        {
          label: "Training",
        },
        {
          label: "Verification",
        },
      ],
      activeStep: 0,
    };
  }

  componentDidMount() {
    this._setAllStates(() => {
      this._getCount(this.props.DateRange);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(TrainCreate.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountStepizo = (callbacks) => {
    this.MountStepizo = callbacks;
  };

  onMountFormizo = (callbacks) => {
    this.MountFormizo = callbacks;
  };

  _onSubmit = async (formprops) => {
    let { onQuitRefresh } = this.props;
    let url = DOMAIN + datalink.Request.Add;
    let payloadOut = {
      JWT: store.user.JWT,
      data: {
        ...formprops,
      },
      addOns: {},
    };

    try {
      store.isLoading(true);
      let res = await axios.post(url, payloadOut);
      store.isLoading(false);
      console.log(datalink.Request.Add, res.data);

      let { Success, payload } = res.data;

      if (Success) {
        if (onQuitRefresh) {
          const trainingID = formprops.alias;
          onQuitRefresh(trainingID);
        } else {
          const { navigate } = this.props;
          debugger;
          navigate("/Training");
        }
      } else {
        ErrorX.Handle(payload);
      }
    } catch (e) {
      store.isLoading(false);
      ErrorX.Handle(e);
    }
  };

  _onChange = async (formValue, name, value) => {
    if (name === "DateRange") {
      this._getCount(value);
    }
  };

  _getCount = async (DateRange) => {
    let url = DOMAIN + datalink.Request.ConvlogDBInfo;
    let payload = {
      JWT: store.user.JWT,
      addOns: {
        DateRange,
      },
    };

    try {
      let res = await axios.post(url, payload);
      console.log(datalink.Request.ConvlogDBInfo, res.data);

      if (res.data.Success === true) {
        this.setState((state, props) => ({
          totalEntries: res.data.payload.doc_count,
        }));
      } else {
        ErrorX.Handle(res.data);
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  renderCreateButton() {
    return (
      <StyledButton
        key="next"
        onClick={() => {
          this.MountFormizo.Submit();
        }}
        theme={{
          color: "white",
          background: ColorX.GetColorCSS("Primary"),
          hover: {
            background: ColorX.GetColorCSS("Primary2"),
          },
          boxShadow: "transparent",
          borderRadius: "0px",
          width: "200px",
        }}
      >
        <HStack>
          <Typography>CREATE SCENARIO</Typography>
          <Spacer />
          <i className="fas fa-arrow-right" />
        </HStack>
      </StyledButton>
    );
  }

  renderTraining() {}

  renderTagging() {}

  renderCreate() {
    let { DateRange } = this.props;
    let { totalEntries } = this.state;
    return (
      <VStack justifyContent="flex-start">
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 20,
            fontFamily: "Segoe UI",
            color: ColorX.GetColorCSS("Primary"),
            margin: "5px 0",
          }}
        >
          CREATE NEW TRAINING SCENARIO
        </Typography>
        <HStack alignItems="flex-start" marginY={3}>
          <Formizo
            height="500px"
            width="600px"
            schema={schema.Training}
            buttons={[this.renderCreateButton()]}
            onMounted={this.onMountFormizo}
            onSubmit={this._onSubmit}
            onChange={this._onChange}
            fieldStyle="grid"
            labelJustify="flex-start"
            buttonAlign="right"
            onInvalid={() => {
              store.Alert("The data is invalid.", "warning");
            }}
            defaultValue={{
              DateRange,
            }}
            addOns={{
              totalEntries: totalEntries,
            }}
          />
        </HStack>
      </VStack>
    );
  }

  renderStep() {
    let { activeStep } = this.state;
    return (
      <Box>
        <Box display={activeStep === 0 ? undefined : "none"}>{this.renderCreate()}</Box>
        <Box display={activeStep === 1 ? undefined : "none"}>{this.renderTagging()}</Box>
        <Box display={activeStep === 2 ? undefined : "none"}>{this.renderTraining()}</Box>
      </Box>
    );
  }

  render() {
    return <VStack>{this.renderStep()}</VStack>;
  }
}

export default withRouter(TrainCreate);
