import { NativeSelect } from "@mui/material";
import { FormizoSchema } from "Components/LabIZO/Formizo";
import { ConditionOperator, CustomFilterValueProps, FieldType, Filterable, TablizoSchema } from "Components/LabIZO/Tablizo";
import _ from "lodash";
import { Accessor } from "static";

const Table: TablizoSchema[] = [
  {
    label: "Date",
    name: "recTime",
    filterable: true,
    defaultSort: "desc",
  },
  {
    label: "API Usage",
    name: "quantity",
    filterable: true,
    valueGetter: (row, field, addOns) => {
      let quantity = Accessor.Get(row, "quantity");
      return quantity !== undefined ? quantity : "";
    },
  },
];

const Info: FormizoSchema[] = [
  {
    label: "ID",
    name: "_id",
    validate: ["required"],
    format: "text",
    readOnly: true,
  },
  {
    label: "SessionID",
    name: "sessionID",
    format: "text",
    readOnly: true,
  },
  {
    label: "Datetime",
    name: "recTime",
    format: "text",
    readOnly: true,
  },
  {
    label: "Grade",
    name: "grade",
    format: "text",
    readOnly: true,
  },
  {
    label: "Reason",
    name: "reason",
    format: "text",
    readOnly: true,
  },
  {
    label: "Irrelevant",
    name: "irrelevant",
    format: "text",
    readOnly: true,
  },
  {
    label: "Incomplete",
    name: "incomplete",
    format: "text",
    readOnly: true,
  },
  {
    label: "Other Answer",
    name: "otherAnswer",
    format: "text",
    readOnly: true,
  },
  {
    label: "Selection",
    name: "selection",
    format: "text",
    readOnly: true,
  },
];

const Filterables: Filterable[] = [
  // {
  //   field: "language",
  //   label: "Language",
  //   type: FieldType.text,
  // },
  // {
  //   field: "FAQ ID",
  //   label: "FAQ_id",
  //   type: FieldType.text,
  // },
];

const Export = [
  {
    label: "Date",
    name: "recTime",
  },
  {
    label: "API Usage",
    name: "quantity",
  },
];

const AdvFilter: any = [];

const schema: any = {
  Table,
  Info,
  Export,
  // AdvFilter,
  // Filterables,
};

export default schema;
