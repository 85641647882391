import { backendConnector, DATA_LINK } from "connectors";
import { useState, useEffect } from "react";

export function useText(_docId: string) {
  const [text, setText] = useState<string | undefined>("");
  useEffect(() => {
    async function getText() {
      const res = await backendConnector.post<{ text: string }>(DATA_LINK.DMGetContext, { _id: _docId });
      setText(res.payload.text);
    }
    getText();
  }, []);
  return { text, setText };
}
