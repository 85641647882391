import React, { Component } from "react";
import PropsType from "prop-types";

import ZCTouch from "../ZCTouch";
import "./ZCMsg.css";

class ZCMHeader extends Component {
  static propTypes = {
    sender: PropsType.object,
  };

  static defaultProps = {
    sender: {},
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    let { cssp, sender, onHeaderClick } = this.state;
    return (
      <ZCTouch cssPrefix={cssp} onPress={() => onHeaderClick(sender)}>
        <div className={cssp + " msg-header"}>{sender.name}</div>
      </ZCTouch>
    );
  }
}

export default ZCMHeader;
