import { Grid, Typography, Switch } from "@mui/material";
import { DBAnswerSpec } from "interfaces";
import { store } from "static";
import { ContextId } from "Pages/FLE/components";
import { useFormContext } from "react-hook-form";
import { ContextTypeTypography } from "../../../components/ContextTypeTypography";
import { IFLEDoc, TFLEContext } from "interfaces/db.interfaces/fle.interface";
import React, { useEffect, useState } from "react";

interface IContextSummaryProps {
  index: number;
  name: `contexts.${number}`;
  canDelete: boolean;
}
export const ContextSummary = ({ index, ...props }: IContextSummaryProps) => {
  const defaultLang = store.sysInfo.Language.default || "EN";
  const { watch, setValue } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  const context = watch(props.name) as TFLEContext;
  const [required, setRequired] = useState(context.required ?? true);

  useEffect(() => {
    if (context.required !== undefined) {
      setRequired(context.required);
    }
  }, [context.required]);

  const handleOptionalChange = (event: any) => {
    const newvalue = event.target.checked;
    setRequired(newvalue);
    setValue(`contexts.${index}.required`, newvalue);
  };
  return (
    <Grid container p={2} item xs={11}>
      <Grid item xs={1}>
        <Switch name={`contexts.${index}.optional`} checked={required} onClick={(event) => event.stopPropagation()} onChange={handleOptionalChange} />
      </Grid>
      <Grid item xs={2}>
        <ContextId name={`contexts.${index}.alias.id`} />
      </Grid>

      <Grid item xs={1}>
        <ContextTypeTypography>{context.type}</ContextTypeTypography>
      </Grid>

      {context.type === "system" && (
        <Grid item xs={2}>
          <ContextTypeTypography>{context.setting.type}</ContextTypeTypography>
        </Grid>
      )}

      {context.type === "entity" && (
        <Grid item xs={2}>
          <Typography sx={{ textAlign: "center" }}>{context.setting.values.map((o) => o.entity).join(", ")}</Typography>
        </Grid>
      )}

      <Grid item xs={6}>
        <Typography sx={{ textAlign: "center" }}>{context.question[defaultLang]}</Typography>
      </Grid>
    </Grid>
  );
};
