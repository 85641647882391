const Request = {
  ConvlogDBInfo: "/Tables/Convlog/Info",
  DBInfo: "/Tables/Training/Info",
  List: "/Tables/Training/List",
  Get: "/Tables/Training/Get",
  Add: "/Tables/Training/Add",
  Delete: "/Tables/Training/Delete",
  Export: "/Tables/Training/Export",
  Train: "/Tables/Training/Train",
  UpdateSummary: "/Tables/Training/UpdateSummary",
};

const TBank = {
  DBInfo: "/Tables/TrainingBank/Info",
  List: "/Tables/TrainingBank/List",
  Get: "/Tables/TrainingBank/Get",
  Add: "/Tables/TrainingBank/Add",
  Delete: "/Tables/TrainingBank/Delete",
  Edit: "/Tables/TrainingBank/Edit",
  Export: "/Tables/TrainingBank/Export",
  Accept: "/Tables/TrainingBank/Accept",
  NewIntent: "/Tables/TrainingBank/NewIntent",
  SuggestedIntent: "/Tables/TrainingBank/SuggestedIntent",
};

let exports = {
  Request,
  TBank,
};

export default exports;
