import { Paper, styled } from "@mui/material";

export const StyledChatInterface = styled(Paper)({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  padding: "20px",
});
