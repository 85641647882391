import { FormizoSchema } from "Components/LabIZO/Formizo";
import { CusAuth } from "interfaces";

const schema: FormizoSchema[] = [
  {
    label: "Default Language",
    name: "Language.default",
    format: "select",
    selectStyle: "dropdown",
    selectRef: "lang",
    selectCap: "caption",
    selectVal: "value",
  },
];

export default schema;
