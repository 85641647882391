import { NativeSelect, Paper, Typography } from "@mui/material";
import { FormizoCustom } from "Components/LabIZO/Formizo";
import { CustomFilterValueProps, FieldType, Filterable, TablizoSchema } from "Components/LabIZO/Tablizo";
import _ from "lodash";
import { Comparison } from "interfaces/db.interfaces/arena.interface";

const Table: TablizoSchema[] = [
  {
    label: "Receive Time",
    name: "recTime",
    type: "dateTime",
    transform: "datetime",
    width: 180,
    filterable: true,
    defaultSort: "desc",
  },
  {
    label: "Input",
    name: "input",
    filterable: true,
    defaultSort: "desc",
    width: 400,
  },
  {
    label: "Model1",
    name: "llm1.id",
    filterable: true,
    defaultSort: "desc",
    Cell: (row, field, addOns) => {
      const compare = row.comparison;
      return compare == Comparison.MODEL1 ? (
        <Paper sx={{ px: 1 }}>
          <Typography sx={{ fontWeight: "bold", color: "primary.main" }}>{row.llm1.id}</Typography>
        </Paper>
      ) : (
        <Typography sx={{}}>{row.llm1.id}</Typography>
      );
    },
  },
  {
    label: "Result",
    name: "comparison",
    filterable: true,
    defaultSort: "desc",
  },
  {
    label: "Model2",
    name: "llm2.id",
    filterable: true,
    defaultSort: "desc",
    Cell: (row, field, addOns) => {
      const compare = row.comparison;
      return compare == Comparison.MODEL2 ? (
        <Paper sx={{ px: 1 }}>
          <Typography sx={{ fontWeight: "bold", color: "primary.main" }}>{row.llm2.id}</Typography>
        </Paper>
      ) : (
        <Typography sx={{}}>{row.llm2.id}</Typography>
      );
    },
  },
];

const Info: FormizoCustom = (formValue, addOns, fLang: { display: string; value: string }) => [
  {
    label: "ID",
    name: `"_id"`,
    format: "text",
    readOnly: true,
  },
  {
    label: "Receive Time",
    name: "recTime",
    format: "text",
    readOnly: true,
  },
  {
    label: "Input",
    name: "input",
    format: "text",
    readOnly: true,
  },
  {
    label: "Model1 (Left)",
    name: "llm1.id",
    format: "text",
    readOnly: true,
  },
  {
    label: "Model2 (Right)",
    name: "llm2.id",
    format: "text",
    readOnly: true,
  },
  {
    label: "Result",
    name: "comparison",
    format: "text",
    readOnly: true,
  },
];

const Filterables: Filterable[] = [
  {
    field: "input",
    label: "Input",
    type: FieldType.text,
  },
  {
    field: "llm1.id",
    label: "Model1",
    type: FieldType.text,
  },
  {
    field: "comparison",
    label: "Result",
    type: FieldType.select,
    CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => {
      return (
        <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
          <option value={""}></option>
          <option value={Comparison.MODEL1}>{Comparison.MODEL1}</option>
          <option value={Comparison.MODEL2}>{Comparison.MODEL2}</option>
          <option value={Comparison.BothGood}>{Comparison.BothGood}</option>
          <option value={Comparison.BothBad}>{Comparison.BothBad}</option>
        </NativeSelect>
      );
    },
  },
  {
    field: "llm2.id",
    label: "Model2",
    type: FieldType.text,
  },
];

const Export = [
  {
    Header: "ID",
    accessor: "_id",
  },
  {
    Header: "User Display Name",
    accessor: "userDisplayName",
  },
  {
    Header: "Subcat",
    accessor: "subcat",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "URL",
    accessor: "url",
  },
  {
    Header: "Receive time",
    accessor: "recTime",
  },
  {
    Header: "Host",
    accessor: "host",
  },
  {
    Header: "Origin",
    accessor: "origin",
  },
  {
    Header: "Referrer",
    accessor: "referrer",
  },
  {
    Header: "IP",
    accessor: "ip",
  },
  {
    Header: "Request Body",
    accessor: "body",
  },
];

const AdvFilter: any = [];

const schema: any = {
  Table,
  Info,
  Export,
  AdvFilter,
  Filterables,
};

export default schema;
