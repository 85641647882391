import React, { Component } from "react";

import { observer } from "mobx-react";
import PropsType from "prop-types";
import _ from "lodash";
import axios from "axios";
import { Box, Typography } from "@mui/material";

import schema from "./tbschema";
import datalink from "../datalink";
import Summary from "./Summary/Summary";
import { DOMAIN } from "config/config";

import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import { Accessor, ColorX, ErrorX, store } from "static";
import { HStack, VStack } from "Components/LabIZO/Stackizo";
import moment from "moment";

class TrainTagging extends Component {
  static propTypes = {
    docID: PropsType.string,
    doc: PropsType.object,
    onQuit: PropsType.func,
    onQuitRefresh: PropsType.func,
    addOns: PropsType.object,
  };

  static defaultProps = {
    docID: "",
    doc: {},
    onQuit: () => {},
    onQuitRefresh: () => {},
    addOns: {},
  };

  constructor() {
    super();
    this.state = {
      title: "",
      serverSidePagination: false,
      intents: [],
      base: {
        title: "",
        exportDoc: "",
        schema: schema,
        reqAuth: "",

        tablizo: {
          showSelector: false,
          columnsToolbar: false,
          filterToolbar: false,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          DBInfo: datalink.TBank.DBInfo,
          List: datalink.TBank.List,
          Get: datalink.TBank.Get,
          schema: schema.Table,
        },

        operations: {
          Edit: {
            title: "Edit TrainTagging ",
            url: datalink.TBank.Edit,
            success: "TrainTagging Edited Successfully",
            fail: "TrainTagging Edit Failed: ",
            schema: schema.Edit,
            buttons: ["Revert", "Submit"],
            onSubmit: "Edit",
          },

          Info: {
            title: "TrainTagging ",
            url: datalink.TBank.Info,
            success: "TrainTagging Load Successfully",
            fail: "TrainTagging Load Failed: ",
            schema: schema.Info,
          },
        },

        buttons: {
          inline: [],
          left: [
            {
              inject: (tableData, addOns) => this.renderLogDetails(addOns),
            },
          ],
          right: [
            {
              inject: (tableData) => (
                <HStack width={200} key="inject">
                  <Summary data={tableData} />
                </HStack>
              ),
            },
            {
              icon: "",
              func: this._StartTraining,
              caption: "Proceed to Training",
              reqFunc: "Train",
              theme: {
                label: "white",
                background: "#1976d2",
                hover: {
                  background: "#115293",
                },
              },
            },
          ],
        },
      },
    };
  }

  componentDidMount() {
    this._setAllStates(() => {
      this.getAllIntents();
      this.getAllAnswers();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(TrainTagging.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  _StartTraining = async () => {
    let { doc, onQuitRefresh } = this.props;
    console.log("_StartTraining", doc);
    let url = DOMAIN + datalink.Request.Train;
    let payload = {
      JWT: store.user.JWT,
      data: doc,
    };

    store.isLoading(true);
    let res = await axios.post(url, payload);
    store.isLoading(false);

    if (res.data.Success === false) {
      store.Alert(res.data?.payload, "error");
    }
    if (onQuitRefresh) onQuitRefresh(doc._id);
  };

  onMountDatumizo = (callbacks) => {
    this.MountDatumizo = callbacks;
  };

  getAllIntents = async () => {
    let url = DOMAIN + "/Tables/Intent/List";
    let payload = {
      JWT: store.user.JWT,
      data: {
        skip: 0,
        limit: 9999,
        fields: ["_id"],
      },
      addOns: {
        workspace: "ALL",
        allIntents: true,
      },
    };
    try {
      let rtn = await axios.post(url, payload);
      console.log("/Tables/Intent/List", rtn.data);

      if (rtn.data.Success === true) {
        let docs = rtn.data.payload.docs;
        let intents = _.map(docs, (o, i) => {
          let splited = o._id.split(":");
          let caption = o._id;
          if (splited.length === 2) {
            caption = splited[1];
          }
          return {
            _id: o._id,
            caption: caption,
          };
        });
        intents = [...intents];
        console.log(intents);
        this.setState((state, props) => ({
          intents: intents,
        }));
      } else {
        ErrorX.Handle(rtn.data);
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  getAllAnswers = async () => {
    let url = DOMAIN + "/Tables/Answer/List";
    let payload = {
      JWT: store.user.JWT,
      data: {
        skip: 0,
        limit: 9999,
      },
      addOns: {
        workspace: "ALL",
        allAnswers: true,
      },
    };
    try {
      let rtn = await axios.post(url, payload);
      console.log("/Tables/Answer/List", rtn.data);

      if (rtn.data.Success === true) {
        let docs = rtn.data.payload.docs;
        console.log(docs);
        this.setState((state, props) => ({
          answers: docs,
        }));
      } else {
        store.Alert(ErrorX.Handle(rtn.data), "error");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  _onPassToggle = async (id, f) => {
    console.log(id, f);
    let { docID } = this.props;
    let url = DOMAIN + datalink.TBank.Accept;

    let payload = {
      data: {
        logID: id,
        trainID: docID,
        value: f,
        mode: "Before",
      },
      JWT: store.user.JWT,
    };

    try {
      console.log(datalink.TBank.Accept, payload);

      let res = await axios.post(url, payload);

      console.log(datalink.TBank.Accept, res.data);

      if (res.data.Success === true) {
        store.Alert("Record Updated", "success");
        this.MountDatumizo.SoftReload(id);
      } else {
        store.Alert("Record Update Failed", "error");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  _onNewToggle = async (id, f) => {
    let { docID } = this.props;
    let url = DOMAIN + datalink.TBank.NewIntent;

    let payload = {
      data: {
        logID: id,
        trainID: docID,
        value: f,
        mode: "Before",
      },
      JWT: store.user.JWT,
    };

    try {
      console.log(datalink.TBank.NewIntent, payload);

      let res = await axios.post(url, payload);

      console.log(datalink.TBank.NewIntent, res.data);

      if (res.data.Success === true) {
        store.Alert("Record Updated", "success");
        this.MountDatumizo.SoftReload(id);
      } else {
        store.Alert("Record Update Failed", "error");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  _onSuggestIntent = async (id, intent) => {
    let { docID } = this.props;
    let url = DOMAIN + datalink.TBank.SuggestedIntent;

    let payload = {
      data: {
        logID: id,
        trainID: docID,
        value: intent,
        mode: "Before",
      },
      JWT: store.user.JWT,
    };

    try {
      console.log(datalink.TBank.SuggestedIntent, payload);

      let res = await axios.post(url, payload);

      console.log(datalink.TBank.SuggestedIntent, res.data);

      if (res.data.Success === true) {
        store.Alert("Record Updated", "success");
        this.MountDatumizo.SoftReload(id);
      } else {
        store.Alert("Record Update Failed", "error");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  renderLogDetails(addOns) {
    if (!addOns) return;
    let { doc } = addOns;
    if (!doc) return;
    return (
      <HStack key="details" justifyContent="flex-start" gap={50} width="fit-content">
        <Typography
          style={{
            textAlign: "left",
            fontSize: 16,
            color: ColorX.GetColorCSS("dark"),
          }}
        >
          {doc.logPeriod && "Log Period: " + moment(doc.logPeriod.from).format("DD/MM/YYYY") + " - " + moment(doc.logPeriod.to).format("DD/MM/YYYY")}
        </Typography>
        <Typography
          style={{
            textAlign: "left",
            fontSize: 16,
            color: ColorX.GetColorCSS("dark"),
          }}
        >
          {"Sample Size: " + (doc.sampleSize * 100 + "%")}
        </Typography>
      </HStack>
    );
  }

  renderAlias() {
    let { doc } = this.props;
    return (
      <HStack justifyContent="flex-start">
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            minWidth: 500,
            fontSize: 25,
            color: ColorX.GetColorCSS("Primary"),
          }}
        >
          {doc.alias}
        </Typography>
      </HStack>
    );
  }

  render() {
    let { base, serverSidePagination, addOns, intents, answers } = this.state;
    let { docID, doc } = this.props;
    let ModaddOns = {
      ...addOns,
      doc: doc,
      trainID: docID,
      onPassToggle: this._onPassToggle,
      onNewToggle: this._onNewToggle,
      onSuggestIntent: this._onSuggestIntent,
      intents: intents,
      answers: answers,
    };
    return (
      <VStack width="100%">
        <Box padding={1} width="100%">
          <VStack alignItems="flex-start">
            {this.renderAlias()}
            {this.renderLogDetails()}
          </VStack>
        </Box>
        <Datumizo
          base={base}
          onMounted={this.onMountDatumizo}
          serverSidePagination={serverSidePagination}
          refreshOnPageChange={false}
          addOns={ModaddOns}
          onDataChange={this.onDataChange}
          languageSelector={false}
        />
      </VStack>
    );
  }
}

export default observer(TrainTagging);
