import { FormControl, SelectChangeEvent, MenuItem, Select, TextField } from "@mui/material";
import { DBAnswerSpec, QuickReply } from "interfaces";
import { useContext } from "react";
import { FLEFormContext } from "Pages/FLE/hooks/FLEContexts";
import _ from "lodash";
import { useEffect, useState } from "react";
import { AddButton, BodyTitle, DeleteButton, MultiLangTabs } from "Components";
import { HStack, VStack } from "Components/Layouts";
import { IFLEDoc } from "interfaces/db.interfaces/fle.interface";
import { useFormContext } from "react-hook-form";
import { backendConnector, DATA_LINK } from "connectors";
import { store, ErrorX } from "static";

type Language = "EN" | "TC" | "SC";
interface IQRBProps {
  _id: string;
  caption: string;
}
interface IAnsQRBTextFieldProps {
  quickReplyValue: QuickReply[] | undefined;
  handleQRChange: (quickReply: QuickReply[]) => void;
}
export const AnsQRBTextField = ({ handleQRChange, quickReplyValue }: IAnsQRBTextFieldProps) => {
  const [buttonPayload, setButtonPayload] = useState<IQRBProps[]>([]);
  const [quickReply, setQuickReplyValue] = useState<QuickReply[] | undefined>(quickReplyValue);
  const { setError, clearErrors } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  const [isEmpty, setIsEmpty] = useState<{ title: boolean; payload: boolean }[]>([]);
  const defaultLang = store.sysInfo.Language.default;
  const formCtxt = useContext(FLEFormContext);
  const disable = formCtxt?.mode == "Info";
  useEffect(() => {
    getQuickReplies();
  }, []);
  const getQuickReplies = async () => {
    const data = {
      skip: 0,
      limit: 9999,
      fields: ["_id"],
    };
    const addOns = {
      workspace: "ALL",
      allIntents: true,
    };
    try {
      const { payload } = await backendConnector.post(DATA_LINK.IntentList, data, addOns);

      let intents = _.map(payload.docs, (o, i) => {
        let [workspace, alias] = o._id.split(":");

        return {
          _id: `CMD_BTN ${alias}`,
          caption: alias,
        };
      });
      intents = _.filter(intents, (o) => o._id !== "");
      intents = [...intents];
      setButtonPayload(intents);
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };

  const isLanguage = (input: string): input is Language => {
    return input === "EN" || input === "TC" || input === "SC";
  };

  const checkError = (index: number) => {
    if (isLanguage(defaultLang) && quickReply && quickReply?.length > 0) {
      const isTitleEmpty = quickReply[index].title[defaultLang] === "" || quickReply[index].title[defaultLang] === undefined;
      const isPayloadEmpty = quickReply[index].payload === "" || quickReply[index].payload === undefined;
      setIsEmpty((prevState) => {
        const updatedIsEmpty = [...prevState];
        updatedIsEmpty[index] = { title: isTitleEmpty, payload: isPayloadEmpty };
        return updatedIsEmpty;
      });
      if (isTitleEmpty || isPayloadEmpty) {
        setError("answers", { type: "manual", message: `Answer Page Setting` });
      } else {
        clearErrors("answers");
      }
    }
  };
  const renderDeleteButton = (index: number) => {
    return (
      <DeleteButton
        onClick={() => {
          setQuickReplyValue((prevQuickReply) => {
            const newQuickReply = prevQuickReply ? [...prevQuickReply] : [];
            newQuickReply.splice(index, 1);
            handleQRChange(newQuickReply);
            return newQuickReply;
          });
          checkError(index);
        }}
      />
    );
  };

  const renderAddButton = () => {
    return (
      <AddButton
        onClick={() => {
          setQuickReplyValue((prevQuickReply) => {
            const newQuickReply = prevQuickReply ? [...prevQuickReply] : [];
            newQuickReply.push({ title: {}, payload: "" });
            return newQuickReply;
          });
          checkError((quickReply && quickReply?.length - 1) || 0);
        }}
      />
    );
  };

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, lang: Language, index: number) => {
    const text = e.target.value;

    setQuickReplyValue((prevQuickReply) => {
      const newQuickReply = prevQuickReply ? [...prevQuickReply] : [];
      if (newQuickReply[index]) {
        newQuickReply[index].title[lang] = text;
      } else {
        newQuickReply[index] = {
          title: { [lang]: text },
          payload: "",
        };
      }
      checkError(index);
      handleQRChange(newQuickReply);
      return newQuickReply;
    });
  };

  const handleSelectChange = (e: SelectChangeEvent, index: number) => {
    const select = e.target.value;

    setQuickReplyValue((prevQuickReply) => {
      const newQuickReply = prevQuickReply ? [...prevQuickReply] : [];
      if (newQuickReply[index]) {
        newQuickReply[index].payload = select;
      } else {
        newQuickReply[index] = {
          title: {},
          payload: select,
        };
      }
      checkError(index);
      handleQRChange(newQuickReply);
      return newQuickReply;
    });
  };

  const renderTextField = (lang: Language, index: number) => {
    const value = quickReply ? quickReply[index]?.title : "";
    const langValue = value[lang as keyof typeof value];
    return <TextField defaultValue={langValue} disabled={disable} fullWidth label="Caption" error={isEmpty[index]?.title} onChange={(e) => handleTextFieldChange(e, lang, index)} />;
  };

  const renderSelect = (index: number) => {
    const value = quickReply ? quickReply[index]?.payload : "";
    return (
      <FormControl fullWidth>
        <Select required error={isEmpty[index]?.payload} id={`quick-reply-select-${index}`} defaultValue={value ? value : ""} onChange={(e) => handleSelectChange(e, index)} disabled={disable}>
          {buttonPayload.map((o, i) => {
            return (
              <MenuItem key={i} value={o._id}>
                {o.caption}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  return (
    <VStack width="100%">
      <HStack width="100%">
        <BodyTitle sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Quick Reply Button Answers</BodyTitle>
        {!disable && renderAddButton()}
      </HStack>
      {quickReply?.map((_, index) => (
        <HStack width="100%">
          <MultiLangTabs>
            {(_lang, _index) => {
              if (isLanguage(_lang)) {
                return renderTextField(_lang, index);
              } else {
                throw new Error(`Invalid language: ${_lang}`);
              }
            }}
          </MultiLangTabs>
          {renderSelect(index)}
          {!disable && renderDeleteButton(index)}
        </HStack>
      ))}
    </VStack>
  );
};
