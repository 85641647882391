import { AddBox } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";

import { DropResult } from "react-beautiful-dnd";
import { v4, v5 } from "uuid";
import { DragAndDrop } from "Components/DragAndDrop/DragAndDrop";
import { HStack, VStack } from "Components/Layouts";
import { IFLEDoc, MultiLangText, IFLEAnswer, TFLEContext, TSystemContextType, IDefaultMessage } from "interfaces/db.interfaces/fle.interface";

import { ContextCard } from "../ContextSettings/ContextCard";
import { useFormContext } from "react-hook-form";
import { useEffect, useState, useContext } from "react";
import { FLEFormContext } from "Pages/FLE/hooks/FLEContexts";
import { store } from "static";
import _ from "lodash";
import { useEntities } from "Pages/FLE/hooks/useEntities";
import { DefaultMsgCard } from "../ContextSettings/DefaultMsgCard";
import { DBAnswerSpec } from "interfaces/db.interfaces/db-answer-spec.interface";

function OptionButtons(props: { handleAddContext: () => void; text: string }) {
  return (
    <HStack justifyContent={"flex-start"} p={2} gap={2}>
      <Button variant="contained" color="primary" startIcon={<AddBox />} onClick={props.handleAddContext}>
        {props.text}
      </Button>
    </HStack>
  );
}
interface IContextSetupProps {
  //
}

type TDragDropContext = {
  id: string;
} & TFLEContext;

export const ContextSetup = ({}: IContextSetupProps) => {
  const entities = useEntities();
  const { getValues, setValue } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  const [ddContexts, setDDContexts] = useState<TDragDropContext[]>([]);
  const [showDefaultMsg, setShowDefaultMsg] = useState(false);
  const formCtxt = useContext(FLEFormContext);

  useEffect(() => {
    const contexts = getValues("contexts") as TFLEContext[];
    if (contexts) {
      setDDContexts(contexts.map((context) => ({ ...context, id: v4() })));
    }
  }, []);

  const methods = {
    Context: {
      add: () => {
        let { contexts, answers } = getValues();
        if (!contexts) contexts = [];

        let multilangText: MultiLangText = {};
        store.sysInfo.Language.available.forEach((lang) => {
          multilangText[lang.value] = "";
        });

        const defaultContext: TFLEContext = {
          type: "system",
          alias: {
            id: "",
            value: _.cloneDeep(multilangText),
          },
          question: _.cloneDeep(multilangText),
          fallback: _.cloneDeep(multilangText),
          setting: {
            type: TSystemContextType.date,
          },
        };

        setDDContexts([...ddContexts, { ...defaultContext, id: v4() }]);
        contexts.push(defaultContext);

        //answers
        if (!answers) {
          setValue("contexts", contexts);
        } else if (!answers.conditionals) {
          setValue("contexts", contexts);
        } else {
          setValue("contexts", contexts);
        }
      },
      delete: (index: number) => {
        // TODO: Confirmation box when removing context
        store.Ask(
          "Delete this context?",
          "Caution: This is irrevertable.",
          () => {
            let contexts = getValues("contexts");
            contexts = contexts?.filter((_, i) => i !== index);
            setValue("contexts", contexts);
            setDDContexts(ddContexts.filter((_, i) => i !== index));
          },
          methods.Delete.onCancel
        );
      },
      swap: (result: DropResult) => {
        let formValue = getValues();
        const { source, destination } = result;
        if (!destination) return;
        if (source.index === destination.index) return;

        let { contexts, answers } = formValue;
        if (!contexts) return;

        //swap contexts
        const [removed] = contexts.splice(source.index, 1);
        contexts.splice(destination.index, 0, removed);
        formValue.contexts = contexts;
        //swap states
        const [removedState] = ddContexts.splice(source.index, 1);
        ddContexts.splice(destination.index, 0, removedState);
        setDDContexts(ddContexts);

        //swap answers
        if (answers && answers.conditionals) {
          let newConditionals = answers.conditionals.map((cond) => {
            let [removed] = cond.conditions.splice(source.index, 1);
            cond.conditions.splice(destination.index, 0, removed);

            return cond;
          });
          formValue.answers = { ...answers, conditionals: newConditionals };
        }

        setValue("contexts", formValue.contexts);
        setValue("answers", formValue.answers);
      },
    },
    Delete: {
      onClick: (index: number) => {
        //delete answeres related to this context
        const conditionals = getValues("answers.conditionals");
        if (!conditionals) return methods.Context.delete(index);
        const contextID = getValues(`contexts.${index}.alias.id`);

        const filteredAns = conditionals.filter((cond) => !cond.conditions.some((c) => c?.key === contextID && c.value !== "default"));

        if (filteredAns.length != conditionals.length) {
          store.Ask(
            "Caution",
            "Deleting this context will also delete the related answer. Do you want to continue?",
            () => methods.Delete.onConfirm(filteredAns, contextID, index),
            methods.Delete.onCancel
          );
        } else {
          methods.Context.delete(index);
        }
      },
      onAlert: () => {},
      onConfirm: (filteredAns: IFLEAnswer<DBAnswerSpec>[], contextID: string, ContextIndex: number) => {
        //update remaining answers condtions
        filteredAns.forEach((cond) => {
          cond.conditions = cond.conditions.filter((c) => c?.key !== contextID);
        });
        setValue("answers.conditionals", filteredAns);
        methods.Context.delete(ContextIndex);
      },
      onCancel: () => {},
    },
    DefaultMsg: {
      add: () => {
        setShowDefaultMsg(true);
      },
    },
  };
  if (!formCtxt || !entities) return <></>;

  const renderButton = () => {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <OptionButtons text="Add Context" handleAddContext={methods.Context.add} />
        </Grid>
        <Grid item>{!showDefaultMsg && !getValues("defaultMessage") && <OptionButtons text="Add Default Message" handleAddContext={methods.DefaultMsg.add} />}</Grid>
      </Grid>
    );
  };
  const renderContextList = () => {
    return (
      <DragAndDrop initItems={ddContexts} onDragEnd={methods.Context.swap} idField="id">
        {(item, index) => {
          return (
            <ContextCard
              name={`contexts.${index}`}
              key={`context-form-${index}`}
              index={index}
              entities={entities}
              onDelete={methods.Delete.onClick}
              canDelete={formCtxt.mode != "Info"}
              canEdit={formCtxt.mode != "Info"}
              expanded={index == ddContexts.length - 1}
            />
          );
        }}
      </DragAndDrop>
    );
  };

  return (
    <VStack width="100%" gap={2} justifyContent="flex-start">
      {formCtxt.mode != "Info" && renderButton()}
      {(showDefaultMsg || getValues("defaultMessage")) && <DefaultMsgCard onDelete={() => setShowDefaultMsg(false)} />}
      {renderContextList()}
    </VStack>
  );
};
