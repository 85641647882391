import { v4 } from "uuid";
import { IZChatProps } from "../interfaces";

export const defaultProps: IZChatProps = {
  cssp: " animated zchat",
  messages: [],
  quickReplies: [],
  browser: "",
  user: {
    _id: "User",
    name: "User",
    avatar: undefined,
  },
  animated: true,

  onSend: (input) => {},
  onQuickReply: (quickReply, msgID) => {},
  onMsgPress: (messageId, content) => {},
  onInputChange: (text: string) => {},
  onAvatarClick: (user: string) => {},
  onImageClick: (src: string) => {},
  onPhoneClick: (payload: string) => {},
  onWebClick: (url: string) => {
    window.open(url);
  },
  onHeaderClick: (user: string) => {},
  onCardBTNPress: async (o: { payload: { suggested: string } }) => {},
  onClearHistory: async () => {},
  onInputRenderAddOns: () => {},
  HTMLSpecialTagParser: (cssPrefix, token) => {
    return <div />;
  },

  hideLongAnswer: false,
  longAnswerLength: 300,
  readMoreCaption: "Read more...",
  revertReadMore: true,
  readLessCaption: "Read less",
  buttonWidthFitContent: true,

  pressEnterToSend: true,
  inputPlaceHolder: "Please input something here...",

  showHeader: false,
  showFooter: true,
  showStatus: false,
  showDateTime: true,
  showLapseTime: true,

  showInAvatar: false,
  showOutAvatar: false,
  hideSameAvatar: true,
  avatarAtTop: false,

  appendTextAfterSend: true,
  quickReplyBar: false,
  showQuickRepliesAsButtons: true,
  showQuickRepliesStack: true,
  disableButtonsAfterSend: true,
  disableTemplateButtonsAfterSend: false,

  canClickOnIn: false,
  canClickOnOut: false,
  HTMLEnabled: true,

  available: true,

  addOns: {},
  lang: "",

  typing: false,
};
