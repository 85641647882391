import { ChatDOMAIN } from "config/config";
import { useEffect, useState } from "react";
import { DATA_LINK } from "connectors";
import { ModelSelectHeader } from "Components/ModelSelectHeader";
import { ZCInput, ZCMQuit } from "Components/Messenger/components";
import { HStack, VStack } from "Components/Layouts";
import { EvaluationPanel } from "./EvaluationPanel";
import { Box, Typography } from "@mui/material";
import { QAMode } from "interfaces";
import { useModelOption } from "./hooks/use-model-option";
import { MiddlewareConnector } from "Pages/CombinedTalk2VA/connectors/middleware.connector";
import { v4 } from "uuid";
import { useMessages } from "Components/Messenger/hooks/use-messages";
import { MessengerLink } from "Components/Messenger/components/MessengerLink";
import ZChat from "Components/Messenger/components/ZChat/ZChat";
import store from "static/AppStore";
import { T2EResponse } from "interfaces/mw.interfaces/response.interface";

interface ArenaTabProps {
  addOns: {
    messengerCallbacks1?: {
      onSendAppend: (text: string) => void;
      onClearHistory: () => void;
    };
    messengerCallbacks2?: {
      onSendAppend: (text: string) => void;
      onClearHistory: () => void;
    };
    project?: string[];
    knowledgeGroup?: string[];
    payload1?: any;
    payload2?: any;
  };
  setAddOns: (addOns: any) => void;
}

export const ArenaChat = ({ addOns, setAddOns }: ArenaTabProps) => {
  const [sessionId1, setSessionId1] = useState<string>(v4());
  const [sessionId2, setSessionId2] = useState<string>(v4());
  const [messageId1, setMessageId1] = useState<string>(v4());
  const [messageId2, setMessageId2] = useState<string>(v4());
  const connector = new MiddlewareConnector(process.env.REACT_APP_CHATBOTDOMAIN ?? "http://localhost:5034");

  //For model select
  const [model1, setModel1] = useState<string>();
  const [model2, setModel2] = useState<string>();
  //For evaluation panel
  const [lastinput, setLastinput] = useState<string>("");
  const [panelStatus, setPanelStatus] = useState<boolean>(true);
  const { modelOption } = useModelOption();

  useEffect(() => {
    if (modelOption && modelOption.length > 1) {
      setModel1(modelOption[0].value);
      setModel2(modelOption[1].value);
    }
  }, [modelOption]);

  const STREAM_URL = `${ChatDOMAIN}${DATA_LINK.Talk2GPTStream}`;

  const usedMsg1 = useMessages({
    connector: connector,
    intermediateResponseUrl: `${ChatDOMAIN}${DATA_LINK.Talk2GPTIntermediateResponse}`,
    enableLiveChat: false,
    onMsgSent: (answer: T2EResponse, msgId: string) => {
      setAddOns((addOns: any) => ({ ...addOns, payload1: answer }));
    },
  });
  const usedMsg2 = useMessages({
    connector: connector,
    intermediateResponseUrl: `${ChatDOMAIN}${DATA_LINK.Talk2GPTIntermediateResponse}`,
    enableLiveChat: false,
    onMsgSent: (answer: T2EResponse, msgId: string) => {
      setAddOns((addOns: any) => ({ ...addOns, payload2: answer }));
    },
  });

  useEffect(() => {
    usedMsg1.stateMethod.sendWelcomeMessage(sessionId1, {
      model: model1,
      qaMode: QAMode.LLM,
      stream: true,
      streamUrl: STREAM_URL,
    });
    usedMsg2.stateMethod.sendWelcomeMessage(sessionId2, {
      model: model2,
      qaMode: QAMode.LLM,
      stream: true,
      streamUrl: STREAM_URL,
    });
  }, []);

  function renderMessenger() {
    const messengerOptions = {
      showQuickRepliesStack: false,
      showQuickRepliesAsButtons: false,
      showQuickReplyBar: false,
      hideInputbar: true,
      disableTextInput: true,
      feedbackUrl: "",
      regenerateUrl: "",
      historyUrl: `${ChatDOMAIN}${DATA_LINK.RemoveHistory}`,
    };

    return (
      <HStack width="100%" height="85%">
        <Box padding={"0px 10px"} height={"100%"} width="100%">
          <VStack sx={{ width: "100%", minHeight: "calc(100%)", height: "100%", background: "#fff" }}>
            {modelOption && model1 && <ModelSelectHeader setPanelStatus={setPanelStatus} option={modelOption} setModel={setModel1} value={model1} />}
            <MessengerLink />
            <Box className="t2va-chat" sx={{ width: "100%", height: "calc(100% - 50px)", paddingBottom: "0", pt: "0px", maxWidth: "600px" }}>
              <ZChat
                typing={usedMsg1.typing}
                messages={usedMsg1.messages}
                quickReplies={usedMsg1.quickReplies}
                detect={usedMsg1.detect}
                connector={connector}
                browser={store.browser}
                user={{ _id: 1 }}
                onMsgPress={(msgId: string, msg: any) => {
                  const record = usedMsg1.analyzerRef.current.findRecord(msgId);
                  setAddOns({ ...addOns, payload1: record.addOns });
                }}
                //methods
                {...messengerOptions}
              />
            </Box>
          </VStack>
        </Box>
        <Box padding={"0px 10px"} width="100%" height={"100%"}>
          <VStack sx={{ width: "100%", minHeight: "calc(100%)", height: "100%", background: "#fff" }}>
            {modelOption && model2 && <ModelSelectHeader setPanelStatus={setPanelStatus} option={modelOption} setModel={setModel2} value={model2} />}
            <MessengerLink />
            <Box className="t2va-chat" sx={{ width: "100%", height: "calc(100% - 50px)", paddingBottom: "0", pt: "0px", maxWidth: "600px" }}>
              <ZChat
                typing={usedMsg2.typing}
                messages={usedMsg2.messages}
                quickReplies={usedMsg2.quickReplies}
                detect={usedMsg2.detect}
                connector={connector}
                browser={store.browser}
                user={{ _id: 1 }}
                onMsgPress={(msgId: string, msg: any) => {
                  const record = usedMsg2.analyzerRef.current.findRecord(msgId);
                  setAddOns({ ...addOns, payload2: record.addOns });
                }}
                //methods
                {...messengerOptions}
              />
            </Box>
          </VStack>
        </Box>
      </HStack>
    );
  }

  ///
  const onBothSend = (payload: { text: string }) => {
    usedMsg1.stateMethod.sendText(sessionId1, payload.text, {
      payloadMeta: {
        knowledgeGroup: addOns.knowledgeGroup,
        qaMode: QAMode.LLM,
        project: addOns.project,
        model: model1,
        message_id: messageId1,
        stream: true,
        streamUrl: STREAM_URL,
      },
    });

    usedMsg2.stateMethod.sendText(sessionId2, payload.text, {
      payloadMeta: {
        knowledgeGroup: addOns.knowledgeGroup,
        qaMode: QAMode.LLM,
        project: addOns.project,
        model: model2,
        message_id: messageId2,
        stream: true,
        streamUrl: STREAM_URL,
      },
    });
    setLastinput(payload?.text);
    setPanelStatus(false);
    setMessageId1(v4());
    setMessageId2(v4());
  };
  const onBothClearHistory = () => {
    const newSessionId1 = v4();
    const newSessionId2 = v4();
    setSessionId1(newSessionId1);
    setSessionId2(newSessionId2);
    usedMsg1.stateMethod.addLineBreak();
    usedMsg2.stateMethod.addLineBreak();
    usedMsg1.stateMethod.sendWelcomeMessage(newSessionId1, {
      model: model1,
      qaMode: QAMode.LLM,
      stream: true,
      streamUrl: STREAM_URL,
    });
    usedMsg2.stateMethod.sendWelcomeMessage(newSessionId2, {
      model: model2,
      qaMode: QAMode.LLM,
      stream: true,
      streamUrl: STREAM_URL,
    });
  };

  function renderEvaluationPanel() {
    const payload = {
      input: lastinput,
      llm1: {
        sessionId: sessionId1,
        messageId: messageId1,
        prevMsg: usedMsg1.messages,
      },
      llm2: {
        sessionId: sessionId2,
        messageId: messageId2,
        prevMsg: usedMsg2.messages,
      },
    };

    return (
      <HStack width="100%" height="5%">
        <EvaluationPanel panelStatus={panelStatus} setPanelStatus={setPanelStatus} payload={payload} model1={model1} model2={model2} />
      </HStack>
    );
  }

  function renderInputBar() {
    return (
      <HStack height="5%" width="100%">
        <ZCMQuit cssp="zchat" onClearHistory={onBothClearHistory} />
        <ZCInput cssp="zchat" _onSend={onBothSend} pressEnterToSend={true} />
      </HStack>
    );
  }

  return modelOption === undefined ? null : modelOption.length > 1 ? (
    <VStack width="100%" height="100%">
      {renderMessenger()}
      {renderEvaluationPanel()}
      {renderInputBar()}
    </VStack>
  ) : (
    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
      There is only one model available. Please add more models for arena chat.
    </Typography>
  );
};
