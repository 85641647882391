import { FormatSchema, FormizoSchema } from "Components/LabIZO/Formizo";
import { TablizoSchema } from "Components/LabIZO/Tablizo";

import _ from "lodash";

const Table: TablizoSchema[] = [
  {
    label: "Image ID",
    name: "_id",
  },
  {
    label: "Name",
    name: "name",
    width: 350,
  },
  {
    label: "Path",
    name: "path",
    width: 500,
  },
  {
    label: "Date",
    name: "date",
    width: 250,
  },
];

const Add: FormizoSchema[] = [
  {
    name: "upload",
    label: "Upload file",
    format: "file",
  },
];

const Info: FormizoSchema[] = [
  {
    columns: [
      {
        page: [
          {
            label: "Image ID:",
            name: "_id",
            validate: ["required"],
            format: "text",
            readOnly: true,
          },
          {
            label: "Name",
            name: "name",
            format: "text",
            readOnly: true,
          },
          {
            label: "Path",
            name: "path",
            format: "text",
            readOnly: true,
          },
          {
            label: "Date",
            name: "date",
            format: "text",
            readOnly: true,
          },
          {
            name: "path",
            label: "Preview",
            format: "display",
            Custom: (row, field, addOns) => {
              console.log("row", row, field);
              return <img src={field} alt={"preview"} />;
            },
          },
        ],
      },
    ],
  },
];

const Filterables = [
  {
    field: "_id",
    label: "Image ID",
    type: "text",
  },
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    label: "path",
    field: "path",
    type: "text",
  },
  {
    label: "date",
    field: "date",
    type: "text",
  },
];

const schema = {
  Table,
  Info,
  Add,
  Filterables,
};

export default schema;
