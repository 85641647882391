import { useEffect, useState } from "react";
import { ErrorX, store } from "static";
import { VStack, HStack } from "Components/LabIZO/Stackizo";
import { DATA_LINK, backendConnector } from "connectors";
import Formizo from "Components/LabIZO/Formizo";
import { schema } from "./schema";
interface IModelConfig {
  parameter: object;
  id: string;
  name: string;
  model: string;
  url?: string;
}
export type IndexTimeframeType = "YYYYMM" | "YYYYMMDD";

export interface IESConvConfig {
  type: "ElasticSearch";
  username: string;
  password: string;
  url: string;
  index: string;
  indexTimeframe: IndexTimeframeType;
}

export interface ICouchConvConfig {
  type: "CouchDB";
  BASE: "http://" | "https://";
  USERNAME: string;
  PASSWORD: string;
  URL: string;
}
export type IConvDBConfig = IESConvConfig | ICouchConvConfig;

const SysModel = () => {
  const [modelOption, setModelOption] = useState<{ models: IModelConfig[]; logger: IConvDBConfig }>();

  useEffect(() => {
    getModelInfo();
  }, []);

  const handleSubmit = async (modelDocs: { models: IModelConfig[]; logger: IConvDBConfig }) => {
    try {
      const res = await backendConnector.post(DATA_LINK.SYSModelEdit, modelDocs);
      if (res.Success) {
        store.Alert("System Model updated successfully", "success");
      } else {
        store.Alert("System Model update fails.", "error");
      }
    } catch (e: any) {
      store.Alert(ErrorX.Handle(e), "error");
    }
  };

  const getModelInfo = async () => {
    const { payload } = await backendConnector.post(DATA_LINK.ArenaInfo, {});
    setModelOption(payload);
  };
  const renderModelSetting = () => {
    return (
      <Formizo
        //
        schema={schema}
        buttons={["Revert", "Submit"]}
        onSubmit={handleSubmit}
        defaultValue={modelOption}
      />
    );
  };

  return (
    <HStack justifyContent="flex-start" height="100%" gap={3} paddingX={4}>
      <VStack width="100%">{renderModelSetting()}</VStack>
    </HStack>
  );
};
export { schema };
export default SysModel;
