import * as React from "react";
import { IKnowledgeGrpManagementPayload, IDocList } from "../../interfaces/knowledge-grp-management-api.interface";
import { useFormContext } from "react-hook-form";
import { DATA_LINK } from "connectors";
import { useEffect, useState, useRef } from "react";
import { store } from "static";
import axios from "axios";
import { DOMAIN } from "config/config";
import { HStack, VStack } from "Components/Layouts";
import { Select, FormControl, TextField, MenuItem, Autocomplete, Grid, Typography, Box, Switch } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { DataGrid, GridColDef, GridToolbarFilterButton, getGridStringOperators } from "@mui/x-data-grid";
import { GridSortDirection } from "@mui/x-data-grid";

interface IProps {
  infoView?: boolean;
}

export const KnowledgeGrpDocList = ({ infoView }: IProps) => {
  const { setValue, getValues, control } = useFormContext<IKnowledgeGrpManagementPayload>();
  const [docList, setDocList] = useState<IDocList[]>([]);
  const [selectedValues, setSelectedValues] = useState<IDocList[]>(getValues("docs") || []);

  useEffect(() => {
    setSelectedValues(getValues("docs") || []);
  }, []);

  useEffect(() => {
    fetechDocList();
  }, []);

  const fetechDocList = async () => {
    let payload = {
      JWT: store.user.JWT,
    };
    let url = DOMAIN + DATA_LINK.DMList;
    const res = await axios.post(url, payload);

    const filterData = res.data?.payload?.docs.filter((item: { status: string; enabled: boolean }) => item.status !== "preparing" && item.enabled == true);
    const data = filterData.map((item: { alias: string; _id: string; knowledge_grps: { _id: string }[] }) => {
      return {
        id: item._id,
        alias: item.alias,
        enabled: selectedValues?.some((doc) => doc.id === item._id && doc.enabled === true),
      };
    });
    setDocList(data);
  };

  const handleEnableOnchange = async (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    // let docsInfo = getValues("docs");
    const updatedEnableValues = selectedValues?.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          enabled: event.target.checked,
        };
      }
      return item;
    });
    setSelectedValues(updatedEnableValues);
    setValue("docs", updatedEnableValues);
  };

  const columns: GridColDef[] = [
    { field: "alias", headerName: "Selected Document Name", flex: 1, filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains" || operator.value === "equals") },
    {
      field: "enabled",
      headerName: "Enabled",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        // const docsInfo = getValues("docs");
        const isInside = selectedValues.some((item) => item.id === params.row.id);
        return isInside && <Switch defaultChecked={params.row.enabled} onChange={(event) => handleEnableOnchange(event, params.row.id)} disabled={infoView || !isInside} />;
      },
    },
  ];

  return (
    <VStack sx={{ width: "100%" }}>
      <Box sx={{ height: 730, width: "100%" }}>
        <DataGrid
          rows={docList}
          columns={columns}
          checkboxSelection
          sortModel={[{field: "alias", sort: "desc" as GridSortDirection}]}
          onSelectionModelChange={(selectedDocIDs) => {
            const updatedSelectedValues: IDocList[] = docList.filter((row) => selectedDocIDs.includes(row.id));
            updatedSelectedValues.forEach((item) => {
              item.enabled = true;
            });
            setSelectedValues(updatedSelectedValues);
            setValue("docs", updatedSelectedValues);
          }}
          selectionModel={selectedValues.map((item) => item.id)}
          components={{
            Toolbar: GridToolbarFilterButton,
          }}
        />
      </Box>
    </VStack>
  );
};
