import { Typography } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import { ColorX } from "static";
import _ from "lodash";
import { Component } from "react";
import { ThumbUp, ThumbDownAlt } from "@mui/icons-material";
import { ConversationHistory } from "./ConversationHistory";
import { ConversationContent } from "./ConversationContent";

const Thumb = ({ like }) => {
  if (like === true) return <ThumbUp sx={{ color: "#3d6eff" }} />;
  else if (like === false) return <ThumbDownAlt sx={{ color: "red" }} />;
  else return <div>--</div>;
};

class ConvlogDetail extends Component {
  renderHeader(label, width = undefined) {
    return (
      <HStack width={width}>
        <Typography style={{ color: ColorX.GetColorCSS("Primary"), fontWeight: "bold" }}>{label && label.toUpperCase()}</Typography>
        <Spacer />
      </HStack>
    );
  }
  renderField(label, value, width = 150, fontSize = 14, maxHeight = 200, sx = {}) {
    if (_.isBoolean(value)) {
      value = value ? <Check /> : <Close />;
    }
    if (label === "Link" && typeof value === "string") {
      value = (
        <a href={value} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      );
    }
    return (
      <HStack justifyContent="flex-start" gap={1} alignItems="flex-start" style={{ flexWrap: "wrap" }} key={label}>
        <Typography style={{ color: ColorX.GetColorCSS("Primary"), width: width, fontSize: fontSize }}>{label && label.toUpperCase()}</Typography>
        <Typography
          sx={{
            color: ColorX.GetColorCSS("TableText"),
            fontSize: fontSize,
            flexWrap: "wrap",
            overflow: "auto",
            maxHeight: maxHeight,
            whiteSpace: "pre-wrap",
            wordBreak: "break-all",
            ...sx,
          }}
        >
          {value}
        </Typography>
      </HStack>
    );
  }

  renderBasic(doc) {
    return (
      <VStack width="70%" gap={2}>
        {this.renderHeader("Basic Information")}
        {this.renderField("Conversation ID", doc._id)}
        {this.renderField("Session ID", doc.session_id)}
        {this.renderField("Created At", doc.CREATED_AT)}
        {this.renderField("Knowledge Base", doc.knowledge_base)}
        {this.renderField("Question", doc.question)}
        {this.renderField("Detected Language", doc.language)}
        {this.renderField("From Follow Up", doc.is_follow_up)}
        {doc.error && this.renderField("Error", doc.error, 200, 14, 100, { color: "red" })}
      </VStack>
    );
  }

  renderUrlParams(doc) {
    return (
      <VStack width="70%" gap={2}>
        {this.renderHeader("URL parameters")}
        {Object.entries(doc.url_params).map(([key, value]) => {
          const stringifiedUrlParam = typeof value === "object" ? JSON.stringify(value) : value;
          return this.renderField(key, stringifiedUrlParam);
        })}
      </VStack>
    );
  }

  renderAnswer(doc) {
    let width = 120;
    return (
      <VStack width="70%" sapcing={2} sx={{ justifyContent: "flex-start" }}>
        {this.renderHeader("Answer")}
        {this.renderField("Answer Messsage", doc.answer)}
        {this.renderField("Answer Time", doc.CREATED_AT)}
        {this.renderField("Model", doc.model_name)}
        {this.renderField("Total Tokens", doc.usage.total_tokens)}
      </VStack>
    );
  }

  renderContent(doc) {
    return (
      <VStack width="100%" sapcing={2} sx={{ justifyContent: "flex-start" }} height={"100%"}>
        {this.renderHeader("Content")}
        <ConversationContent doc={doc} renderField={this.renderField} />
      </VStack>
    );
  }
  renderInfo(doc) {
    return (
      <HStack width="calc(100vw - 80px)" gap={2} height="auto">
        <VStack gap={2} width="45%" justifyContent="flex-start" alignItems="flex-start">
          {this.renderHeader("Conversation History")}
          <ConversationHistory doc={doc} period={this.props.period} />
          {this.renderBasic(doc)}
          {this.renderUrlParams(doc)}
          {this.renderAnswer(doc)}
          {this.renderReview(doc)}
        </VStack>
        <VStack gap={2} width="55%" justifyContent="flex-start" alignItems="flex-start">
          {this.renderContent(doc)}
        </VStack>
      </HStack>
    );
  }

  renderReview(doc) {
    return (
      <VStack width="70%" sapcing={2} sx={{ justifyContent: "flex-start" }}>
        {this.renderHeader("Review")}
        {this.renderField("Like", <Thumb like={doc.user_feedback.like} />)}
        {this.renderField("Review Time", doc.user_feedback.last_updated)}
        {this.renderField("Reviewer", doc.user_feedback.feedback_by)}
      </VStack>
    );
  }
  render() {
    const { doc } = this.props;
    if (!doc) {
      return (
        <VStack>
          <Typography>{"Conversation Log cannot be loaded"}</Typography>
        </VStack>
      );
    }
    return (
      <HStack justifyContent="flex-start" sx={{ flexWrap: "wrap", height: "auto" }}>
        <VStack
          padding={1}
          height="100%"
          sx={{
            flexWrap: "wrap",
            width: "80%",
            minWidth: "768px",
          }}
        >
          {this.renderInfo(doc)}
        </VStack>

        <VStack width={"40%"} padding={1} alignItems="flex-start" height="100%" sx={{ alignItems: "flex-start", justifyContent: "flex-start", minWidth: "300px" }}></VStack>
      </HStack>
    );
  }
}

export default ConvlogDetail;
