import { NativeSelect } from "@mui/material";
import { CustomFilterValueProps, FieldType, Filterable, TablizoSchema } from "Components/LabIZO/Tablizo";
import _ from "lodash";
import { IExportSchemaEntry } from "interfaces/be.interfaces/file-export-interface";
import { AuditActionDisplay, SYVAComponent } from "interfaces/db.interfaces/AuditLog.interface";

const Table: TablizoSchema[] = [
  {
    label: "Receive Time",
    name: "recTime",
    type: "dateTime",
    transform: "datetime",
    width: 180,
    filterable: true,
    defaultSort: "desc",
  },
  {
    label: "User Display Name",
    name: "user.userDisplayName",
    filterable: true,
    valueGetter: (_: any, cellVal: any) => cellVal || "N/A",
  },
  {
    label: "Role",
    name: "user.role",
    filterable: true,
    valueGetter: (_: any, cellVal: any) => cellVal || "N/A",
  },
  {
    label: "Component",
    name: "action.component",
    filterable: true,
  },
  {
    label: "Action Type",
    name: "action.displayType",
    filterable: true,
  },
  {
    label: "# Data Events",
    name: "data_events",
    valueGetter: (_: any, cellVal: any) => cellVal.length,
    sortable: false,
  },
  {
    label: "Successful?",
    name: "outcome.success",
    filterable: true,
    valueGetter: (_: any, cellVal: any) => (cellVal ? "true" : "false"),
    width: 120,
  },
  {
    label: "URL",
    name: "api.url",
    filterable: true,
  },
];

const Filterables: Filterable[] = [
  {
    field: "user.userDisplayName",
    label: "User Display Name",
    type: FieldType.text,
  },
  {
    field: "user.role",
    label: "Role",
    type: FieldType.text,
  },
  {
    field: "action.component",
    label: "Component",
    type: FieldType.select,
    CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => (
      <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
        <option value={""}></option>
        <option value={SYVAComponent.FAQ}>{SYVAComponent.FAQ}</option>
        <option value={SYVAComponent.FLE}>{SYVAComponent.FLE}</option>
        <option value={SYVAComponent.Intent}>{SYVAComponent.Intent}</option>
        <option value={SYVAComponent.Entity}>{SYVAComponent.Entity}</option>
        <option value={SYVAComponent.CounterExample}>{SYVAComponent.CounterExample}</option>
        <option value={SYVAComponent.Answer}>{SYVAComponent.Answer}</option>
        <option value={SYVAComponent.Talk2VATrainer}>{SYVAComponent.Talk2VATrainer}</option>
        <option value={SYVAComponent.DocumentManager}>{SYVAComponent.DocumentManager}</option>
        <option value={SYVAComponent.UserAccessControl}>{SYVAComponent.UserAccessControl}</option>
        <option value={SYVAComponent.RoleAccessControl}>{SYVAComponent.RoleAccessControl}</option>
        <option value={SYVAComponent.Training}>{SYVAComponent.Training}</option>
        <option value={SYVAComponent.ConvLog}>{SYVAComponent.ConvLog}</option>
        <option value={SYVAComponent.AuditLog}>{SYVAComponent.AuditLog}</option>
        <option value={SYVAComponent.RequestLog}>{SYVAComponent.RequestLog}</option>
        <option value={SYVAComponent.Arena}>{SYVAComponent.Arena}</option>
        <option value={SYVAComponent.SystemSetting}>{SYVAComponent.SystemSetting}</option>
        <option value={SYVAComponent.Dashboard}>{SYVAComponent.Dashboard}</option>
        <option value={SYVAComponent.StarterDashboard}>{SYVAComponent.StarterDashboard}</option>
        <option value={SYVAComponent.GPTQADashboard}>{SYVAComponent.GPTQADashboard}</option>
        <option value={SYVAComponent.System}>{SYVAComponent.System}</option>
      </NativeSelect>
    ),
  },
  {
    field: "action.displayType",
    label: "Action Type",
    type: FieldType.select,
    CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => (
      <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
        <option value={""}></option>
        <option value={AuditActionDisplay.CREATE}>{AuditActionDisplay.CREATE}</option>
        <option value={AuditActionDisplay.UPDATE}>{AuditActionDisplay.UPDATE}</option>
        <option value={AuditActionDisplay.DELETE}>{AuditActionDisplay.DELETE}</option>
        <option value={AuditActionDisplay.LIST}>{AuditActionDisplay.LIST}</option>
        <option value={AuditActionDisplay.IMPORT}>{AuditActionDisplay.IMPORT}</option>
        <option value={AuditActionDisplay.EXPORT}>{AuditActionDisplay.EXPORT}</option>
        <option value={AuditActionDisplay.UPLOAD}>{AuditActionDisplay.UPLOAD}</option>
        <option value={AuditActionDisplay.BACKUP}>{AuditActionDisplay.BACKUP}</option>
        <option value={AuditActionDisplay.RESTORE}>{AuditActionDisplay.RESTORE}</option>
        <option value={AuditActionDisplay.SYNC}>{AuditActionDisplay.SYNC}</option>
        <option value={AuditActionDisplay.ANALYSE}>{AuditActionDisplay.ANALYSE}</option>
        <option value={AuditActionDisplay.ENABLE_DISABLE}>{AuditActionDisplay.ENABLE_DISABLE}</option>
        <option value={AuditActionDisplay.INIT}>{AuditActionDisplay.INIT}</option>
        <option value={AuditActionDisplay.LOGIN}>{AuditActionDisplay.LOGIN}</option>
        <option value={AuditActionDisplay.RESET_PASSWORD}>{AuditActionDisplay.RESET_PASSWORD}</option>
        <option value={AuditActionDisplay.CHECK_USER_NAME}>{AuditActionDisplay.CHECK_USER_NAME}</option>
        <option value={AuditActionDisplay.TRAIN}>{AuditActionDisplay.TRAIN}</option>
        <option value={AuditActionDisplay.OTHER}>{AuditActionDisplay.OTHER}</option>
      </NativeSelect>
    ),
  },
  {
    field: "outcome.success",
    label: "Outcome",
    type: FieldType.select,
    CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => {
      return (
        <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
          <option value={""}></option>
          <option value={"true"}>Success</option>
          <option value={"false"}>Fail</option>
        </NativeSelect>
      );
    },
  },
];

const Export: IExportSchemaEntry[] = [
  {
    Header: "Receive Time",
    accessor: "recTime",
    format: "datetime",
  },
  {
    Header: "ID",
    accessor: "_id",
  },
  {
    Header: "IP",
    accessor: "api.ip",
  },
  {
    Header: "User Display Name",
    accessor: "user.userDisplayName",
  },
  {
    Header: "User Role",
    accessor: "user.role",
  },
  {
    Header: "Component",
    accessor: "action.component",
  },
  {
    Header: "Action Type",
    accessor: "action.displayType",
  },
  {
    Header: "Successful?",
    accessor: "outcome.success",
  },
  {
    Header: "URL",
    accessor: "api.url",
  },
  {
    Header: "Remarks",
    accessor: "outcome.message",
  },
];

const AdvFilter: any = [];

const schema: any = {
  Table,
  Export,
  AdvFilter,
  Filterables,
};

export default schema;
