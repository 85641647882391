import { Typography } from "@mui/material";
import { TablizoCustom } from "Components/LabIZO/Tablizo";

export const TABLE_SCHEMA: TablizoCustom = (tbLang) => [
  {
    label: "Alias",
    name: "alias",
  },
  {
    label: "Flow Description",
    name: "description",
  },
  {
    label: "API integration",
    name: "integrations.api.enabled",
    Cell: (row, field, addOns) => {
      return <Typography variant="body2">{field ? "Yes" : "No"}</Typography>;
    },
  },
  {
    label: "Default Answer",
    name: "answers.defaultAnsDoc._id",
  },
  {
    label: `Created Time`,
    name: `CREATED_AT`,
    filterable: false,
  },
  {
    label: `Last Updated Time`,
    name: `LASTMODIFIED`,
    filterable: false,
  },
];
