import { FormizoSchema } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import _ from "lodash";
import { ConfigAnswer } from "../SysGeneralSetting/schema";

const messages: FormizoSchema[] = [
  {
    label: "Captcha Messages",
    foldStyle: "outlined",
    defaultShow: true,
    collapse: [
      {
        label: "Captcha message",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [ConfigAnswer("captchaConfig.captchaMessage")],
      },
      {
        label: "Captcha confirmation message",
        foldStyle: "outlined",
        defaultShow: true,
        collapse: [ConfigAnswer("captchaConfig.captchaSuccess")],
      },
    ],
  },
];

const config: FormizoSchema[] = [
  {
    label: "Captcha Config",
    foldStyle: "outlined",
    defaultShow: true,
    collapse: [
      {
        label: "Captcha appear per messages",
        name: "captchaConfig.captchaAppearPer",
        format: "number",
      },
      {
        label: "Captcha length",
        name: "captchaConfig.captchaLength",
        format: "number",
      },
    ],
  },
];

export const schema: FormizoSchema[] = [...config, ...messages];
