import React, { useEffect, useState } from "react";
import axios from "axios";
import { DOMAIN } from "config/config";
import { store, ErrorX } from "static";
import { ScatterBoard } from "Components/ScatterChart";
import { HStack, VStack } from "Components/Layouts";

const IScatterBoard = () => {
  const [chartData, setData] = useState();
  const getData = async () => {
    let payload = {
      JWT: store.user.JWT,
    };
    try {
      const postUrl = DOMAIN + "/Tables/DocumentManager/Chart";
      console.log(postUrl);
      const { data } = await axios.post(postUrl, payload);
      if (!data.Success) ErrorX.Handle(data);
      setData(data.payload.docs);
    } catch (e) {
      store.Alert("Cannot get chart info", "error");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const renderChart = () => {
    return <ScatterBoard data={chartData} colorKey="filename" labelKeys={["filename", "text"]} searchKeys={["filename", "text"]} is3d={true} />;
  };
  return <HStack width={"100vw"}>{chartData && renderChart()}</HStack>;
};

export default IScatterBoard;
