import React, { Component } from "react";

import PropsType from "prop-types";
import _ from "lodash";
import { Box } from "@mui/material";

import FField from "./FField";
import { FGAccess, FGInline, FGArray, FGFold, FGCollapse, FGTabs, FGColumns } from "./_groups";

import { Accessor } from "static";

class FItem extends Component {
  static propTypes = {
    //data
    ischema: PropsType.oneOfType([PropsType.object.isRequired, PropsType.func.isRequired]),
    preAccessor: PropsType.string.isRequired,
    addOns: PropsType.object.isRequired,

    //function
    _onValueChange: PropsType.func.isRequired,
    _onBlurInlineSubmit: PropsType.func.isRequired,
    _onInlineSubmit: PropsType.func.isRequired,
    _onInlineRevert: PropsType.func.isRequired,
    _setHiddenValue: PropsType.func.isRequired,
    _Validate: PropsType.func.isRequired,

    //runtime
    formValue: PropsType.object,
    formError: PropsType.object.isRequired,

    //access
    auth: PropsType.object.isRequired,
    level: PropsType.number.isRequired,

    //controls
    enableInlineSubmit: PropsType.bool.isRequired,
    errorsShowOnHelperText: PropsType.bool.isRequired,
    readOnly: PropsType.bool.isRequired,

    //style
    fieldStyle: PropsType.oneOf(["grid", "standard", "filled", "outlined"]).isRequired,

    //grid specific
    labelXS: PropsType.number.isRequired,
    labelPaddingX: PropsType.number.isRequired,
    labelJustify: PropsType.string.isRequired,
    fieldXS: PropsType.number.isRequired,
    fieldPaddingX: PropsType.number.isRequired,
    separator: PropsType.string.isRequired,

    //input style
    fieldSize: PropsType.string,
    theme: PropsType.object,
  };

  static defaultProps = {
    ischema: {},
    preAccessor: "",
    addOns: {},

    _onValueChange: () => {},
    _onBlurInlineSubmit: () => {},
    _onInlineSubmit: () => {},
    _onInlineRevert: () => {},
    _setHiddenValue: () => {},
    _Validate: () => {},

    formValue: {},
    formError: {},

    auth: {},
    level: 999,

    enableInlineSubmit: false,
    errorsShowOnHelperText: true,
    readOnly: false,

    fieldStyle: "grid",

    fieldSize: "normal",
    theme: {},
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(FItem.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  getSchema = () => {
    let { ischema, formValue, addOns } = this.props;
    if (_.isFunction(ischema)) {
      return ischema(formValue, addOns);
    }
    return ischema;
  };

  renderHeader(ischema) {
    let { textAlign, fontSize, fontWeight, color } = ischema;
    return (
      <Box textAlign={textAlign || "center"} fontSize={fontSize || 16} fontWeight={fontWeight || "bold"} color={color || "black"}>
        {ischema.header}
      </Box>
    );
  }

  renderInject(ischema) {
    let { formValue, addOns } = this.props;
    if (_.isFunction(ischema.inject)) {
      return ischema.inject(formValue, addOns);
    }
    return ischema.inject;
  }

  renderAccessizo(ischema) {
    return <FGAccess key="accessizo" {...this.props} ischema={ischema} />;
  }

  renderArray(ischema) {
    return <FGArray key="array" {...this.props} ischema={ischema} />;
  }

  renderInline(ischema) {
    return <FGInline key="inline" {...this.props} ischema={ischema} />;
  }

  renderFold(ischema) {
    return <FGFold key="fold" {...this.props} ischema={ischema} />;
  }

  renderCollapse(ischema) {
    return <FGCollapse key="collapse" {...this.props} ischema={ischema} />;
  }

  renderTabs(ischema) {
    return <FGTabs key="tabs" {...this.props} ischema={ischema} />;
  }

  renderField(ischema) {
    let { preAccessor } = this.state;
    let iname = ischema.name;
    if (!_.isEmpty(preAccessor)) {
      if (!_.isEmpty(ischema.name)) {
        iname = preAccessor + "." + ischema.name;
      } else {
        iname = preAccessor;
      }
    }
    return <FField key={0} iname={iname} {...this.props} ischema={ischema} />;
  }

  renderColumns(ischema) {
    return <FGColumns key="columns" {...this.props} ischema={ischema} />;
  }

  renderItem(ischema) {
    if (ischema.header) {
      return this.renderHeader(ischema);
    }

    if (ischema.inject) {
      return this.renderInject(ischema);
    }

    if (ischema.accessizo) {
      return this.renderAccessizo(ischema);
    }

    if (ischema.array) {
      return this.renderArray(ischema);
    }

    if (ischema.inline) {
      return this.renderInline(ischema);
    }

    if (ischema.fold) {
      return this.renderFold(ischema);
    }

    if (ischema.collapse) {
      return this.renderCollapse(ischema);
    }

    if (ischema.tabs) {
      return this.renderTabs(ischema);
    }

    if (ischema.columns) {
      return this.renderColumns(ischema);
    }

    return this.renderField(ischema);
  }

  render() {
    if (!this.state || !this.state.ischema) return null;
    const { formValue } = this.props;

    let ischema = this.getSchema();
    if (ischema.optional && !Accessor.Get(formValue, ischema.name) && Accessor.Get(formValue, ischema.name) !== 0) {
      return <></>;
    }
    return (
      <Box marginY={0.75} lineHeight={"30px"} width="100%" height={ischema.fitemHeight}>
        {this.renderItem(ischema)}
      </Box>
    );
  }
}

export default FItem;
