import React, { Component } from "react";

import PropsType from "prop-types";
import { Input } from "@mui/material";

import MUIUtils from "./_gears/MUIUtils";

import { Accessor, ColorX } from "static";

const styles = {
  root: {
    "&:hover:before": {
      borderBottomColor: MUIUtils.getImportantMappedProps(ColorX.GetColorCSS, "theme.lineHover", "theme.line", "theme.input"),
    },
    "&:before": {
      borderBottomColor: MUIUtils.getMappedProps(ColorX.GetColorCSS, "theme.lineBefore", "theme.line", "theme.input"),
    },
    "&:after": {
      borderBottomColor: MUIUtils.getMappedProps(ColorX.GetColorCSS, "theme.lineAfter", "theme.line", "theme.input"),
    },
    color: MUIUtils.getMappedProps(ColorX.GetColorCSS, "theme.text", "theme.input"),
    backgroundColor: MUIUtils.getMappedProps(ColorX.GetColorCSS, "theme.background"),
  },
};

class StyledInput extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { theme, classes, ...other } = this.props;
    return <Input sx={{ ...styles, ...theme }} {...other} />;
  }
}

export default StyledInput;
