import { Link, styled } from "@mui/material";
import { HStack, VStack } from "Components";
import ZCMsg from "Components/Messenger/components/ZChat/ZCMsg/ZCMsg";
import { backendConnector, DATA_LINK } from "connectors";
import { useEffect, useState } from "react";
import { v1 } from "uuid";
import { Accessor, Authority, ColorX } from "static";

const StyledVstack = styled(VStack)({
  border: "1px solid " + ColorX.GetColorCSS("Primary", 0.2),
  overflow: "scroll",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  height: "400px",
  width: "100%",
  padding: 2,
});

interface ConversationHistoryProps {
  doc: any;
  period: any;
}

export function ConversationHistory({ doc, period }: ConversationHistoryProps) {
  const [history, setHistory] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const opacity = 1;
  const eachPage = 3;
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (doc?.sessionID) getHistory(doc.sessionID);
  }, [doc.sessionID, page]);
  async function getHistory(sessionID: string) {
    const res = await backendConnector.post(
      DATA_LINK.ConvlogList,
      {
        selector: {
          $and: [
            {
              sessionID: {
                $eq: sessionID,
              },
            },
          ],
        },
        sort: [
          {
            recTime: "asc",
          },
        ],
        limit: eachPage,
        skip: page * eachPage,
      },
      period
    );

    if (total == 0) setTotal(res?.payload?.total);
    setHistory(res?.payload?.docs);
  }

  const onNextPage = (e: any) => {
    setPage((page) => page + 1);
  };

  const onPreviousPage = (e: any) => {
    setPage((page) => Math.max(0, page - 1));
  };
  if (!doc?.sessionID) return <></>;
  return (
    <StyledVstack>
      {history.map((o) => {
        console.log(o);
        const lang = o.lang;
        const input = {
          msg: {
            text: o.Response[0].msg.text || o.Response[0].msg?.fallback[lang][0],
          },
          user: {},
        };

        const output = {
          msg: {
            text: o.Input.Content,
          },
          user: {},
        };

        // Show border if the conversation log is selected
        const borderWidth = o._id === doc._id ? 2 : 0;
        return (
          <VStack
            sx={{
              width: "100%",
              border: `${borderWidth}px solid`,
              borderImage: "linear-gradient(to bottom right, rgba(0, 95, 115, 0.3) , rgba(0, 95, 115, 1))",
              borderImageSlice: 1,
            }}
            key={v1()}
          >
            <ZCMsg
              message_id=""
              cssp="zchat z"
              key={v1()}
              _onQuickReply={() => {}}
              pos={"out"}
              item={output}
              last={true}
              typingBubbles={false}
              showQuickRepliesAsButtons={true}
              HTMLEnabled={true}
              opacity={opacity}
            />
            <ZCMsg
              message_id=""
              cssp="zchat z"
              key={v1()}
              _onQuickReply={() => {}}
              pos={"in"}
              item={input}
              last={true}
              typingBubbles={false}
              showQuickRepliesAsButtons={true}
              HTMLEnabled={true}
              opacity={opacity}
            />
          </VStack>
        );
      })}
      <HStack sx={{ marginTop: "auto" }}>
        {page > 0 && (
          <Link component="button" variant="body2" sx={{ marginX: "20px" }} onClick={onPreviousPage}>
            Previous Page
          </Link>
        )}
        {page < total / eachPage - 1 && (
          <Link component="button" variant="body2" sx={{ marginX: "20px" }} onClick={onNextPage}>
            Next Page
          </Link>
        )}
      </HStack>
    </StyledVstack>
  );
}
