import { defaultProps } from "./defaultProps";
import { Accessor, Env, ErrorX, store, ColorX } from "static";
import { VStack, HStack, Spacer } from "Components/LabIZO/Stackizo";
import { Typography, Box } from "@mui/material";
import Datumizo from "Components/LabIZO/Datumizo";
import { useState, useCallback } from "react";

const SysRole = () => {
  const { title, base } = defaultProps;
  const [MountDatumizo, setMountDatumizo] = useState<any>(false);
  const [data, setData] = useState<any>(false);
  function onMountDatumizo(callback: any) {
    setMountDatumizo(callback);
  }

  async function onQuitInner() {
    await MountDatumizo?.Reload(true);
  }
  return (
    <VStack height="100%" justifyContent="flex-start">
      <Box padding={1} width="100%">
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 25,
            color: ColorX.GetColorCSS("Primary"),
          }}
        >
          {title}
        </Typography>
      </Box>

      <Datumizo
        //
        base={base}
        addOns={{ workspace: "master", data }}
        languageSelector={false}
        onMounted={onMountDatumizo}
        onQuitInner={onQuitInner}
        serverSidePagination={false}
      ></Datumizo>
    </VStack>
  );
};
export default SysRole;
