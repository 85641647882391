import { Box, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridOverlay, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport } from "@mui/x-data-grid";
import _ from "lodash";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import { StyledIconButton, StyledLinearProgress } from "Components/LabIZO/Stylizo";
import { Accessor, Authority, ColorX, store } from "static";
import moment from "moment";
import PropsType from "prop-types";
import { Component } from "react";
import CellExpand from "./_gears/CellExpand";
import MultiFilter from "./_gears/MultiFilter";
import { ColumnHtmlParser } from "Components/HtmlParserWrapper";
import { DOMAIN } from "config/config";
import axios from "axios";
/**
 * [Props]{@tutorial Tablizo}
 * @see [schema]{@tutorial Tablizo-schema}
 * @augments {Component<Props, State>}
 */
class Tablizo extends Component {
  static propTypes = {
    //container
    height: PropsType.oneOfType([PropsType.number, PropsType.string]),
    width: PropsType.oneOfType([PropsType.number, PropsType.string]),

    //function
    onMounted: PropsType.func,

    //runtime data
    schema: PropsType.array,
    data: PropsType.array,
    loading: PropsType.bool,

    //inline operation
    inlineButtons: PropsType.array,
    inlineButtonsAlign: PropsType.string,
    inlineButtonsOpposite: PropsType.array,

    //listener
    onRowSelected: PropsType.func,
    onSortChange: PropsType.func,

    //selector
    showSelector: PropsType.bool,
    rowIdAccessor: PropsType.string,
    selectionOnClick: PropsType.bool,

    //pagination
    pagination: PropsType.bool,
    serverSidePagination: PropsType.bool,
    rowCount: PropsType.number,
    onPageChange: PropsType.func,
    onPageSizeChange: PropsType.func,

    //no data overlay
    noRowsOverlay: PropsType.element,

    //pagesize
    defaultPageSize: PropsType.number,
    pageSizeOption: PropsType.arrayOf(PropsType.number),

    //authority
    auth: PropsType.object,
    level: PropsType.number,

    //addOns
    addOns: PropsType.object,

    //preset toolbar
    columnsToolbar: PropsType.bool,
    densityToolbar: PropsType.bool,
    exportToolbar: PropsType.bool,

    //preset
    density: PropsType.oneOf(["compact", "comfortable", "standard"]),

    //mutli filter
    Filterables: PropsType.array,
    DefaultFilter: PropsType.object,
    onFilterChange: PropsType.func,
    activeSelector: PropsType.object,

    datagridProps: PropsType.object,

    reqAuth: PropsType.string,
  };

  static defaultProps = {
    height: "500px",
    width: "100%",

    onMounted: undefined,

    schema: [],
    data: [],
    loading: false,

    inlineButtons: [],
    inlineButtonsAlign: "left",
    inlineButtonsOpposite: [],

    showSelector: true,
    rowIdAccessor: "_id",
    selectionOnClick: false,

    pagination: true,
    serverSidePagination: false,
    rowCount: undefined,
    onPageChange: () => {},
    onPageSizeChange: () => {},

    noRowsOverlay: undefined,

    defaultPageSize: 50,
    pageSizeOption: [25, 50, 100],

    auth: {},
    level: 999,

    addOns: {},

    columnsToolbar: false,
    densityToolbar: false,
    exportToolbar: false,

    density: "standard",

    store: {},
    datagridProps: {},
  };

  constructor() {
    super();
    this.state = {
      selectedRows: [],
      columnVisibilityModel: {},
    };
  }

  componentDidMount() {
    this.getColumnVisibilitySettings();
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Tablizo.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      () => {
        if (this.props.onMounted) {
          this.props.onMounted({
            GetSelectedRows: this._GetSelectedRows,
            ClearSelected: this._ClearSelected,
            SetSelectedRows: this._SetSelectedRows,
          });
        }
        if (callback) callback();
      }
    );
  };

  _onRowSelected = (params) => {
    let { onRowSelected } = this.props;
    this.setState(
      {
        selectedRows: params,
      },
      () => {
        if (onRowSelected) {
          onRowSelected(params.length);
        }
      }
    );
  };

  _ClearSelected = () => {
    let { onRowSelected } = this.props;
    this.setState(
      {
        selectedRows: [],
      },
      () => {
        if (onRowSelected) {
          onRowSelected(0);
        }
      }
    );
  };

  _onFilterChange = (params) => {
    let { onFilterChange } = this.props;
    this.setState(
      {
        filterModel: params,
      },
      () => {
        if (onFilterChange) {
          onFilterChange();
        }
      }
    );
  };

  _SetSelectedRows = (selectedRows) => {
    this.setState({
      selectedRows: selectedRows,
    });
  };

  _GetSelectedRows = (includeDocs = false) => {
    let { data, rowIdAccessor } = this.props;
    let { selectedRows } = this.state;
    if (includeDocs) {
      return _.filter(data, (o) => selectedRows.includes(Accessor.Get(o, rowIdAccessor)));
    }
    return selectedRows;
  };

  _onPageChange = (page) => {
    let { onPageChange } = this.props;
    if (onPageChange) {
      onPageChange(page);
    }
  };

  _onPageSizeChange = (param) => {
    let { onPageSizeChange } = this.props;
    if (onPageSizeChange) {
      onPageSizeChange(param);
    }
  };

  _onColumnHeaderClick = (params) => {
    const field = params.field;
    const schema = this.getSchema();
    const colSchema = schema.find((col) => col.name === field);
    if (colSchema.sortable !== false) {
      params = colSchema.coerceNumericType !== undefined ? { ...params, coerceNumericType: colSchema.coerceNumericType } : params;
      this.props.onSortChange(params);
    }
  };

  _onColumnVisibilityModelChange = (model, details) => {
    console.log("colChanged", model);
    this.setState(
      () => ({
        columnVisibilityModel: model,
      }),
      () => {
        this.setColumnVisibilitySettings(model);
      }
    );
  };
  getColumnVisibilitySettings = async () => {
    console.log("get column vis");
    if (!this.props.reqAuth) {
      return;
    }
    let url = `${DOMAIN}/Tables/${this.props.reqAuth}/GetColumnVisibilitySetting`;
    let payloadOut = {
      JWT: store.user.JWT,
    };
    try {
      console.log("get column vis", url, payloadOut);
      let res = await axios.post(url, payloadOut);
      let { Success, payload } = res.data;
      if (Success && payload) {
        let column = Accessor.Get(payload, `${this.props.reqAuth}`);
        if (column) {
          this.setState(() => ({
            columnVisibilityModel: column,
          }));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  setColumnVisibilitySettings = async (model) => {
    console.log("set column vis", model);
    if (!this.props.reqAuth) {
      return;
    }
    const key = `${this.props.reqAuth}`;
    let url = `${DOMAIN}/Tables/${this.props.reqAuth}/SetColumnVisibilitySetting`;
    let payloadOut = {
      JWT: store.user.JWT,
      data: {
        [key]: model,
      },
    };
    try {
      let res = await axios.post(url, payloadOut);
      console.log("set column vis", res.data.Success);
    } catch (e) {
      console.error(e);
    }
  };

  _defaultButtons = (buttons) => {
    let { auth, level, rowIdAccessor } = this.props;
    let btns = [];
    _.map(buttons, (o, i) => {
      if (Authority.IsAccessible(auth, level, o.reqAuth, o.reqLevel, o.reqFunc)) {
        btns.push({
          headerName: "",
          renderHeader: () => <div />,
          field: "<Button> " + o.caption,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          disableClickEventBubbling: true,
          alignment: "center",
          width: 50,
          renderCell: (param) => {
            // TODO: add html parser
            return (
              <HStack>
                <StyledIconButton
                  id={Accessor.Get(param.row, rowIdAccessor).replace(":", "-") + "-" + o.caption}
                  theme={o.theme || { label: ColorX.GetColorCSS("TablizoLabel", 0.54) }}
                  disabled={o.disableFunc && o.disableFunc(Accessor.Get(param.row, rowIdAccessor), param.row)}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (o.func) {
                      o.func(Accessor.Get(param.row, rowIdAccessor), param.row);
                    } else {
                      store && store.Alert("Function is not implemented.", "warning");
                    }
                  }}
                >
                  <Tooltip title={o.caption} arrow={true} placement="top">
                    {o.icon}
                  </Tooltip>
                </StyledIconButton>
              </HStack>
            );
          },
        });
      }
    });

    return btns;
  };

  getSchema = () => {
    let { schema, data, addOns } = this.props;
    if (_.isFunction(schema)) {
      return schema(data, addOns);
    }
    return schema;
  };

  getColumns = () => {
    let { auth, level, addOns, inlineButtons, inlineButtonsOpposite, inlineButtonsAlign, selectionOnClick, fetchData } = this.props;
    let schema = this.getSchema();
    let cols = _.map(schema, (o, i) => {
      if (Authority.IsAccessible(auth, level, o.reqAuth, o.reqLevel, o.reqFunc)) {
        let renderCell;

        if (!o.Cell && o.transform === "datetime") {
          o.transform = undefined;
          o.Cell = (row, field, addOns) => {
            if (field) {
              return moment(field).format(o.dateFormat || "DD MMM YYYY, HH:mm:ss");
            } else {
              return o.fallback || "N/A";
            }
          };
        }

        if (o.Cell) {
          renderCell = (param) => o.Cell(param.row, Accessor.Get(param.row, o.name), addOns, fetchData);
        } else {
          renderCell = (param) => <CellExpand value={<ColumnHtmlParser html={param.value} />} width={param.colDef.width || param.colDef.computedWidth} />;
        }

        let renderHeader = undefined;
        let headerName = undefined;
        if (_.isString(o.label)) {
          headerName = o.label;
        } else {
          renderHeader = () => o.label;
        }

        let cellClassName = undefined;
        if (_.isFunction(o.cellClass)) {
          cellClassName = (param) => o.cellClass(param.row, Accessor.Get(param.row, o.name), addOns);
        } else if (_.isString(o.cellClass)) {
          cellClassName = o.cellClass;
        }

        let sortComparator = undefined;
        if (_.isFunction(o.sortComparator)) {
          sortComparator = (v1, v2, param1, param2) => {
            return o.sortComparator(param1.row, param2.row, Accessor.Get(param1.row, o.name), Accessor.Get(param2.row, o.name));
          };
        }

        let valueGetter = (param) => {
          let htmlString = (Accessor.Get(param.row, o.name) || "").toString();
          return htmlString;
        };

        if (o.valueGetter) {
          valueGetter = (param) => {
            return o.valueGetter(param.row, Accessor.Get(param.row, o.name), addOns);
          };
        }

        let rtn = {
          headerName: headerName,
          renderHeader: renderHeader,
          headerAlign: o.headerAlign || "center",
          headerClassName: o.headerClass,
          field: o.customFieldName || o.name,
          width: o.width,
          flex: o.width ? undefined : o.flex || 1,
          valueGetter: valueGetter,
          sortable: o.sortable !== false,
          filterable: o.filterable !== false,
          disableColumnMenu: !(o.menu || false),
          type: o.type,
          renderCell: renderCell,
          cellClassName: cellClassName,
          description: o.description,
          autoHeight: o.autoHeight || false,
          disableClickEventBubbling: !selectionOnClick,
          hide: o.hide,
        };

        if (sortComparator) rtn.sortComparator = sortComparator;
        return rtn;
      }
    });
    cols = _.filter(cols, (o) => o);

    //Inline Buttons
    let btns = this._defaultButtons(inlineButtons);

    //Opposite Inline Buttons
    let oppositeBtns = this._defaultButtons(inlineButtonsOpposite);

    let rtn = [];

    if (inlineButtonsAlign === "left") {
      rtn = [...btns, ...cols, ...oppositeBtns];
    } else {
      rtn = [...oppositeBtns, ...cols, ...btns];
    }

    return rtn;
  };

  getSortModel = () => {
    let { auth, level } = this.props;
    let schema = this.getSchema();
    let sortModel = _.map(schema, (o, i) => {
      if (Authority.IsAccessible(auth, level, o.reqAuth, o.reqLevel, o.reqFunc)) {
        if (o.defaultSort) {
          return {
            field: o.name,
            sort: o.defaultSort,
          };
        }
      }
    });
    sortModel = _.filter(sortModel, (o) => o);
    return sortModel;
  };

  CustomToolbar = () => {
    let { columnsToolbar, densityToolbar, exportToolbar, Filterables, onFilterChanged, DefaultFilter } = this.props;
    return (
      <GridToolbarContainer>
        {columnsToolbar && <GridToolbarColumnsButton />}
        {Filterables && <MultiFilter _onFilterChange={onFilterChanged} filterables={Filterables} DefaultFilter={DefaultFilter} activeSelector={this.props.activeSelector} />}
        {densityToolbar && <GridToolbarDensitySelector />}
        {exportToolbar && <GridToolbarExport />}
      </GridToolbarContainer>
    );
  };

  GridLoadingOverlay = () => {
    return (
      <GridOverlay>
        <div style={{ position: "absolute", top: 0, width: "100%" }}>
          <StyledLinearProgress theme={{ bar: ColorX.GetColorCSS("Primary"), background: ColorX.GetColorCSS("Primary2") }} />
        </div>
      </GridOverlay>
    );
  };

  GridNoRowsOverlay = () => {
    return (
      <VStack>
        <Spacer />
        <Box width="250px">
          <img src={`${process.env.PUBLIC_URL}/Images/data-not-found.svg`} alt="nodata" />
        </Box>
        <Typography>Data not found</Typography>
        <Spacer />
      </VStack>
    );
  };

  handleGridKeyDown = (params, event, details) => {
    //Handle Ctrl+A with popup open
    let { isPopupOpen } = this.props;
    if (isPopupOpen && event.ctrlKey && event.key === "a") {
      //UI Error: stopImmediatePropagation  is not a function
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  };

  render() {
    let {
      height,
      width,
      data,
      showSelector,
      rowIdAccessor,
      pagination,
      defaultPageSize,
      pageSizeOption,
      loading,
      rowCount,
      serverSidePagination,
      density,
      selectionOnClick,
      datagridProps,
      sortModel,
    } = this.props;
    let { filterModel, selectedRows } = this.state;
    const cellSx = {
      borderBottom: "1px solid",
      borderColor: "grey.200",
      maxHeight: "fit-content",
      // overflow: "auto",
      wordWrap: "break-word",
      whiteSpace: "initial",
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
    };
    return (
      <Box height={height} width={width} overflow={"hidden"} sx={{ background: ColorX.GetColorCSS("TableBackground") }}>
        <DataGrid
          rows={data}
          disableSelectionOnClick={!selectionOnClick}
          columns={this.getColumns()}
          checkboxSelection={showSelector}
          onSelectionModelChange={this._onRowSelected}
          onFilterModelChange={this._onFilterChange}
          onColumnHeaderClick={this._onColumnHeaderClick}
          getRowId={(o) => Accessor.Get(o, rowIdAccessor)}
          pageSize={defaultPageSize}
          rowsPerPageOptions={pageSizeOption}
          pagination={pagination}
          loading={loading}
          components={{
            Toolbar: this.CustomToolbar,
            LoadingOverlay: this.GridLoadingOverlay,
            NoRowsOverlay: this.GridNoRowsOverlay,
          }}
          paginationMode={serverSidePagination ? "server" : "client"}
          onPageChange={this._onPageChange}
          onPageSizeChange={this._onPageSizeChange}
          onColumnVisibilityModelChange={this._onColumnVisibilityModelChange}
          columnVisibilityModel={this.state.columnVisibilityModel}
          rowCount={rowCount}
          density={density}
          selectionModel={selectedRows || []}
          sortingMode={"client"}
          sortModel={sortModel}
          filterModel={filterModel}
          disableColumnReorder={true}
          sx={{
            border: 1,
            borderColor: "grey.200",
            "& .MuiDataGrid-columnHeader": {
              ...cellSx,
            },
            "& .MuiDataGrid-cell": {
              ...cellSx,
            },
            ".MuiDataGrid-viewport, .MuiDataGrid-row,.MuiDataGrid-renderingZone": {
              maxHeight: " fit-content",
            },
            ".MuiTablePagination-toolbar": {
              alignItems: "baseline",
            },
          }}
          {...datagridProps}
          onCellKeyDown={this.handleGridKeyDown}
        />
      </Box>
    );
  }
}

export default Tablizo;
